import { AddCircleOutline, Delete } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBase, ModalOpcoes, TableNavigation, TableRowLoading, TableRowNotFound, useResponseSnackbar, InputTime } from 'src/ds';
import { excluirPorDiaDaSemanaStart, limparSalvarPorDiaDaSemana, listarPorDiaDaSemanaStart, salvarPorDiaDaSemanaStart } from 'src/store/reducers/taxaExtra';

interface AgendamentoHorarioProps {
    data: any;
}

const AgendamentoHorario = ({ data }: AgendamentoHorarioProps) => {
    const [showSelector, setShowSelector] = React.useState(false);
    const [openDeletar, setOpenDeletar] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState<any>(null);
    const response = useSelector((state: any) => state.taxaExtra.listarPorDiaDaSemana);
    const response2 = useSelector((state: any) => state.taxaExtra.excluirPorDiaDaSemana);
    const response3 = useSelector((state: any) => state.taxaExtra.salvarPorDiaDaSemana);
    const loadingSalvar = response3?.data?.loading;

    useResponseSnackbar([
        { response: response3, clearAction: () => dispatch(limparSalvarPorDiaDaSemana()) }
    ]);

    const dadosLista = response.data?.dados;
    const loading = response?.loading;


    const hookForm = useForm({
        defaultValues: {
            taxaExtraDiaSemanaID: '',
            taxaExtraID: data?.taxaExtraID,
            diaSemana: '',
            horaInicial: '',
            horaFinal: '',
            pagina: 1,
            paginacao: 20
        }
    });
    const { control, formState: { errors } } = hookForm;
    const dispatch = useDispatch();
    const listRef = useRef({
        listar: () => {
            dispatch(listarPorDiaDaSemanaStart({
                pagina: hookForm.watch("pagina") || 1,
                paginacao: hookForm.watch("paginacao") || 20,
                filtros: `taxaExtraID.toString() = "${data?.taxaExtraID}"`,
            }));
        }
    });

    useEffect(() => {
        listRef.current.listar();
    }, []);
    const handleSave = () => {
        dispatch(salvarPorDiaDaSemanaStart(hookForm.getValues()))
        setShowSelector(false);
        listRef.current.listar();
    }

    useEffect(() => {
        const subscription = hookForm.watch((value, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                listRef.current.listar();
            }
        });

        return () => subscription.unsubscribe();
    }, [hookForm]);

    const sanitizeDiaSemana = (diaSemana: string): string => {
        const diasSemana: { [key: string]: string } = {
            '1': 'Domingo',
            '2': 'Segunda-feira',
            '3': 'Terça-feira',
            '4': 'Quarta-feira',
            '5': 'Quinta-feira',
            '6': 'Sexta-feira',
            '7': 'Sábado'
        };

        return diasSemana[diaSemana] || 'Dia inválido';
    };


    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Button
                variant="containedFilled"
                sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                onClick={() => setShowSelector(true)}
                startIcon={<AddCircleOutline />}
            >
                Adicionar novo agendamento
            </Button>


            <ModalBase onClose={() => setShowSelector(false)} open={showSelector} headerModal={{ title: 'Adicionar novo agendamento' }} size='sm'>
                <Box component={'form'} onSubmit={hookForm.handleSubmit(handleSave)}>
                    <Stack spacing={2} p={2}>
                        <FormControl size={'small'}>
                            <InputLabel>Dia da semana</InputLabel>
                            <Controller
                                name={'diaSemana'}
                                control={control}
                                rules={{ required: 'Dia da semana obrigatório' }}
                                render={({ field }) => (
                                    <Select
                                        label={'Dia da semana'}
                                        size="small"
                                        {...field}
                                    >
                                        <MenuItem value={'1'}>{'Domingo'}</MenuItem>
                                        <MenuItem value={'2'}>{'Segunda-feira'}</MenuItem>
                                        <MenuItem value={'3'}>{'Terça-feira'}</MenuItem>
                                        <MenuItem value={'4'}>{'Quarta-feira'}</MenuItem>
                                        <MenuItem value={'5'}>{'Quinta-feira'}</MenuItem>
                                        <MenuItem value={'6'}>{'Sexta-feira'}</MenuItem>
                                        <MenuItem value={'7'}>{'Sabado'}</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>

                        <InputTime
                            hookForm={{
                                name: 'horaInicial',
                                methods: hookForm,
                                label: 'Hora inicial',
                            }}
                        />
                        <InputTime
                            hookForm={{
                                name: 'horaFinal',
                                methods: hookForm,
                                label: 'Hora final',
                            }}
                        />
                        <Button
                            variant="containedFilled"
                            sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                            type='submit'
                            disabled={loadingSalvar}
                        >
                            Salvar
                        </Button>
                    </Stack>
                </Box>
            </ModalBase>

            <Stack sx={{ flex: 1, gap: 2 }}>
                {dadosLista?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={dadosLista.indiceInicial}
                        indiceFinal={dadosLista.indiceFinal}
                        indiceTotal={dadosLista.total}
                        ultimaPagina={dadosLista?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Dia da semana</TableCell>
                            <TableCell align="left">Hora inicial</TableCell>
                            <TableCell align="left">Hora final</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowLoading />
                        ) : dadosLista?.lista?.length > 0 ? (
                            dadosLista?.lista?.map((item: any) => (
                                <TableRow key={item.id}>
                                    <TableCell align="left">{sanitizeDiaSemana(item?.diaSemana)}</TableCell>
                                    <TableCell align="left">
                                        {dayjs(item?.horaInicial, 'HH:mm').isValid() ? dayjs(item?.horaInicial, 'HH:mm').format('HH:mm') : 'Invalid Time'}
                                    </TableCell>
                                    <TableCell align="left">
                                        {dayjs(item?.horaFinal, 'HH:mm').isValid() ? dayjs(item?.horaFinal, 'HH:mm').format('HH:mm') : 'Invalid Time'}
                                    </TableCell>

                                    <TableCell align="right">
                                        <IconButton aria-label="delete" size="small">
                                            <Delete color="error" onClick={() => {
                                                setSelectedItem(item);
                                                setOpenDeletar(true)
                                            }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRowNotFound text="Nenhum resultado encontrado." fezRequisicao={true} />
                        )}
                    </TableBody>
                </Table>
            </Stack>
            <ModalOpcoes
                descricao={(
                    <Typography>
                        Tem certeza que deseja excluir <span style={{ fontWeight: 'bold' }}>{sanitizeDiaSemana(selectedItem?.diaSemana)}</span> da tabela?
                    </Typography>
                )}
                acao="Excluir"
                open={openDeletar}
                onClose={() => { setOpenDeletar(false) }}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => setOpenDeletar(false)
                    },
                    {
                        label: 'Excluir',
                        action: () => {
                            dispatch(excluirPorDiaDaSemanaStart(selectedItem.taxaExtraDiaSemanaID))
                            listRef.current.listar()
                            setOpenDeletar(false)
                        }
                    }
                ]}
            />
        </Stack>
    );
}

export default AgendamentoHorario