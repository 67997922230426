import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    Typography,
    Tooltip,
    Stack,
    useTheme
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AddCircleOutline, SensorsOffOutlined, Sensors, Android, Apple } from '@mui/icons-material';
import { excluirEntregador } from '../../../store/reducers/config';
import EditarEntregador from './EditarEntregador';
import CadastrarEntregador from './CadastrarEntregador';
import { tornarEntregadorOnline } from '../../../store/reducers/utils';
import { telefone } from '../../../ds/common';
import { listaEntregadoresStart } from '../../../store/reducers/entregador';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { ContainerBodyModal, ModalBase, ModalOpcoes, PLANO_FREEMIUM, TableNavigation, TableRowLoading, TableRowNotFound, useResponsive } from 'src/ds';
import { RootState } from 'src/store/reducers';
import { Entregador } from 'src/dtos/EntregadoresDTO';
import { useForm } from 'react-hook-form';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface Props {
    abrirEntregadores: boolean;
    setAbrirEntregadores: Dispatch<SetStateAction<boolean>>;
}

type StatusEntregadores = { [key: string]: boolean }

const ListaEntregadores = ({ abrirEntregadores, setAbrirEntregadores }: Props) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext();
    const theme = useTheme();
    const isMobile = useResponsive();
    const entregadoresUsuario = useSelector((state: RootState) => state.entregadores?.listaEntregadores.data?.dados?.lista);
    const entregadoresDados = useSelector((state: any) => state.entregadores.listaEntregadores?.data?.dados);
    const [filteredEntregadores, setFilteredEntregadores] = useState(entregadoresUsuario);
    const [showEditar, setShowEditar] = useState(false);
    const [showCadastrar, setShowCadastrar] = useState(false);
    const [selectedEntregador, setSelectedEntregador] = useState<Entregador | null>(null);
    const [excluirOpen, setExcluirOpen] = useState(false);
    const [atualizar, setAtualizar] = useState(false);
    const resposta = useSelector((state: RootState) => state.config.resposta);
    const erro = useSelector((state: RootState) => state.config.error);
    const [statusEntregadores, setStatusEntregadores] = useState<StatusEntregadores>({});
    const usuarioPlano = useSelector((state: RootState) => state.config?.usuarioPlano);
    const listaEntregadoresLoading = useSelector((state: RootState) => state.entregadores?.listaEntregadores.loading);

    const hookForm = useForm({
        defaultValues: {
            pagina: 1,
            paginacao: 20
        }
    })

    const { watch } = hookForm

    useEffect(() => {
        const statusInicial: StatusEntregadores = {}
        entregadoresUsuario?.forEach((entregador: Entregador) => {
            statusInicial[entregador.id] = entregador.online;
        });
        setStatusEntregadores(statusInicial);
    }, [entregadoresUsuario]);

    useEffect(() => {
        if (resposta) showSuccess({
            message: resposta,
            duration: 5000
        });
        if (erro) showError({
            message: erro,
            duration: 5000
        });
    }, [resposta, erro]);

    useEffect(() => {
        setFilteredEntregadores(entregadoresUsuario);
    }, [entregadoresUsuario]);

    const [filters, setFilters] = useState({
        nome: '',
        email: '',
        telefone: ''
    });

    const opcoesExcluir = [
        { label: 'Cancelar', action: () => setExcluirOpen(false) },
        {
            label: 'Confirmar', action: () => {
                //dispatch(cancelarPedidoRequest(pedido.pedidoID))
                setExcluirOpen(false)
                setTimeout(() => {
                    dispatch(excluirEntregador(selectedEntregador?.id))
                    setAtualizar(!atualizar)
                }, 1000)
            }
        },
    ];

    const handleClose = () => {
        setAbrirEntregadores(false);
        setSelectedEntregador(null);
        setShowEditar(false);
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const handleFilterSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const filtered = entregadoresUsuario?.filter((entregador: Entregador) => {
            return (
                (filters.nome === '' || entregador.nome.toLowerCase().includes(filters.nome.toLowerCase())) &&
                (filters.email === '' || entregador.email.toLowerCase().includes(filters.email.toLowerCase())) &&
                (filters.telefone === '' || entregador.telefone.includes(filters.telefone))
            );
        });
        setFilteredEntregadores(filtered);
    };

    const handleEditClick = (entregador: Entregador) => {
        setSelectedEntregador(entregador);
        setShowEditar(true);
    };

    const handleExcluirClick = (entregador: Entregador) => {
        setSelectedEntregador(entregador);
        setExcluirOpen(true);
    };

    useEffect(() => {
        const statusInicial: StatusEntregadores = {};
        entregadoresUsuario?.forEach((entregador: Entregador) => {
            statusInicial[entregador.usuarioID] = entregador.online;
        });
        setStatusEntregadores(statusInicial);
    }, [entregadoresUsuario]);

    const forcarOnline = (entregador: Entregador) => {
        const novoStatus = !statusEntregadores[entregador.usuarioID];

        setStatusEntregadores(prev => ({ ...prev, [entregador.usuarioID]: novoStatus }));

        dispatch(tornarEntregadorOnline({ usuarioID: entregador?.usuarioID, condition: true }));
        dispatch(listaEntregadoresStart({
            nome: '',
            cpf: '',
            dtInicial: '',
            dtFinal: '',
            tagsFiltro: '',
            paginacao: watch("paginacao") || 20,
            pagina: watch("pagina") || 1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '(entregador || dataEntregadorSolicitacao != null)',
            titulos: '',
            variaveis: '',
            formato: ''
        }))
    };

    const forcarOffline = (entregador: Entregador) => {
        const novoStatus = !statusEntregadores[entregador.usuarioID];

        setStatusEntregadores(prev => ({ ...prev, [entregador.usuarioID]: novoStatus }));
        dispatch(tornarEntregadorOnline({ usuarioID: entregador?.usuarioID, condition: false }));
        dispatch(listaEntregadoresStart({
            nome: '',
            cpf: '',
            dtInicial: '',
            dtFinal: '',
            tagsFiltro: '',
            paginacao: watch("paginacao") || 20,
            pagina: watch("pagina") || 1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '(entregador || dataEntregadorSolicitacao != null)',
            titulos: '',
            variaveis: '',
            formato: ''
        }))
    };

    useEffect(() => {
        dispatch(listaEntregadoresStart({
            nome: '',
            cpf: '',
            dtInicial: '',
            dtFinal: '',
            tagsFiltro: '',
            paginacao: watch("paginacao") || 20,
            pagina: watch("pagina") || 1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '(entregador || dataEntregadorSolicitacao != null)',
            titulos: '',
            variaveis: '',
            formato: ''
        }));
    }, [atualizar, watch("paginacao"), watch("pagina")]);

    const handleAndroid = () => { window.open('https://play.google.com/store/apps/details?id=online.pickngo', '_blank') }

    const handleIOS = () => { window.open('https://apps.apple.com/br/app/pickngo/id1601875773', '_blank') }

    return (

        <ModalBase
            open={abrirEntregadores}
            onClose={handleClose}
            headerModal={{ title: "Entregadores" }}
        >
            <ContainerBodyModal>
                {false &&
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1, width: '100%', flexDirection: isMobile ? 'column' : 'row' }} >

                        <TextField
                            size={'small'}
                            label="Nome"
                            name="nome"
                            value={filters.nome}
                            onChange={handleFilterChange}
                            sx={{ flex: 1 }}
                            fullWidth={isMobile ? true : false}
                        />
                        <TextField
                            size={'small'}
                            label="Email"
                            name="email"
                            value={filters.email}
                            onChange={handleFilterChange}
                            sx={{ flex: 1 }}
                            fullWidth={isMobile ? true : false}

                        />
                        <TextField
                            size={'small'}
                            label="Telefone"
                            name="telefone"
                            value={filters.telefone}
                            onChange={handleFilterChange}
                            sx={{ flex: 1 }}
                            fullWidth={isMobile ? true : false}

                        />

                        <Button
                            variant="iconeComTextoFilled"
                            onClick={(e) => handleFilterSubmit(e)}
                            sx={{ height: 'fit-content', width: isMobile ? '100%' : '10%' }}
                        >
                            Filtrar
                        </Button>
                    </Box>}

                {isMobile && <Button variant="iconeComTextoFilled" onClick={() => setShowCadastrar(true)} sx={{ height: 'fit-content', width: isMobile ? '100%' : 'fit-content' }}><AddCircleOutline />Cadastrar</Button>}
                <Stack direction="row" gap={1} sx={{ justifyContent: 'flex-end', alignItems: 'center' }} mt={2}>
                    {(isMobile && usuarioPlano === PLANO_FREEMIUM) && <Button variant="iconeComTextoFilled" sx={{ width: '50%' }} onClick={handleAndroid} ><Android /></Button>}
                    {(isMobile && usuarioPlano === PLANO_FREEMIUM) && <Button variant="iconeComTextoFilled" sx={{ width: '50%' }} onClick={handleIOS}> <Apple /></Button>}
                </Stack>
                {/*                 <TableNavigation
                    indiceInicial={entregadoresDados?.indiceInicial}
                    indiceFinal={entregadoresDados?.indiceFinal}
                    indiceTotal={entregadoresDados?.total}
                    ultimaPagina={entregadoresDados?.ultimaPagina}
                    
                    /> */}
                {entregadoresDados &&
                    <TableNavigation
                        indiceInicial={entregadoresDados.indiceInicial}
                        indiceFinal={entregadoresDados.indiceFinal}
                        indiceTotal={entregadoresDados.total}
                        ultimaPagina={entregadoresDados?.ultimaPagina}
                        hookForm={hookForm}
                    />
                }
                <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" width="25%">
                                    Nome do entregador
                                </TableCell>
                                <TableCell align="center" width="25%">
                                    Email
                                </TableCell>
                                <TableCell align="center" width="15%">
                                    Telefone
                                </TableCell>
                                <TableCell align="center" width="15%">
                                    Tipo de Veículo
                                </TableCell>
                                <TableCell align="center" width="25%">
                                    Ações
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listaEntregadoresLoading
                                ? <TableRowLoading />
                                : Array.isArray(filteredEntregadores) && filteredEntregadores.length > 0
                                    ? filteredEntregadores.map((entregador: Entregador, index: number) => (
                                        <TableRow
                                            key={index}
                                            hover
                                            role="checkbox"
                                        >
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                width="25%"
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '150px'
                                                }}>
                                                {entregador?.nome ?? '-'}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                width="25%"
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '150px'
                                                }}>
                                                {entregador?.email ?? '-'}
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                {entregador?.telefone ? telefone(entregador.telefone) : '-'}
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                {entregador?.nomeTipoVeiculo ?? '-'}
                                            </TableCell>
                                            <TableCell align="center" width="25%">
                                                <Tooltip title={`Forçar ${entregador?.online ? 'Offline' : 'Online'}`} arrow placement="top">
                                                    <IconButton
                                                        onClick={entregador?.online
                                                            ? () => forcarOffline(entregador)
                                                            : () => forcarOnline(entregador)}
                                                    >
                                                        {statusEntregadores[entregador.id]
                                                            ? <Sensors sx={{ color: theme.palette.green[600] }} />
                                                            : <SensorsOffOutlined sx={{ color: theme.palette.red[600] }} />}
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Editar" arrow placement="top">
                                                    <IconButton onClick={() => handleEditClick(entregador)}>
                                                        <EditRoundedIcon color='warning' />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Excluir" arrow placement="top">
                                                    <IconButton onClick={() => handleExcluirClick(entregador)}>
                                                        <DeleteRoundedIcon color='error' />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )) : <TableRowNotFound />}
                        </TableBody>
                    </Table>
                </TableContainer>

            </ContainerBodyModal>
            {showEditar && (
                <EditarEntregador
                    redefinir={true}
                    entregador={selectedEntregador}
                    showEditar={showEditar}
                    setShowEditar={setShowEditar}
                    atualizar={() => { setAtualizar(!atualizar) }}
                />
            )}
            {showCadastrar &&
                <CadastrarEntregador
                    showEditar={showCadastrar}
                    setShowEditar={setShowCadastrar}
                    atualizar={() => { setAtualizar(!atualizar) }}
                />
            }
            <ModalOpcoes
                open={excluirOpen}
                onClose={() => setExcluirOpen(false)}
                opcoes={opcoesExcluir}
                acao={'Excluir entregador'}
                descricao={
                    `Tem certeza que deseja excluir o entregador "${selectedEntregador?.nome}"? `
                }
            />
        </ModalBase>
    );
};

export default ListaEntregadores;
