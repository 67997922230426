
import { Box, Card, Stack, Tab, Tabs, useTheme, } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import timer from "../../assets/icons/time.svg";
import dayjs from "dayjs";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/components/CardFormFiltros";
import { RootState } from "../../store/reducers";
import { BoxTableMinHeight,  formatTime, ListCardTabelaTotal, MenuTabelas, TableNavigation } from "src/ds";
import { useForm } from "react-hook-form";
import TabelaPerformance from "src/components/PerformancePedidos/TabelaPerformance";
import DashboardPerformance from "src/components/PerformancePedidos/DashboardPerformance";
import { exportarPerformancePedidosStart, listarPerformancePedidosStart } from "src/store/reducers/pedidos";

const PerformancePedido = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const hookForm = useForm({
        defaultValues: {
            empresaID: '',
            filtroCodigo: '',
            distanciaInicial: '',
            distanciaFinal: '',
            dtInicial: dayjs().subtract(1, 'day').format('DD/MM/YYYY 00:00'),
            filtroNome: '',
            dtFinal: '',
            faixaKm: '',
            paginacao: 20,
            pagina: 1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
        }
    });

    const { watch } = hookForm;
    const pedidosDados = useSelector((state: RootState) => state.pedidos.performancePedidos?.data?.dados);
    const loadingPedidos = useSelector((state: RootState) => state.pedidos.performancePedidos.loading);
    const loadingExportar = useSelector((state: RootState) => state.pedidos.exportarPerformancePedidos.loading);
    const [activeSection, setActiveSection] = useState('pedidos');

    const handleSectionChange = (section: any) => {
        if (section === null) return;
        setActiveSection(section);
    }

    const cardRef = useRef<CardFormFiltrosRef>(null)

    useEffect(() => {
        dispatch(listarPerformancePedidosStart({
            ...hookForm.getValues(),
        }));
    }, [watch("paginacao"), watch("pagina")]);

    const atualizarDashboard = cardRef.current?.atualizarDispatch

    const menuOptions = [{
        label: 'Exportar',
        action: () => {
            dispatch(exportarPerformancePedidosStart(hookForm.getValues()))
        }
    }];

    return (
        <Box sx={{ p: 2, backgroundColor: theme.palette.background.paperDark }}>
            <CardFormFiltros
                ref={cardRef}
                fields={[
                    { name: 'Empresa', value: 'empresaID', type: 'autocompleteEmpresa' },
                    { name: 'Data inicial', value: 'dtInicial', type: 'dateTime', rules: { required: 'Data inicial é obrigatória' } },
                    { name: 'Data final', value: 'dtFinal', type: 'dateTime', rules: { required: 'Data final é obrigatória' } },
                    //{ name: 'Código', value: 'filtroCodigo' },
                    { name: 'Entregador', value: 'filtroNome' },
                    {
                        name: 'Quilometragem', value: 'faixaKm', type: 'customSelect', options: [
                            { label: 'Todas', value: 'todos' },
                            { label: 'Faixa de 1Km', value: '1' },
                            { label: 'Faixa de 2Km', value: '2' },
                            { label: 'Faixa de 3Km', value: '3' },
                            { label: 'Faixa de 4Km', value: '4' },
                            { label: 'Faixa de 5Km', value: '5' },
                            { label: 'Faixa de 6Km', value: '6' },
                            { label: 'Faixa de 7Km', value: '7' },
                            { label: 'Faixa de 8Km', value: '8' },
                            { label: 'Faixa de 9Km', value: '9' },
                            { label: 'Faixa de 10Km ou mais', value: '10' }
                        ],
                        valueKey: 'value',
                        labelKey: 'label',
                    }
                ]}
                formMethods={hookForm}
                dispatchMethods={{ actionName: 'listarPerformancePedidosStart' }}
                loading={loadingPedidos}
            />
            <ListCardTabelaTotal
                cardData={[
                    {
                        srcIcon: timer,
                        altIcon: 'Ícone Tempo Médio do Pedido Pronto',
                        bgcolor: theme.palette.grey[600],
                        texts: {
                            title1: 'TEMPO MÉDIO DO PEDIDO PRONTO',
                            subtitle1: pedidosDados?.mediaTempoPedidoPronto ? formatTime(pedidosDados?.mediaTempoPedidoPronto) : '0'
                        }
                    },
                    {
                        srcIcon: timer,
                        altIcon: 'Ícone Tempo Médio do Despacho',
                        bgcolor: theme.palette.blue[600],
                        texts: {
                            title1: 'TEMPO MÉDIO DO DESPACHO',
                            subtitle1: pedidosDados?.tempoMedioDespacho ? formatTime(pedidosDados?.tempoMedioDespacho) : '0'
                        }
                    },
                    {
                        srcIcon: timer,
                        altIcon: 'Tempo Médio Rota do Entregador',
                        bgcolor: theme.palette.yellow[600],
                        texts: {
                            title1: 'TEMPO MÉDIO ROTA DO ENTREGADOR',
                            subtitle1: pedidosDados?.mediaTempoRotaEntregador ? formatTime(pedidosDados?.mediaTempoRotaEntregador) : '0'
                        }
                    },
                    {
                        srcIcon: timer,
                        altIcon: 'Ícone Porcentagem Entregas Dentro da Estimativa (ETA)',
                        bgcolor: theme.palette.green[600],
                        texts: {
                            title1: 'PORCENTAGEM ENTREGAS DENTRO DA ESTIMATIVA (ETA)',
                            subtitle1: pedidosDados?.porcentagemNegativos ? `${pedidosDados?.porcentagemNegativos.toFixed(2)} %` : '0%'
                        }
                    },
                ]}
            />
            <Stack sx={{ mt: 4, gap: 2, flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }} direction="row">
                <Tabs
                    value={activeSection}
                    onChange={(_, value) => handleSectionChange(value)}
                    textColor="inherit"
                    variant="fullWidth"
                >
                    <Tab label="Pedidos" value="pedidos" />
                    <Tab label="Dashboard" value="dashboard" />
                </Tabs>
            </Stack>
            <Card elevation={1} sx={{ mt: 2, borderRadius: 2 }}>
                {(pedidosDados?.ultimaPagina && pedidosDados.ultimaPagina > 0) && activeSection === 'pedidos' ? (
                    <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, alignItems: 'center' }}>
                        <TableNavigation
                            indiceInicial={pedidosDados?.indiceInicial}
                            indiceFinal={pedidosDados?.indiceFinal}
                            indiceTotal={pedidosDados?.total}
                            ultimaPagina={pedidosDados.ultimaPagina}
                            hookForm={hookForm}
                            podeTodos={false}
                            requiredFields={['dtInicial', 'dtFinal']}
                        />
                        <MenuTabelas options={menuOptions} loading={loadingExportar} />
                    </Box>
                ) : null}
                {activeSection === 'pedidos'
                    ? (
                        <BoxTableMinHeight>
                            <TabelaPerformance
                                pedidos={pedidosDados?.lista}
                                loading={loadingPedidos}
                                fezRequisicao={cardRef.current?.fezRequisicao}
                                hookForm={hookForm}
                            />
                        </BoxTableMinHeight>
                    ) : (
                        <DashboardPerformance
                            formMethods={hookForm}
                            loading={loadingPedidos}
                            atualizar={atualizarDashboard}
                            fezRequisicao={cardRef.current?.fezRequisicao}
                        />
                    )}
            </Card>
        </Box>
    );
};

export default PerformancePedido;
