import { useEffect, useMemo, useState } from 'react';
import { Box, Typography, useTheme, Grid2, SxProps, Theme, TableBody, Table, Button, CardContent, Collapse, Card } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, Sector, Brush } from 'recharts';
import { TableRowLoading, TableRowNotFound } from 'src/ds';
import { useDispatch, useSelector } from 'react-redux';
import { listarLocalizacoesStart, listarPedidosPorFaixaKm, listarPedidosPorFaixaKmPorDia, listarPedidosPorTipo, listarPedidosPorTipoEmpresa, listarPedidosPorTipoPorDia, listarRankingBairros, listarRankingEmpresas, listarRankingEntregadores } from 'src/store/reducers/pedidos';
import { RootState } from 'src/store/reducers';
import { UseFormReturn } from 'react-hook-form';
import TabelaRanking from './TabelaRanking';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import MapaDeCalor from './MapaDeCalor';
import { current } from '@reduxjs/toolkit';
dayjs.locale('pt-br');

interface DashboardPedidosProps {
  formMethods: UseFormReturn<any>;
  loading: boolean;
  fezRequisicao?: boolean;
  atualizar: boolean;
}

const useChartHover = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const onLegendHover = (index: number) => {
    setActiveIndex(index);
  };

  const onLegendLeave = () => {
    setActiveIndex(null);
  };

  return { activeIndex, onLegendHover, onLegendLeave };
};

const DashboardPedidos = ({ formMethods, loading, atualizar, fezRequisicao }: DashboardPedidosProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const chartHoverPedidosDia = useChartHover();
  const chartHoverPedidosTipo = useChartHover();
  const chartHoverPedidosTipoEmpresa = useChartHover();
  const chartHoverFaixaKm = useChartHover();
  const chartHoverFaixaKmPorDia = useChartHover();
  const chartHoverRankingEmpresas = useChartHover();
  const chartHoverRankingEntregadores = useChartHover();
  const chartHoverRankingBairros = useChartHover();

  const { getValues } = formMethods;

  useEffect(() => {
    if (!atualizar) return
    const currentFormValues = getValues();
    delete currentFormValues.tipoPedidoCodigo;
    console.log(currentFormValues);
    if (
      currentFormValues.situacao === undefined ||
      currentFormValues.situacao === null ||
      (typeof currentFormValues.situacao === 'string' && currentFormValues.situacao.trim() === '') ||
      currentFormValues.situacao.length === 0
    ) {
      delete currentFormValues.situacao;
    }

    Object.keys(currentFormValues).forEach((key) => {
      const value = currentFormValues[key];
      if (typeof value === 'boolean' || value === '' || value === null || value === undefined) {
        delete currentFormValues[key];
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (typeof item === 'object' && item !== null && 'value' in item) {
            currentFormValues[key] = item.value;
          }
        });
      } else if (typeof value === 'object' && value !== null && 'value' in value) {
        currentFormValues[key] = value.value;
      }
    });

    dispatch(listarPedidosPorTipoPorDia(currentFormValues));
    dispatch(listarPedidosPorTipoEmpresa(currentFormValues));
    dispatch(listarPedidosPorTipo(currentFormValues));
    dispatch(listarPedidosPorFaixaKm(currentFormValues));
    dispatch(listarPedidosPorFaixaKmPorDia(currentFormValues));
    dispatch(listarRankingEmpresas(currentFormValues));
    dispatch(listarRankingEntregadores(currentFormValues));
    dispatch(listarRankingBairros(currentFormValues));
    dispatch(listarLocalizacoesStart({
      pagina: -1,
      paginacao: -1,
      dataInicio: getValues('dtInicialFinalizado'),
      dataFim: getValues('dtFinalFinalizado') || dayjs().format('DD/MM/YYYY 00:00'),

    }))
  }, [getValues, atualizar]);

  const pedidosTipoDia = useSelector((state: RootState) => state.pedidos.pedidosPorTipoPorDia.data);
  const pedidosTipo = useSelector((state: RootState) => state.pedidos.pedidosPorTipo.data);
  const pedidosTipoEmpresa = useSelector((state: RootState) => state.pedidos.pedidosPorTipoEmpresa.data);
  const pedidosFaixaKm = useSelector((state: RootState) => state.pedidos.pedidosPorFaixaKm.data);
  const pedidosFaixaKmPorDia = useSelector((state: RootState) => state.pedidos.pedidosPorFaixaKmPorDia.data);
  const rankingEmpresas = useSelector((state: RootState) => state.pedidos.rankingEmpresas.data?.dados?.lista);
  const rankingEntregadores = useSelector((state: RootState) => state.pedidos.rankingEntregadores.data?.dados?.lista);
  const rankingBairros = useSelector((state: RootState) => state.pedidos.rankingBairros.data?.dados?.lista);
  const locCalor = useSelector((state: RootState) => state.pedidos.listarLocalizacoes?.data?.dados?.lista);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
    dispatch(listarLocalizacoesStart({
      pagina: -1,
      paginacao: -1,
      dataInicio: getValues('dtInicialFinalizado'),
      dataFim: getValues('dtFinalFinalizado')
    }))
  };

  const pedidosPorcento = pedidosTipo?.dados?.lista?.reduce((sum, pedido) => sum + pedido.qtdPedidos, 0) || 0;

  const dataForChart = useMemo(() => {
    const groupedByDate: any = {};

    pedidosTipoDia?.dados?.lista.forEach(item => {
      const dateKey = dayjs(item.data, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY');
      if (!groupedByDate[dateKey]) {
        groupedByDate[dateKey] = {
          dataCadastro: dateKey
        };
      }

      if (!groupedByDate[dateKey][item.tipoPedido]) {
        groupedByDate[dateKey][item.tipoPedido] = 0;
      }

      groupedByDate[dateKey][item.tipoPedido] += item.qtdPedidos;
    });

    return Object.values(groupedByDate);
  }, [pedidosTipoDia]);

  const dataForLineChart = useMemo(() => {
    const groupedData: Record<string, any> = {};
    const faixasKm = ['De 0 a 1 km', 'De 1 a 2 km', 'De 2 a 3 km', 'De 3 a 4 km', 'De 4 a 5 km', 'De 5 a 6 km', 'De 6 a 7 km', 'De 7 a 8 km', 'De 8 a 9 km', 'De 9 a 10 km', 'Acima de 10 km'];

    pedidosFaixaKmPorDia?.dados?.lista.forEach((item: any) => {
      const dateKey = dayjs(item.data, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY');
      const faixaKm = item.faixaKm;
      const qtdPedidos = item.qtdPedidos;

      if (!groupedData[dateKey]) {
        groupedData[dateKey] = { dataCadastro: dateKey };

        faixasKm.forEach(faixa => {
          groupedData[dateKey][faixa] = 0;
        });
      }

      const faixaKey = faixaKm < 10 ? `De ${faixaKm} a ${faixaKm + 1} km` : 'Acima de 10 km';

      groupedData[dateKey][faixaKey] = (groupedData[dateKey][faixaKey] || 0) + qtdPedidos;
    });

    return Object.values(groupedData);
  }, [pedidosFaixaKmPorDia]);



  const faixaKmPorDia = useMemo(() => {
    const groupedData: Record<string, any> = {};

    pedidosFaixaKmPorDia?.dados?.lista.forEach((item: any) => {
      const dateKey = dayjs(item.data, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY');
      const faixaKm = item.faixaKm;
      const qtdPedidos = item.qtdPedidos;

      if (!groupedData[dateKey]) {
        groupedData[dateKey] = { dataCadastro: dateKey };
      }

      const key = faixaKm < 10 ? `De ${faixaKm} a ${faixaKm + 1} km` : 'Acima de 10 km';
      groupedData[dateKey][key] = (groupedData[dateKey][key] || 0) + qtdPedidos;
    });

    return Object.values(groupedData);
  }, [pedidosFaixaKmPorDia]);

  if (loading) {
    return (
      <Table>
        <TableBody>
          <TableRowLoading />
        </TableBody>
      </Table>
    );
  }

  if (!pedidosTipoDia || pedidosTipoDia?.dados?.lista?.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <TableRowNotFound fezRequisicao={fezRequisicao} />
      </Box>
    );
  }

  const colors = [theme.palette.primary.light, theme.palette.secondary.main, theme.palette.red[500], theme.palette.blue[500], theme.palette.grey[500], theme.palette.yellow[500]];

  const groupDataByKmRange = (data: any) => {
    const groupedData = [
      { faixaKm: '0 a 1 km', qtdPedidos: 0, totalCobrada: 0, totalPaga: 0 },
      { faixaKm: '2 a 3 km', qtdPedidos: 0, totalCobrada: 0, totalPaga: 0 },
      { faixaKm: '3 a 4 km', qtdPedidos: 0, totalCobrada: 0, totalPaga: 0 },
      { faixaKm: '4 a 5 km', qtdPedidos: 0, totalCobrada: 0, totalPaga: 0 },
      { faixaKm: 'Acima de 10 km', qtdPedidos: 0, totalCobrada: 0, totalPaga: 0 },
    ];

    data?.forEach((item: any) => {
      if (item.faixaKm >= 0 && item.faixaKm <= 1) {
        groupedData[0].qtdPedidos += item.qtdPedidos;
        groupedData[0].totalCobrada += item.totalCobrada;
        groupedData[0].totalPaga += item.totalPaga;
      } else if (item.faixaKm >= 2 && item.faixaKm <= 3) {
        groupedData[1].qtdPedidos += item.qtdPedidos;
        groupedData[1].totalCobrada += item.totalCobrada;
        groupedData[1].totalPaga += item.totalPaga;
      } else if (item.faixaKm >= 3 && item.faixaKm <= 4) {
        groupedData[2].qtdPedidos += item.qtdPedidos;
        groupedData[2].totalCobrada += item.totalCobrada;
        groupedData[2].totalPaga += item.totalPaga;
      } else if (item.faixaKm >= 4 && item.faixaKm <= 5) {
        groupedData[3].qtdPedidos += item.qtdPedidos;
        groupedData[3].totalCobrada += item.totalCobrada;
        groupedData[3].totalPaga += item.totalPaga;
      } else if (item.faixaKm >= 10) {
        groupedData[4].qtdPedidos += item.qtdPedidos;
        groupedData[4].totalCobrada += item.totalCobrada;
        groupedData[4].totalPaga += item.totalPaga;
      }
    });

    return groupedData.filter((item) => item.qtdPedidos > 0);
  };

  //@ts-ignore
  const groupedFaixaKmData = groupDataByKmRange(pedidosFaixaKm?.dados?.lista);
  const totalPedidos = groupedFaixaKmData.reduce((acc, curr) => acc + curr.qtdPedidos, 0);

  const stylesBox: SxProps<Theme> = {
    width: '100%',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
    p: 2,
    overflow: 'auto',
    scrollbarWidth: 'thin',
    height: 400,
  }

  return (
    <Box sx={{ p: 2, gap: 2 }}>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, lg: 6 }} sx={stylesBox}>
          <Typography variant="h6">Pedidos por dia</Typography>
          {dataForChart?.length ? (
            <ResponsiveContainer width={'100%'} height={300}>
              <LineChart data={dataForChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="dataCadastro"
                  tickFormatter={(tick) => dayjs(tick, 'DD/MM/YYYY').format('DD MMM')}
                />
                <YAxis />
                <Tooltip />
                <Legend
                  onMouseEnter={(_, index) => chartHoverPedidosDia.onLegendHover(index)}
                  onMouseLeave={chartHoverPedidosDia.onLegendLeave}
                />
                {pedidosTipoDia?.tipos?.map((tipo, index) => (
                  <Line
                    key={tipo}
                    type="monotone"
                    dataKey={tipo}
                    name={tipo}
                    stroke={colors[index % colors?.length]}
                    strokeOpacity={
                      chartHoverPedidosDia.activeIndex === index || chartHoverPedidosDia.activeIndex === null
                        ? 1
                        : 0.2
                    }
                  />
                ))}
                {chartHoverPedidosDia.activeIndex !== null && (
                  <text
                    x={250}
                    y={20}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      fill: colors[chartHoverPedidosDia.activeIndex % colors?.length],
                    }}
                  >
                    {pedidosTipoDia?.tipos[chartHoverPedidosDia.activeIndex]}
                  </text>
                )}
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height="90%">
              <Typography variant="notFound" align="center">Nenhum item encontrado</Typography>
            </Box>)}
        </Grid2>

        <Grid2 size={{ md: 12, lg: 6 }} sx={stylesBox}>
          <Typography variant="h6">Por faixa de km por dia</Typography>
          {dataForLineChart?.length ? (
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={dataForLineChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="dataCadastro"
                  tickFormatter={(tick) => dayjs(tick, 'DD/MM/YYYY').format('DD MMM')}
                />
                <YAxis tickFormatter={(tick) => `${tick}`} />
                <Tooltip
                  formatter={(value) => `${value} pedidos`}
                  labelFormatter={(label) => `Data: ${label}`}
                />
                <Legend />
                {[
                  'De 0 a 1 km',
                  'De 1 a 2 km',
                  'De 2 a 3 km',
                  'De 3 a 4 km',
                  'De 4 a 5 km',
                  'De 5 a 6 km',
                  'De 6 a 7 km',
                  'De 7 a 8 km',
                  'De 8 a 9 km',
                  'De 9 a 10 km',
                  'Acima de 10 km',
                ].map((faixa, index) => (
                  <Line
                    key={faixa}
                    type="monotone"
                    dataKey={faixa}
                    name={faixa}
                    stroke={colors[index % colors?.length]}
                    strokeWidth={2}
                    dot={{ r: 4 }}
                    activeDot={{ r: 6 }}
                  />
                ))}
                <Brush height={30} stroke={theme.palette.primary.main} />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height="90%">
              <Typography variant="notFound" align="center">Nenhum item encontrado</Typography>
            </Box>)}
        </Grid2>

        <Grid2 size={{ md: 12, lg: 4 }} sx={stylesBox}>
          <Typography variant="h6">Tipo de pedido</Typography>
          {pedidosTipo?.dados?.lista?.length ? (
            <ResponsiveContainer width={'100%'} height={300}>
              <PieChart>
                <Pie
                  data={pedidosTipo?.dados?.lista}
                  dataKey="qtdPedidos"
                  nameKey="tipoPedido"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  innerRadius={80}
                  activeIndex={chartHoverPedidosTipo.activeIndex ?? undefined}
                  activeShape={(props: any) => (
                    <g>
                      <text x={props.cx} y={props.cy - 10} textAnchor="middle" dominantBaseline="middle">
                        {props.name}
                      </text>
                      <Sector
                        cx={props.cx}
                        cy={props.cy}
                        innerRadius={props.innerRadius}
                        outerRadius={props.outerRadius + 10}
                        startAngle={props.startAngle}
                        endAngle={props.endAngle}
                        fill={props.fill}
                      />
                    </g>
                  )}
                  onMouseEnter={(_, index) => chartHoverPedidosTipo.onLegendHover(index)}
                  onMouseLeave={chartHoverPedidosTipo.onLegendLeave}
                  label={({ name, qtdPedidos }) => `${name}: ${((qtdPedidos / totalPedidos) * 100).toFixed(1)}%`}
                >
                  {pedidosTipo?.dados?.lista?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors?.length]} />
                  ))}
                </Pie>
                {/* @ts-ignore */}
                <Tooltip formatter={(value) => `${((value / totalPedidos) * 100).toFixed(1)}%`} />
                <Legend
                  onMouseEnter={(_, index) => chartHoverPedidosTipo.onLegendHover(index)}
                  onMouseLeave={chartHoverPedidosTipo.onLegendLeave}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height="90%">
              <Typography variant="notFound" align="center">Nenhum item encontrado</Typography>
            </Box>
          )}
        </Grid2>

        <Grid2 size={{ md: 12, lg: 4 }} sx={stylesBox}>
          <Typography variant="h6">Distância dos pedidos</Typography>
          {groupedFaixaKmData?.length ? (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={groupedFaixaKmData}
                  dataKey="qtdPedidos"
                  nameKey="faixaKm"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill={theme.palette.secondary.main}
                  activeIndex={chartHoverFaixaKm.activeIndex ?? undefined}
                  activeShape={(props: any) => (
                    <g>

                      <Sector
                        cx={props.cx}
                        cy={props.cy}
                        innerRadius={props.innerRadius}
                        outerRadius={props.outerRadius + 10}
                        startAngle={props.startAngle}
                        endAngle={props.endAngle}
                        fill={props.fill}
                      />
                    </g>
                  )}
                  onMouseEnter={(_, index) => chartHoverFaixaKm.onLegendHover(index)}
                  onMouseLeave={chartHoverFaixaKm.onLegendLeave}
                  label={({ name, value }) => {
                    const percentage = ((value / totalPedidos) * 100).toFixed(2);
                    return `${name}: ${percentage}%`;
                  }}
                >
                  {groupedFaixaKmData?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors?.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  onMouseEnter={(_, index) => chartHoverFaixaKm.onLegendHover(index)}
                  onMouseLeave={chartHoverFaixaKm.onLegendLeave}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height="90%">
              <Typography variant="notFound" align="center">Nenhum item encontrado</Typography>
            </Box>
          )}
        </Grid2>
        <Grid2 size={{ md: 12, lg: 4 }} sx={stylesBox}>
          <Typography variant="h6">Tipo de empresa</Typography>
          {pedidosTipoEmpresa?.dados?.lista?.length ? (

            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pedidosTipoEmpresa?.dados?.lista}
                  dataKey="qtdPedidos"
                  nameKey="tipoEmpresa"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill={theme.palette.info.main}
                  activeIndex={chartHoverPedidosTipoEmpresa.activeIndex ?? undefined}
                  activeShape={(props: any) => (
                    <g>

                      <Sector
                        cx={props.cx}
                        cy={props.cy}
                        innerRadius={props.innerRadius}
                        outerRadius={props.outerRadius + 10}
                        startAngle={props.startAngle}
                        endAngle={props.endAngle}
                        fill={props.fill}
                      />
                    </g>
                  )}
                  onMouseEnter={(_, index) => chartHoverPedidosTipoEmpresa.onLegendHover(index)}
                  onMouseLeave={chartHoverPedidosTipoEmpresa.onLegendLeave}
                  label={({ name, value }) => `${value} pedidos`}
                >
                  {pedidosTipoEmpresa?.dados?.lista?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors?.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  onMouseEnter={(_, index) => chartHoverPedidosTipoEmpresa.onLegendHover(index)}
                  onMouseLeave={chartHoverPedidosTipoEmpresa.onLegendLeave}
                />
              </PieChart>
            </ResponsiveContainer>
          ) :
            (
              <Box display="flex" justifyContent="center" alignItems="center" height="90%">
                <Typography variant="notFound" align="center">Nenhum item encontrado</Typography>
              </Box>
            )}
        </Grid2>
        <Grid2 container spacing={2} size={12} sx={{ margin: 0, bgcolor: 'red' }}>
          <Grid2 size={{ xs: 12, md: 4 }} sx={{ overflow: 'auto', scrollbarWidth: 'thin' }}>
            <TabelaRanking data={rankingEmpresas} title="Top 10 Empresas" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }} sx={{ overflow: 'auto', scrollbarWidth: 'thin' }}>
            <TabelaRanking data={rankingEntregadores} title="Top 10 Entregadores" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }} sx={{ overflow: 'auto', scrollbarWidth: 'thin' }}>
            <TabelaRanking data={rankingBairros} title="Top 10 Bairros" />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 spacing={2} mt={2} >
        <Grid2>
          <Button variant="contained" onClick={toggleCollapse}>
            {isCollapsed ? 'Ocultar' : 'Mostrar'} mapa de calor
          </Button>
          <Collapse in={isCollapsed} sx={{ my: 2 }}>
            <MapaDeCalor data={locCalor} />
          </Collapse>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default DashboardPedidos;
