import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { TableRowLoading, TableRowNotFound, formatTime } from 'src/ds';
import { useState } from 'react';
import TableCellOrdenado from 'src/ds/components/TableCellOrdenado';
import { useDispatch } from 'react-redux';
import { listarPerformanceEntregadorStart } from 'src/store/reducers/entregador';

interface TabelaPerformanceProps {
    data: any[] | undefined;
    loading: boolean;
    fezRequisicao?: boolean;
    hookForm: any; // Adicionei para suportar os filtros como no exemplo anterior
}

const TabelaPerformance = ({ data, loading, fezRequisicao, hookForm }: TabelaPerformanceProps) => {
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        entregador: 'asc',
        pedidosAceitos: 'asc',
        recusadosAutomatico: 'asc',
        pontuacao: 'asc',
        tempoOnline: 'asc',
    });

    const dispatch = useDispatch();

    const toggleSortDirection = (column: string) => {
        const newSortDirection: 'asc' | 'desc' = sortDirection[column] === 'asc' ? 'desc' : 'asc';

        const updatedSortDirection: { [key: string]: 'asc' | 'desc' } = {
            ...sortDirection,
            [column]: newSortDirection,
        };

        setSortDirection(updatedSortDirection);

        const formValues = hookForm.getValues();
        const filteredFormValues = Object.entries(formValues).reduce((acc, [key, value]) => {
            if (value !== undefined && value !== null && value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {} as Record<string, any>);

        const payload = {
            ...filteredFormValues,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
        };

        dispatch(listarPerformanceEntregadorStart(payload));
    };

    return (
        <TableContainer
            sx={{
                width: '100%',
                overflowX: 'auto',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellOrdenado
                            label="Entregador"
                            column="nomeUsuario"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '15%' }}
                        />
                        <TableCellOrdenado
                            label="Qtd. pedidos aceitos"
                            column="totalPedidosAceitos"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Qtd. recusados manualmente"
                            column='recusados'
                            sortDirection={sortDirection}
                            onSortChange={() => { }}
                            sx={{ width: '20%' }}

                        />
                        <TableCellOrdenado
                            label="Qtd. recusados automaticamente"
                            column="totalPedidosRecusadosAutomatico"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '20%' }}
                        />
                        <TableCellOrdenado
                            label="Pontuação"
                            column="totalPontos"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Tempo online"
                            column="tempoOnline"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading
                        ? <TableRowLoading />
                        : (!data || data?.length === 0)
                            ? <TableRowNotFound fezRequisicao={fezRequisicao} />
                            : data.map((row) => (
                                <TableRow key={row.usuarioID} hover>
                                    <TableCell align="center">
                                        <Typography variant="body2" sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                            {row.nomeUsuario}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                whiteSpace: 'normal',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            {row.totalPedidosAceitos}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">{row.totalPedidosRecusadosManual}</TableCell>
                                    <TableCell align="center">{row.totalPedidosRecusadosAutomatico}</TableCell>
                                    <TableCell align="center">{row.pontuacao}</TableCell>
                                    <TableCell align="center">{row.tempoOnline ? formatTime(row.tempoOnline) : '-'}</TableCell>
                                </TableRow>
                            ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TabelaPerformance;
