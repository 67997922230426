import { Box, Card } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TabelaFaturas from 'src/components/MinhasFaturas/TabelaFaturas';
import { BoxTableMinHeight, TableNavigation, useResponsive } from 'src/ds';
import { CardFormFiltrosRef } from 'src/ds/components/CardFormFiltros';
import { Pick } from 'src/dtos/ConfigDTO';
import { RootState } from 'src/store/reducers';
import { listarMinhasFaturasStart } from 'src/store/reducers/faturas';

const MinhasFaturas = () => {
    const dispatch = useDispatch();
    const cardRef = useRef<CardFormFiltrosRef>(null);
    const dadosFaturas = useSelector((state: RootState) => state.faturas?.listarMinhasFaturas?.data?.dados);
    const listaFaturas = useSelector((state: RootState) => state.faturas?.listarMinhasFaturas?.data?.dados?.lista);
    const pick: Pick = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada);
    const loading = useSelector((state: RootState) => state.faturas?.listarMinhasFaturas.loading);
    const template = useSelector((state: RootState) => state.config?.template?.template);

    const [selectedItem, setSelectedItem] = useState<any>(null);


    const hookForm = useForm({
        defaultValues: {
            pagina: 1,
            paginacao: 20,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
        }
    })
    const { watch } = hookForm;

    const paginacao = watch("paginacao")
    const pagina = watch("pagina")

    useEffect(() => {
        dispatch(listarMinhasFaturasStart(hookForm.getValues()));
        if (listaFaturas?.length > 0) setSelectedItem(listaFaturas[0]);
    }, []);

    const { isMobile } = useResponsive();

    useEffect(() => {
        console.log('paginacao', watch("paginacao"));
        dispatch(listarMinhasFaturasStart({
            ...hookForm.getValues(),
        }));
    }, [paginacao, pagina]);

    return (
        <Box p={2} >
            <Card elevation={1} sx={{ mt: 2, p: 2 }}>
                {(dadosFaturas?.ultimaPagina) ? (
                    <TableNavigation
                        indiceInicial={dadosFaturas?.indiceInicial}
                        indiceFinal={dadosFaturas?.indiceFinal}
                        indiceTotal={dadosFaturas?.indiceFinal}
                        ultimaPagina={dadosFaturas?.ultimaPagina}
                        hookForm={hookForm}
                        podeTodos={false} //Todo: Temporário, quando seleciona todos, depois seleciona 5 ou sla, acaba quebrando a requisição pq manda negativo.
                    />
                ) : null}
                <BoxTableMinHeight>
                    <TabelaFaturas data={listaFaturas} loading={loading} fezRequisicao={cardRef.current?.fezRequisicao} />
                </BoxTableMinHeight>
            </Card>
        </Box>
    );
}

export default MinhasFaturas;
