// @ts-nocheck
import { useCallback, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { cancelarPedidoRequest, despacharRequest, marcarFinalizadoRequest, resetRota } from '../../../store/reducers/criarPedido';
import { usePedido } from '../../../context/PedidoContext';
import './styles.css';
import { Box, Button, CircularProgress, Collapse, styled } from '@mui/material';
import { Route } from '@mui/icons-material';
import { telefone } from '../../../ds/common';
import { CloseOutlined } from "@mui/icons-material";
import ModalCalculos from '../ModalCalculos';
import { ModalOpcoes, sanitizeSituacao, useResponsive } from '../../../ds';
import ModalPesquisarEntregador from 'src/components/common/ModalPesquisarEntregador';
import { RootState } from 'src/store/reducers';
import OcorrenciaPorEntregador from '../CriarOcorrencia/OcorrenciaPorEntregador';
import { useSnackbarContext } from 'src/context/SnackbarContext';

const MapContainer = styled('div')`
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
`;

// TODO: Deixar este componente no ts e acabar com os erros de console

function MapHome({ position, abrirTrocarStatus, abrirAtribuirPedido, abrirChatLateral, styleProps = null }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isMobile } = useResponsive();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const pickSelecionada = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada);
  const latlngPick = pickSelecionada &&
    !isNaN(pickSelecionada?.latitude) &&
    !isNaN(pickSelecionada?.longitude)
    ? { lat: pickSelecionada?.latitude, lng: pickSelecionada?.longitude }
    : null;
  const defaultPosition = { lat: -15.7801, lng: -47.9292 };
  const [currentCenter, setCurrentCenter] = useState(latlngPick || defaultPosition);
  const entregadores = useSelector((state: RootState) => state.config.entregadores);
  const emAndamento = useSelector((state: RootState) => state.config?.emAndamento?.dados?.lista);
  const empresas = useSelector((state: RootState) => state.config?.empresasPick?.dados?.lista);
  const filtros = useSelector((state: RootState) => state.utils.filtros);
  const [selectedSituacao, setSelectedSituacao] = useState('ALL');
  const [selectedEntregador, setSelectedEntregador] = useState('ALL');
  const [openEntregadorModal, setOpenEntregadorModal] = useState(false);
  const [openEntregadorModal1, setOpenEntregadorModal1] = useState(false);
  const [cancelarOpen, setCancelarOpen] = useState(false);
  const { selecionarPedido, selecionarMarker, selecionarMarkers, cardPedidoSelecionado, rotaAtual, selecionarRota, roteando, iniciarRota, pararRota } = usePedido();
  const [pedidoID, setPedidoID] = useState(null);
  const [selectedPedidos, setSelectedPedidos] = useState([]);
  const [isRouting, setIsRouting] = useState(false);
  const [showExtraButtons, setShowExtraButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openCalculos, setOpenCalculos] = useState(false);
  const [dadosOcorrencia, setDadosOcorrencia] = useState({});
  const [openOcorrencia, setOpenOcorrencia] = useState(false);
  const colorMode = useSelector(state => state.utils.colorMode);
  const podeAtribuirRota = useSelector(state => state.config.masterPage?.empresa?.podeGerarRota);
  const podeKmTotal = useSelector(state => state.config.masterPage?.empresa?.rotaCobrarKmTotal);
  const podeEntrePontos = useSelector(state => state.config.masterPage?.empresa?.rotaCobrarEntrePontos);
  const [payloadzin, setPayloadzin] = useState({});
  const limparRota = useSelector(state => state.criarPedido?.limparRota);
  const tipoDeUsuario = useSelector(state => state.config?.tipoDeUsuario);
  const template = useSelector(state => state.config?.template);
  const podeRota = pickSelecionada?.tipoPagamento !== 2;
  const podeRotear = useSelector(state => state.config.masterPage?.empresa?.podeRoterizarEntregador);
  const { showSuccess, showError } = useSnackbarContext();

  const toggleExtraButtons = () => {
    setShowExtraButtons(prevState => !prevState);
    setIsRouting(prevState => {
      const newRoutingState = !prevState;
      if (!newRoutingState) {
        setTimeout(() => {
          if (mapRef.current && mapRef.current.getSource('route')) {
            mapRef.current.removeLayer('route');
            mapRef.current.removeSource('route');
            setSelectedPedidos([]);
          }
        }, 0);
      }

      return newRoutingState;
    });
  };

  useEffect(() => {
    if (isRouting) {
      iniciarRota();
    } else {
      pararRota();
    }
  }, [isRouting])

  useEffect(() => {
    if (limparRota) {
      limparRotas();
    }
    setTimeout(() => {
      dispatch(resetRota());
    }, 2000);
  }, [limparRota]);

  // Função Para Centralizar Marker no Mapa
  const flyToMarker = (markerID) => {
    if (isRouting) return
    const marker = mapRef.current?.markers?.filter(marker => marker._element.id === markerID);
    if (!marker) return
    mapRef.current.flyTo({ center: marker[0]?.getLngLat(), zoom: 13, speed: 1.5, curve: 1.5, essential: true })
  }

  // Função Para Limpar Rotas
  const limparRotas = () => {
    if (mapRef.current && mapRef.current.getSource('route')) {
      mapRef.current.removeLayer('route');
      mapRef.current.removeSource('route');
      setSelectedPedidos([]);
    }
  }

  // Função Ativada no clique de um Marker Pedido
  const handlePedidoClick = (pedido) => {
    if (roteando) {
      selecionarMarkers(pedido.pedidoID);
    }
    selecionarMarker(pedido.pedidoID);

    flyToMarker(pedido.pedidoID);

    if (isRouting && mapRef.current && mapRef.current.isStyleLoaded()) {
      if (pedido.situacao !== 'PRONTO' && pedido.situacao !== 'RECEBIDO') {
        showError({
          message: "Apenas pedidos com a situação 'PRONTO' ou 'RECEBIDO' podem ser selecionados.",
          duration: 5000
        });
        return; // Retorna sem adicionar o pedido
      }
      setSelectedPedidos(prev => {
        const pedidoExists = prev.some(p => p.pedidoID === pedido.pedidoID);
        const empresa = empresas?.find(e => e.empresaID === pedido.empresaID);
        const firstPedido = prev[0];
        if (firstPedido) {
          const firstEmpresa = empresas?.find(e => e.empresaID === firstPedido.empresaID);

          if (
            (empresa?.endereco?.latitude !== firstEmpresa?.endereco?.latitude) ||
            (empresa?.endereco?.longitude !== firstEmpresa?.endereco?.longitude)
          ) {
            showError({
              message: 'Todos os pedidos devem ter a mesma origem para traçar a rota.',
              duration: 5000
            });
            return prev; // Retorna a lista anterior sem adicionar o novo pedido
          }
        }

        const newSelectedPedidos = !pedidoExists ? [...prev, pedido] : prev;

        const routeCoordinates = [
          empresa ? [empresa.endereco?.longitude, empresa.endereco?.latitude] : [],
          ...newSelectedPedidos.map(p => [p?.longitude, p?.latitude])
        ];

        drawRoute(routeCoordinates);

        return newSelectedPedidos;
      });
    }
  };

  const atribuirRota = useCallback(() => {
    try {
      if (selectedPedidos.length === 0) {
        showError({
          message: "A lista de pedidos não pode estar vazia.",
          duration: 5000
        })
        return;
      }

      const pedidosUnicos = new Set();
      let novosPedidosSelecionados = [...selectedPedidos];

      novosPedidosSelecionados = novosPedidosSelecionados.filter((pedido) => {
        if (pedidosUnicos.has(pedido.pedidoID)) {
          showError({
            message: "Não é permitido adicionar itens repetidos.",
            duration: 5000
          })
          return false; // Remove pedido repetido
        }
        pedidosUnicos.add(pedido.pedidoID);

        return true;
      });

      setSelectedPedidos(novosPedidosSelecionados);
      selecionarRota(novosPedidosSelecionados);
      abrirAtribuirPedido();
      toggleExtraButtons();
    } catch (error) {
      window.alert(error.message);
    }
  }, [selectedPedidos, selecionarRota, abrirAtribuirPedido]);

  const calcularPorKM = (entregador) => {
    const payload = {
      pedidoID: selectedPedidos.map(p => p.pedidoID),
      usuarioID: entregador.usuarioID,
      rota: !!rotaAtual,
      cobrarKm: true
    };
    setPayloadzin(payload);
    dispatch(despacharRequest(payload))
  }

  const calcularEntrePontos = (entregador) => {
    const payload = {
      pedidoID: selectedPedidos.map(p => p.pedidoID),
      usuarioID: entregador.usuarioID,
      rota: !!rotaAtual,
      cobrarKm: false,
      orcamento: true
    };
    setPayloadzin(payload);
    dispatch(despacharRequest(payload))
    setOpenCalculos(true);
  }

  const drawRoute = (coordinates) => {
    if (mapRef.current.getSource('route')) {
      mapRef.current.removeLayer('route');
      mapRef.current.removeSource('route');
    }

    if (!mapRef.current.getSource('route')) {
      mapRef.current.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: coordinates
          }
        }
      });

      mapRef.current.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
          
        },
        paint: {
          'line-color': theme.palette.primary.main,
          'line-width': 4
        }
      });
    }
  };

  const handleOpenChat = () => {
    abrirChatLateral();
  };

  const handleOcorrencia = () => {
    setOpenOcorrencia(true);
  };

  const handleZap = (zap, texto) => {
    window.open(`https://api.whatsapp.com/send?phone=55${zap}&text=${texto}`, '_blank');
  };

  const handleOpenParceira = () => {
    console.log('Abrir parceira');
  };

  const alterarSituacao = (situacao) => {
    console.log('Alterar situacao', situacao);
  };

  const atribuirEntregador = (entregador) => {
    console.log('Atribuir entregador', entregador);
  };

  // Cria um novo mapa caso o mesmo não exista, adiciona eventos de resize quando currentCenter muda
  useEffect(() => {
    if (!mapRef.current) {
      if (!mapboxgl.supported) {
        alert('Mapbox não está disponível');
        return
      }
      mapboxgl.accessToken = 'pk.eyJ1IjoicGlja25nby1kZW1vIiwiYSI6ImNsOXlxNXpxNzA3Y20zb2x4djBodmh0dHIifQ.F4C8zTSN6X257GKlrB5nWQ';
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: template?.mapa,
        center: currentCenter,
        zoom: 12,
        attributionControl: false,
        logoPosition: 'bottom-left',
        projection: 'globe',
      });

      mapRef.current.on('style.load', () => {
        mapRef.current.touchZoomRotate.disableRotation();
        mapRef.current.dragRotate.disable();
        setIsLoading(false);
      });
      if (latlngPick) {
        mapRef.current.setCenter([latlngPick.lng, latlngPick.lat]);
      }
      mapRef.current.on('moveend', () => {
        const center = mapRef.current?.getCenter();
        setCurrentCenter([center.lng, center.lat]);
      });
    }

    const mapContainer = mapContainerRef.current;
    if (mapContainer) {
      const resizeObserver = new ResizeObserver(() => {
        if (mapRef.current) { mapRef.current.resize() }
      });

      resizeObserver.observe(mapContainer);

      return () => {
        if (mapContainer) { resizeObserver.unobserve(mapContainer) }
      };
    }
  }, [currentCenter]);

  useEffect(() => {
    if (mapRef.current && latlngPick) {
      mapRef.current.flyTo({ center: [latlngPick.lng, latlngPick.lat], zoom: 13 });
    }
  }, [pickSelecionada]);

  // Use Effect para trocar o tema do Mapa
  useEffect(() => {
    if (colorMode === 'light') {
      mapRef.current?.setStyle(template?.mapa || 'mapbox://styles/pickngo-demo/clabx63al002z14qpcdwwsq40');
    } else if (colorMode === 'dark') {
      mapRef.current?.setStyle('mapbox://styles/pickngo-demo/clkbehgyk03kl01ns9k0t2o39');
    }
  }, [colorMode])

  useEffect(() => {
    if (filtros?.status) {
      setSelectedSituacao(filtros.status);
    } else {
      setSelectedSituacao('ALL');
    }
  }, [filtros?.status]);

  useEffect(() => {
    if (filtros?.entregador) {
      setSelectedEntregador(filtros.entregador);
    } else {
      setSelectedEntregador('ALL');
    }
  }, [filtros?.entregador]);


  // Use Effect para resolver bug de resize no modal mobile 
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [styleProps]);

  useEffect(() => {
    const addMarkerWithPopup = (position, color, customElement = null, popup, onClick) => {
      if (!position || !position.latitude || !position?.longitude) {
        return;
      }

      const marker = new mapboxgl.Marker({
        element: customElement,
        color,
        anchor: 'bottom',
        offset: [0, 0]
      })
        .setLngLat([position?.longitude, position?.latitude]);

      // Check if the popup is a valid Popup instance before setting it
      if (popup instanceof mapboxgl.Popup) {
        marker.setPopup(popup);
      } else {
        console.error('Invalid popup:', popup);
      }

      marker.addTo(mapRef.current);

      marker.getElement().addEventListener('click', () => {
        popup.setLngLat([position?.longitude, position?.latitude]).addTo(mapRef.current);
        if (onClick) {
          onClick();
        }
      });

      if (!mapRef.current.markers) mapRef.current.markers = [];
      mapRef.current.markers.push(marker);
    };


    const clearMarkers = () => {
      if (mapRef.current?.markers) {
        mapRef.current.markers.forEach(marker => marker.remove());
        mapRef.current.markers = [];
      }
    };
    clearMarkers();

    if (pickSelecionada && pickSelecionada.latitude && pickSelecionada?.longitude) {
      const popupContentEmpresa = document.createElement('div');
      popupContentEmpresa.innerHTML = `Nome da empresa: <b>${pickSelecionada?.nome}</b><br>Localização: <b>${pickSelecionada?.enderecoStr}</b>`;
      popupContentEmpresa.style.color = 'black';

      const popUpPick = new mapboxgl.Popup({ offset: [0, 0] })

      addMarkerWithPopup(
        { latitude: pickSelecionada.latitude, longitude: pickSelecionada?.longitude },
        theme.palette.primary.main,
        null,
        popUpPick,
        () => { }
      );
    } else {
      console.error('pickSelecionada is undefined or does not contain valid latitude/longitude.');
    }


    empresas?.forEach((empresa) => {
      if (empresa?.longitude && empresa?.latitude) {
        const popupContentEmpresa = document.createElement('div');
        popupContentEmpresa.innerHTML = `Nome da empresa: <b>${empresa?.nome}</b><br>Localização: <b>${empresa?.enderecoStr}</b>`;
        popupContentEmpresa.style.color = 'black';

        const popUpEmpresa = new mapboxgl.Popup({ offset: [0, -40] }).setDOMContent(popupContentEmpresa);

        addMarkerWithPopup(
          { latitude: empresa?.latitude, longitude: empresa?.longitude },
          theme.palette.primary.main,
          null,
          popUpEmpresa,
          () => { }
        )
      } else {
        console.error('Longitude ou latitude inválida para a empresa:', empresa);
      }
    })

    entregadores?.lista?.forEach(entregador => {
      if (selectedEntregador == 'ALL' || entregador.id === selectedEntregador) {

        const entregadorElement = document.createElement('div');
        let primeiroNome = entregador.nome.split(' ')[0];

        if (primeiroNome.length > 5) {
          primeiroNome = primeiroNome.substring(0, 6);
        }

        // const ghost = moment().diff(moment(entregador.dataUltimaLocalizacaoStr), 'minutes') > 10;
        //* Tá Ghosteado

        entregadorElement.innerHTML = `
          <div style="position: relative; display: inline-block; text-align: center;">
            <svg width="40" height="40" id="Camada_2" data-name="Camada 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203.82 223.63">
              <defs></defs>
              <g id="Camada_1-2" data-name="Camada_1">
                <g>
                  <g>
                    <circle class="${entregador.pedidosEmAndamento?.length > 0 ? 'ocupado' : 'cls-2'}" cx="97" cy="91" r="91"/>
                    <rect class="${entregador.pedidosEmAndamento?.length > 0 ? 'ocupado' : 'cls-2'}" x="1" y="148.63" width="999.82" height="195" rx="1.3" ry="1.3"/>
                    <text x="197" y="191" class="cls-5">Delivery</text>
                  </g>
                  <g>
                    <path class="cls-6" d="M150.73,87.67c-.48,6.83-2.31,13.3-5.6,19.34-2,3.67-4.48,6.97-7.27,10.08-.89.99-1.94,1.27-3.11,1.44-8.28,1.22-16.56,2.46-24.85,3.67-6.94,1.02-13.89,2-20.83,3.03-6.98,1.03-13.96,2.1-20.94,3.15-5.64.84-11.28,1.69-16.94,2.48-4.25.6-7.24-2.03-7.24-6.28,0-5.47.03-10.94.04-16.41.73-3.39,1.44-6.79,2.2-10.18,1.63-7.22,3.07-14.49,4.95-21.65.69-2.65,1.6-5.24,2.89-7.67,3.22-7.22,7.92-13.34,13.91-18.48,7.43-6.39,16-10.45,25.64-12.24,27.61-5.14,51.52,12.61,56.39,37.65.18.96.44,1.93.37,2.93.31.65.23,1.34.22,2.02.48,2.37.35,4.77.18,7.13Z"/>
                    <path class="cls-7" d="M150.73,87.67c-.48,6.83-2.31,13.3-5.6,19.34-2,3.67-4.48,6.97-7.27,10.08-.89.99-1.94,1.27-3.11,1.44-8.28,1.22-16.56,2.46-24.85,3.67-6.94,1.02-13.89,2-20.83,3.03-6.98,1.03-13.96,2.1-20.94,3.15-5.64.84-11.28,1.69-16.94,2.48-4.25.6-7.24-2.03-7.24-6.28,0-5.47.03-10.94.04-16.41.4-.38.91-.35,1.4-.36,1.56,0,3.12-.04,4.68.02.04,0,.08,0,.11,0,.07,0,.14,0,.2,0,1.18-.02,2.36-.02,3.53,0h.26c8.76.03,17.53.17,26.29-.04,7.94-.19,14.9-3.08,20.69-8.65,5.61-5.39,8.79-11.91,9.57-19.62,0-.04,0-.08.01-.11.02-.1.03-.19.04-.29.43-2.97.14-5.76-2.17-8.04-.22-.22-.42-.48-.48-.8,0,0,0-.02,0-.04,0-.04,0-.08,0-.12.01-.48.29-.84.59-1.19,6.17-7.37,16.62-10.36,25.74-7.3,7.59,2.55,12.72,7.63,15.39,15.17.21.6.25,1.22.4,1.82,0,.03.02.06.02.09,0,.03.02.06.03.09.18.56.05,1.17.27,1.73.48,2.37.35,4.77.18,7.13Z"/>
                    <path class="cls-1" d="M150.28,78.81c-.05.02-.13.01-.22,0-.79-3.74-2.58-6.98-5.06-9.84-4.11-4.74-9.37-7.48-15.58-8.1-6.11-.61-11.74.98-16.67,4.72-1.73,1.31-3.38,2.74-4.51,4.65-.04.02-.09.04-.13.06-.19.07-.4.03-.56-.08-1.79-1.18-3.8-1.24-5.84-1.17-.05,0-.1,0-.16,0-.18,0-.37,0-.55,0-11.09.1-22.17.02-33.26.04-.7,0-1.4.05-2.09-.11-.24-.1-.39-.21-.47-.33-.01-.01-.02-.03-.03-.04h0c-.2-.36.16-.79.36-1.13,1.04-1.78,2.28-3.42,3.62-4.99,7.48-8.79,17.03-13.83,28.4-15.5,1.5-.22,3.02-.44,4.55-.35.33.02.66.01.99-.03,1.16-.15,1.89-.87,1.83-1.79-.06-.9-.75-1.47-1.93-1.48-4.69-.02-9.27.73-13.75,2.1-6.31,1.93-12.01,5.01-17,9.3-4.24,3.65-7.85,7.85-10.62,12.74-.2.36-.38.73-.67,1.03-.04.05-.08.09-.13.13-.07.07-.14.12-.22.17,0,0-.02.02-.03.02-.04.02-.07.04-.11.05-.1.04-.19.07-.3.1-1.67.16-3.34.07-5.01.06-.4,0-.82-.03-1.11-.39,3.22-7.22,7.92-13.34,13.91-18.48,7.43-6.39,16-10.45,25.64-12.24,27.61-5.14,51.52,12.61,56.39,37.65.18.96.44,1.93.37,2.93.07.18.04.26-.05.29Z"/>
                    <path class="cls-3" d="M60.43,69.76c-2.76,5.22-3.98,10.93-5.22,16.63-1.49,6.86-3.06,13.7-4.56,20.55-.07.31-.13.62-.28.88-.07.12-.16.22-.27.32-2.04,0-4.08.02-6.12.02.73-3.39,1.44-6.79,2.2-10.18,1.63-7.22,3.07-14.49,4.95-21.65.69-2.65,1.6-5.24,2.89-7.67,2.17.02,4.34.04,6.5.06.02.03.04.07.06.1.16.33,0,.64-.15.94Z"/>
                    <path class="cls-8" d="M150.55,80.54c-.39-.51-.23-1.18-.49-1.73.06-.06.11-.12.16-.18.04-.04.07-.08.11-.11.31.65.23,1.34.22,2.02Z"/>
                    <path class="cls-3" d="M65.23,68.64c8.44.01,16.89.02,25.33.04,3.75,0,7.5.03,11.24.04-.02.52-.48.49-.8.61-3.4,1.27-5.52,4.34-5.29,7.65.25,3.59,2.68,6.27,6.37,7.05,2.9.61,6.2-1.02,7.82-3.8.26-.44.28-1.17,1.07-1.13.02,0,.04.06.04.09,0,.04,0,.08-.01.12-.53,7.43-3.52,13.78-8.65,19.11-6.15,6.39-13.74,9.62-22.59,9.72-8.56.1-17.12,0-25.68,0-.36-.51-.12-1.04,0-1.54,1.87-8.35,3.7-16.7,5.58-25.05.84-3.75,2.17-7.33,3.88-10.77.37-.74.74-1.47,1.3-2.09.12-.09.25-.1.39-.05Z"/>
                    <path class="cls-5" d="M64.99,68.72c-1.57,2.98-3.03,6.01-4,9.25-1.54,5.15-2.45,10.44-3.68,15.67-1.13,4.82-2.16,9.67-3.23,14.51-1.33,0-2.66,0-3.99,0,.83-3.84,1.64-7.68,2.5-11.51,1.38-6.15,2.76-12.3,4.2-18.43.78-3.33,2.27-6.4,3.72-9.48,0,0,0-.08,0-.08,1.5-.45,3-.66,4.48.08Z"/>
                    <path class="cls-4" d="M111.01,79.32c-.89.44-.96,1.45-1.52,2.14-2.69,3.29-7.38,4.05-10.9,1.41-2.52-1.89-3.63-4.51-3.1-7.55.58-3.32,2.59-5.59,6-6.41.11-.03.21-.12.31-.19,2.33-.3,4.48.19,6.43,1.5,2.76,2.47,3.49,5.56,2.77,9.09Z"/>
                    <path class="cls-7" d="M64.99,68.72c-1.49-.03-2.99-.05-4.48-.08,1.22-1.9,2.29-3.88,3.65-5.69,4.09-5.45,8.96-10,14.9-13.42,4.71-2.71,9.71-4.6,15.04-5.64,2.94-.57,5.91-.93,8.91-.88,1.41.02,2.22.71,2.26,1.9.03,1.09-.9,2.05-2.18,2.03-3.1-.05-6.13.37-9.15,1.02-6.7,1.44-12.73,4.29-18.13,8.51-3.89,3.04-7.13,6.65-9.8,10.78-.29.45-.53.93-.79,1.39-.08.03-.16.06-.24.08Z"/>
                    <path class="cls-3" d="M99.35,76.62c-.01-2.24,1.73-3.98,3.97-3.98,2.19,0,3.91,1.71,3.94,3.89.03,2.22-1.81,4.02-4.09,4.01-2-.01-3.81-1.87-3.82-3.92Z"/>
                  </g>
                </g>
              </g>
            </svg>
            <div style="position: absolute; bottom: 0; padding-bottom: 1px; font-size: 10px; color: white; width: 100%; text-align: center; text-shadow: 0.2px 0.2px 0.1px black, -1px -1px 1px black, -1px 1px 1px black, 1px -1px 2px black;">
              ${primeiroNome}
            </div>
          </div>`;

        const popupContent = document.createElement('div');
        popupContent.className = 'popupContent';
        popupContent.style.zIndex = '9999';

        const tituloDiv = document.createElement('div');
        tituloDiv.innerHTML = `${entregador.nome}`;
        tituloDiv.className = 'tituloDiv';

        const dataDiv = document.createElement('div');
        dataDiv.innerHTML = `${entregador.dataUltimaLocalizacaoStr}`;
        dataDiv.className = 'dataDiv';

        const tipoVeiculoDiv = document.createElement('div');
        tipoVeiculoDiv.innerHTML = `Tipo de veículo: <b>${entregador.tipoVeiculoNome}</b>`;
        tipoVeiculoDiv.className = 'tipoVeiculoDiv';

        const ultimoPedidoDiv = document.createElement('div');
        ultimoPedidoDiv.innerHTML = `Último pedido: <b>${entregador.dataUltimoPedidoStr}</b>`;
        ultimoPedidoDiv.className = 'ultimoPedidoDiv';

        const bateriaDiv = document.createElement('div');
        bateriaDiv.className = 'bateriaDiv';

        const bateriaPercentual = document.createElement('b');
        bateriaPercentual.innerText = `${entregador.percentualBateria}%`;

        if (entregador.percentualBateria > 60) {
          bateriaPercentual.className = 'bateriaAlta';
        } else if (entregador.percentualBateria >= 30) {
          bateriaPercentual.className = 'bateriaMedia';
        } else {
          bateriaPercentual.className = 'bateriaBaixa';
        }

        bateriaDiv.innerHTML = 'Bateria: ';
        bateriaDiv.appendChild(bateriaPercentual);

        const contatoDiv = document.createElement('div');
        contatoDiv.innerHTML = `Contato: <a><b>${telefone(entregador.telefone)}</b></a>`;
        contatoDiv.className = 'contatoDiv';

        const buttonsDiv = document.createElement('div');
        buttonsDiv.className = 'buttonsDiv';

        const button1 = document.createElement('button');
        button1.className = 'button';
        button1.style.width = '90%';
        button1.innerHTML = 'Abrir chat';
        button1.addEventListener('click', handleOpenChat);

        const button2 = document.createElement('button');
        button2.className = 'button';
        button2.innerHTML = 'Ocorrência';
        button2.addEventListener('click', handleOcorrencia);

        const button3 = document.createElement('button');
        button3.className = 'button button3';
        button3.addEventListener('click', () => { handleZap(entregador.telefone) });

        const whatsappIcon = document.createElement('img');
        whatsappIcon.src = 'https://i.ibb.co/qdy3Wx3/3c43d9252fbd1cae5e071c62e0dd9d6f-removebg-preview.png';
        whatsappIcon.className = 'whatsappIcon';

        //contatoDiv.appendChild(whatsappIcon);

        button3.appendChild(whatsappIcon);
        tipoDeUsuario !== 'Representante' && buttonsDiv.appendChild(button1);
        tipoDeUsuario === 'Representante' && buttonsDiv.appendChild(button2);
        buttonsDiv.appendChild(button3);

        popupContent.appendChild(tituloDiv);
        popupContent.appendChild(dataDiv);
        popupContent.appendChild(tipoVeiculoDiv);
        popupContent.appendChild(ultimoPedidoDiv);
        popupContent.appendChild(bateriaDiv);
        popupContent.appendChild(contatoDiv);
        popupContent.appendChild(buttonsDiv);

        const popup = new mapboxgl.Popup({ offset: 50 }).setDOMContent(popupContent);

        addMarkerWithPopup({ latitude: entregador.latitude, longitude: entregador?.longitude }, null, entregadorElement, popup);
      }
    });

    const pedidosFiltrados = emAndamento?.filter(pedido => {
      const filtrarPorSituacao = selectedSituacao === 'ALL' || pedido.situacao === selectedSituacao;
      const filtrarPorEntregador = selectedEntregador === 'ALL' || pedido.entregadorUsuarioID === selectedEntregador;
      return filtrarPorSituacao && filtrarPorEntregador;
    }) || [];

    pedidosFiltrados?.forEach(pedido => {
      {
        const pedidoElement = document.createElement('div');
        pedidoElement.className = 'pedidoElement';
        pedidoElement.style.backgroundColor = theme.palette.status[sanitizeSituacao(pedido)];
        pedidoElement.style.border = `0px solid ${theme.palette.status[sanitizeSituacao(pedido)]}`;
        pedidoElement.style.color = theme.palette.getContrastText(theme.palette.status[sanitizeSituacao(pedido)]);

        const codigoDiv = document.createElement('div');
        codigoDiv.innerText = `#${pedido.codigoQuatro}`;
        codigoDiv.className = 'codigoDiv';

        const setaDiv = document.createElement('div');
        setaDiv.className = 'setaDiv';
        setaDiv.style.borderTop = `10px solid ${theme.palette.status[sanitizeSituacao(pedido)]}`;

        pedidoElement.appendChild(codigoDiv);
        pedidoElement.appendChild(setaDiv);

        const popupContent = document.createElement('div');
        popupContent.className = 'popupContent';

        const tituloDiv = document.createElement('div');
        tituloDiv.innerHTML = `${pedido.empresa.nome} - <b>#${pedido.codigoQuatro}</b>`;
        tituloDiv.className = 'tituloDiv';

        const dataCriacaoDiv = document.createElement('div');
        dataCriacaoDiv.innerText = `${pedido.dataCadastroStr}(${pedido.tempoDecorridoCadastro})`;
        dataCriacaoDiv.className = 'dataCriacaoDiv';

        const rastreio = `${window.location.origin}/rastreio/${pedido.pedidoID}`;

        const botaoZapCliente = document.createElement('button');
        botaoZapCliente.className = 'button button3';
        botaoZapCliente.addEventListener('click', () => {
          handleZap(pedido.clienteTelefone, `Olá, ${pedido.clienteNome}! Seu pedido está a caminho, segue link de rastreio: ${rastreio}`);
        });
        const whatsappIcon = document.createElement('img');
        whatsappIcon.src = 'https://i.ibb.co/qdy3Wx3/3c43d9252fbd1cae5e071c62e0dd9d6f-removebg-preview.png';
        whatsappIcon.className = 'whatsappIcon';

        botaoZapCliente.appendChild(whatsappIcon);

        const nomeDiv = document.createElement('div');
        nomeDiv.innerHTML = `Cliente: <b>${pedido.clienteNome}</b>`;
        nomeDiv.className = 'nomeDiv';

        nomeDiv.appendChild(botaoZapCliente);

        const enderecoDiv = document.createElement('div');
        enderecoDiv.innerHTML = `Destino: <b>${pedido.entregaEnderecoLogradouro}</b>`;
        enderecoDiv.className = 'enderecoDiv';

        const pagamentoDiv = document.createElement('div');
        pagamentoDiv.className = 'pagamentoDiv';
        pagamentoDiv.innerHTML = `Forma de pagamento: <b>${pedido.formaPagamento?.nome}</b>`;

        const botoesDiv = document.createElement('div');
        botoesDiv.className = 'botoesDiv';

        const botaoAtribuir = document.createElement('button');
        botaoAtribuir.innerText = (pedido.situacao === 'RECEBIDO' || pedido.situacao === 'PRONTO') ? 'Atribuir' : 'Reatribuir';
        botaoAtribuir.className = 'botao';
        botaoAtribuir.addEventListener('click', () => {
          selecionarPedido(pedido);
          abrirAtribuirPedido();
        });

        const botaoSituacao = document.createElement('button');
        botaoSituacao.innerText = 'Alterar situação';
        botaoSituacao.className = 'botao';
        botaoSituacao.addEventListener('click', () => {
          abrirTrocarStatus(pedido);
        });

        const botaoEntregue = document.createElement('button');
        botaoEntregue.innerText = 'Entregue';
        botaoEntregue.className = 'botao';
        botaoEntregue.addEventListener('click', () => {
          dispatch(marcarFinalizadoRequest(pedido.pedidoID));
        });

        const botaoColetado = document.createElement('button');
        botaoColetado.innerText = 'Coletado';
        botaoColetado.className = 'botao';
        botaoColetado.addEventListener('click', () => {
          console.log('Coletado');
        });

        const botaoRetornando = document.createElement('button');
        botaoRetornando.innerText = 'Pagamento Entregue';
        botaoRetornando.className = 'botao';
        botaoRetornando.addEventListener('click', () => {
          dispatch(marcarFinalizadoRequest(pedido.pedidoID));
        });

        const botaoCancelar = document.createElement('button');
        botaoCancelar.innerText = 'Cancelar';
        botaoCancelar.className = 'botao';
        botaoCancelar.addEventListener('click', () => {
          setPedidoID(pedido.pedidoID);
          setCancelarOpen(true);
        });

        switch (pedido.situacao) {
          case 'RECEBIDO':
            botoesDiv.appendChild(botaoCancelar);
            botoesDiv.appendChild(botaoAtribuir);
            //botoesDiv.appendChild(botaoSituacao);
            break;
          case 'DESPACHADO':
            botoesDiv.appendChild(botaoAtribuir);
            botoesDiv.appendChild(botaoCancelar);
            break;
          case 'ACEITO':
            botoesDiv.appendChild(botaoAtribuir);
            botoesDiv.appendChild(botaoZapCliente);
            break;
          case 'NO_ESTABELECIMENTO':
            botoesDiv.appendChild(botaoAtribuir);
            botoesDiv.appendChild(botaoZapCliente);

            break;
          case 'PRONTO':
            botoesDiv.appendChild(botaoAtribuir);
            botoesDiv.appendChild(botaoZapCliente);

            break;
          case 'EM_ROTA':
            botoesDiv.appendChild(botaoEntregue);
            botoesDiv.appendChild(botaoZapCliente);

            break;
          case 'RETORNANDO':
            botoesDiv.appendChild(botaoRetornando);
            break;
          default:
            break;
        }

        pedidoElement.id = pedido.pedidoID
        popupContent.appendChild(tituloDiv);
        popupContent.appendChild(dataCriacaoDiv);
        popupContent.appendChild(nomeDiv);
        popupContent.appendChild(pagamentoDiv);
        popupContent.appendChild(enderecoDiv);
        { tipoDeUsuario !== 'Representante' && popupContent.appendChild(botoesDiv); }

        const popup = new mapboxgl.Popup({ offset: [0, -30] }).setDOMContent(popupContent);

        addMarkerWithPopup({ latitude: pedido.latitude, longitude: pedido?.longitude }, null, pedidoElement, popup, () => handlePedidoClick(pedido));

        if (pedido.origemEnderecoLatitude !== null && pedido.origemEnderecoLongitude !== null) {
          const origemElement = document.createElement('div');
          origemElement.className = 'origemElement';
          origemElement.style.backgroundColor = 'black';
          origemElement.style.color = 'white';
          origemElement.style.padding = '2px 5px';
          origemElement.style.borderRadius = '3px';
          origemElement.innerText = `C^${pedido.codigoQuatro}`;

          const origemPopupContent = popupContent.cloneNode(true);

          const origemPopup = new mapboxgl.Popup({ offset: [0, 0] }).setDOMContent(origemPopupContent);

          addMarkerWithPopup({ latitude: pedido?.origemEnderecoLatitude, longitude: pedido?.origemEnderecoLongitude }, 'black', origemElement, origemPopup);
        }
      }
    });
  }, [position, entregadores, emAndamento, filtros?.status, selectedSituacao, selectedEntregador, isRouting, selectedPedidos, isLoading, cardPedidoSelecionado]);

  const opcoes = [
    { label: 'Cancelar', action: () => setCancelarOpen(false) },
    {
      label: 'Confirmar', action: () => {
        dispatch(cancelarPedidoRequest(pedidoID))
        setCancelarOpen(false)
      }
    },
  ]

  useEffect(() => {
    if (cardPedidoSelecionado) {
      flyToMarker(cardPedidoSelecionado.pedidoID)
      // const empresa = empresas.find(e => e.empresaID === cardPedidoSelecionado.empresaID)
      if (isRouting && mapRef.current && mapRef.current.isStyleLoaded()) {
        handlePedidoClick(cardPedidoSelecionado)
      }
    }
  }, [cardPedidoSelecionado]);

  if (!mapboxgl.supported) return <Box />
  return (
    <>
      <MapContainer ref={mapContainerRef} style={{ height: '100%', width: '100%', ...styleProps }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          position: 'absolute',
          top: '.5rem',
          left: '1rem',
          gap: '1rem',
          ...isMobile && { padding: '.5rem 0 0' },
        }}
      >
        {podeRota && podeRotear && <Button
          variant='containedFilled'
          sx={{
            width: 'fit-content',
            py: '0.39rem'
          }}
          onClick={toggleExtraButtons}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : showExtraButtons ? <CloseOutlined size={20} /> : <Route size={20} />}
        </Button>}
        <Collapse in={showExtraButtons} orientation={isMobile ? 'vertical' : 'horizontal'} timeout={300}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: '1rem'
            }}
          >
            <Button variant='containedFilled' sx={{ width: 'fit-content', height: '40px' }} onClick={limparRotas}>Limpar rotas</Button>
            {!isMobile && <Button variant='containedFilled' sx={{ width: 'fit-content' }} onClick={atribuirRota}>Atribuir</Button>}
            {podeKmTotal && <Button variant='containedFilled' sx={{ width: 'max-content', flexShrink: 0 }} onClick={() => { setOpenEntregadorModal(true) }}>Calcular por KM total</Button>}
            {podeEntrePontos && <Button variant='containedFilled' sx={{ width: 'max-content', flexShrink: 0 }} onClick={() => { setOpenEntregadorModal1(true) }}>Calcular entre pontos</Button>}
          </Box>
        </Collapse >
      </Box>
      <ModalOpcoes
        open={cancelarOpen}
        onClose={() => setCancelarOpen(false)}
        acao="Cancelar pedido"
        descricao="Tem certeza que deseja cancelar este pedido?"
        opcoes={opcoes}
      />
      <ModalPesquisarEntregador
        modalProps={{
          open: openEntregadorModal,
          onClose: () => setOpenEntregadorModal(false),
        }}
        callback={calcularPorKM}
      />
      <ModalPesquisarEntregador
        modalProps={{
          open: openEntregadorModal1,
          onClose: () => setOpenEntregadorModal1(false),
        }}
        callback={calcularEntrePontos}
      />
      <ModalCalculos open={openCalculos} handleClose={() => setOpenCalculos(false)} payloadzin={payloadzin} />
      <ModalCalculos open={openCalculos} handleClose={() => setOpenCalculos(false)} payloadzin={payloadzin} />
      <OcorrenciaPorEntregador dados={dadosOcorrencia} show={openOcorrencia} setShow={setOpenOcorrencia} />
    </>
  );
}

export default MapHome;
