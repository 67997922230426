import { PaletteMode } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultAsyncState } from ".";
import { GerarQrCode } from "src/dtos/QrCodeDTOS";
import { Cidades, Estados } from "src/dtos/UtilsDTO";

interface Filtros {
  status?: string;
  entregador?: string;
  pedidos?: string;
  empresas?: string;
}

interface State {
  filtros: Filtros | null;
  resposta: any | null;
  erro: any | null;
  loading: boolean;
  visualizouTreinamento: boolean;
  sizeAtual: number;
  colorMode: PaletteMode;
  cnpjDados: any[];
  cidades: Cidades;
  cidadesLoading: boolean;
  estados: Estados;
  estadosLoading: boolean;
  parceiros: any[];
  parceirosLoading: boolean;
  planos: any[];
  criarPedido: boolean;
  permiteSom: boolean;
  listarQrCode: DefaultAsyncState<any>;
  gerarQrCode: DefaultAsyncState<GerarQrCode>;
  atualizarQrCode: DefaultAsyncState<any>;
  inativarQrCode: DefaultAsyncState<any>;
  devMode: boolean;
  bancos: any;
  comunicados: any;
  openMenuItem: any;
  comunicadosAberto: any;
  xtokenMasterpage: any;
  templateLogin: any;
}

const initialState: State = {
  filtros: null,
  resposta: null,
  erro: null,
  loading: false,
  visualizouTreinamento: false,
  sizeAtual: 530,
  colorMode: "light",
  cidades: [],
  cidadesLoading: false,
  estados: [],
  estadosLoading: false,
  parceiros: [],
  parceirosLoading: false,
  planos: [],
  cnpjDados: [],
  criarPedido: false,
  permiteSom: false,
  listarQrCode: {
    loading: false,
    error: null,
    data: null,
  },
  gerarQrCode: {
    loading: false,
    error: null,
    data: null,
  },
  atualizarQrCode: {
    loading: false,
    error: null,
    data: null,
  },
  inativarQrCode: {
    loading: false,
    error: null,
    data: null,
  },
  devMode: false,
  bancos: {
    loading: false,
    error: null,
    data: null,
  },
  comunicados: {
    loading: false,
    error: null,
    data: null,
  },
  openMenuItem: null,
  comunicadosAberto: {},
  xtokenMasterpage: {
    loading: false,
    error: null,
    data: null,
  },
  templateLogin: {
    loading: false,
    error: null,
    data: null,
  },
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setFiltros: (state, action) => {
      state.filtros = action.payload;
    },
    limparFiltros: (state) => {
      state.filtros = null;
    },
    tornarEntregadorOnline: (state, action) => { },
    respostaOnline: (state, action) => {
      state.resposta = action.payload;
    },
    registrarErro: (state, action) => {
      state.erro = action.payload;
    },
    limparErro: (state) => {
      state.erro = null;
    },
    limparResposta: (state) => {
      state.resposta = null;
    },
    concluirTreinamento: (state) => {
      state.visualizouTreinamento = true;
    },
    limparTreinamento: (state) => {
      state.visualizouTreinamento = false;
    },
    mudarSize: (state, action) => {
      state.sizeAtual = action.payload;
    },
    toggleColorMode: (state) => {
      state.colorMode = state.colorMode === "light" ? "dark" : "light";
    },
    lightMode: (state) => {
      state.colorMode = "light";
    },
    listarCidades: (state) => {
      state.cidadesLoading = true;
      state.cidades = [];
    },
    listarCidadesSuccess: (state, action) => {
      state.cidadesLoading = false;
      state.cidades = action.payload;
    },
    listarCidadesFailure: (state, action) => {
      state.cidadesLoading = false;
      state.erro = action.payload;
    },
    listarestados: (state) => {
      state.estados = [];
      state.estadosLoading = true;
    },
    listarestadosSuccess: (state, action) => {
      state.estados = action.payload;
      state.estadosLoading = false;
    },
    listarestadosFailure: (state, action) => {
      state.erro = action.payload;
      state.estadosLoading = false;
    },
    listarParceiros: (state, action) => {
      state.parceirosLoading = true;
    },
    listarParceirosSuccess: (state, action) => {
      state.parceiros = action.payload;
      state.parceirosLoading = false;
    },
    listarParceirosFailure: (state, action) => {
      state.parceirosLoading = false;
      state.erro = action.payload;
    },
    listarPlanos: (state, action) => { },
    listarPlanosSuccess: (state, action) => {
      state.planos = action.payload;
    },
    listarPlanosFailure: (state, action) => {
      state.erro = action.payload;
    },
    listarQrCodeStart: (state, action) => {
      state.listarQrCode.loading = true;
      state.listarQrCode.error = null;
      state.listarQrCode.data = null;
    },
    listarQrCodeSuccess: (state, action) => {
      state.listarQrCode.loading = false;
      state.listarQrCode.error = null;
      state.listarQrCode.data = action.payload;
    },
    listarQrCodeFailure: (state, action) => {
      state.listarQrCode.loading = false;
      state.listarQrCode.error = action.payload;
      state.listarQrCode.data = null;
    },
    gerarQrCodeStart: (state, action) => {
      state.gerarQrCode.loading = true;
      state.gerarQrCode.error = null;
      state.gerarQrCode.data = null;
    },
    gerarQrCodeSuccess: (state, action) => {
      state.gerarQrCode.loading = false;
      state.gerarQrCode.error = null;
      state.gerarQrCode.data = action.payload;
    },
    gerarQrCodeFailure: (state, action) => {
      state.gerarQrCode.loading = false;
      state.gerarQrCode.error = action.payload;
      state.gerarQrCode.data = null;
    },
    limparQrCode: (state) => {
      state.gerarQrCode = initialState.gerarQrCode;
      state.listarQrCode = initialState.listarQrCode;
      state.inativarQrCode = initialState.inativarQrCode;
      state.atualizarQrCode = initialState.atualizarQrCode;
    },
    inativarQrCodeStart: (state, action) => {
      state.inativarQrCode.loading = true;
      state.inativarQrCode.error = null;
      state.inativarQrCode.data = null;
    },
    inativarQrCodeSuccess: (state, action) => {
      state.inativarQrCode.loading = false;
      state.inativarQrCode.error = null;
      state.inativarQrCode.data = action.payload;
    },
    inativarQrCodeFailure: (state, action) => {
      state.inativarQrCode.loading = false;
      state.inativarQrCode.error = action.payload;
      state.inativarQrCode.data = null;
    },
    atualizarQrCodeStart: (state, action) => {
      state.atualizarQrCode.loading = true;
      state.atualizarQrCode.error = null;
      state.atualizarQrCode.data = null;
    },
    atualizarQrCodeSuccess: (state, action) => {
      state.atualizarQrCode.loading = false;
      state.atualizarQrCode.error = null;
      state.atualizarQrCode.data = action.payload;
    },
    atualizarQrCodeFailure: (state, action) => {
      state.atualizarQrCode.loading = false;
      state.atualizarQrCode.error = action.payload;
      state.atualizarQrCode.data = null;
    },
    fetchCnpjStart: (state) => {
      state.loading = true;
    },
    fetchCnpjSuccess: (state, action) => {
      state.cnpjDados = action.payload;
      state.loading = false;
    },
    fetchCnpjFailure: (state, action) => {
      state.loading = false;
      state.erro = action.payload;
    },
    limparCnpjAction: (state) => {
      state.cnpjDados = [];
      state.erro = null;
    },
    toggleDevMode: (state) => {
      state.devMode = !state.devMode;
    },
    toggleCriarPedido: (state, action) => {
      state.criarPedido = action.payload;
    },
    listarBancosStart: (state, action) => {
      state.bancos.loading = true;
      state.bancos.data = null;
      state.bancos.error = null;
    },
    listarBancosSuccess: (state, action) => {
      state.bancos.loading = false;
      state.bancos.data = action.payload;
      state.bancos.error = null;
    },
    listarBancosFailure: (state, action) => {
      state.bancos.loading = false;
      state.bancos.data = null;
      state.bancos.error = action.payload;
    },
    listarComunicadosStart: (state, action) => {
      state.comunicados.loading = true;
      state.comunicados.data = null;
      state.comunicados.error = null;
    },
    listarComunicadosSuccess: (state, action) => {
      state.comunicados.loading = false;
      state.comunicados.data = action.payload;
      state.comunicadosAberto = action.payload.dados?.lista.reduce((acc: { [key: string]: boolean }, comunicado: any) => {
        acc[comunicado.comunicadoID] = true;
        return acc;
      }, {});
      state.comunicados.error = null;
    },
    listarComunicadosFailure: (state, action) => {
      state.comunicados.loading = false;
      state.comunicados.data = null;
      state.comunicados.error = action.payload;
    },
    toggleSom: (state) => {
      state.permiteSom = !state.permiteSom;
    },
    setOpenMenuItem: (state, action) => {
      state.openMenuItem = state.openMenuItem === action.payload ? null : action.payload;
    },
    abrirComunicado: (state, action: PayloadAction<string>) => {
      state.comunicadosAberto[action.payload] = true;
    },
    fecharComunicado: (state, action: PayloadAction<string>) => {
      state.comunicadosAberto[action.payload] = false;
    },
    xtokenMasterpageStart: (state, action) => {
      state.xtokenMasterpage.loading = true;
      state.xtokenMasterpage.error = null;
      state.xtokenMasterpage.data = null;
    },
    xtokenMasterpageSuccess: (state, action) => {
      state.xtokenMasterpage.loading = false;
      state.xtokenMasterpage.error = null;
      state.xtokenMasterpage.data = action.payload;
    },
    xtokenMasterpageFailure: (state, action) => {
      state.xtokenMasterpage.loading = false;
      state.xtokenMasterpage.error = action.payload;
      state.xtokenMasterpage.data = null;
    },
    buscarTemplateLoginStart: (state, action) => {
      state.templateLogin.loading = true;
      state.templateLogin.error = null;
      state.templateLogin.data = null;
    },
    buscarTemplateLoginSuccess: (state, action) => {
      state.templateLogin.loading = false;
      state.templateLogin.error = null;
      state.templateLogin.data = action.payload;
    },
    buscarTemplateLoginFailure: (state, action) => {
      state.templateLogin.loading = false;
      state.templateLogin.error = action.payload;
      state.templateLogin.data = null;
    },
  },
});

export const {
  setFiltros,
  limparFiltros,
  tornarEntregadorOnline,
  respostaOnline,
  registrarErro,
  limparErro,
  limparResposta,
  concluirTreinamento,
  limparTreinamento,
  mudarSize,
  toggleColorMode,
  listarCidades,
  listarCidadesSuccess,
  listarCidadesFailure,
  listarParceiros,
  listarParceirosFailure,
  listarParceirosSuccess,
  listarPlanos,
  listarPlanosFailure,
  listarPlanosSuccess,
  listarQrCodeStart,
  listarQrCodeSuccess,
  listarQrCodeFailure,
  gerarQrCodeStart,
  gerarQrCodeSuccess,
  gerarQrCodeFailure,
  limparQrCode,
  listarestados,
  listarestadosSuccess,
  listarestadosFailure,
  inativarQrCodeStart,
  inativarQrCodeSuccess,
  inativarQrCodeFailure,
  atualizarQrCodeStart,
  atualizarQrCodeSuccess,
  atualizarQrCodeFailure,
  fetchCnpjStart,
  fetchCnpjSuccess,
  fetchCnpjFailure,
  limparCnpjAction,
  lightMode,
  toggleDevMode,
  toggleCriarPedido,
  listarBancosStart,
  listarBancosFailure,
  listarBancosSuccess,
  listarComunicadosFailure,
  listarComunicadosStart,
  listarComunicadosSuccess,
  toggleSom,
  setOpenMenuItem,
  abrirComunicado,
  fecharComunicado,
  xtokenMasterpageFailure,
  xtokenMasterpageStart,
  xtokenMasterpageSuccess,
  buscarTemplateLoginStart,
  buscarTemplateLoginSuccess,
  buscarTemplateLoginFailure,

} = utilsSlice.actions;
export default utilsSlice;
