import React from 'react';
import { Typography, Container, IconButton, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';

interface ListaVaziaProps {
    onCreatePedido: () => void;
}

const ListaVazia: React.FC<ListaVaziaProps> = ({ onCreatePedido }) => {
    const theme = useTheme();
    return (
        <Container style={{
            position: 'relative',
            top: 0,
            textAlign: 'center',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Typography variant="h6" gutterBottom>
                Criar um novo pedido
            </Typography>
            <Typography variant="body2" color={theme.palette.grey[500]} gutterBottom>
                A lista dos seus pedidos estará aqui!
            </Typography>
            {/* @ts-ignore */}
            <IconButton variant="containedFilled" color="primary" onClick={onCreatePedido} size="large"> <Add /></IconButton>
        </Container>
    );

}
export default ListaVazia;
