import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGoogleMapsLoader } from 'src/context/GoogleMapsLoaderContext';

const containerStyle = {
    width: '100%',
    height: '400px',
};

interface MapaPoligonoProps {
    onPolygonComplete: (path: any) => void;
    initialPath?: { lat: number; lng: number }[];
}

const MapaPoligono: React.FC<MapaPoligonoProps> = ({ onPolygonComplete, initialPath = [] }) => {
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [path, setPath] = useState<{ lat: number; lng: number }[]>([]);
    const [center, setCenter] = useState({
        lat: -23.55052,
        lng: -46.633308,
    });
    const pick = useSelector((state: any) => state.config?.masterPage?.pickSelecionada);

    const { isLoaded, loadError } = useGoogleMapsLoader();

    useEffect(() => {
        console.log('initialPath useEffect triggered:', initialPath);
        console.log('Type of initialPath:', typeof initialPath);

        let parsedPath = initialPath;

        if (typeof initialPath === 'string') {
            try {
                parsedPath = JSON.parse(initialPath);
                console.log('Parsed initialPath:', parsedPath);
                console.log('Parsed path isArray:', Array.isArray(parsedPath));
            } catch (error) {
                console.error('Error parsing initialPath:', error);
            }
        }

        if (Array.isArray(parsedPath) && parsedPath.length > 0) {
            console.log('Initial path received and is a valid array:', parsedPath);
            setPath(parsedPath);
            setCenter({
                lat: parsedPath[0].lat,
                lng: parsedPath[0].lng
            });
        } else {
            console.log('No valid initial path or empty array:', parsedPath);
            setCenter({ lat: pick?.latitude || -23.55052, lng: pick?.longitude || -46.633308 });
        }
    }, [initialPath]);


    const onLoad = useCallback((mapInstance: google.maps.Map) => {
        setMap(mapInstance);
        console.log('Map loaded successfully');
    }, [pick]);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();

            const newPoint = { lat, lng };
            const updatedPath = [...path, newPoint];
            setPath(updatedPath);
            onPolygonComplete(updatedPath);
        }
    };

    const handleClearPolygons = () => {
        setPath([]);
        onPolygonComplete([]);
        console.log('Polygons cleared');
    };

    const polygonOptions = {
        fillColor: '#2196F3',
        fillOpacity: 0.5,
        strokeWeight: 2,
        clickable: true,
        editable: true,
        zIndex: 1,
    };

    if (loadError) {
        return <div>Erro ao carregar o Google Maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                onClick={handleMapClick}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                {path.length > 0 && (
                    <Polygon
                        paths={path}
                        options={polygonOptions}
                    />
                )}
            </GoogleMap>
            <Button variant="contained" color="primary" onClick={handleClearPolygons} sx={{ marginTop: 2 }}>
                Limpar Polígonos
            </Button>
        </div>
    );
};

export default MapaPoligono;
