import React, { useEffect, useState } from 'react';
import {
    Typography,
    Container,
    Button,
    Alert,
    TextField,
    Link,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Paper,
    Collapse,
    Stack,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Tooltip,
    TableBody,
    ToggleButtonGroup,
    ToggleButton,
    Grid2,
    Box
} from "@mui/material";
import { CheckBox, ControlPoint, KeyboardDoubleArrowRight, Search, Send, Sensors } from '@mui/icons-material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { useForm } from 'react-hook-form';
import { AutocompleteMultiple, BotaoSituacao, ButtonLoading, InputDate, InputDateTime, SelectMultiple, AutocompleteMap, TextFieldWithButton, BoxLine, AutocompleteEmpresas, AutocompleteTipoVeiculo, SelectBanco, SelectCidade, SelectEstado, SelectFormaPagamento } from 'src/ds';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { useDispatch } from 'react-redux';

const DesignSystem = () => {
    const { showError, showSuccess, showInfo, showWarning } = useSnackbarContext()
    const [checked, setChecked] = React.useState(false);
    const [toggleButton1, setToggleButton1] = React.useState('pedidos');
    const [secret, showSecret] = React.useState(false);
    const [api, setApi] = React.useState('');
    const dispatch = useDispatch();

    React.useEffect(() => {
        console.log('toggleButton1', api);
    }, [api]);

    const changeApiSecret = () => {
        localStorage.setItem('@api', api);

        showSuccess({ message: 'Alterada com sucesso', duration: 2000 });
    }

    const [openPaper, setOpenPaper] = React.useState({
        button: false,
        typography: false,
        input: false,
        grid: false,
        card: false,
        snackbar: false,
        alert: false,
        table: false,
    });

    const veiculos = [
        { nomeAutomovel: 'Carro', veiculoID: '1' },
        { nomeAutomovel: 'Moto', veiculoID: '2' },
        { nomeAutomovel: 'Caminhonete', veiculoID: '3' },
        { nomeAutomovel: 'Caminhão', veiculoID: '4' },
        { nomeAutomovel: 'Truck', veiculoID: '5' },
        { nomeAutomovel: 'Outro', veiculoID: '6' },
    ]

    const hookForm = useForm();
    const { register, formState: { errors }, watch, setValue, getValues } = hookForm;

    const [formResults, setFormResults] = useState({});

    useEffect(() => {
        console.log(formResults);
    }, [formResults]);

    return (
        <Container sx={{ p: 2, gap: 2, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
            <Typography variant="h4" gutterBottom>Design System</Typography>
            {/* Buttons */}
            <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
                <Stack direction="row" spacing={2}>
                    <IconButton color="primary" onClick={() => setOpenPaper({ ...openPaper, button: !openPaper.button })}>
                        {openPaper.button ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    </IconButton>
                    <Typography variant="h5">Buttons</Typography>
                </Stack>
                <Collapse in={openPaper.button}>
                    <Grid2 container spacing={2} border="1px solid black" p={2} borderRadius={5} justifyContent="center" alignItems="center" m={2} width="100%">
                        <Typography variant="h6" gutterBottom align="center" sx={{ width: '100%' }}>Variants</Typography>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Stack direction="column" gap={2}>
                                <Button>Botão Normal</Button>
                                <Button color="secondary">Botão Normal</Button>
                                <Button color="grey">Botão Normal</Button>
                            </Stack>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Stack direction="column" gap={2}>
                                <Button variant='outlined'>Botão Variant Outlined</Button>
                                <Button variant='outlined' color="secondary">Botão Variant Outlined</Button>
                                <Button variant='outlined' color="grey">Botão Variant Outlined</Button>
                            </Stack>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Stack direction="column" gap={2}>
                                <Button variant='filled'>Botão Variant Filled</Button>
                                <Button variant='filled' color="secondary">Botão Variant Filled</Button>
                                <Button variant='filled' color="grey">Botão Variant Filled</Button>
                            </Stack>
                        </Grid2>

                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Stack direction="column" gap={2}>
                                <Button variant='contained'>Botão Variant Contained</Button>
                                <Button variant='contained' color="secondary">Botão Variant Contained</Button>
                                <Button variant='contained' color="grey">Botão Variant Contained</Button>
                            </Stack>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Stack direction="column" gap={2}>
                                <Button variant='containedFilled'>Botão Variant Contained Filled</Button>
                                <Button variant='containedFilled' color="secondary">Botão Variant Contained Filled</Button>
                                <Button variant='containedFilled' color="grey">Botão Variant Contained Filled</Button>
                            </Stack>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }} rowSpacing={2}>
                            <Stack direction="column" gap={2}>
                                <Button variant='iconeComTextoFilled'><ControlPoint />Botão Variant iconeComTextoFilled</Button>
                                <Button variant='iconeComTextoFilled' color="secondary"><ControlPoint />Botão Variant iconeComTextoFilled</Button>
                                <Button variant='iconeComTextoFilled' color="grey"><ControlPoint />Botão Variant iconeComTextoFilled</Button>
                            </Stack>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Stack direction="column" gap={2}>
                                <Button variant='iconeComTexto'><ControlPoint />Botão Variant iconeComTexto</Button>
                                <Button variant='iconeComTexto' color="secondary"><ControlPoint />Botão Variant iconeComTexto</Button>
                                <Button variant='iconeComTexto' color="grey"><ControlPoint />Botão Variant iconeComTexto</Button>
                            </Stack>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Button variant='textoSimples'>Botão Variant textoSimples</Button>
                        </Grid2>
                        <Typography variant="h6" gutterBottom align="center" sx={{ width: '100%' }}>Personalizados</Typography>

                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <BotaoSituacao situacao="Recebido">Situação <KeyboardDoubleArrowRight /></BotaoSituacao>
                            <BotaoSituacao situacao="Aceito">Situação <KeyboardDoubleArrowRight /></BotaoSituacao>
                            <BotaoSituacao situacao="Em rota">Situação <KeyboardDoubleArrowRight /></BotaoSituacao>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Typography variant='body2'>Botão de Loading Dinâmico</Typography>
                            <ButtonLoading>Clique Aqui</ButtonLoading>
                            <ButtonLoading
                                variant='outlined'
                                loading={true}
                            >
                                Você nem vai ver isso aqui
                            </ButtonLoading>
                        </Grid2>
                        <Typography variant="h6" gutterBottom align="center" sx={{ width: '100%' }}>Icon Button</Typography>
                        <Typography variant='body2'>sizes:'small'|'medium'|'large'</Typography>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Typography variant='body2'>Botão padrão</Typography>
                            <IconButton>
                                <AcUnitIcon />
                            </IconButton>
                            <IconButton color="secondary">
                                <AddAPhotoIcon />
                            </IconButton>
                            <IconButton color="primary">
                                <AndroidIcon />
                            </IconButton>
                            <IconButton>
                                <AppleIcon />
                            </IconButton>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Typography variant='body2'>Variante containedFilled</Typography>
                            {/* @ts-expect-error */}
                            <IconButton variant="containedFilled">
                                <AcUnitIcon />
                            </IconButton>
                            {/* @ts-expect-error */}
                            <IconButton variant="containedFilled" color="secondary" size="small">
                                <AddAPhotoIcon />
                            </IconButton>
                            {/* @ts-expect-error */}
                            <IconButton variant="containedFilled" color="primary" size="medium">
                                <AndroidIcon />
                            </IconButton>
                            {/* @ts-expect-error */}
                            <IconButton variant="containedFilled" color="default" size="large">
                                <AppleIcon />
                            </IconButton>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Typography variant='body2'>Variante contained</Typography>
                            {/* @ts-expect-error */}
                            <IconButton variant="contained">
                                <AcUnitIcon />
                            </IconButton>
                            {/* @ts-expect-error */}
                            <IconButton variant="contained" color="secondary" size="small">
                                <AddAPhotoIcon />
                            </IconButton>
                            {/* @ts-expect-error */}
                            <IconButton variant="contained" color="primary" size="medium">
                                <AndroidIcon />
                            </IconButton>
                            {/* @ts-expect-error */}
                            <IconButton variant="contained" color="default" size="large">
                                <AppleIcon />
                            </IconButton>
                        </Grid2>
                        <Typography variant="h6" gutterBottom align="center" sx={{ width: '100%' }}>Toggle Button</Typography>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Typography variant='body2'>Default</Typography>
                            <ToggleButtonGroup
                                size="small"
                                sx={{ mt: 2 }}
                                exclusive
                                value={toggleButton1}
                                onChange={(_, value) => setToggleButton1(value)}
                            >
                                <ToggleButton value="pedidos">Pedidos</ToggleButton>
                                <ToggleButton value="dashboard">Dashboard</ToggleButton>
                                <ToggleButton value="controle">Controle</ToggleButton>
                                <ToggleButton value="outro">Outro</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            <Typography variant='body2'>Variante plain</Typography>
                            <ToggleButtonGroup
                                size="small"
                                sx={{ mt: 2 }}
                                exclusive
                                value={toggleButton1}
                                onChange={(_, value) => setToggleButton1(value)}
                            >
                                {/* @ts-expect-error */}
                                <ToggleButton variant="plain" value="pedidos">Pedidos</ToggleButton>
                                {/* @ts-expect-error */}
                                <ToggleButton variant="plain" value="dashboard">Dashboard</ToggleButton>
                                {/* @ts-expect-error */}
                                <ToggleButton variant="plain" value="controle">Controle</ToggleButton>
                                {/* @ts-expect-error */}
                                <ToggleButton variant="plain" value="outro">Outro</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid2>

                    </Grid2>
                </Collapse>
            </Paper>

            {/* Texts */}
            <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
                <Stack direction="row" spacing={2}>
                    <IconButton color="primary" onClick={() => setOpenPaper({ ...openPaper, typography: !openPaper.typography })}>
                        {openPaper.typography ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    </IconButton>
                    <Typography variant="h5">Typographys</Typography>
                </Stack>
                <Collapse in={openPaper.typography}>
                    <Grid2 container spacing={2} border="1px solid black" p={2} borderRadius={5} justifyContent="center" alignItems="center" m={2} width="100%">
                        <Grid2 size={{ xs: 12 }}>
                            <Typography variant="h1">
                                variant="h1" Titulo1 - Lorem ipsum dolor.
                            </Typography>
                            <Typography variant="h2">
                                variant="h2" Titulo2 - Lorem ipsum dolor sit amet.
                            </Typography>
                            <Typography variant="body1" >
                                variant="body1" Body1 - Lorem ipsum dolor sit amet consectetur.
                            </Typography>
                            <Typography variant="body2">
                                variant="body2" Body2 - Lorem ipsum dolor sit amet consectetur.
                            </Typography>
                            <Typography variant="button">
                                variant="button" Texto3 - Lorem ipsum dolor sit amet consectetur.
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Collapse>
            </Paper>

            {/* Inputs */}
            <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
                <Stack direction="row" spacing={2}>
                    <IconButton color="primary" onClick={() => setOpenPaper({ ...openPaper, input: !openPaper.input })}>
                        {openPaper.input ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    </IconButton>
                    <Typography variant="h5" gutterBottom>Inputs</Typography>
                </Stack>
                <Collapse in={openPaper.input}>
                    <Link href="https://mui.com/material-ui/react-text-field/">MUI TextField</Link>
                    <Grid2 container spacing={2} border="1px solid black" p={2} borderRadius={5} justifyContent="center" alignItems="center" m={2} width="100%">
                        <TextField label="Input Padrão" variant="outlined" />
                        <Typography variant="h6" gutterBottom>Variants</Typography>
                        <BoxLine sx={{ justifyContent: 'space-between', mt: '1rem', display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                            <TextField
                                variant='outlined'
                                label="Variant Outlined (Default) - Small"
                                size='small'
                                sx={{ flex: '1 1 auto' }}
                            />
                            <TextField
                                variant='outlined'
                                label="Variant Outlined (Default) - Normal"
                                sx={{ flex: '1 1 auto' }}
                            />
                        </BoxLine>
                        <BoxLine sx={{ justifyContent: 'space-between', mt: '1rem', display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                            <TextField
                                variant='filled'
                                label="Variant Filled - Small"
                                size='small'
                                sx={{ flex: '1 1 auto' }}
                            />
                            <TextField
                                variant='filled'
                                label="Variant Filled - Normal"
                                sx={{ flex: '1 1 auto' }}
                            />
                        </BoxLine>
                        <BoxLine sx={{ justifyContent: 'space-between', mt: '1rem', display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                            <TextField
                                variant='standard'
                                label="Variant Standard - Small"
                                size='small'
                                sx={{ flex: '1 1 auto' }}
                            />
                            <TextField
                                variant='standard'
                                label="Variant Standard - Normal"
                                sx={{ flex: '1 1 auto' }}
                            />
                        </BoxLine>
                        <Typography variant="h6" gutterBottom>Inputs Com Botões</Typography>
                        <BoxLine sx={{ justifyContent: 'space-between', mt: '1rem', display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                            <TextFieldWithButton
                                label="Input with Button"
                                buttonProps={{
                                    text: "Comprar",
                                    variant: "filled",
                                    onClick: () => alert("Clicou")
                                }}
                            />
                            <TextFieldWithButton
                                label="Input with Button"
                                size='small'
                                buttonProps={{
                                    text: "Comprar",
                                    variant: "filled",
                                    onClick: () => alert("Clicou")
                                }}
                            />
                            <TextFieldWithButton
                                label="Input with Button"
                                buttonProps={{
                                    text: "Comprar",
                                    position: "start",
                                    variant: "filled",
                                    onClick: () => alert("Clicou")
                                }}
                            />
                            <TextFieldWithButton
                                label="Input with Button"
                                buttonProps={{
                                    text: "Comprar",
                                    position: "start",
                                    variant: "filled",
                                    onClick: () => alert("Clicou")
                                }}
                                size='small'
                            />
                        </BoxLine>
                        <BoxLine sx={{ justifyContent: 'space-between', mt: '1rem', display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                            <TextFieldWithButton
                                label="Input with Button"
                                buttonProps={{
                                    text: "Comprar",
                                    onClick: () => alert("Clicou")
                                }}
                            />
                            <TextFieldWithButton
                                label="Input with Button"
                                buttonProps={{
                                    text: "Comprar",
                                    position: "start",
                                    onClick: () => alert("Clicou")
                                }}
                            />
                        </BoxLine>
                        <Typography variant="h6" gutterBottom>Select</Typography>
                        <Stack direction={'column'} width={'100%'} gap={2}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id='tipoVeiculoLabel'>Tipo de veículo</InputLabel>
                                <Select
                                    labelId='tipoVeiculoLabel'
                                    id='tipoVeiculo'
                                    label='Tipo de veículo'
                                >
                                    <MenuItem value={''} disabled>Selecione Um Veículo</MenuItem>
                                    <MenuItem value={'1'}>Opção 1</MenuItem>
                                    <MenuItem value={'2'}>Opção 2</MenuItem>
                                    <MenuItem value={'3'}>Opção 3</MenuItem>
                                </Select>
                            </FormControl>
                            <SelectMultiple
                                hookForm={{
                                    methods: hookForm,
                                    name: 'veiculos',
                                }}
                                label='Veículos'
                                options={veiculos}
                                labelKey='nomeAutomovel'
                                valueKey='veiculoID'
                            />
                        </Stack>
                        <Typography variant="h6" gutterBottom>Date Pick Selector</Typography>
                        <Grid2 size={{ xs: 12 }} container justifyContent={'space-between'}>
                            <Grid2 size={{ xs: 12 }} sx={{ mb: 2 }}>
                                <InputDateTime
                                    hookForm={{
                                        name: 'dataAgendamento',
                                        methods: hookForm,
                                        rules: { required: 'Campo Obrigatório' },
                                    }}
                                    label='Data e Hora de Agendamento'
                                    sx={{ width: '100%' }}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <InputDate
                                    hookForm={{
                                        name: 'dataFimDiaria',
                                        methods: hookForm,
                                        rules: { required: 'Data de Referência obrigatório' },
                                    }}
                                    label='Campo Obrigatório'
                                    sx={{ width: '100%' }}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <AutocompleteMap
                                    size='small'
                                    setPosition={(position) => { console.log(position) }}
                                    setFormState={setFormResults}
                                />
                            </Grid2>
                        </Grid2>
                        <Typography variant="h6" gutterBottom>CheckBox</Typography>
                        <BoxLine>
                            <Grid2 size={{ xs: 6 }} alignContent={'center'}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={checked}
                                        onChange={(event) => {
                                            setChecked(event.target.checked);
                                        }} />}
                                    label="Quantidade Mínima de Pedidos"
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6 }} alignContent={'center'}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={!checked}
                                        onChange={(event) => { setChecked(!event.target.checked) }}
                                    />}
                                    label="Quantidade Mínima de Pedidos"
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6 }} alignContent={'center'}>
                                <FormControlLabel
                                    control={<Checkbox
                                        disabled
                                        checked={checked}
                                        onChange={(event) => { setChecked(!event.target.checked) }}
                                    />}
                                    label="Quantidade Mínima de Pedidos"
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6 }} alignContent={'center'}>
                                <Checkbox
                                    checked={checked}
                                    onChange={(event) => { setChecked(!event.target.checked) }}
                                />
                                <Checkbox
                                    checked={!checked}
                                    onChange={(event) => { setChecked(!event.target.checked) }}
                                />
                                <Checkbox checked={false} disabled />
                                <Checkbox checked={false} />
                                <Checkbox checked={true} />
                                <Checkbox checked={secret} onClick={() => showSecret(!secret)} />
                                {secret &&
                                    <Box display='flex' >
                                        <TextField size='small' value={api} onChange={(e) => setApi(e.target.value)} />
                                        <Button variant='text' size='small' sx={{ width: 'min-content' }} onClick={changeApiSecret}><Send /></Button>
                                    </Box>
                                }
                            </Grid2>
                        </BoxLine>
                        <Typography variant="h6" gutterBottom>Inputs Prontos</Typography>
                        <Stack direction={'column'} width={'100%'} gap={2}>
                            <Typography variant='body2'>SelectMultiple</Typography>
                            <SelectMultiple
                                hookForm={{
                                    methods: hookForm,
                                    name: 'veiculos',
                                }}
                                label='Veículos'
                                options={veiculos}
                                labelKey='nomeAutomovel'
                                valueKey='veiculoID'
                            />
                            <Typography variant='body2'>AutocompleteEmpresas</Typography>
                            <AutocompleteEmpresas
                                hookForm={{
                                    name: 'empresas',
                                    methods: hookForm
                                }}
                            />
                            <Typography variant='body2'>AutocompleteMultiple</Typography>
                            <AutocompleteMultiple
                                label='AutocompleteMultiple'
                                hookForm={{
                                    name: 'multiple',
                                    methods: hookForm
                                }}
                                options={[
                                    { value: '1', label: 'Opção 1' },
                                    { value: '2', label: 'Opção 2' },
                                    { value: '3', label: 'Opção 3' },
                                ]}
                            />
                            <Typography variant='body2'>AutocompleteTipoVeiculo</Typography>
                            <AutocompleteTipoVeiculo
                                label='Tipo de Veículo'
                                hookForm={{
                                    name: 'veiculo',
                                    methods: hookForm
                                }}
                            />
                            <Typography variant='body2'>SelectBanco</Typography>
                            <SelectBanco
                                hookForm={hookForm}
                            />
                            <Typography variant='body2'>SelectEstado</Typography>
                            <SelectEstado
                                hookForm={{
                                    methods: hookForm
                                }}
                            />
                            <Typography variant='body2'>SelectCidade</Typography>
                            <SelectCidade
                                hookForm={{
                                    methods: hookForm
                                }}
                            />
                            <Typography variant='body2'>SelectFormaPagamento</Typography>
                            <SelectFormaPagamento
                                hookForm={{
                                    methods: hookForm
                                }}
                            />
                            <Typography variant='body2'>SelectMultiple</Typography>
                            <SelectMultiple
                                hookForm={{
                                    methods: hookForm,
                                    name: 'multiple'
                                }}
                                label='Select Multiple'
                                labelKey='label'
                                valueKey='value'
                                options={[
                                    { value: '1', label: 'Opção 1' },
                                    { value: '2', label: 'Opção 2' },
                                    { value: '3', label: 'Opção 3' },
                                ]}
                            />
                            <Typography variant='body2'>SelectTipoDespacho</Typography>
                            <Typography variant='body2'>SelectTipoFatura</Typography>
                            <Typography variant='body2'>SelectTipoPedido</Typography>
                            <Typography variant='body2'>SelectToggleAtivo</Typography>
                            <Typography variant='body2'>Select</Typography>
                            <Typography variant='body2'>Select</Typography>
                            <Typography variant='body2'>Select</Typography>
                            <Typography variant='body2'>Select</Typography>
                        </Stack>
                    </Grid2>
                </Collapse>
            </Paper>

            {/* Grids */}
            <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
                <Stack direction="row" spacing={2}>
                    <IconButton color="primary" onClick={() => setOpenPaper({ ...openPaper, grid: !openPaper.grid })}>
                        {openPaper.grid ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    </IconButton>
                    <Typography variant="h5" gutterBottom>Grids</Typography>
                </Stack>
                <Collapse in={openPaper.grid}>
                    <Typography variant="h5" gutterBottom>Grids</Typography>

                </Collapse>
            </Paper>

            {/* Cards */}
            <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
                <Stack direction="row" spacing={2}>
                    <IconButton color="primary" onClick={() => setOpenPaper({ ...openPaper, card: !openPaper.card })}>
                        {openPaper.card ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    </IconButton>
                    <Typography variant="h5" gutterBottom>Cards</Typography>
                </Stack>
                <Collapse in={openPaper.card}>
                    <Typography variant="h5" gutterBottom>Cards</Typography>
                    <Grid2 container border="1px solid black" p={2} borderRadius={5} justifyContent={"center"} alignItems={"center"} m={2}>
                        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
                            Lorem, ipsum dolor.
                        </Grid2>
                    </Grid2>
                </Collapse>
            </Paper>

            {/* Snackbar */}
            <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
                <Stack direction="row" spacing={2}>
                    <IconButton color="primary" onClick={() => setOpenPaper({ ...openPaper, snackbar: !openPaper.snackbar })}>
                        {openPaper.snackbar ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    </IconButton>
                    <Typography variant="h5" gutterBottom>Snackbar</Typography>
                </Stack>
                <Collapse in={openPaper.snackbar}>
                    <Typography variant="h5" gutterBottom>Snackbar</Typography>
                    <BoxLine gap={2} flexWrap={"wrap"}>
                        <Button
                            variant='outlined'
                            onClick={() => showSuccess({
                                message: "Mensagem de Sucesso",
                                duration: 5000
                            })}
                            sx={{ flex: '1 0 0' }}
                        >
                            Mostrar Snackbar Success
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={() => showInfo({
                                message: "Mensagem de Comunicate",
                                duration: 5000
                            })}
                            sx={{ flex: '1 0 0' }}
                        >
                            Mostrar Snackbar Info
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={() => showWarning({
                                message: "Mensagem de Warning",
                                duration: 5000
                            })}
                            sx={{ flex: '1 0 0' }}
                        >
                            Mostrar Snackbar Info
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={() => showError({
                                message: "Mensagem de Erro",
                                duration: 5000
                            })}
                            sx={{ flex: '1 0 0' }}
                        >
                            Mostrar Snackbar Error
                        </Button>
                    </BoxLine>
                </Collapse>
            </Paper>

            {/* Alert */}
            <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
                <Stack direction="row" spacing={2}>
                    <IconButton color="primary" onClick={() => setOpenPaper({ ...openPaper, alert: !openPaper.alert })}>
                        {openPaper.alert ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    </IconButton>
                    <Typography variant="h5" gutterBottom>Alert</Typography>
                </Stack>
                <Collapse in={openPaper.alert}>
                    <Typography variant="h5" gutterBottom>Alertas</Typography>
                    <Alert severity="error">Caso precise de ajuda, entre em contato conosco!</Alert>
                    <Alert severity="success">Caso precise de ajuda, entre em contato conosco!</Alert>
                    <Alert severity="info">Caso precise de ajuda, entre em contato conosco!</Alert>
                    <Alert severity="warning">Caso precise de ajuda, entre em contato conosco!</Alert>
                </Collapse>
            </Paper>

            {/* Tables */}
            <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
                <Stack direction="row" spacing={2}>
                    <IconButton color="primary" onClick={() => setOpenPaper({ ...openPaper, table: !openPaper.table })}>
                        {openPaper.table ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    </IconButton>
                    <Typography variant="h5" gutterBottom>Tables</Typography>
                </Stack>
                <Collapse in={openPaper.table}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox" align="center">
                                        <Tooltip title="Selecionar todos" arrow placement="top">
                                            <CheckBox
                                                sx={{ color: 'primary.main' }}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="center">Nome do entregador</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Telefone</TableCell>
                                    <TableCell align="center">Tipo de Veículo</TableCell>
                                    <TableCell align="center">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    hover
                                    role="checkbox"
                                >
                                    <TableCell padding="checkbox" align="center">
                                        <Checkbox sx={{ color: 'primary.main' }} />
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        Lorem, ipsum dolor.
                                    </TableCell>
                                    <TableCell align="center">Lorem, ipsum dolor.</TableCell>
                                    <TableCell align="center">9999999999</TableCell>
                                    <TableCell align="center">Lorem, ipsum dolor.</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title={'lorem'} arrow placement="top">
                                            <IconButton>
                                                <Sensors />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Editar" arrow placement="top">
                                            <IconButton>
                                                <Sensors />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Excluir" arrow placement="top">
                                            <IconButton>
                                                <Sensors />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                                        <Typography variant='notFound'>Nenhum dado encontrado</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Collapse>
            </Paper>

            {/* Snackbar */}
            {/* <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
                <Stack direction="row" spacing={2}>
                    <IconButton color="primary" onClick={() => setOpenPaper({ ...openPaper, snackbar: !openPaper.snackbar })}>
                        {openPaper.snackbar ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    </IconButton>
                    <Typography variant="h5" gutterBottom>Snackbar</Typography>
                </Stack>
                <Collapse in={openPaper.snackbar}>
                    
                </Collapse>
            </Paper> */}

            {/* Modal */}
            {/* <Typography variant="h5" gutterBottom>Modal</Typography>
            <Button onClick={() => setModalOpen(true)}>Mostrar Modal</Button> */}

            {/* Calendário */}
            {/* <Typography variant="h5" gutterBottom>Calendário</Typography>
            {/* Outros componentes */}
            {/* <Typography variant="h5" gutterBottom>Outros Componentes</Typography>
            <Container width={"100%"} minHeight={"300px"}>
                <Typography variant='body1'>Loading Container.</Typography>
            </Container>

            <Dropdown native>
                <option value="" />
                <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
            </Dropdown> */}
        </Container>
    );
};

export default DesignSystem;
