import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper, IconButton, Avatar } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { TableRowLoading, TableRowNotFound, ButtonTableOrder } from "src/ds";
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
import ModalQrCode from "../common/ModalQrCode";
import { formatCnpj } from "src/ds/common";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "src/context/PermissionsContext";
import { listaEmpresasStart } from "src/store/reducers/empresa";
import { useState } from "react";
import { RootState } from "src/store/reducers";

interface Empresa {
    empresaID: string;
    codigoIdentificador: string;
    nome: string;
    dataCadastro: string;
    cnpj: string;
    enderecoStr: string;
    telefoneComercial: string;
    razaoSocial: string;
    inscricaoEstadual: string;
    tagsSelecionadas?: string[];
    logoUrl?: string;
}

interface TabelaEmpresasProps {
    empresas: Empresa[];
    loading: boolean;
    fezRequisicao?: boolean;
}

const TabelaEmpresas = ({ empresas, loading, fezRequisicao }: TabelaEmpresasProps) => {
    const dispatch = useDispatch();
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        nome: 'asc',
        codigoIdentificador: 'asc',
        dataCadastro: 'asc',
        cnpj: 'asc',
    });
    const [qrCodeEmpresaID, setQrCodeEmpresaID] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const podeEditarEmpresa = useSelector((state: any) => state.config?.masterPage?.usuario?.['empresa.editar']);


    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        setSortDirection((prevState) => ({
            ...prevState,
            [column]: newSortDirection,
        }));

        const payload = {
            paginacao: 20,
            pagina: 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
        };

        dispatch(listaEmpresasStart(payload));
    };

    const handleQrCodeClick = (empresaID: string) => {
        setQrCodeEmpresaID(empresaID);
        setModalOpen(true);
    }

    const handleQrCodeClose = () => {
        setQrCodeEmpresaID(null);
        setModalOpen(false);
    }

    const permissions = usePermissions();

    return (
        <>
            <TableContainer component={Paper} sx={{ width: '100%', overflowY: 'auto', margin: '0 auto' }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ borderTop: '1px solid #ccc' }}>
                            <TableCell align="center" sx={{ width: '5%' }}>
                                <ButtonTableOrder>
                                    Imagem
                                </ButtonTableOrder>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '20%' }}>
                                <ButtonTableOrder onClick={() => toggleSortDirection('nome')} sortDirection={sortDirection} nome="nome">
                                    Empresa
                                </ButtonTableOrder>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '15%' }}>
                                <ButtonTableOrder onClick={() => toggleSortDirection('codigoIdentificador')} sortDirection={sortDirection} nome="codigoIdentificador">
                                    Identificador
                                </ButtonTableOrder>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '15%' }}>
                                <ButtonTableOrder onClick={() => toggleSortDirection('dataCadastro')} sortDirection={sortDirection} nome="dataCadastro">
                                    Data de cadastro
                                </ButtonTableOrder>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '20%' }}>
                                <ButtonTableOrder>
                                    Razão social
                                </ButtonTableOrder>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '15%' }}>
                                <ButtonTableOrder onClick={() => toggleSortDirection('cnpj')} sortDirection={sortDirection} nome="cnpj">
                                    CNPJ
                                </ButtonTableOrder>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '15%' }}>
                                <ButtonTableOrder>
                                    IE
                                </ButtonTableOrder>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '15%' }}>
                                <ButtonTableOrder>
                                    Tags
                                </ButtonTableOrder>
                            </TableCell>
                            <TableCell align="center" sx={{ width: '20%' }}>
                                <ButtonTableOrder>
                                    Ações
                                </ButtonTableOrder>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowLoading />
                        ) : (
                            empresas?.length > 0
                                ? empresas.map((empresa, index) => (
                                    <TableRow key={index} hover>
                                        <TableCell align="center" sx={{ py: 2 }}>
                                            <Avatar sx={{ width: 40, height: 40 }} src={empresa.logoUrl} />
                                        </TableCell>
                                        <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                            {empresa.nome}
                                        </TableCell>
                                        <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                            {empresa.codigoIdentificador}
                                        </TableCell>
                                        <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                            {empresa.dataCadastro}
                                        </TableCell>
                                        <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                            {empresa.razaoSocial}
                                        </TableCell>
                                        <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                            {formatCnpj(empresa.cnpj)}
                                        </TableCell>
                                        <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                            {empresa.inscricaoEstadual}
                                        </TableCell>
                                        <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                            {empresa.tagsSelecionadas}
                                        </TableCell>
                                        <TableCell align="center" sx={{ py: 2 }}>
                                            <Stack direction="row" spacing={1} justifyContent="center">
                                                {permissions.usuario?.["empresa.visualizar"] && <Tooltip title="Ver QR Code" arrow placement="top">
                                                    <IconButton
                                                        sx={{
                                                            width: 24,
                                                            height: 24,
                                                            cursor: 'pointer',
                                                        }}
                                                        color="info"
                                                        onClick={() => handleQrCodeClick(empresa.empresaID)}
                                                    >
                                                        <QrCode2RoundedIcon />
                                                    </IconButton>
                                                </Tooltip>}
                                                {permissions.usuario?.["empresa.editar"] && <Tooltip title="Editar" arrow placement="top">
                                                    <Edit
                                                        color="secondary"
                                                        sx={{
                                                            width: 24,
                                                            height: 24,
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => console.log(empresa.empresaID)}
                                                    />
                                                </Tooltip>}
                                                {permissions.usuario?.["empresa.excluir"] && <Tooltip title="Excluir" arrow placement="top">
                                                    <Delete
                                                        color="error"
                                                        sx={{
                                                            width: 24,
                                                            height: 24,
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => console.log(empresa.empresaID)}
                                                    />
                                                </Tooltip>}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))
                                : <TableRowNotFound fezRequisicao={fezRequisicao} />
                        )}
                    </TableBody>
                </Table>
            </TableContainer >
            {(modalOpen && qrCodeEmpresaID) && (
                <ModalQrCode
                    open={modalOpen}
                    onClose={handleQrCodeClose}
                    empresaID={qrCodeEmpresaID}
                />
            )}
        </>
    );
}

export default TabelaEmpresas;
