import { useEffect, useRef } from 'react';
import { useSnackbarContext } from 'src/context/SnackbarContext';

const useMultipleSnack = (
    responses: Array<{ response: any, clearAction: () => void }>
) => {
    const { showSuccess, showError } = useSnackbarContext();
    const processedResponses = useRef<Set<any>>(new Set());

    useEffect(() => {
        responses.forEach(({ response, clearAction }) => {
            if (!response || processedResponses.current.has(response)) return;

            if (response?.data) {
                showSuccess({
                    message: response.data.mensagem,
                    duration: 5000,
                })
            } else if (response?.error) {
                showError({
                    message: response.error,
                    duration: 5000
                })
            }
            processedResponses.current.add(response);
            setTimeout(() => {
                clearAction();
            }, 1000);
        });
    }, [responses]);
}; 

export default useMultipleSnack;
