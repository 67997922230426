import React, { useRef, useState } from "react";
import { Box, Button, ClickAwayListener, Fade, Popper, Tooltip, Typography, useTheme } from "@mui/material";
import { EditNote as EditNoteIcon, ReportProblemOutlined, BoltOutlined, KeyboardDoubleArrowRight, GroupAdd } from "@mui/icons-material";
import { BotaoSituacao, BoxLine, findOrigemImg, PLANO_FREEMIUM, sanitizeSituacao, TimeCounter } from "src/ds";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import { usePedido } from "src/context/PedidoContext";
import ModalTrocarStatus from "../ModalTrocarStatus";
import { FaMotorcycle } from "react-icons/fa";
import dayjs from "dayjs";

interface CardHeaderProps {
    pedido: any;
    toggleCollapse: () => void;
    abrirEntregaParceira: () => void
    abrirEditarPedido: () => void
    abrirAtribuirPedido: () => void
    collapsed: boolean
}

const CardHeader = ({ pedido, toggleCollapse, abrirEntregaParceira, abrirEditarPedido, abrirAtribuirPedido, collapsed }: CardHeaderProps) => {
    const tipoDeUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario);
    const { selecionarPedido, roteando, markersSelecionados, markerSelecionado, selecionarCardPedido, cardPedidoSelecionado, adicionandoPedidos } = usePedido();
    const buttonRef = useRef(null);
    const template = useSelector((state: RootState) => state.config?.template?.template);
    const usuarioPlano = useSelector((state: RootState) => state.config?.usuarioPlano);
    const podeParceira1 = useSelector((state: RootState) => state.config?.masterPage?.empresa?.podePedirEntregaParceira);
    const podeAtribuir1 = useSelector((state: RootState) => state.config?.masterPage?.empresa?.podeAtribuirEntregador);

    const podeAtribuir = tipoDeUsuario === "SAAS" ? true : podeAtribuir1

    const podeParceira = tipoDeUsuario === "SAAS" ? true : podeParceira1


    const [anchorSituacao, setAnchorSituacao] = useState(null);
    const openModalStatus = Boolean(anchorSituacao);
    function closePopper() {
        setAnchorSituacao(null);
    }


    const podeEntregaParceira = useSelector((state: RootState) => {
        if (tipoDeUsuario === "SAAS") {
            return true;
        } else {
            return state.config.masterPage?.empresa?.podePedirEntregaParceira;
        }
    });

    const abrirParceira = () => {
        selecionarPedido(pedido);
        abrirEntregaParceira();
    }

    const abrirEditar = () => {
        selecionarPedido(pedido);
        abrirEditarPedido();
    }

    const abrirAtribuir = () => {
        if (adicionandoPedidos) return

        selecionarPedido(pedido);
        abrirAtribuirPedido();
    }

    const theme = useTheme();

    const toggleModalStatus = (e: any) => {
        if (usuarioPlano === PLANO_FREEMIUM) return
        if (e?.currentTarget) {
            {
                tipoDeUsuario !== 'Representante' && setAnchorSituacao(anchorSituacao ? null : e.target);
            }
        }
    }

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={findOrigemImg(pedido?.aplicacaoOrigemNome, template?.faviconUrl)}
                        alt="Logo"
                        style={{ width: 33, height: 33, borderRadius: '8px' }}
                    />
                </Box>

                <Box width="100%" justifyContent="space-between" mx={.5}>
                    <BoxLine alignItems="center" onClick={toggleCollapse} mb={'.1rem'} sx={{ gap: 1 }} >
                        <Tooltip
                            title="Tempo decorrido desde o cadastro"
                            arrow
                            placement="top"
                        >
                            <Box
                                sx={{ flex: '.37', cursor: 'pointer' }}
                            >
                                <TimeCounter
                                    initialTimeString={pedido?.tempoDecorridoCadastro}
                                />
                            </Box>
                        </Tooltip>
                        <Tooltip
                            title="Tempo decorrido desde a última atualização"
                            arrow
                            placement="top"
                        >
                            <Box
                                sx={{ flex: '.37', cursor: 'pointer' }}
                            >
                                <TimeCounter
                                    initialTimeString={pedido?.tempoDecorridoSituacao}
                                    parenteses={true}
                                    sx={{
                                        color: 'grey'
                                    }}
                                />
                            </Box>
                        </Tooltip>

                        <BoxLine gap={'.2rem'} sx={{ justifyContent: 'space-between', flex: '3', cursor: 'pointer' }}>

                            {
                                pedido.situacao !== 'RETORNANDO' &&
                                podeEntregaParceira &&
                                podeParceira && (
                                    <Button variant='textoSimples' onClick={abrirParceira} sx={{ flexGrow: '1' }}>
                                        <Tooltip title='Entrega Parceira' arrow placement="top">
                                            <BoltOutlined sx={{ fontSize: '1rem' }} />
                                        </Tooltip>
                                    </Button>
                                )
                            }



                            <Button variant='textoSimples' onClick={() => { abrirEditar() }} sx={{ flexGrow: '1' }}>
                                <Tooltip title='Editar Pedido' arrow placement="top" >
                                    <EditNoteIcon sx={{ fontSize: '1rem' }} />
                                </Tooltip>
                            </Button>
                            {podeAtribuir && <Button variant='textoSimples' onClick={abrirAtribuir} sx={{ flexGrow: '1' }}>
                                <Tooltip title='Atribuir' arrow placement="top" >
                                    <GroupAdd sx={{ fontSize: '1rem' }} />
                                </Tooltip>
                            </Button>}
                        </BoxLine>
                        < Box sx={{ flex: '1' }}>
                            <BotaoSituacao ref={buttonRef} sx={{ maxWidth: '100%' }} situacao={sanitizeSituacao(pedido)} onClick={toggleModalStatus} >
                                {sanitizeSituacao(pedido)}
                                {tipoDeUsuario !== 'Representante' && <KeyboardDoubleArrowRight sx={{ fontSize: theme.sizes.fontSize.xs }} />}
                            </BotaoSituacao>
                        </Box>

                        <Popper
                            id={'simple-popper'}
                            open={openModalStatus}
                            anchorEl={anchorSituacao}
                            sx={{ zIndex: 10000 }}
                            placement="bottom-end"
                            modifiers={
                                [
                                    {
                                        name: 'flip',
                                        enabled: true,
                                        options: {
                                            altBoundary: true,
                                            rootBoundary: 'viewport',
                                            padding: 8,
                                        },
                                    },
                                    {
                                        name: 'preventOverflow',
                                        enabled: true,
                                        options: {
                                            boundary: 'viewport',
                                            altAxis: true,
                                        },
                                    },
                                ]}
                            transition
                        >
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={200} >
                                    <Box>
                                        <ClickAwayListener onClickAway={() => { setAnchorSituacao(null) }}>
                                            <ModalTrocarStatus
                                                visible={openModalStatus}
                                                // @ts-expect-error
                                                onClose={toggleModalStatus}
                                                pedido={pedido}
                                                closePopper={closePopper}
                                                statusAtual={sanitizeSituacao(pedido)}
                                            />
                                        </ClickAwayListener>
                                    </Box>
                                </Fade>
                            )}
                        </Popper>
                    </BoxLine >
                    < BoxLine sx={{ display: 'flex', mb: '0rem', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '.3rem', mb: '.1rem' }}>

                            <Typography variant={'span'} fontWeight={'900'} sx={{ cursor: 'pointer' }} onClick={() => { toggleCollapse() }} >
                                {`#${pedido?.codigoQuatro}`}
                            </Typography>
                            {pedido?.clienteNome?.length > 14 ? (
                                <Tooltip title={pedido?.clienteNome} arrow>
                                    <Typography variant={'span'} fontWeight={'bold'} sx={{ cursor: 'pointer' }} onClick={() => { toggleCollapse() }}>
                                        {collapsed
                                            ? `${pedido?.clienteNome.substring(0, 14)}...`
                                            : pedido?.clienteNome}
                                    </Typography>
                                </Tooltip>
                            ) : (
                                <Typography variant={'span'} fontWeight={'bold'} sx={{ cursor: 'pointer' }} onClick={() => { toggleCollapse() }}>
                                    {pedido?.clienteNome}
                                </Typography>
                            )}

                        </Box>
                        {
                            pedido?.dataEtaEntrega &&
                            <Typography variant='caption' fontWeight={'700'} sx={{ display: 'flex', alignItems: 'center', gap: '.3rem', mr: '.5rem', width: 'fit-content', cursor: 'pointer', }
                            }>
                                <FaMotorcycle style={{ fontSize: '1rem' }} color={theme.palette.secondary.main} /> até: {dayjs(pedido.dataEtaEntrega, 'DD/MM/YYYY HH:mm:ss').format('HH:mm')}
                            </Typography>
                        }
                        {
                            pedido?.dataAgendamentoStr &&

                            <Typography variant='caption' fontWeight={'700'} sx={{ cursor: 'pointer' }} >
                                Agendado para {dayjs(pedido.dataAgendamentoStr, 'DD/MM/YYYY HH:mm:ss').format('HH:mm [de] DD/MM ')}
                            </Typography>

                        }
                    </BoxLine>
                </Box>

            </Box>

        </>

    );
}

export default CardHeader;
