// @ts-nocheck
import { Typography, useTheme, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { tempoMedioRequest } from "../../../store/reducers/config";
import React, { useEffect } from "react";
import { FlagOutlined, Timer } from "@mui/icons-material";
import { GridPainelLateral, HeaderCard } from "../../../ds";
import ContainerLoading from "src/ds/components/ContainerLoading";

const TempoMedio = ({ close }) => {
    const dispatch = useDispatch();
    const devMode = useSelector(state => state.utils?.devMode);

    useEffect(() => {
        dispatch(tempoMedioRequest());
    }, []);

    const tempoMedio = useSelector(state => state.config.tempoMedio);
    const loading = useSelector(state => state.config.loading);

    const handleCloseClick = () => {
        close();
    };

    return (
        <GridPainelLateral container sx={{ padding: "1rem" }}>
            <HeaderCard title="Tempo médio por quilometragem" onClick={handleCloseClick} />
            {loading ?
                devMode ?
                    <>
                        <img
                            src={"https://i.ibb.co/wgbNJnw/unnamed-1-removebg-preview.png"}
                            alt="unnamed-1-removebg-preview"
                            border="0"
                            style={{
                                transform: "scaleX(-1)",
                                animation: "rotate 5s linear infinite",
                                width: "80%",
                            }}
                        />
                        <style>
                        {`
                            @keyframes rotate {
                                from {
                                    transform: scaleX(-1) rotate(0deg);
                                }
                                to {
                                    transform: scaleX(1) rotate(360deg);
                                }
                            }
                        `}
                        </style>
                    </>
                    :
                    <ContainerLoading />
                : (
                    <Grid container spacing={2}>
                        <Grid container sx={{ display: "flex", alignContent: "center", }}>
                            <Grid item xs={6} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                <Typography variant="body1" sx={{ display: "flex", fontWeight: "500", textAlign: "center", alignSelf: "center" }}>
                                    <FlagOutlined /> Quilometragem
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ justifyContent: "center", alignItems: "center", display: "flex", alignSelf: "center", borderLeft: "1px solid #DFDFDF" }}>
                                <Typography variant="body1" sx={{ fontWeight: "500", textAlign: "center" }}>
                                    <Timer /> Tempo Médio
                                </Typography>
                            </Grid>
                        </Grid>

                        {Array.isArray(tempoMedio) && tempoMedio.length > 0 ? (
                            tempoMedio.map((item, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={6}>
                                        <Typography sx={{ padding: "0.5rem", textAlign: "center" }}>{item.kmFinal} km</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ padding: "0.5rem", textAlign: "center" }}>{item.tempoMedio} min</Typography>
                                    </Grid>
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography sx={{ textAlign: "center", mt: "1rem", width: "100%" }}>Não há dados disponíveis</Typography>
                        )}
                    </Grid>
                )}
            <div style={{ marginTop: "auto" }}></div>
        </GridPainelLateral>
    );
};

export default TempoMedio;
