import { Box, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import TabelaBots from '../../components/Bots/TabelaBots'
import { useDispatch, useSelector } from 'react-redux'
import { botsClear, botsStart, listarFuncoesStart } from 'src/store/reducers/bots'
import { RootState } from 'src/store/reducers'
import MensagensPadroes from 'src/components/Bots/MensagensPadroes'
import { useSnackbarContext } from 'src/context/SnackbarContext'

const Bots = () => {
  const dispatch = useDispatch();
  const listarBotsStore = useSelector((state: RootState) => state.bots.lista);
  const loading = useSelector((state: RootState) => state.bots.lista.loading);
  const bots = useSelector((state: RootState) => state.bots.lista.data?.dados?.lista);
  const [activeSection, setActiveSection] = useState('Whatsapp bots');
  const pickID = useSelector((state: RootState) => state.config.masterPage?.pickSelecionada?.pickID) || '';
  const listaFuncoes = useSelector((state: RootState) => state.bots.listaFuncoes);
  const { showError } = useSnackbarContext();

  const handleSectionChange = (section: string | null) => {
    if (section === null) return
    setActiveSection(section);
  }

  const listarRequest = () => {
    dispatch(listarFuncoesStart({
      paginacao: -1,
      pagina: 1,
      filtros: '',
      ordenacaoOrdem: '',
      ordenacaoCampo: '',
      id: pickID,
    }))

    dispatch(botsStart({
      paginacao: 20,
      pagina: 1,
      ordenacaoOrdem: '',
      ordenacaoCampo: '',
      filtros: `pickID=${pickID}`,
      // titulos: '',
      // variaveis: '',
      // formato: '',
    }))
  }

  useEffect(() => {
    if (!pickID) return
    listarRequest()
  }, [pickID])

  const callbackAtualizar = () => {
    if (!pickID) return
    listarRequest()
  }

  useEffect(() => {
    if (listarBotsStore.error) {
      showError({
        message: listarBotsStore.error,
        duration: 5000
      });
    }
    return () => {
      dispatch(botsClear())
    }
  }, [listarBotsStore.error])

  return (
    <>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 2 }}>
          <Tabs
            textColor="inherit"
            sx={{ mb: 2 }}
            value={activeSection}
            onChange={(_, value) => handleSectionChange(value)}
          >
            <Tab
              value="Whatsapp bots"
              label="Whatsapp bots"
            />
            <Tab
              value="Mensagens padrão"
              label="Mensagens padrão"
            />
          </Tabs>
          <Box sx={{ gap: 2, width: '100%' }}>
            {activeSection === 'Whatsapp bots'
              ? <TabelaBots bots={bots} loading={loading} funcoes={listaFuncoes.data?.dados.lista} callback={callbackAtualizar} />
              : <MensagensPadroes pickID={pickID as string} />
            }
          </Box>
        </Box>
      </Box >
    </>
  )
}

export default Bots