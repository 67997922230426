import React, { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

const PaginaContext = createContext();

export function usePagina() {
    return useContext(PaginaContext);
}

export function PaginaProvider({ children }) {
    const [paginaAtual, setPaginaAtual] = useState(null);


    const selecionarPagina = (pagina) => {
        setPaginaAtual(pagina);
    };

    return (
        <PaginaContext.Provider value={{
            paginaAtual,
            selecionarPagina
        }}>
            {children}
        </PaginaContext.Provider>
    );
}
