import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper, IconButton, Button, useTheme } from "@mui/material";
import { ArrowDownward, ArrowUpward, Delete, Edit } from "@mui/icons-material";
import { useState } from "react";
import { telefone } from "src/ds/common";
import { TableRowLoading, TableRowNotFound, ButtonTableOrder } from "src/ds"; // Import ButtonTableOrder
import { listaClientesStart } from "src/store/reducers/cliente";
import { useDispatch } from "react-redux";
import { usePermissions } from "src/context/PermissionsContext";
import { useNavigate } from "react-router-dom";

interface Cliente {
    empresaClienteID: string;
    empresaID: string;
    empresaNome: string;
    dataCadastro: string;
    nome: string;
    telefone: string;
    cpf: string;
    enderecoStr: string;
    qtdPedidos: number;
}

interface TabelaClientesProps {
    clientes: Cliente[];
    loading: boolean;
    fezRequisicao?: boolean;
}

const TabelaClientes = ({ clientes, loading, fezRequisicao }: TabelaClientesProps) => {
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        nome: 'asc',
        empresaNome: 'asc',
        enderecoStr: 'asc',
        telefone: 'asc',
        qtdPedidos: 'asc',
    });
    const dispatch = useDispatch();
    const { usuario } = usePermissions();


    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';

        const updatedSortDirection = Object.keys(sortDirection).reduce((acc, key) => {
            acc[key] = key === column ? newSortDirection : 'asc';
            return acc;
        }, {} as { [key: string]: 'asc' | 'desc' });

        setSortDirection(updatedSortDirection);

        const payload = {
            paginacao: 20,
            pagina: 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
            empresaID: clientes[0]?.empresaID,
        };

        dispatch(listaClientesStart(payload));
    };

    const theme = useTheme();
    const navigate = useNavigate();

    const todasEmpresasIguais = clientes?.every((cliente) => cliente.empresaNome === clientes[0]?.empresaNome);


    return (
        <TableContainer component={Paper} sx={{ width: '100%', overflowY: 'auto', margin: '0 auto' }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ borderTop: '1px solid #ccc' }}>
                        {!todasEmpresasIguais && (
                            <TableCell align="center">
                                <ButtonTableOrder onClick={() => toggleSortDirection('empresaNome')} sortDirection={sortDirection} nome="empresaNome">
                                    Empresa
                                </ButtonTableOrder>
                            </TableCell>
                        )}

                        <TableCell align="center">
                            <ButtonTableOrder onClick={() => toggleSortDirection('nome')} sortDirection={sortDirection} nome="nome">
                                Cliente
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" >
                            <ButtonTableOrder onClick={() => toggleSortDirection('enderecoStr')} sortDirection={sortDirection} nome="enderecoStr">
                                Endereço
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" >
                            <ButtonTableOrder onClick={() => toggleSortDirection('telefone')} sortDirection={sortDirection} nome="telefone">
                                Telefone
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" >
                            <ButtonTableOrder onClick={() => toggleSortDirection('qtdPedidos')} sortDirection={sortDirection} nome="qtdPedidos">
                                Qtd. de Pedidos
                            </ButtonTableOrder>
                        </TableCell>
                        {usuario?.["empresa.clientes"] && <TableCell align="center" >Ações</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : (
                        clientes?.length > 0
                            ? clientes?.map((row, index) => (
                                <TableRow key={index} hover>
                                    {!todasEmpresasIguais && (
                                        <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                            {row.empresaNome}
                                        </TableCell>
                                    )}
                                    <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        {row.nome}
                                    </TableCell>
                                    <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        {row.enderecoStr}
                                    </TableCell>
                                    <TableCell align="center">{telefone(row.telefone)}</TableCell>
                                    <TableCell align="center">{row.qtdPedidos}</TableCell>
                                    {usuario?.["empresa.clientes"] &&
                                        <TableCell align="center">
                                            <Stack direction="row" justifyContent="center">
                                                {usuario?.["empresa.clientes"] && <Tooltip title="Editar" arrow placement="top">
                                                    <IconButton onClick={() => navigate(`/EmpresaCliente/Index/${row.empresaClienteID}`)}>
                                                        <Edit color="secondary" />
                                                    </IconButton>
                                                </Tooltip>}
                                                {usuario?.["empresa.clientes"] && <Tooltip title="Excluir" arrow placement="top">
                                                    <IconButton onClick={() => console.log(row)}>
                                                        <Delete color="error" />
                                                    </IconButton>
                                                </Tooltip>}
                                            </Stack>
                                        </TableCell>}
                                </TableRow>
                            ))
                            : <TableRowNotFound fezRequisicao={fezRequisicao} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TabelaClientes;
