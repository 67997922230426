import { InputAdornment, TextField, TextFieldProps, ButtonProps } from "@mui/material";
import { forwardRef, Ref } from "react";
import ButtonLoading from "./ButtonLoading";

interface CustomButtonProps extends ButtonProps {
  /** Texto opcional a ser exibido no botão. */
  text?: string;
  /** Ícone opcional a ser exibido no botão. */
  icon?: JSX.Element;
  /** Posição do botão (início ou fim do campo de texto). */
  position?: 'start' | 'end';
  onClick?: () => void;
}

interface TextFieldWithButtonProps extends Omit<TextFieldProps, 'InputProps'> {
  /** Indicador de carregamento que desativa o campo de texto e o botão. */
  loading?: boolean;
  /** Propriedades personalizadas do botão. */
  buttonProps?: CustomButtonProps;
  /** Componente customizado a ser renderizado como o botão. */
  ButtonComponent?: React.ElementType;
}

/**
 * Componente de campo de texto que inclui um botão.
 * 
 * @component
 * @param {string} label - Rótulo do campo de texto.
 * @param {('small' | 'medium')} [size="medium"] - Tamanho do campo de texto e do botão.
 * @param {boolean} [loading=false] - Estado de carregamento que desativa o campo de texto e o botão.
 * @param {CustomButtonProps} [buttonProps] - Propriedades adicionais para o botão.
 * @param {React.ElementType} [ButtonComponent] - Componente personalizado a ser usado como o botão, opcional.
 * @param {TextFieldProps} textFieldProps - Propriedades adicionais do campo de texto.
 * @param {Ref<HTMLInputElement>} ref - Referência passada para o campo de texto.
 * @returns {JSX.Element} Retorna um campo de texto com um botão.
 * 
 * @example
 * <TextFieldWithButton
 *   label="Buscar"
 *   size="small"
 *   loading={false}
 *   buttonProps={{ text: "Procurar", position: "end" }}
 * />
 */
const TextFieldWithButton = forwardRef(function TextFieldWithButton(
  {
    label,
    size = "medium",
    loading = false,
    buttonProps,
    ButtonComponent,
    ...textFieldProps
  }: TextFieldWithButtonProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <TextField
      variant="outlined"
      label={label}
      size={size}
      disabled={loading}
      ref={ref}
      {...textFieldProps}
      sx={{
        display: "flex",
        flex: "1 1 auto",
        "& .MuiInputBase-root": {
          padding: "0",
        },
        "& .MuiInputAdornment-root": {
          height: "100%",
          maxHeight: "100%",
          borderRadius: "inherit",
          "& .MuiButtonBase-root": {
            height: "100%",
            width: "fit-content",
            borderRadius: "inherit",
            "& .MuiSvgIcon-fontSizeMedium": {
              fontSize: "2rem",
            },
          },
        },
        ...textFieldProps?.sx,
      }}
      slotProps={{
        input: {
          [(buttonProps?.position ?? "end") + "Adornment"]: (
            <InputAdornment position={buttonProps?.position ?? "end"}>
              {ButtonComponent ? (
                <ButtonComponent
                  disabled={loading}
                  size={size}
                  {...buttonProps}
                />
              ) : (
                <ButtonLoading
                  loading={loading}
                  variant={buttonProps?.variant ?? "contained"}
                  sx={{
                    fontSize: buttonProps?.text ? ".8rem" : "inherit",
                  }}
                  // startIcon={buttonProps?.icon} //TODO: Reformular isso aqui com calma 
                  {...buttonProps}
                >
                  {buttonProps?.icon} {buttonProps?.text}
                </ButtonLoading>
              )}
            </InputAdornment>
          ),
          ...textFieldProps?.slotProps?.input,
        },
        ...textFieldProps?.slotProps,
      }}
    />
  );
});

export default TextFieldWithButton;
