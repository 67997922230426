import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { ChipSituacao, TableRowLoading, TableRowNotFound } from 'src/ds';
import { enviarMensagemTesteClear, excluirMensagemPadraoClear, listarMensagensPadroesStart, salvarMensagemPadraoClear } from 'src/store/reducers/bots';
import ScienceRoundedIcon from '@mui/icons-material/ScienceRounded';
import { Edit, Delete } from "@mui/icons-material";
import ModalEnviarMsgTeste from './ModalEnviarMsgTeste';
import { MensagemPadrao } from 'src/dtos/BotsDTO';
import ModalAddMsgPadrao from './ModalAddMsgPadrao';
import ModalExcluirMsgPadrao from './ModalExcluirMsgPadrao';
import { useSnackbarContext } from 'src/context/SnackbarContext';
interface TabelaMensagensProps {
    pickID: string;
}

const MensagensPadroes = ({ pickID }: TabelaMensagensProps) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext();

    //* Mensagens Padrões
    const mensagensPadroesStore = useSelector((state: RootState) => state.bots.listaMensagensPadroes);

    //* Adicionar e Editar Mensagem Padrão
    const salvarMensagemPadraoStore = useSelector((state: RootState) => state.bots.salvarMensagemPadrao);
    const [modalAddMsgPadrao, setModalAddMsgPadrao] = useState(false);

    //* Excluir Mensagem Padrão
    const excluirMensagemPadraoStore = useSelector((state: RootState) => state.bots.excluirMensagemPadrao);
    const [modalConfirmacaoExcluir, setModalConfirmacaoExcluir] = useState(false);

    //* Enviar Mensagem Teste
    const mensagemTesteStore = useSelector((state: RootState) => state.bots.enviarMensagemTeste);
    const [modalMsgTeste, setModalMsgTeste] = useState(false);

    const [msgSelecionada, setMsgSelecionada] = useState<MensagemPadrao | null>(null);

    const listarRequest = () => {
        dispatch(listarMensagensPadroesStart({
            pickID: pickID,
            pagina: 1,
            paginacao: -1,
        }));
    }

    //* Adicionar e Editar Mensagem Padrão
    const handleOpenModalAddMsgPadrao = () => {
        setModalAddMsgPadrao(true)
        setMsgSelecionada(null)
    }

    const handleEditarMsgPadrao = (msg: MensagemPadrao) => {
        setMsgSelecionada(msg)
        setModalAddMsgPadrao(true);
    }

    const handleCloseAddMsgPadrao = () => {
        setModalAddMsgPadrao(false)
        setMsgSelecionada(null);
    };

    //* Enviar Mensagem de Teste
    const handleTestarMsg = (mensagem: MensagemPadrao) => {
        setMsgSelecionada(mensagem);
        setModalMsgTeste(true);
    }

    const handleCloseModalMsgTeste = () => {
        setModalMsgTeste(false)
        setMsgSelecionada(null)
    };

    //* Exclusão de Mensagem Padrão
    const handleExcluirMsgPadrao = (msg: MensagemPadrao) => {
        setMsgSelecionada(msg);
        setModalConfirmacaoExcluir(true);
    }

    const handleCloseExcluirMsgPadrao = () => {
        setModalConfirmacaoExcluir(false)
        setMsgSelecionada(null);
    };

    useEffect(() => {
        if (mensagemTesteStore.data) {
            showSuccess({
                message: mensagemTesteStore.data.mensagem,
                duration: 5000
            })

            handleCloseModalMsgTeste()
        } else if (mensagemTesteStore.error) {
            showError({
                message: mensagemTesteStore.error,
                duration: 5000
            })
        }
        return () => {
            dispatch(enviarMensagemTesteClear())
        }
    }, [mensagemTesteStore.data, mensagemTesteStore.error])

    useEffect(() => {
        if (salvarMensagemPadraoStore.data) {
            showSuccess({
                message: salvarMensagemPadraoStore.data.mensagem,
                duration: 5000
            })
            handleCloseAddMsgPadrao()
            listarRequest()
        } else if (salvarMensagemPadraoStore.error) {
            showError({
                message: salvarMensagemPadraoStore.error,
                duration: 5000
            })
        }
        return () => {
            dispatch(salvarMensagemPadraoClear())
        }
    }, [salvarMensagemPadraoStore.data, salvarMensagemPadraoStore.error])

    useEffect(() => {
        if (excluirMensagemPadraoStore.data) {
            showSuccess({
                message: excluirMensagemPadraoStore.data.mensagem,
                duration: 5000
            })

            handleCloseExcluirMsgPadrao()
            listarRequest()
        } else if (excluirMensagemPadraoStore.error) {
            showError({
                message: excluirMensagemPadraoStore.error,
                duration: 5000
            })
        }
        return () => {
            dispatch(excluirMensagemPadraoClear())
        }
    }, [excluirMensagemPadraoStore.data, excluirMensagemPadraoStore.error])

    useEffect(() => {
        listarRequest()
    }, [pickID])

    return (
        <>
            <Box sx={{ bgcolor: 'background.default' }}>
                <Stack direction={'row'} sx={{ justifyContent: 'flex-end', alignItems: 'center', p: 2 }}>
                    <Button
                        variant="iconeComTextoFilled"
                        sx={{ width: 'fit-content' }}
                        onClick={handleOpenModalAddMsgPadrao}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                    >
                        Adicionar Mensagem Padrão
                    </Button>
                </Stack>
                <TableContainer sx={{ width: '100%', overflowY: 'auto', margin: '0 auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ borderTop: '1px solid #ccc' }}>
                                <TableCell align='center' width='10%'>
                                    Status
                                </TableCell>
                                <TableCell align="center" width='10%'>
                                    Função do bot
                                </TableCell>
                                <TableCell align="center" width='10%'>
                                    Função da mensagem
                                </TableCell>
                                <TableCell align="center" width='50%'>
                                    Mensagem
                                </TableCell>
                                <TableCell align="center" width='20%'>
                                    Ações
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mensagensPadroesStore.loading
                                ? <TableRowLoading />
                                : mensagensPadroesStore.data?.dados.lista && mensagensPadroesStore.data?.dados.lista.length > 0
                                    ? mensagensPadroesStore.data?.dados.lista.map((mensagem, index) => (
                                        <TableRow key={index}>
                                            <TableCell align='center'>
                                                {mensagem.ativo ? <ChipSituacao situacao="ATIVA" label="ATIVO" sx={{ bgcolor: 'success.main', color: 'success.contrastText' }} /> : <ChipSituacao situacao="INATIVO" label="INATIVO" />}
                                            </TableCell>
                                            <TableCell align="center">
                                                {mensagem.funcaoBotLabel}
                                            </TableCell>
                                            <TableCell align="center">
                                                {mensagem.funcaoMsgLabel}
                                            </TableCell>
                                            <TableCell align="center">
                                                {mensagem.mensagem}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Testar Mensagem" arrow placement='top'>
                                                    <IconButton
                                                        aria-label='Testar Mensagem'
                                                        onClick={() => { handleTestarMsg(mensagem) }}
                                                    >
                                                        <ScienceRoundedIcon color='success' />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Editar" arrow placement='top'>
                                                    <IconButton
                                                        aria-label='Editar'
                                                        onClick={() => { handleEditarMsgPadrao(mensagem) }}
                                                    >
                                                        <Edit color='warning' />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Excluir" arrow placement='top'>
                                                    <IconButton
                                                        aria-label='Excluir'
                                                        onClick={() => { handleExcluirMsgPadrao(mensagem) }}
                                                    >
                                                        <Delete color='error' />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    : <TableRowNotFound />
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {modalMsgTeste && msgSelecionada && (
                <ModalEnviarMsgTeste
                    open={modalMsgTeste}
                    onClose={handleCloseModalMsgTeste}
                    pickWppBotMensagemPadraoID={msgSelecionada.pickWppBotMensagemPadraoID}
                />
            )}

            {modalConfirmacaoExcluir && msgSelecionada && (
                <ModalExcluirMsgPadrao
                    open={modalConfirmacaoExcluir}
                    onClose={handleCloseExcluirMsgPadrao}
                    pickWppBotMensagemPadraoID={msgSelecionada.pickWppBotMensagemPadraoID}
                />
            )}

            {modalAddMsgPadrao && pickID && (
                <ModalAddMsgPadrao
                    modalProps={{
                        open: modalAddMsgPadrao,
                        onClose: () => handleCloseAddMsgPadrao(),
                        headerModal: {
                            title: 'Adicionar Mensagem Padrão',
                        }
                    }}
                    msgTemplate={msgSelecionada}
                    pickID={pickID}
                />
            )}
        </>

    );
};

export default MensagensPadroes;
