import { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { IconButtonFechar } from './styledIconButtons'

interface HeaderCardProps {
    onClick: () => void
    title: string
}

const HeaderCard = ({ onClick, title }: HeaderCardProps) => {
    useEffect(() => {
        const handleKeydown = (e: KeyboardEvent) => {
            e.key === 'Escape' && onClick()
        }
        window.addEventListener('keydown', handleKeydown)

        return () => window.removeEventListener('keydown', handleKeydown)
    }, [])
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
                mb: "1rem",
                minHeight: '3rem'
            }}
        >
            <IconButtonFechar
                onClick={onClick}
                sx={{ position: "absolute", left: 0 }}
            />
            <Typography
                sx={{
                    fontWeight: 500,
                    textAlign: "center",
                    px: "3rem",
                    width: "100%",
                }}
                variant="h6"
            >
                {title}
            </Typography>
        </Box>
    )
}

export default HeaderCard
