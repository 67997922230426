const permissions = {
  usuario: {
    "bank.depositos": false,
    "pagamento.inserir": false,
    "antecipacao.inserir": false,
    "taxaExtra.inserir": false,
    "empresa.inserir": false,
    "pick.inserir": false,
    "pontuacao.excluir": false,
    "tipoEmpresa.editar": false,
    "comunicado.excluir": false,
    "assinatura.visualizar": false,
    "planoAssinatura.editar": false,
    "acessoLog.visualizar": false,
    "empresa.visualizar": false,
    "compromisso.excluir": false,
    "bank.extrato": false,
    "tipoEmpresa.visualizar": false,
    "processo.visualizar": false,
    "tabelaCobranca.visualizar": false,
    "publicidade.editar": false,
    "formulario.visualizar": false,
    "tecnologia.excluir": false,
    "tipoMovimentacao.excluir": false,
    "checklist.inserir": false,
    "tipoCompromisso.inserir": false,
    "pontuacao.visualizar": false,
    "tabelaDespachoAutomatico.inserir": false,
    "compromisso.inserir": false,
    "pedido.editar": false,
    "zonaExclusao.visualizar": false,
    "tipoCompromisso.editar": false,
    "estado.editar": false,
    "score.inserir": false,
    "cidade.visualizar": false,
    "usuario.visualizar": false,
    "tipoMovimentacao.visualizar": false,
    "cartaoCredenciadora.excluir": false,
    "menu.visualizar": false,
    "antecipacao.visualizar": false,
    "tipoOcorrencia.excluir": false,
    "pessoa.excluir": false,
    "score.excluir": false,
    "compromisso.editar": false,
    "formulario.excluir": false,
    "tipoOcorrencia.editar": false,
    "formaPagamento.visualizar": false,
    "score.editar": false,
    "checklist.editar": false,
    "tag.excluir": false,
    "profissao.excluir": false,
    "cartaoCredenciadora.inserir": false,
    "checklist.visualizar": false,
    "planoAssinatura.visualizar": false,
    "antecipacao.editar": false,
    "zonaExclusao.excluir": false,
    "planoCredito.visualizar": false,
    "compromisso.visualizar": false,
    "antecipacao.excluir": false,
    "entregador.visualizar": false,
    "tipoMovimentacao.editar": false,
    "geral.areaDeTrabalho": false,
    "habilidade.editar": false,
    "politica.editar": false,
    "tabelaCobranca.editar": false,
    "cupomDesconto.visualizar": false,
    "tipoVeiculo.visualizar": false,
    "comunicado.editar": false,
    "pedido.excluir": false,
    "estado.excluir": false,
    "sistemaParametro.excluir": false,
    "tipoCompromisso.excluir": false,
    "tag.visualizar": false,
    "pick.editar": false,
    "pick.excluir": false,
    "planoCredito.editar": false,
    "menu.inserir": false,
    "tipoOcorrencia.inserir": false,
    "pedido.diarias": false,
    "tabelaDespachoAutomatico.visualizar": false,
    "pagamento.excluir": false,
    "tabelaValor.visualizar": false,
    "banco.inserir": false,
    "profissao.visualizar": false,
    "comunicado.visualizar": false,
    "acessoPerfil.excluir": false,
    "publicidade.excluir": false,
    "processo.editar": false,
    "tipoVeiculo.excluir": false,
    "tag.inserir": false,
    "publicidade.visualizar": false,
    "tag.editar": false,
    "empresa.excluir": false,
    "empresa.configuracoesAvancadas": false,
    "entregador.enviarNotificacao": false,
    "banco.excluir": false,
    "tipoEmpresa.excluir": false,
    "pessoa.visualizar": false,
    "geral.generico": false,
    "taxaExtra.excluir": false,
    "cupomDesconto.inserir": false,
    "pontuacao.inserir": false,
    "bank.cobrancas": false,
    "prioridade.visualizar": false,
    "planoCredito.inserir": false,
    "formaPagamento.excluir": false,
    "financeiroMovimentacao.editar": false,
    "usuario.enviarNotificacao": false,
    "relatorio.geral": false,
    "cidade.editar": false,
    "financeiroMovimentacao.inserir": false,
    "empresa.operador": false,
    "usuario.editar": false,
    "tabelaCobranca.inserir": false,
    "comunicado.inserir": false,
    "politica.excluir": false,
    "entregador.inserir": false,
    "cupomDesconto.editar": false,
    "sistemaParametro.inserir": false,
    "arquivo.visualizar": false,
    "prioridade.excluir": false,
    "tipoOcorrencia.visualizar": false,
    "planoAssinatura.excluir": false,
    "usuario.ocorrencias": false,
    "pessoa.editar": false,
    "taxaExtra.editar": false,
    "cartaoCredenciadora.editar": false,
    "financeiroMovimentacao.visualizar": false,
    "publicidade.inserir": false,
    "pagamento.editar": false,
    "formaPagamento.inserir": false,
    "zonaExclusao.editar": false,
    "pagamento.visualizar": false,
    "institucionalTexto.excluir": false,
    "bank.pagamentos": false,
    "empresa.representante": false,
    "entregador.excluir": false,
    "tipoCompromisso.visualizar": false,
    "tipoVeiculo.editar": false,
    "empresa.editar": false,
    "banco.editar": false,
    "empresa.clientes": false,
    "fatura.excluir": false,
    "assinatura.inserir": false,
    "tabelaDespachoAutomatico.excluir": false,
    "politica.inserir": false,
    "profissao.editar": false,
    "cartaoCredenciadora.visualizar": false,
    "acessoPerfil.editar": false,
    "financeiroMovimentacao.excluir": false,
    "fatura.editar": false,
    "pedido.solicitarEntregadorParceiro": false,
    "formulario.editar": false,
    "profissao.inserir": false,
    "cidade.inserir": false,
    "arquivo.inserir": false,
    "estado.inserir": false,
    "prioridade.inserir": false,
    "tabelaValor.excluir": false,
    "pick.visualizar": false,
    "usuario.liderDeEscala": false,
    "estado.visualizar": false,
    "processo.excluir": false,
    "habilidade.excluir": false,
    "pedido.adicionarFinanceiro": false,
    "fatura.inserir": false,
    "cidade.excluir": false,
    "pontuacao.editar": false,
    "entregador.editar": false,
    "tipoMovimentacao.inserir": false,
    "institucionalTexto.editar": false,
    "pedido.inserir": false,
    "arquivo.editar": false,
    "pessoa.inserir": false,
    "usuario.excluir": false,
    "formulario.inserir": false,
    "prioridade.editar": false,
    "acessoPerfil.visualizar": false,
    "planoCredito.excluir": false,
    "tecnologia.editar": false,
    "institucionalTexto.visualizar": false,
    "sistemaParametro.visualizar": false,
    "taxaExtra.visualizar": false,
    "cupomDesconto.excluir": false,
    "menu.editar": false,
    "banco.visualizar": false,
    "tipoEmpresa.inserir": false,
    "checklist.excluir": false,
    "tecnologia.visualizar": false,
    "arquivo.excluir": false,
    "usuario.inserir": false,
    "tabelaCobranca.excluir": false,
    "politica.visualizar": false,
    "processo.inserir": false,
    "assinatura.excluir": false,
    "tecnologia.inserir": false,
    "tabelaValor.inserir": false,
    "fatura.visualizar": false,
    "institucionalTexto.inserir": false,
    "tipoVeiculo.inserir": false,
    "tabelaDespachoAutomatico.editar": false,
    "planoAssinatura.inserir": false,
    "score.visualizar": false,
    "menu.excluir": false,
    "habilidade.inserir": false,
    "tabelaValor.editar": false,
    "assinatura.editar": false,
    "zonaExclusao.inserir": false,
    "pedido.visualizar": false,
    "sistemaParametro.editar": false,
    "acessoPerfil.inserir": false,
    "formaPagamento.editar": false,
    "habilidade.visualizar": false,
  },
};

export type Permissions = typeof permissions;
export default permissions;
