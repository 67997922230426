import { Chip, ChipProps, styled } from "@mui/material";
import getSituacaoStyles from "../utils/getSituacaoStyles";

export type ChipSituacao =
    | 'CRIADO'
    | 'PRONTO'
    | 'DESPACHADO'
    | 'RECEBIDO'
    | 'Em rota'
    | 'ACEITO'
    | 'No local'
    | 'RETORNANDO'
    | 'CANCELADO'
    | 'FINALIZADO'
    | 'ATIVA'
    | 'INATIVA';

interface ChipSituacaoProps extends ChipProps {
    situacao?: ChipSituacao | string | null
}

export const ChipSituacao = styled(({ ...props }) => {
    return <Chip {...props} />
}, {
    shouldForwardProp: (prop) => prop !== 'situacao',
})<ChipSituacaoProps>(({ theme, situacao }) => ({
    ...getSituacaoStyles(theme, situacao),
}))
