import { cleanInput } from "../common";

const validateCNPJ = (cnpj?: string): boolean => {
  if (cnpj) {
    const cleanedCNPJ = cleanInput(cnpj); 
    if (cleanedCNPJ.length !== 14) return false;

    let length = cleanedCNPJ.length - 2;
    let numbers = cleanedCNPJ.substring(0, length);
    let digits = cleanedCNPJ.substring(length);
    let sum = 0,
      pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += parseInt(numbers.charAt(length - i)) * pos--;
      if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;

    length = length + 1;
    numbers = cleanedCNPJ.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
      sum += parseInt(numbers.charAt(length - i)) * pos--;
      if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
  }
  return false;
};

export default validateCNPJ;
