import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
    loading: boolean;
    data: any | null;
    error: string | null;
}

interface WalletState {
    pagina: State;
    minhasTransacoes: State;
    comprarCreditos: State
    consultarPagamento: State;
    novoCredito: State  
}

const initialState: WalletState = {
    pagina: {
        loading: false,
        data: null,
        error: null
    },
    minhasTransacoes: {
        loading: false,
        data: null,
        error: null
    },
    comprarCreditos: {
        loading: false,
        data: null,
        error: null
    },
    consultarPagamento: {
        loading: false,
        data: null,
        error: null
    },
    novoCredito: {
        loading: false,
        data: null,
        error: null
    }
};

const walletSlice = createSlice({
    name: "Wallet",
    initialState: initialState,
    reducers: {
        paginaStart(state, action: PayloadAction<any>) {
            state.pagina.loading = true;
            state.pagina.error = null;
        },
        paginaFailure(state, action: PayloadAction<string>) {
            state.pagina.loading = false;
            state.pagina.error = action.payload;
        },
        paginaSuccess(state, action: PayloadAction<any>) {
            state.pagina.loading = false;
            state.pagina.data = action.payload;
        },
        minhasTransacoesStart(state, action: PayloadAction<any>) {
            state.minhasTransacoes.loading = true;
            state.minhasTransacoes.error = null;
        },
        minhasTransacoesFailure(state, action: PayloadAction<string>) {
            state.minhasTransacoes.loading = false;
            state.minhasTransacoes.error = action.payload;
        },
        minhasTransacoesSuccess(state, action: PayloadAction<any>) {
            state.minhasTransacoes.loading = false;
            state.minhasTransacoes.data = action.payload;
        },
        comprarCreditosStart(state, action: PayloadAction<any>) {
            state.comprarCreditos.loading = true;
            state.comprarCreditos.error = null;
        },
        comprarCreditosFailure(state, action: PayloadAction<string>) {
            state.comprarCreditos.loading = false;
            state.comprarCreditos.error = action.payload;
        },
        comprarCreditosSuccess(state, action: PayloadAction<any>) {
            state.comprarCreditos.loading = false;
            state.comprarCreditos.data = action.payload;
            state.novoCredito.data = action.payload;
        },
        limparComprarCreditos(state) {
            state.comprarCreditos.loading = false;
            state.comprarCreditos.error = null;
            state.comprarCreditos.data = null;
            state.novoCredito.data = null;
        },
        consultarPagamentoStart(state, action: PayloadAction<any>) {
            state.consultarPagamento.loading = true;
            state.consultarPagamento.error = null;
        },
        consultarPagamentoFailure(state, action: PayloadAction<string>) {
            state.consultarPagamento.loading = false;
            state.consultarPagamento.error = action.payload;
        },
        consultarPagamentoSuccess(state, action: PayloadAction<any>) {
            state.consultarPagamento.loading = false;
            state.consultarPagamento.data = action.payload;
        },
        limparConsultarPagamento(state) {
            state.consultarPagamento.loading = false;
            state.consultarPagamento.error = null;
            state.consultarPagamento.data = null;
        }
    },
});

export const {
    paginaStart,
    paginaFailure,
    paginaSuccess,
    minhasTransacoesStart,
    minhasTransacoesFailure,
    minhasTransacoesSuccess,
    comprarCreditosFailure,
    comprarCreditosStart,
    comprarCreditosSuccess,
    consultarPagamentoStart,
    consultarPagamentoFailure,
    consultarPagamentoSuccess,
    limparConsultarPagamento,
    limparComprarCreditos
} = walletSlice.actions;

export default walletSlice;
