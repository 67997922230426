import { call, put, takeLatest } from "redux-saga/effects";
import {
    pedidoFailure, pedidoStart, pedidoSuccess,
    entregadoresStart, entregadoresFailure, entregadoresSuccess,
    empresaStart, empresaSuccess, empresaFailure,
    avaliacaoRequest,
} from "../reducers/rastreio";
import { api } from "../../services/api";

function* fetchPedido(action) {
    const form = {
        paginacao: -1,
        pagina: 1,
        pedidoID: action.payload,
    }
    try {
        const response = yield call(api.post, '/Pedido/ListarEmAndamento', form);
        yield put(pedidoSuccess(response.data))
    } catch (error) {
        yield put(pedidoFailure(error.message));
    }
}

function* fetchEntregador(action) {
    const form = {
        paginacao: -1,
        pagina: 1,
        pedidoID: action.payload,
    }
    try {
        const response = yield call(api.post, 'Usuario/ListarEntregadoresOnline', form);
        yield put(entregadoresSuccess(response.data))
    } catch (error) {
        yield put(entregadoresFailure(error.message));
    }
}

function* fetchEmpresa(action) {
    const form = {
        paginacao: -1,
        pagina: 1,
        pedidoID: action.payload,
    }
    try {
        const response = yield call(api.post, 'Empresa/Listar', form);
        yield put(empresaSuccess(response.data))
    } catch (error) {
        yield put(empresaFailure(error.message));
    }
}

function* fetchAvaliacao(action) {
    const { comentario, avaliacao, pedidoID, } = action.payload;
    const form = {
        pedidoID,
        avaliacao,
        comentario,
    }
    try {
        yield call(api.post, 'Pedido/AvaliacaoClientesAcompanhamento', form);
    } catch (error) {
        console.log('Erro Inesperado ao enviar avaliação', error);
    }
}

function* rastreioSaga() {
    yield takeLatest(pedidoStart.type, fetchPedido);
    yield takeLatest(entregadoresStart.type, fetchEntregador);
    yield takeLatest(empresaStart.type, fetchEmpresa);
    yield takeLatest(avaliacaoRequest.type, fetchAvaliacao);
}

export default rastreioSaga