const maskCpf = (input?: string | number | null) => {
  if (!input) return "";
  // Remove todos os caracteres que não são números
  const valorString = String(input).replace(/\D/g, "");

  // Limita a entrada a 11 dígitos numéricos
  const valorLimitado = valorString.slice(0, 11);

  // Adiciona os pontos e o hífen conforme a máscara de CPF
  const cpfMascarado = valorLimitado
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return cpfMascarado;
};

export default maskCpf;
