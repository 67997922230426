import { call, put, takeLatest } from "redux-saga/effects";
import { comprarCreditosFailure, comprarCreditosStart, comprarCreditosSuccess, consultarPagamentoFailure, consultarPagamentoStart, consultarPagamentoSuccess, minhasTransacoesFailure, minhasTransacoesStart, minhasTransacoesSuccess, paginaFailure, paginaStart, paginaSuccess } from "../reducers/wallet";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse, DefaultDTO } from "src/dtos/DefaultDTO";
import { api } from "src/services/api";
import { gerarQrCodeStart } from "../reducers/utils";

function* pagina() {
    try {
        const response: ApiResponse<DefaultDTO<any>> = yield call(
            api.post,
            "/Pick/PaginaAbastecerWallet"
        );
        if (response.data.sucesso === true) {
            yield put(paginaSuccess(response.data));
        } else {
            yield put(paginaFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* minhasTransacoes(action: PayloadAction<any>) {
    try {
        const response: ApiResponse<DefaultDTO<any>> = yield call(
            api.post,
            "/PickWallet/MinhasTransacoes",
            action.payload
        );
        if (response.data.sucesso === true) {
            yield put(minhasTransacoesSuccess(response.data));
        } else {
            yield put(minhasTransacoesFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* comprarCreditos(action: PayloadAction<any>) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response: ApiResponse<any> = yield call(
            api.post,
            "/PickWallet/ComprarCreditos",
            form
        );
        if (response.data.sucesso === true) {
            yield put(comprarCreditosSuccess(response.data));
            yield put(gerarQrCodeStart(response.data.pixQrcode));
        } else {
            yield put(comprarCreditosFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* consultarPagamento(action: PayloadAction<any>) {
    try {
        const response: ApiResponse<DefaultDTO<any>> = yield call(
            api.post,
            "/PickWallet/Consultar?id=" + action.payload.id,
        );
        if (response.data.sucesso === true) {
            yield put(consultarPagamentoSuccess(response.data));
        } else {
            yield put(consultarPagamentoFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* walletSaga() {
    yield takeLatest(paginaStart, pagina);
    yield takeLatest(minhasTransacoesStart, minhasTransacoes);
    yield takeLatest(comprarCreditosStart, comprarCreditos);
    yield takeLatest(consultarPagamentoStart, consultarPagamento);
}

export default walletSaga;
