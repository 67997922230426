import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper, useTheme, IconButton, Typography } from '@mui/material';
import { AddToPhotos, ArrowDownward, ArrowUpward, Delete, Edit } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { TableRowLoading, ChipSituacao, TableRowNotFound, ButtonTableOrder, ModalOpcoes } from 'src/ds';
import { useDispatch, useSelector } from 'react-redux';
import { duplicarTabelaCobrancaStart, excluirTabelaStart, listaTabelaCobrancaStart } from 'src/store/reducers/tabelaCobranca'; // Adicione a action listaTabelaCobrancaStart
import { useNavigate } from 'react-router-dom';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface TabelaCobrancaProps {
    tabelasCobranca: any[];
    loading: boolean;
    fezRequisicao?: boolean;
    atualizar: () => void;
    ultimaTabelaCadastrada: any;
    triggerEditar: boolean;
    setTriggerEditar: (trigger: boolean) => void
}

const TabelaCobranca = ({
    tabelasCobranca,
    loading,
    fezRequisicao,
    atualizar,
    ultimaTabelaCadastrada,
    triggerEditar,
    setTriggerEditar
}: TabelaCobrancaProps) => {
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        nome: 'asc',
        dataCadastro: 'asc',
        empresas: 'asc',
        valorPonto: 'asc',
    });
    const { showSuccess, showError } = useSnackbarContext();
    const [visible, setVisible] = useState(false);
    const [excluir, setExcluir] = useState(false);
    const [row, setRow] = useState<any>(null);
    const [abrirOpcoes, setAbrirOpcoes] = useState(false);
    const response = useSelector((state: any) => state.tabelaCobranca.duplicarTabelaCobranca);

    useEffect(() => {
        if (response.data?.sucesso === true) {
            showSuccess({
                message: response?.data.mensagem,
                duration: 5000
            });
            setAbrirOpcoes(false);
            atualizar();
        } else if (response.data?.sucesso === false) {
            showError({
                message: response?.data.erro,
                duration: 5000
            });
        }
    }, [response.data]);

    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        const updatedSortDirection = Object.keys(sortDirection).reduce((acc, key) => {
            acc[key] = key === column ? newSortDirection : 'asc';
            return acc;
        }, {} as { [key: string]: 'asc' | 'desc' });

        setSortDirection(updatedSortDirection);

        const payload = {
            paginacao: 20,
            pagina: 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
        };

        dispatch(listaTabelaCobrancaStart(payload));
    };

    useEffect(() => {
        if (triggerEditar && ultimaTabelaCadastrada) {
            handleView(ultimaTabelaCadastrada);
            setTriggerEditar(false);
        }
    }, [triggerEditar, ultimaTabelaCadastrada]);

    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleView = (row: any) => {
        setVisible(true);
        setRow(row);
    };

    const handleExcluir = (row: any) => {
        setExcluir(true);
        setRow(row);
    };

    const handleDouble = (row: any) => {
        setRow(row);
        setAbrirOpcoes(true);
    };

    const handleConfirm = () => {
        dispatch(excluirTabelaStart(row.tabelaCobrancaID));
        setTimeout(() => {
            atualizar();
            setExcluir(false)
        }, 500);
    };

    return (
        <TableContainer component={Paper} sx={{ width: '100%', overflowY: 'auto', margin: '0 auto' }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ borderTop: '1px solid #ccc' }}>
                        <TableCell align="center" sx={{ width: '5%' }}>
                            <ButtonTableOrder>
                                Situação
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('nome')} sortDirection={sortDirection} nome="nome">
                                Tabela
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('dataCadastro')} sortDirection={sortDirection} nome="dataCadastro">
                                Data de Cadastro
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('empresas')} sortDirection={sortDirection} nome="empresas">
                                Empresas
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder>
                                Tipos de veículo
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder>
                                Ações
                            </ButtonTableOrder>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : (
                        tabelasCobranca && tabelasCobranca.length > 0
                            ? tabelasCobranca?.map((row, index) => (
                                <TableRow key={index} hover>
                                    <TableCell align="center" sx={{ py: 2 }}>
                                        <ChipSituacao situacao={row.ativo ? 'Ativa' : 'Inativa'} label={row.ativo ? 'Ativa' : 'Inativa'} />
                                    </TableCell>
                                    <TableCell align="center" sx={{ py: 2 }}>{row.nome}</TableCell>
                                    <TableCell align="center" sx={{ py: 2 }}>{row.dataCadastro}</TableCell>
                                    <TableCell align="center" sx={{ py: 2 }}>
                                        {row.empresas && row.empresas.length > 0 ? (
                                            row.empresas.map((empresa: any, index: number) => (
                                                <div key={index}>{empresa?.nome || 'Sem nome'}</div>
                                            ))
                                        ) : (
                                            <div>Nenhuma empresa disponível</div>
                                        )}
                                    </TableCell>                                <TableCell align="center" sx={{ py: 2 }}>
                                        {row.tiposVeiculo && row.tiposVeiculo.length > 0 ? (
                                            row.tiposVeiculo.map((tipoVeiculo: any, index: number) => (
                                                <div key={index}>{tipoVeiculo?.nome || 'Sem nome'}</div>
                                            ))
                                        ) : (
                                            <div>Nenhum tipo de veículo disponível</div>
                                        )}
                                    </TableCell>
                                    <TableCell align="center" sx={{ py: 2 }}>
                                        <Stack direction="row" spacing={1} justifyContent="center">
                                            <Tooltip title="Duplicar" arrow placement="top">
                                                <IconButton onClick={() => handleDouble(row)}>
                                                    <AddToPhotos color="warning" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Editar" arrow placement="top">
                                                <IconButton onClick={() => { navigate('/TabelaCobranca/Index/' + row.tabelaCobrancaID) }}>
                                                    <Edit color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Excluir" arrow placement="top">
                                                <IconButton onClick={() => handleExcluir(row)}>
                                                    <Delete color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))
                            : <TableRowNotFound fezRequisicao={fezRequisicao} />
                    )}
                </TableBody>
            </Table>
            <ModalOpcoes
                open={excluir}
                onClose={() => setExcluir(false)}
                descricao={(
                    <Typography>
                        Tem certeza que deseja excluir <span style={{ fontWeight: 'bold' }}>{row?.nome}</span> da tabela?
                    </Typography>
                )}
                acao='Excluir'
                opcoes={[
                    {
                        label: 'Cancelar',
                        action() { setExcluir(false) },
                    },
                    {
                        label: 'Excluir',
                        action() { handleConfirm() },
                    },
                ]}
            />
            <ModalOpcoes
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => { setAbrirOpcoes(false) }
                    },
                    {
                        label: 'Confirmar',
                        action: () => { dispatch(duplicarTabelaCobrancaStart({ tabelaCobrancaID: row.tabelaCobrancaID })); }
                    }
                ]}
                open={abrirOpcoes}
                onClose={() => setAbrirOpcoes(false)}
                acao='Duplicar'
                descricao='Tem certeza que deseja duplicar essa tabela?'
            />
        </TableContainer>
    );
};

export default TabelaCobranca;
