import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
    Box, TextField, Button, FormControl, MenuItem, InputLabel, Select, Typography
} from '@mui/material';
import { cleanInput, formatCnpj, telefone } from 'src/ds/common';
import { maskCpf, validateCNPJ, validateCPF } from 'src/ds';
import maskRG from 'src/ds/utils/maskRG';
import { excluirEntregador, limparSalvarUsuario, salvarUsuarioReq } from 'src/store/reducers/config';
import { AvatarWithModal, InputDate, ModalOpcoes, SelectCidade, SelectEstado, SelectToggleAtivo, TextFieldChip, useResponseSnackbar } from 'src/ds';
import { desbloquearSenhaStart, invalidarSessoesStart, limparDesbloquearSenha, limparInvalidarSessoes, usuarioPaginaStart } from 'src/store/reducers/entregador';
import { useNavigate } from 'react-router-dom';
import ContainerLoading from 'src/ds/components/ContainerLoading';

type Endereco = {
    CEP: string;
    logradouro: string;
    numero: string;
    complemento?: string;
    pontoReferencia?: string;
    estadoID: string;
    cidadeID: string;
    bairro: string;
};

type FormData = {
    id: string;
    nome: string;
    senha: string;
    confirmarSenha: string;
    email: string;
    ativo: boolean | string;
    bloqueado: string;
    dataDesbloqueioSenha?: string;
    entregador: string;
    podePegarEscala: string;
    receberEmails: string;
    receberNotificacoes: string;
    telefone: string;
    cpf: string;
    cnpj: string;
    rg: string;
    rgOrgao: string;
    estadoCivil: string;
    dataNascimento: string;
    endereco: Endereco;
    perfilArquivo?: File | null;
    tagsSelecionadas: string[];
    tagsPermissaoSelecionadas: string[];
};

const DadosBasicos = ({ id }: any) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const entregador = useSelector((state: any) => state.entregadores?.usuarioPagina?.data?.modelo);
    const hookForm = useForm<FormData>({
        defaultValues: {
            id: id,
            nome: '',
            senha: '',
            confirmarSenha: '',
            email: '',
            ativo: '',
            bloqueado: 'livre',
            dataDesbloqueioSenha: '',
            entregador: 'false',
            podePegarEscala: 'nao',
            receberEmails: 'nao',
            receberNotificacoes: 'nao',
            telefone: '',
            cpf: '',
            cnpj: '',
            rg: '',
            rgOrgao: '',
            estadoCivil: '',
            dataNascimento: '',
            endereco: {
                CEP: '',
                logradouro: '',
                numero: '',
                complemento: '',
                pontoReferencia: '',
                estadoID: '',
                cidadeID: '',
                bairro: '',
            },
            perfilArquivo: null,
            tagsSelecionadas: [],
            tagsPermissaoSelecionadas: [],
        }
    });
    const { register, control, handleSubmit, setValue, watch, formState: { errors } } = hookForm
    const response = useSelector((state: any) => state.config?.salvarUsuario);
    const invalidarSessoes = useSelector((state: any) => state.entregadores?.invalidarSessoes);
    const desbloquearSenha = useSelector((state: any) => state.entregadores?.desbloquearSenha);
    const [openModalOpcoes, setOpenModalOpcoes] = useState(false);
    const [modalData, setModalData] = useState({
        descricao: '',
        acao: '',
        opcoes: []
    });
    useResponseSnackbar([
        { response, clearAction: () => { dispatch(limparSalvarUsuario()); } },
        { response: invalidarSessoes, clearAction: () => { dispatch(limparInvalidarSessoes()); } },
        { response: desbloquearSenha, clearAction: () => { dispatch(limparDesbloquearSenha()); } }
    ]);

    useEffect(() => {
        dispatch(usuarioPaginaStart({ id: id }));
    }, []);

    useEffect(() => {
        if (entregador) {
            Object.keys(entregador).forEach((key: any) => {
                if (key === 'CEP' || key === 'logradouro' || key === 'numero' || key === 'complemento' || key === 'pontoReferencia' || key === 'estadoID' || key === 'cidadeID' || key === 'bairro') {
                    //@ts-ignore
                    setValue(`endereco.${key}`, entregador[key as keyof typeof entregador]);
                } else {

                    setValue(key as keyof FormData, entregador[key as keyof typeof entregador]);
                }
            });
            setLoading(false);
        }

    }, [entregador]);

    const handleOpenModal = (descricao: string, acao: string, opcoes: any) => {
        setModalData({ descricao, acao, opcoes });
        setOpenModalOpcoes(true);
    };
    const onSubmit = (data: any) => {
        if (!validateCPF(data.cpf)) {
            return;
        }
        if (data.cnpj && !validateCNPJ(data.cnpj)) {
            return;
        }

        const endereco = data.endereco || {};

        const cleanedData = {
            ...data,
            telefone: cleanInput(data.telefone),
            cpf: cleanInput(data.cpf),
            cnpj: cleanInput(data.cnpj),
            endereco: {
                ...endereco,
                CEP: cleanInput(endereco.CEP || ''),
            },
        };

        const arrayTags = ['tagsSelecionadas', 'tagsPermissaoSelecionadas']
        const formData = new FormData();

        Object.keys(cleanedData).forEach((key) => {
            if (!arrayTags.includes(key) && key !== 'perfilArquivo' && typeof cleanedData[key] !== 'object') {
                formData.append(key, cleanedData[key]);
            } else if (arrayTags.includes(key)) {
                formData.append(key, cleanedData[key]?.join('|'));
            }
        });

        Object.keys(cleanedData.endereco).forEach((endKey) => {
            formData.append(`endereco.${endKey}`, cleanedData.endereco[endKey]);
        });

        if (data.perfilArquivo && data.perfilArquivo[0] instanceof File) {
            formData.append('perfilArquivo', data.perfilArquivo[0]);
        }

        //@ts-ignore
        dispatch(salvarUsuarioReq(formData));
    };

    useEffect(() => {
        if (entregador) {
            Object.keys(entregador).forEach((key: any) => {
                if (key === 'endereco') {
                    const endereco = entregador[key] as Endereco;

                    if (endereco) {
                        Object.keys(endereco).forEach(endKey => {
                            const keyTyped = endKey as keyof Endereco;
                            setValue(`endereco.${keyTyped}` as const, endereco[keyTyped]);
                        });
                    }
                } else {
                    setValue(key as keyof FormData, entregador[key as keyof typeof entregador]);
                }
            });
            setLoading(false);
        }
    }, [entregador]);


    const perfilArquivoUrl = entregador?.perfilArquivo?.url;
    const bloqueado = watch('bloqueado');

    if (loading) {
        return <ContainerLoading />;
    }


    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={(theme) => ({ display: 'flex', gap: 2, flexDirection: 'column', p: 2, backgroundColor: theme.palette.background.paper })}
        >
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="subtitle1">Dados do usuário</Typography>
                    <TextField fullWidth size="small" label="Nome" {...register('nome')} />
                    <TextField fullWidth size="small" label="Senha" type="password" {...register('senha')} />
                    <TextField fullWidth size="small" label="Confirmar Senha" type="password" {...register('confirmarSenha')} />
                    <TextField fullWidth size="small" label="Email" {...register('email')} />

                    <SelectToggleAtivo
                        hookForm={{ methods: hookForm, name: "ativo" }}
                        label="Situação"
                    />

                    <FormControl fullWidth size="small">
                        <InputLabel>Bloqueado por excesso de tentativa</InputLabel>
                        <Controller
                            control={control}
                            name="bloqueado"
                            render={({ field }) => (
                                <Select {...field} label="Bloqueado por excesso de tentativa" size="small">
                                    <MenuItem value="bloqueado">Bloqueado</MenuItem>
                                    <MenuItem value="livre">Livre</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>

                    {bloqueado === 'bloqueado' && (
                        <Controller
                            control={control}
                            name="dataDesbloqueioSenha"
                            render={({ field }) => (
                                <TextField {...field} fullWidth label="Bloqueio até" type="datetime-local" size="small" />
                            )}
                        />
                    )}

                    <FormControl fullWidth size="small">
                        <InputLabel>Habilitado como entregador</InputLabel>
                        <Controller
                            control={control}
                            name="entregador"
                            render={({ field }) => (
                                <Select {...field} label="Habilitado como entregador">
                                    <MenuItem value='true'>Sim</MenuItem>
                                    <MenuItem value='false'>Não</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>

                    <FormControl fullWidth size="small">
                        <InputLabel>Pode pegar escalas?</InputLabel>
                        <Controller
                            control={control}
                            name="podePegarEscala"
                            render={({ field }) => (
                                <Select {...field} label="Pode pegar escalas?">
                                    <MenuItem value="true">Sim</MenuItem>
                                    <MenuItem value="false">Não</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>

                    <FormControl fullWidth size="small">
                        <InputLabel>Receber emails?</InputLabel>
                        <Controller
                            control={control}
                            name="receberEmails"
                            render={({ field }) => (
                                <Select {...field} label="Receber emails?">
                                    <MenuItem value="true">Sim</MenuItem>
                                    <MenuItem value="false">Não</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>

                    <FormControl fullWidth size="small">
                        <InputLabel>Receber notificações?</InputLabel>
                        <Controller
                            control={control}
                            name="receberNotificacoes"
                            render={({ field }) => (
                                <Select {...field} label="Receber notificações?">
                                    <MenuItem value="true">Sim</MenuItem>
                                    <MenuItem value="false">Não</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                    <Box sx={{ display: 'flex', gap: 2 }}>

                        {perfilArquivoUrl && (
                            <AvatarWithModal src={perfilArquivoUrl}
                                alt="Foto de perfil" />
                        )}
                        <TextField
                            fullWidth
                            type="file"
                            size='small'
                            label="Foto de perfil"
                            inputProps={{ accept: 'image/*' }}
                            InputLabelProps={{ shrink: true }}
                            {...register('perfilArquivo')}
                        />
                    </Box>
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="subtitle1">Dados pessoais</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        label="Telefone"
                        value={telefone(watch("telefone") || "")}
                        {...register('telefone')}
                        slotProps={{ htmlInput: { maxLength: 10 } }}

                    />

                    <TextField
                        fullWidth
                        size="small"
                        label="CPF"
                        error={!!errors.cpf}
                        helperText={errors.cpf ? "CPF inválido" : ""}
                        value={maskCpf(watch("cpf") || "")}
                        {...register('cpf', {
                            validate: validateCPF,
                        })}
                    />

                    <TextField
                        fullWidth
                        size="small"
                        label="CNPJ"
                        value={formatCnpj(watch("cnpj") || "")}
                        error={!!errors.cnpj}
                        helperText={errors.cnpj ? errors.cnpj.message : ""}
                        {...register('cnpj', {
                            validate: (value) => {
                                if (!value) return true;
                                return validateCNPJ(value) || "CNPJ inválido";
                            },
                        })}
                    />

                    <TextField
                        fullWidth
                        size="small"
                        label="RG"
                        value={maskRG(watch("rg") || "")}
                        {...register('rg')}
                        slotProps={{ htmlInput: { maxLength: 19 } }}
                    />

                    <TextField
                        fullWidth
                        size="small"
                        label="Órgão Expedidor"
                        {...register('rgOrgao')}
                    />

                    <FormControl fullWidth size="small">
                        <InputLabel>Estado Civil</InputLabel>
                        <Controller
                            control={control}
                            name="estadoCivil"
                            render={({ field }) => (
                                <Select {...field} label="Estado Civil">
                                    <MenuItem value="solteiro">Solteiro</MenuItem>
                                    <MenuItem value="casado">Casado</MenuItem>
                                    <MenuItem value="divorciado">Divorciado</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                    <InputDate
                        hookForm={{
                            name: 'dataNascimento',
                            methods: hookForm,
                        }}
                        label='Data de Nascimento'
                    />
                    <TextFieldChip
                        hookForm={{
                            methods: hookForm,
                            name: 'tagsSelecionadas',
                            defaultValue: entregador?.tagsSelecionadas || [],
                        }}
                        label='Tags'
                    />
                    {/* <TextField fullWidth size="small" label="Tags" {...register('tagsSelecionadas')} /> */}
                    <TextFieldChip
                        hookForm={{
                            methods: hookForm,
                            name: 'tagsPermissaoSelecionadas',
                            defaultValue: entregador?.tagsPermissaoSelecionadas || [],
                        }}
                        label='Tags de permissão'
                    />
                    {/* <TextField fullWidth size="small" label="Tags de permissão" {...register('tagsPermissaoSelecionadas')} /> */}
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="subtitle1">Endereço</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        label="CEP"
                        value={cleanInput(watch("endereco.CEP") || "")}
                        {...register('endereco.CEP')}
                    />
                    <TextField fullWidth size="small" label="Logradouro" {...register('endereco.logradouro')} />
                    <TextField fullWidth size="small" label="Número" {...register('endereco.numero')} />
                    <TextField fullWidth size="small" label="Complemento" {...register('endereco.complemento')} />
                    <TextField fullWidth size="small" label="Ponto de Referência" {...register('endereco.pontoReferencia')} />
                    <SelectEstado hookForm={{ methods: hookForm }} />
                    <SelectCidade hookForm={{ methods: hookForm }} />
                    <TextField fullWidth size="small" label="Bairro" {...register('endereco.bairro')} />
                </Box>
            </Box>
            <Box
                sx={(theme) => ({ display: 'flex', gap: 2, flexDirection: 'column', p: 2, backgroundColor: theme.palette.background.paper })}
            >
                <Box display="flex" justifyContent="flex-end" sx={{ width: '100%', mt: 2, gap: 2 }}>
                    <Button
                        variant="containedFilled"
                        color="red"
                        sx={{ width: 'fit-content' }}
                        onClick={() => {
                            handleOpenModal(
                                'Deseja excluir o entregador?',
                                'Excluir entregador',
                                [
                                    {
                                        label: 'Cancelar',
                                        action: () => setOpenModalOpcoes(false)
                                    },
                                    {
                                        label: 'Confirmar',
                                        action: () => {
                                            dispatch(excluirEntregador(id));
                                            setOpenModalOpcoes(false);
                                            setTimeout(() => {
                                                navigate('/Usuario/Entregadores');
                                            }, 1000)
                                        }
                                    }
                                ]
                            )
                        }}
                    >
                        Excluir
                    </Button>
                    <Button
                        variant="containedFilled"
                        color="secondary"
                        sx={{ width: 'fit-content' }}
                        onClick={() => handleOpenModal(
                            'Deseja invalidar todas as sessões?',
                            'Invalidar sessões',
                            [
                                {
                                    label: 'Cancelar',
                                    action: () => setOpenModalOpcoes(false)
                                },
                                {
                                    label: 'Confirmar',
                                    action: () => {
                                        dispatch(invalidarSessoesStart({ id: id }));
                                        setOpenModalOpcoes(false);
                                    }
                                }
                            ]
                        )}
                    >
                        Invalidar sessões
                    </Button>
                    <Button
                        variant="containedFilled"
                        color="secondary"
                        sx={{ width: 'fit-content' }}
                        onClick={() => handleOpenModal(
                            'Deseja desbloquear a senha?',
                            'Desbloquear senha',
                            [
                                {
                                    label: 'Cancelar',
                                    action: () => setOpenModalOpcoes(false)
                                },
                                {
                                    label: 'Confirmar',
                                    action: () => {
                                        dispatch(desbloquearSenhaStart({ id: id }));
                                        setOpenModalOpcoes(false);
                                    }
                                }
                            ]
                        )}
                    >
                        Desbloquear senha
                    </Button>
                    <Button variant="containedFilled" color="primary" type="submit" sx={{ width: 'fit-content' }}>
                        Salvar
                    </Button>
                </Box>
                <ModalOpcoes
                    open={openModalOpcoes}
                    onClose={() => setOpenModalOpcoes(false)}
                    descricao={modalData.descricao}
                    acao={modalData.acao}
                    opcoes={modalData.opcoes}
                />
            </Box>
        </Box>
    );
};

export default DadosBasicos;