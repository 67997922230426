const maskRG = (input: string | number) => {
    const valorString = String(input).replace(/\D/g, ''); // Remove tudo que não for dígito

    // Aplica as máscaras dependendo do tamanho da entrada
    let rgMascarado = valorString;

    if (rgMascarado.length <= 9) {
        // Para RGs com até 9 dígitos (ex: XX.XXX.XXX ou XX.XXX-XX)
        rgMascarado = rgMascarado
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else if (rgMascarado.length <= 14) {
        // Para RGs maiores, como no formato de CNPJ ou numeração estendida
        rgMascarado = rgMascarado
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    }

    return rgMascarado;
}

export default maskRG;
