// ListOfSkeleton.tsx
import { Skeleton } from '@mui/material';
import React from 'react';

interface ListOfSkeletonProps {
  qnt: number;
  type?: 'text' | 'circular' | 'rectangular' | 'rounded';
  height?: string;
  width?: string;
  props?: any
}

/**
 * Componente para gerar esqueletos
 *
 * @component
 * @param {*} qnt - Quantidade de esqueletos que se repetirão
 * @param {*} type - Tipo de esqueleto (text, circular, rectangular, rounded)
 * @param {*} height - Altura do Esqueleto
 * @param {*} width - Largura do Esqueleto
 */
const ListOfSkeleton: React.FC<ListOfSkeletonProps> = ({
  qnt,
  type = 'rounded',
  height = 'auto',
  width = '100%',
  props
}) => {
  return [...Array(qnt)].map((_, index) => (
    <Skeleton
      key={index}
      variant={type}
      width={width}
      height={height}
      animation="pulse"
      sx={{ marginBottom: '1rem' }}
      {...props}
    />
  ))
};

export default ListOfSkeleton;
