import { Avatar, Box, Button, Container, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowForward, Search } from "@mui/icons-material";
import { usePedido } from "../../../context/PedidoContext";
import { desatribuirRequest, despacharRequest, limparDespachar } from "../../../store/reducers/criarPedido";
import { telefone } from "../../../ds/common";
import { emAndamentoStart, listarEntregadoresRequest } from "../../../store/reducers/config";
import { RootState } from "src/store/reducers";
import { ButtonLoading, CardPrincipal, GridColumn, GridPainelLateral, HeaderCard, ModalOpcoes } from "src/ds";
import { Entregador } from "src/dtos/EntregadoresDTO";
import { taxaExtraStart } from "src/store/reducers/taxaExtra";
import ModalTaxaExtra from "./ModalTaxaExtra";
import ModalConfigCobranca from "./ModalConfigCobranca";
import { useSnackbarContext } from "src/context/SnackbarContext";

interface AtribuirProps {
    close: () => void;
}

interface ErroDespachar {
    bairro: string;
    mensagem: string;
    sucesso: boolean;
    tabelaCobrancaID: string | null;
    tabelaValorID: string | null;
}

const AtribuirPedido = ({ close }: AtribuirProps) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext();
    const entregadores = useSelector((state: RootState) => state.config.entregadores.lista);
    const [filterText, setFilterText] = useState(""); // Estado para o filtro
    const [shouldClose, setShouldClose] = useState(false);
    const { pedidoAtual, rotaAtual, novaListaPedidos, limparNovaLista } = usePedido();
    const atribuirStore = useSelector((state: any) => state.criarPedido?.atribuir);
    const desatribuirStore = useSelector((state: any) => state.criarPedido?.desatribuir);
    const taxasExtra = useSelector((state: RootState) => state.taxaExtra?.lista.data?.dados?.lista);
    const manuais = taxasExtra?.filter(t => t.aplicarAutomaticamente === false) || [];
    const [entregadorSelecionado, setEntregadorSelecionado] = useState<Entregador | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [erroDespachar, setErroDespachar] = useState<ErroDespachar | null>(null);
    const [modalConfigCobranca, setModalConfigCobranca] = useState(false);
    const tabelaCobranca = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.tipoCobranca);
    const tabelaPagamento = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.tipoPagamento);
    const [openDesatribuir, setOpenDesatribuir] = useState(false);

    const handleDesatribuir = () => {
        console.log(pedidoAtual);
        dispatch(desatribuirRequest({
            pedidoID: rotaAtual ? rotaAtual.map((p: any) => p.pedidoID) : pedidoAtual?.pedidoID
        }));
    }

    const handleAtribuir = (entregador: Entregador | null, direto: boolean) => {
        setEntregadorSelecionado(entregador);
        if (!entregador) return;
        let payload
        if (novaListaPedidos?.length > 0) {
            payload = {
                pedidoID: novaListaPedidos.map((p: any) => p.pedidoID),
                usuarioID: entregador.usuarioID,
                rota: true
            }
        } else {
            payload = {
                pedidoID: rotaAtual ? rotaAtual.map((p: any) => p.pedidoID) : pedidoAtual?.pedidoID,
                usuarioID: entregador.usuarioID,
                rota: !!rotaAtual,
            };
        }

        if (manuais.length > 0 && !direto) {
            setOpenModal(true);
        } else {
            dispatch(despacharRequest(payload));
            if (novaListaPedidos) {
                limparNovaLista();
            }
        }

        setShouldClose(false);
    };

    const opcoesDesatribuir = [
        {
            label: 'Cancelar',
            action: () => setOpenDesatribuir(false)
        },
        {
            label: 'Confirmar',
            action: () => {
                handleDesatribuir()
                setOpenDesatribuir(false)

            }
        }
    ]



    useEffect(() => {
        if (atribuirStore.error) {
            showError({
                message: atribuirStore.error?.mensagem || "Erro ao atribuir entregador.",
                duration: 5000
            });
            setErroDespachar(atribuirStore.error);
        } else if (atribuirStore.data) {
            showSuccess({
                message: atribuirStore.data?.mensagem || "Entregador atribuído com sucesso.",
                duration: 5000
            });
            setShouldClose(true);
        }
        if (desatribuirStore?.resposta?.sucesso === false) {
            showError({
                message: desatribuirStore.mensagem || "Erro ao desatribuir entregador.",
                duration: 5000
            });

        } else if (desatribuirStore?.resposta?.sucesso === true) {
            showSuccess({
                message: desatribuirStore.data?.mensagem || "Entregador desatribuído com sucesso.",
                duration: 5000
            });
            setShouldClose(true);
        }
        dispatch(limparDespachar());
    }, [atribuirStore.data, atribuirStore.error, desatribuirStore?.resposta]);

    useEffect(() => {
        if (erroDespachar && tabelaPagamento == 2 && tabelaCobranca == 2) {
            setModalConfigCobranca(true);
        }
    }, [erroDespachar]);

    const handleCloseConfigCobranca = () => {
        setErroDespachar(null);
        setModalConfigCobranca(false);
    };

    useEffect(() => {
        if (shouldClose) {
            setTimeout(() => {
                close();
            }, 500);
        }
    }, [shouldClose]);

    useEffect(() => {
        dispatch(taxaExtraStart({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }));
    }, []);

    useEffect(() => {
        const empresaID = pedidoAtual?.empresaID || (rotaAtual && rotaAtual[0]?.empresaID);

        if (empresaID) {
            dispatch(listarEntregadoresRequest({ empresaID }));
            dispatch(emAndamentoStart({ empresaID }));
        }
        setShouldClose(false);
    }, [pedidoAtual, rotaAtual]);

    // Filtro e ordenação de entregadores
    const filteredAndSortedEntregadores = entregadores
        .filter((entregador: any) => entregador.nome.toLowerCase().includes(filterText.toLowerCase()))
        .sort((a: any, b: any) => a.nome.localeCompare(b.nome)); // Ordenação alfabética

    const texto = novaListaPedidos.length > 0 ? `Atribuir ${novaListaPedidos?.map((p: any) => ` #${p.codigoQuatro}`)}` : `Atribuir ${!rotaAtual ? `pedido #${pedidoAtual?.codigoQuatro}` : rotaAtual?.map((p: any) => ` #${p.codigoQuatro}`)}`

    return (
        <>
            <GridPainelLateral container sx={{ padding: '.5rem', justifyContent: 'stretch' }}>
                <HeaderCard
                    title={texto}
                    onClick={close}
                />
                <Box sx={{ pb: '1rem', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <TextField
                        placeholder="Filtrar Entregadores"
                        variant="outlined"
                        fullWidth
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        size="small"
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }
                        }}
                    />
                    <ButtonLoading loading={desatribuirStore.loading} variant="text" sx={{ mt: '1rem', width: 'min-content' }} onClick={() => { setOpenDesatribuir(true) }}>
                        Desatribuir
                    </ButtonLoading>
                </Box>
                <Box sx={{ flexGrow: 1, width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
                    {(filteredAndSortedEntregadores && filteredAndSortedEntregadores.length > 0)
                        ? filteredAndSortedEntregadores.map((entregador: Entregador, index: number) => (
                            <CardPrincipal sx={{ py: '1rem', '&:last-child': { pb: '1rem' } }} key={index}>
                                <Stack sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <Avatar sx={{ width: 48, height: 48, mr: '1rem' }}>{entregador.nome.charAt(0)}</Avatar>
                                    <GridColumn style={{ alignItems: 'flex-start' }}>
                                        <Typography variant="h6">{entregador?.nome}</Typography>
                                        <Typography variant="body2">{telefone(entregador?.telefone)}</Typography>
                                    </GridColumn>
                                    <ButtonLoading
                                        variant="iconeComTextoFilled"
                                        onClick={() => { handleAtribuir(entregador, false); }}
                                        style={{ marginLeft: 'auto' }}
                                        loading={atribuirStore.loading}
                                    >
                                        Atribuir <ArrowForward />
                                    </ButtonLoading>
                                </Stack>
                            </CardPrincipal>
                        ))
                        : <Container sx={{ flexGrow: 1, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ textAlign: 'center', width: '100%' }} variant="notFound">
                                Nenhum Entregador Disponível
                            </Typography>
                        </Container>
                    }
                </Box>
            </GridPainelLateral>
            <ModalTaxaExtra open={openModal} setOpen={setOpenModal} data={manuais} entregador={entregadorSelecionado} />
            {modalConfigCobranca && <ModalConfigCobranca
                modalProps={{
                    open: modalConfigCobranca,
                    onClose: handleCloseConfigCobranca,
                }}
                erroDespachar={erroDespachar}
                callback={() => {
                    console.log('ativou');
                    handleAtribuir(entregadorSelecionado, true);
                }}
            />}
            <ModalOpcoes open={openDesatribuir} onClose={() => { setOpenDesatribuir(false) }} opcoes={opcoesDesatribuir} acao="Desatribuir" descricao={`Deseja remover o entregador ${pedidoAtual?.entregadorNome} do pedido #${pedidoAtual?.codigoQuatro}?`} />
        </>
    );
};

export default AtribuirPedido;
