import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Box, } from "@mui/material";
import { BoxLoading, GridPainelLateral, HeaderCard, SwitchOnOff, TableRowLoading, TableRowNotFound } from 'src/ds'
import { useDispatch, useSelector } from 'react-redux';
import { taxaExtraStart, alterarSituacaoStart, alterarSituacaoReset } from 'src/store/reducers/taxaExtra';
import { RootState } from 'src/store/reducers';
import { TaxaExtra as TypeTaxaExtra } from 'src/dtos/TaxaExtraDTO';
import { dinheiro } from 'src/ds/common';
import { useSnackbarContext } from 'src/context/SnackbarContext';

export const TaxaExtra = ({ close }: { close: () => void }) => {
    const dispatch = useDispatch();
    const { showSnackbar } = useSnackbarContext();
    const taxaExtra = useSelector((state: RootState) => state.taxaExtra.lista);
    const alterarSituacao = useSelector((state: RootState) => state.taxaExtra.alterarSituacao);
    const filtrosDispatch = {
        pagina: 1,
        paginacao: -1,
    }

    useEffect(() => {
        dispatch(taxaExtraStart(filtrosDispatch))
        return () => { dispatch(alterarSituacaoReset()) }
    }, [])

    useEffect(() => {
        if (!alterarSituacao.data?.mensagem) return
        alterarSituacao.data?.sucesso && dispatch(taxaExtraStart(filtrosDispatch))
        showSnackbar({
            message: alterarSituacao.data.mensagem,
            severity: alterarSituacao.data?.sucesso ? 'success' : 'error',
            duration: 5000
        });
    }, [alterarSituacao.data])

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        dispatch(alterarSituacaoStart({
            taxaExtraID: id,
            ativo: event.target.checked
        }))
    }

    const formatValor = (tipoValor: TypeTaxaExtra['tipoValor'], valor: TypeTaxaExtra['valorCobrar' | 'valorPagar']) => {
        switch (tipoValor) {
            case 0:
                return dinheiro(valor);
            case 1:
                return `${valor}%`;
            default:
                return valor;
        }
    }

    const handleClose = () => {
        dispatch(alterarSituacaoReset())
        close();
    }

    return (
        <>
            <GridPainelLateral sx={{ minHeight: '100%', padding: '.5rem' }}>
                <HeaderCard title="Taxas extras" onClick={handleClose} />
                <BoxLoading loading={alterarSituacao.loading}>
                    <Box>
                        <TableContainer component={Paper}>
                            <Table aria-label="Tabela Taxa Extra" stickyHeader >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>Taxa extra</TableCell>
                                        <TableCell align='center'>Valor a cobrar</TableCell>
                                        <TableCell align='center'>Valor a pagar</TableCell>
                                        <TableCell align='center'>Tipos de veículos</TableCell>
                                        <TableCell align='center'>Situação</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {taxaExtra.loading
                                        ? (<TableRowLoading />)
                                        : (!taxaExtra.data?.dados || taxaExtra.data.dados?.lista.length === 0)
                                            ? <TableRowNotFound text="Nenhuma taxa extra encontrada" />
                                            : taxaExtra.data.dados.lista.map((taxa) => (
                                                <TableRow hover>
                                                    <TableCell align='center'>{taxa.nome}</TableCell>
                                                    <TableCell align='center'>{formatValor(taxa.tipoValor, taxa.valorCobrar)}</TableCell>
                                                    <TableCell align='center'>{formatValor(taxa.tipoValor, taxa.valorPagar)}</TableCell>
                                                    <TableCell align='center'>{taxa.tiposVeiculo.length > 0 ? taxa.tiposVeiculo.map((veiculo) => veiculo.nome).join(', ') : '-'}</TableCell>
                                                    <TableCell align='center'>
                                                        <Tooltip
                                                            arrow
                                                            placement='top'
                                                            title={taxa.ativo ? 'Ativo' : 'Inativo'}
                                                        >
                                                            <SwitchOnOff
                                                                checked={!!taxa.ativo}
                                                                onChange={(event) => handleSwitch(event, taxa.taxaExtraID)}
                                                                inputProps={{ "aria-label": 'Situação Switch' }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </BoxLoading>
            </GridPainelLateral>
        </>
    );
}

export default TaxaExtra;
