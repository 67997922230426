import { FormControl, InputLabel, MenuItem, Select, FormHelperText, SxProps, InputAdornment, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { listarCidades } from 'src/store/reducers/utils';

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name?: string;
  estadoName?: string;
}

interface SelectCidadeProps {
  hookForm: HookForm;
  label?: string;
  sx?: SxProps;
  size?: 'small' | 'medium';
}

const NoIcon: React.FC = () => <span />;

/**
 * Componente de seleção de cidade dependente do estado selecionado.
 * 
 * O `SelectCidade` é um componente que exibe uma lista de cidades baseada no estado selecionado.
 * Caso nenhum estado esteja selecionado, o campo é desabilitado e exibe uma mensagem informativa ao
 * usuário ao tentar interagir com ele.
 * Este componente exibe um indicador de carregamento enquanto as opções de pagamento estão sendo buscadas.
 * 
 * @component
 * @param {SelectCidadeProps} props - Propriedades do componente.
 * @param {HookForm} props.hookForm - Objeto que contém métodos e configurações do `react-hook-form`.
 * @param {string} [props.label='Cidade'] - Rótulo exibido no campo de seleção de cidade.
 * @param {SxProps} [props.sx] - Estilos adicionais para customizar o componente.
 * @param {'small' | 'medium'} [props.size='small'] - Tamanho do componente, pode ser 'small' ou 'medium'.
 * @returns {React.ReactElement} Componente de seleção de cidade.
 * 
 * @example
 * ```jsx
 * const methods = useForm();
 * <SelectCidade
 *    hookForm={{ methods: methods, name: 'cidadeID', estadoName: 'estadoID' }}
 *    estadoName="estadoID"
 *    name="cidadeID"
 *    label="Escolha sua cidade"
 * />
 * ```
 */
const SelectCidade: React.FC<SelectCidadeProps> = ({
  hookForm: { methods, rules, name = 'cidadeID', estadoName = 'estadoID' },
  sx,
  label = 'Cidade',
  size = 'small',
}) => {
  const dispatch = useDispatch();
  const cidades = useSelector((state: RootState) => state.utils?.cidades);
  const estados = useSelector((state: RootState) => state.utils?.estados);
  const loading = useSelector((state: RootState) => state.utils?.cidadesLoading);
  const { register, watch, setValue, formState: { errors } } = methods;
  const [filteredCidades, setFilteredCidades] = useState({});
  const [showHelperText, setShowHelperText] = useState(false);
  const estadoID = watch(estadoName);

  const [cidadesCarregadas, setCidadesCarregadas] = useState(false);

  useEffect(() => {
    if (estadoID && !cidadesCarregadas && (!cidades || cidades.length === 0) && estados.length > 0) {
      const timeoutID = setTimeout(() => {

        dispatch(listarCidades());
      }, 1000);
      return () => clearTimeout(timeoutID);
    }
  }, [estadoID, estados]);

  useEffect(() => {
    if (estadoID) {
      const cidadesFiltradas = cidades.filter((cidade): any => cidade.estadoID === estadoID);
      setFilteredCidades(cidadesFiltradas);
    } else {
      setFilteredCidades([]);
    }
  }, [estadoID, cidades]);

  return (
    <FormControl
      size={size}
      sx={{ width: '100%', ...sx }}
      error={!!errors[name] || showHelperText && !estadoID}
      disabled={!estadoID}
      onClick={() => setShowHelperText(true)}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        {...register(name, {
          required: estadoID ? 'Você deve selecionar uma cidade' : 'Selecione um estado primeiro',
          ...rules
        })}
        value={watch(name) || ''}
        onChange={(event) => setValue(name, event.target.value)}
        fullWidth
        endAdornment={(
          loading ? (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ) : null
        )}
        disabled={loading}
        IconComponent={loading ? NoIcon : undefined}
      >
        <MenuItem value="">
          Selecione...
        </MenuItem>
        {Array.isArray(filteredCidades) && filteredCidades.map((cidade: any) => (
          <MenuItem key={cidade.cidadeID} value={cidade.cidadeID}>
            {cidade.cidadeNome}
          </MenuItem>
        ))}
      </Select>
      {showHelperText && !estadoID && (
        <FormHelperText>Selecione um estado primeiro</FormHelperText>
      )}
      {errors[name] && (
        <FormHelperText>{`${errors[name]?.message}`}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectCidade;
