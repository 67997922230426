import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper, IconButton, Button, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward, Visibility, Edit, Delete, Business } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ButtonTableOrder, ChipSituacao, ModalOpcoes, SnackbarAlert, SwitchOnOff, TableRowLoading, TableRowNotFound } from "src/ds";
import { Bots, TiposFuncao } from "src/dtos/BotsDTO";
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
import ModalMsgs from "./ModalMsgs";
import ModalBotQrCode from "./ModalBotQrCode";
import ModalRemoveBot from "./ModalRemoveBot";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import ModalCriarBot from "./ModalCriarBot";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { criarBotClear, criarBotStart, excluirBotClear } from "src/store/reducers/bots";
import ModalEmpresasVinculadas from "./ModalEmpresasVinculadas";
import { maskTelefone } from "src/ds";
import { useSnackbarContext } from "src/context/SnackbarContext";

interface TabelaBotsProps {
    bots?: Bots[];
    loading: boolean;
    funcoes?: TiposFuncao[];
    callback: () => void;
}

const TabelaBots = ({ bots, loading, funcoes, callback }: TabelaBotsProps) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext();
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        nomeSessao: 'asc',
        funcao: 'asc',
        telefone: 'asc',
        status: 'asc',
    });
    const [openQrCode, setOpenQrCode] = useState(false);
    const [openMessages, setOpenMessages] = useState(false);
    const [openEmpresasVinculadas, setOpenEmpresasVinculadas] = useState(false);
    const [createBotModal, setCreateBotModal] = useState(false);
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [modalConfirmacao, setModalConfirmacao] = useState(false);
    const [botSelected, setBotSelected] = useState<Bots | null>(null);
    const pickID = useSelector((state: RootState) => state.config.masterPage?.pickSelecionada?.pickID)

    const criarBotStore = useSelector((state: RootState) => state.bots.criarBot);

    useEffect(() => {
        if (criarBotStore.data) {
            showSuccess({
                message: criarBotStore.data.mensagem,
                duration: 5000
            })
            callback()
        } else if (criarBotStore.error) {
            showError({
                message: criarBotStore.error,
                duration: 5000
            })
            callback()
        }

        return () => {
            dispatch(criarBotClear())
            if (modalConfirmacao) setModalConfirmacao(false)
        }
    }, [criarBotStore.data, criarBotStore.error])

    const excluirBotState = useSelector((state: RootState) => state.bots.excluirBot);

    useEffect(() => {
        if (excluirBotState.data) {
            showSuccess({
                message: excluirBotState.data.mensagem,
                duration: 5000
            })
            callback()
        } else if (excluirBotState.error) {
            showError({
                message: excluirBotState.error,
                duration: 5000
            })
            callback()
        }

        return () => {
            dispatch(excluirBotClear())
        }
    }, [excluirBotState.data, excluirBotState.error])

    const handleModalConfirmacaoClose = () => {
        setModalConfirmacao(false);
        setBotSelected(null);
    };

    const toggleSituacao = (bot: Bots) => {
        dispatch(criarBotStart({
            pickID: bot.pickID,
            pickWppBotID: bot.pickWppBotID,
            ativo: !bot.ativo,
        }))
    }

    const handleChangeStatus = (bot: Bots) => {
        const ativo = bot.ativo
        if (ativo) {
            setBotSelected(bot)
            setModalConfirmacao(true)
        } else {
            toggleSituacao(bot)
        }
    }

    const toggleSortDirection = (column: string) => {
        setSortDirection((prevState) => ({
            ...prevState,
            [column]: prevState[column] === 'asc' ? 'desc' : 'asc',
        }));
    };

    const getTipoFuncao = (funcao: number) => {
        return funcoes?.find((f) => f.valor === funcao)?.label;
    }

    const handleCreateBot = (row: Bots | null) => {
        setBotSelected(row);
        setCreateBotModal(true);
    }

    const handleViewQrCode = (row: Bots) => {
        setBotSelected(row);
        setOpenQrCode(true);
    }

    const handleViewMessages = (bot: Bots) => {
        setBotSelected(bot);
        setOpenMessages(true);
    }

    const handleRemoveBot = (bot: Bots) => {
        setBotSelected(bot);
        setOpenRemoveModal(true);
    }

    const handleEmpresasVinculadas = (bot: Bots) => {
        setBotSelected(bot);
        setOpenEmpresasVinculadas(true);
    }

    // type SessionStatus = 'UNKNOWN' | 'STARTING ' | 'SCAN_QR_CODE' | 'WORKING' | 'STOPPED' | 'FAILED';
    const returnSituacao = (status: string) => {
        switch (status) {
            case ('UNKNOWN'):
                return <ChipSituacao sx={{ bgcolor: 'grey.main', color: 'grey.contrastText' }} label="Inexistente" />
            case ('STARTING	'):
                return <ChipSituacao sx={{ bgcolor: 'warning.main', color: 'warning.contrastText' }} label="Iniciando" />
            case ('SCAN_QR_CODE'):
                return <ChipSituacao sx={{ bgcolor: 'info.main', color: 'info.contrastText' }} label="Não Autenticado" />
            case ('WORKING'):
                return <ChipSituacao situacao="ATIVA" label="Ativo" />
            case ('STOPPED'):
                return <ChipSituacao situacao="INATIVA" label="Inativo" />
            case ('FAILED'):
                return <ChipSituacao sx={{ bgcolor: 'error.main', color: 'error.contrastText' }} label="Erro" />
            default:
                return <ChipSituacao sx={{ bgcolor: 'warning.main', color: 'warning.contrastText' }} label={status} />
        }
    }

    return (
        <>
            <Box sx={{ bgcolor: 'background.default' }}>
                <Stack direction={'row'} sx={{ justifyContent: 'flex-end', alignItems: 'center', p: 2 }}>
                    <Button
                        variant="iconeComTextoFilled"
                        sx={{ width: 'fit-content' }}
                        onClick={() => handleCreateBot(null)}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                    >
                        Adicionar Bot
                    </Button>
                </Stack>
                <TableContainer component={Paper} sx={{ width: '100%', overflowY: 'auto', margin: '0 auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ borderTop: '1px solid #ccc' }}>
                                <TableCell sx={{ width: '25%' }}>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('nomeSessao')}>
                                        Nome da sessão
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell align="center" sx={{ width: '25%' }}>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('funcao')}>
                                        Função {sortDirection.funcao === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell align="center" sx={{ width: '25%' }}>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('telefone')}>
                                        Telefone {sortDirection.telefone === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell align="center" sx={{ width: '15%' }}>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('status')}>
                                        Status {sortDirection.status === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell align="center" sx={{ width: '10%' }}>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading || criarBotStore.loading ? (
                                <TableRowLoading />
                            ) : !bots
                                ? (<TableRowNotFound />)
                                : bots && bots?.length === 0
                                    ? (<TableRowNotFound />)
                                    : bots?.map((row, index) => (
                                        <TableRow key={index} hover>
                                            <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                                <Typography>
                                                    {row.nomeSessao}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                                {getTipoFuncao(row.funcao) || '-'}
                                            </TableCell>
                                            <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                                {row.chatId ? maskTelefone(row.chatId) : '-'}
                                            </TableCell>
                                            <TableCell align="center" sx={{ py: 2 }}>{returnSituacao(row.status)}</TableCell>
                                            <TableCell align="center" sx={{ py: 2 }}>
                                                <Stack direction="row" spacing={1} justifyContent="center">
                                                    {row.status === 'SCAN_QR_CODE' &&
                                                        <Tooltip title="Ver QR Code" arrow placement="top">
                                                            <IconButton onClick={() => handleViewQrCode(row)}>
                                                                <QrCode2RoundedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    <Tooltip title="Ver mensagens enviadas" arrow placement="top">
                                                        <IconButton onClick={() => handleViewMessages(row)}>
                                                            <Visibility color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={row.ativo ? "Ativo" : 'Inativo'} arrow placement="top">
                                                        <SwitchOnOff
                                                            checked={row.ativo}
                                                            onChange={() => handleChangeStatus(row)}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Editar" arrow placement="top">
                                                        <IconButton onClick={() => handleCreateBot(row)}>
                                                            <Edit color="warning" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Empresas" arrow placement="top">
                                                        <IconButton onClick={() => handleEmpresasVinculadas(row)}>
                                                            <Business color="info" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Excluir" arrow placement="top">
                                                        <IconButton onClick={() => handleRemoveBot(row)}>
                                                            <Delete color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* Modal QR Code */}
            {openQrCode && botSelected &&
                <ModalBotQrCode
                    modalProps={{
                        open: openQrCode,
                        onClose: (() => {
                            setOpenQrCode(false);
                            setBotSelected(null);
                            callback();
                        }),
                        size: "md",
                        headerModal: {
                            title: 'QR Code',
                        },
                    }}
                    bot={botSelected}
                />
            }

            {/* Criar bot */}
            {createBotModal && pickID &&
                <ModalCriarBot
                    open={createBotModal}
                    onClose={() => {
                        setCreateBotModal(false);
                        setBotSelected(null);
                    }}
                    pickID={pickID}
                    bot={botSelected}
                />}

            {/* Excluir bot */}
            {openRemoveModal && botSelected &&
                <ModalRemoveBot
                    bot={botSelected}
                    open={openRemoveModal}
                    onClose={(() => {
                        setOpenRemoveModal(false);
                        setBotSelected(null);
                    })}
                />
            }

            {/* Modal Mensagens */}
            {(openMessages && botSelected) &&
                <ModalMsgs
                    modalProps={{
                        open: openMessages,
                        onClose: (() => {
                            setOpenMessages(false);
                            setBotSelected(null);
                        }),
                        size: "md",
                        headerModal: {
                            title: 'Mensagens enviadas',
                        },
                    }}
                    bot={botSelected}
                />
            }

            {/* Modal Vincular Empresa */}
            {openEmpresasVinculadas && botSelected &&
                <ModalEmpresasVinculadas
                    modalProps={{
                        open: openEmpresasVinculadas,
                        onClose: (() => {
                            setOpenEmpresasVinculadas(false);
                            setBotSelected(null);
                        }),
                        headerModal: {
                            title: 'Empresas vinculadas'
                        }
                    }}
                    bot={botSelected}
                />
            }

            {/* Modal Confirmar Desativar Bot */}
            {modalConfirmacao && botSelected &&
                <ModalOpcoes
                    open={modalConfirmacao}
                    onClose={handleModalConfirmacaoClose}
                    acao="Desativar Bot"
                    descricao="Tem certeza que deseja desativar o bot?"
                    opcoes={[
                        {
                            label: 'Cancelar',
                            action: handleModalConfirmacaoClose
                        },
                        {
                            label: 'Confirmar',
                            action: () => toggleSituacao(botSelected),
                            loading: criarBotStore.loading,
                        },
                    ]}
                />
            }
        </>
    );
};

export default TabelaBots;
