import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { listarRequest } from 'src/store/reducers/config';

const FormasDePagamento = ({ hookForm }: { hookForm: UseFormReturn<any> }) => {
    const formasPagamento = useSelector((state: RootState) => state.config?.formaDePagamento);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!formasPagamento) {
            dispatch(listarRequest());
        }
    },[])
    const { register, handleSubmit, watch, setValue, formState: { errors } } = hookForm;

    return (
        <FormControl size="small" fullWidth>
            <InputLabel id="formaPagamento-label">
                {'Forma de pagamento'.charAt(0).toUpperCase() + 'Forma de pagamento'.slice(1)}
            </InputLabel>
            <Select
                label={'Forma de pagamento'.charAt(0).toUpperCase() + 'Forma de pagamento'.slice(1)}
                labelId="formaPagamento-label"
                id="formaPagamento"
                {...register('formaPagamentoID')}
                value={watch('formaPagamentoID') || ''}
                onChange={(event) => setValue('formaPagamentoID', event.target.value)}
                defaultValue={''}
                fullWidth
            >
                <MenuItem value="">
                    Selecione...
                </MenuItem>
                {Array.isArray(formasPagamento) && formasPagamento.map((option: any) => {
                    return (
                        <MenuItem key={option.formaPagamentoID} value={option.formaPagamentoID}>
                            {option.nome}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default FormasDePagamento;
