import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip } from '@mui/material';
import { ButtonTableOrder, ModalOpcoes, TableNavigation, TableRowLoading, TableRowNotFound } from 'src/ds';
import { RootState } from 'src/store/reducers';
import { excluirDespachoStart, pedidoDespachosClear, pedidoDespachosStart } from 'src/store/reducers/pedidos';
import { useForm } from 'react-hook-form';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useSnackbarContext } from 'src/context/SnackbarContext';

const Despachos: React.FC = () => {
    const dispatch = useDispatch();
    const { id: pedidoID } = useParams();
    const { showSuccess, showError } = useSnackbarContext();
    const pedidoDespachos = useSelector((state: RootState) => state.pedidos.pedidoDespachos);
    const permissoesPedidoModelo = useSelector((state: RootState) => state.pedidos.pedidoModelo?.data?.permissoes);
    const excluirDespacho = useSelector((state: RootState) => state.pedidos.excluirDespacho);
    const hookForm = useForm({
        defaultValues: {
            pagina: 1,
            paginacao: 20
        }
    });
    const { watch } = hookForm;
    const [order, setOrder] = useState<{ ordenacaoCampo: string; ordenacaoOrdem: 'asc' | 'desc' } | null>(null);
    const [modalExcluir, setModalExcluir] = useState<{ pedidoDespachoID: string; } | null>(null);

    const handleOpen = (pedidoDespachoID: string) => { setModalExcluir({ pedidoDespachoID }) };

    const handleClose = () => { setModalExcluir(null) };

    const handleConfirmar = () => {
        const id = modalExcluir?.pedidoDespachoID
        if (!id) return
        dispatch(excluirDespachoStart({ id }))
    };

    const updateOrder = (field: string) => {
        setOrder(prevOrder => ({
            ordenacaoCampo: field,
            ordenacaoOrdem: prevOrder?.ordenacaoCampo === field && prevOrder.ordenacaoOrdem === 'asc' ? 'desc' : 'asc'
        }));
    };

    const reqPedidoDespachos = () => {
        if (!pedidoID) return;
        dispatch(pedidoDespachosStart({
            pedidoID,
            pagina: watch('pagina'),
            paginacao: watch('paginacao'),
            ordenacaoCampo: order?.ordenacaoCampo || '',
            ordenacaoOrdem: order?.ordenacaoOrdem || ''
        }));
    };

    useEffect(() => {
        reqPedidoDespachos()
    }, [order, watch, pedidoID, dispatch, watch('pagina'), watch('paginacao')]);

    useEffect(() => {
        if (excluirDespacho.error) {
            showError({
                message: excluirDespacho.error,
                duration: 5000
            });
        } else if (excluirDespacho.data) {
            showSuccess({
                message: excluirDespacho.data.mensagem,
                duration: 5000
            });
            reqPedidoDespachos()
            handleClose()
        }
        return () => { dispatch(pedidoDespachosClear()); };
    }, [excluirDespacho.error, excluirDespacho.data]);

    useEffect(() => {
        if (pedidoDespachos.error) {
            showError({
                message: pedidoDespachos.error,
                duration: 5000
            });
        }
        return () => { dispatch(pedidoDespachosClear()) }
    }, [pedidoDespachos.error]);

    return (
        <>
            <Box>
                <Stack>
                    {pedidoDespachos.data?.dados &&
                        <TableNavigation
                            hookForm={hookForm}
                            ultimaPagina={pedidoDespachos.data?.dados?.ultimaPagina}
                            indiceFinal={pedidoDespachos.data?.dados?.indiceFinal}
                            indiceInicial={pedidoDespachos.data?.dados?.indiceInicial}
                            indiceTotal={pedidoDespachos.data?.dados?.total}
                        />
                    }
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>
                                        <ButtonTableOrder
                                            sortDirection={order?.ordenacaoCampo === 'dataCadastro' ? { dataCadastro: order.ordenacaoOrdem } : {}}
                                            nome='dataCadastro'
                                            onClick={() => updateOrder('dataCadastro')}
                                        >
                                            Data
                                        </ButtonTableOrder>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ButtonTableOrder
                                            sortDirection={order?.ordenacaoCampo === 'dataResposta' ? { dataResposta: order.ordenacaoOrdem } : {}}
                                            nome='dataResposta'
                                            onClick={() => updateOrder('dataResposta')}
                                        >
                                            Data de resposta
                                        </ButtonTableOrder>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ButtonTableOrder
                                            sortDirection={order?.ordenacaoCampo === 'respostaAutomatica' ? { respostaAutomatica: order.ordenacaoOrdem } : {}}
                                            nome='respostaAutomatica'
                                            onClick={() => updateOrder('respostaAutomatica')}
                                        >
                                            Resposta automática
                                        </ButtonTableOrder>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ButtonTableOrder
                                            sortDirection={order?.ordenacaoCampo === 'aceito' ? { aceito: order.ordenacaoOrdem } : {}}
                                            nome='aceito'
                                            onClick={() => updateOrder('aceito')}
                                        >
                                            Aceito
                                        </ButtonTableOrder>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ButtonTableOrder
                                            sortDirection={order?.ordenacaoCampo === 'usuario.nome' ? { 'usuario.nome': order.ordenacaoOrdem } : {}}
                                            nome='usuario.nome'
                                            onClick={() => updateOrder('usuario.nome')}
                                        >
                                            Entregador
                                        </ButtonTableOrder>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ButtonTableOrder>Log</ButtonTableOrder>
                                    </TableCell>
                                    {(permissoesPedidoModelo && permissoesPedidoModelo['pedido.excluir']) && <TableCell align='center'>
                                        <ButtonTableOrder>Ações</ButtonTableOrder>
                                    </TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pedidoDespachos.loading
                                    ? <TableRowLoading />
                                    : (pedidoDespachos.data?.dados && pedidoDespachos.data.dados.lista?.length > 0)
                                        ? pedidoDespachos.data.dados.lista.map((despacho) => (
                                            <TableRow hover key={despacho.pedidoDespachoID}>
                                                <TableCell align='center'>{despacho.dataCadastro || '-'}</TableCell>
                                                <TableCell align='center'>{despacho.dataResposta || '-'}</TableCell>
                                                <TableCell align='center'>{despacho.respostaAutomatica ? 'Sim' : 'Não'}</TableCell>
                                                <TableCell align='center'>{despacho.aceito ? 'Sim' : 'Não'}</TableCell>
                                                <TableCell align='center'>{despacho.usuarioNome || '-'}</TableCell>
                                                <TableCell align='center'>{despacho.log || '-'}</TableCell>
                                                {(permissoesPedidoModelo && permissoesPedidoModelo['pedido.excluir']) && (
                                                    <TableCell align='center'>
                                                        <Tooltip arrow placement='top' title='Excluir'>
                                                            <IconButton onClick={() => { handleOpen(despacho.pedidoDespachoID) }}>
                                                                <DeleteRoundedIcon color='error' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))
                                        : <TableRowNotFound />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>

            {!!modalExcluir && <ModalOpcoes
                open={!!modalExcluir}
                onClose={handleClose}
                acao={'Excluir despacho'}
                descricao={`Deseja excluir o despacho?`}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action() { handleClose() },
                    },
                    {
                        label: 'Confirmar',
                        action() { handleConfirmar() },
                        loading: excluirDespacho.loading,
                    },
                ]}
            />}
        </>
    );
};

export default Despachos;
