// SelectFormaPagamento.tsx
import { CircularProgress, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useEffect } from 'react';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { listarFormasPagamentoClear, listarFormasPagamentoStart } from 'src/store/reducers/config';

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name?: string;
}

interface SelectFormaPagamentoProps {
  hookForm: HookForm;
  label?: string;
  sx?: SxProps;
  size?: 'small' | 'medium';
}

const NoIcon: React.FC = () => <span />;

/**
 * Componente de seleção de forma de pagamento.
 * 
 * O `SelectFormaPagamento` é um componente que exibe uma lista de opções de formas de pagamento para seleção,
 * integrando o `react-hook-form` para controle de estado do formulário e `redux` para busca das opções.
 * Este componente exibe um indicador de carregamento enquanto as opções de pagamento estão sendo buscadas.
 *
 * @component
 * @param {SelectFormaPagamentoProps} props - Propriedades do componente.
 * @param {HookForm} props.hookForm - Objeto que contém métodos e configurações do `react-hook-form`.
 * @param {string} [props.label='Forma de Pagamento'] - Texto exibido no rótulo do campo de seleção.
 * @param {SxProps} [props.sx] - Estilos adicionais para customizar o componente.
 * @param {'small' | 'medium'} [props.size='small'] - Define o tamanho do componente.
 * @returns {React.ReactElement} Componente de seleção de forma de pagamento.
 * 
 * @example
 * ```jsx
 * const methods = useForm();
 * <SelectFormaPagamento
 *    hookForm={{ methods: methods, name: 'formaPagamentoID' }}
 *    label="Escolha a forma de pagamento"
 * />
 * ```
 */
const SelectFormaPagamento: React.FC<SelectFormaPagamentoProps> = ({
  hookForm: { methods, rules, name = 'formaPagamentoID' },
  label = 'Forma de Pagamento',
  sx,
  size = 'small',
}) => {
  const dispatch = useDispatch()
  const { register, formState: { errors }, watch, setValue } = methods;
  const loading = useSelector((state: RootState) => state.config.formasPagamento.loading)
  const listaFormasPagamento = useSelector((state: RootState) => state.config.formasPagamento.data?.dados?.lista)

  useEffect(() => {
    dispatch(listarFormasPagamentoStart({
      pagina: -1,
      paginacao: -1,
      filtros: 'visivel'
    }))

    return () => {
      dispatch(listarFormasPagamentoClear())
    }
  }, [])

  return (
    <FormControl
      size={size}
      sx={sx}
      error={!!errors?.[name]}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        {...register(name, rules)}
        value={watch(name) || ''}
        onChange={(event) => setValue(name, event.target.value)}
        endAdornment={(
          loading ? (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ) : null
        )}
        disabled={loading}
        IconComponent={loading ? NoIcon : undefined}
      >
        <MenuItem value="">Selecione...</MenuItem>
        {listaFormasPagamento && listaFormasPagamento.map((formaPagamento) => (
          <MenuItem
            key={formaPagamento.formaPagamentoID}
            value={formaPagamento.formaPagamentoID}
          >
            {formaPagamento.nome}
          </MenuItem>
        ))}
      </Select>
      {errors?.[name] && <FormHelperText>{String(errors?.[name]?.message ?? '')}</FormHelperText>}
    </FormControl>
  );
};

export default SelectFormaPagamento;
