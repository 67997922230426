import { Button, styled, Theme } from "@mui/material";

interface BotaoSituacaoProps {
    situacao: string;
    theme?: Theme;
}

const BotaoSituacao = styled(Button)<BotaoSituacaoProps>(({ theme, situacao }) => {
    const statusColor = theme.palette.status?.[situacao];

    return {
        backgroundColor: statusColor || theme.palette.grey[400],
        color: theme.palette.getContrastText(statusColor || theme.palette.grey[400]),
        maxHeight: "1rem",
        padding: "0.2rem 0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "20%",
        minWidth: "20%",
        textTransform: "none",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-all",
        textAlign: "center",
        position: "relative",
        fontSize: theme.sizes?.fontSize?.xs || "0.75rem",
        "&:hover": {
            backgroundColor: statusColor || theme.palette.grey[400],
        },
        "& .MuiButton-label": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
        },
    };
});

export default BotaoSituacao;
