import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import {
    exportarTabelaEntregadores,
    listaEntregadoresFailure,
    listaEntregadoresStart,
    listaEntregadoresSuccess,
    financeiroPorEntregadorStart,
    financeiroPorEntregadorSuccess,
    financeiroPorEntregadorFailure,
    exportarTabelaEntregadoresSuccess,
    invalidarSessoesSuccess,
    invalidarSessoesFailure,
    invalidarSessoesStart,
    desbloquearSenhaStart,
    desbloquearSenhaFailure,
    desbloquearSenhaSuccess,
    salvarContatoStart,
    salvarContatoSuccess,
    salvarContatoFailure,
    dispararPushStart,
    dispararPushSuccess,
    dispararPushFailure,
    usuarioPaginaStart,
    usuarioPaginaSuccess,
    usuarioPaginaFailure,
    listarPerformanceEntregadorSuccess,
    listarPerformanceEntregadorFailure,
    listarPerformanceEntregadorStart,
    exportarPerformanceEntregadorFailure,
    exportarPerformanceEntregadorSuccess,
    exportarPerformanceEntregadorStart,
    exportarFinanceiroEntregadorFailure,
    exportarFinanceiroEntregadorSuccess,
    exportarFinanceiroEntregadorStart
} from '../reducers/entregador';
import { listarEntregadoresFailure } from '../reducers/config';
import { listarBancosFailure, listarBancosStart, listarBancosSuccess } from '../reducers/utils';

export function* listaEntregadores(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Usuario/ListarEntregadoresSql', form);
        if (response.data.sucesso !== true) {
            yield put(listarEntregadoresFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(listaEntregadoresSuccess(response.data));
    } catch (error) {
        yield put(listaEntregadoresFailure(error.toString()));
    }
}

export function* exportarEntregadores(action) {
    try {
        const queryParams = [
            `paginacao=${action.payload.paginacao || -1}`,
            `pagina=${action.payload.pagina || 1}`,
            `ordenacaoCampo=${action.payload.ordenacaoCampo || ''}`,
            `ordenacaoOrdem=${action.payload.ordenacaoOrdem || ''}`,
            `filtros=${encodeURIComponent(action.payload.filtros || '(entregador || dataEntregadorSolicitacao != null)')}`,
            `titulos=${encodeURIComponent(action.payload.titulos || 'Nome,Email,Telefone,CPF,Habilitado como entregador,Data de resposta,Data de nascimento,Cidade,Bairro,CEP,CNH,Chave PIX,Veículo,Data de solicitação,Situação,Avaliação,Pontuação,Crédito')}`,
            `variaveis=${encodeURIComponent(action.payload.variaveis || 'nome,email,telefone,cpf,entregadorStr,dataEntregadorResposta,dataNascimento,nomeCidade,nomeBairro,CEP,cnh,chavePix,nomeTipoVeiculo,dataEntregadorSolicitacao,situacaoStr,notaMedia,pontuacaoTotal,creditoPreAprovado')}`,
            `formato=${action.payload.formato || 'csv'}`
        ];

        Object.keys(action.payload).forEach((key) => {
            if (
                ![
                    'paginacao',
                    'pagina',
                    'ordenacaoCampo',
                    'ordenacaoOrdem',
                    'filtros',
                    'titulos',
                    'variaveis',
                    'formato'
                ].includes(key)
            ) {
                queryParams.push(`${key}=${encodeURIComponent(action.payload[key])}`);
            }
        });

        const queryString = queryParams.join('&');

        const response = yield call(api.get, `/Usuario/ListarEntregadoresSql?${queryString}`, {
            responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Entregadores.csv');
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);

        if (response.data.sucesso !== true) {
            yield put(listarEntregadoresFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(listaEntregadoresSuccess(response.data));
    } catch (error) {
        yield put(listaEntregadoresFailure(error.toString()));
    }
}

export function* financeiroPorEntregador(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        if (key === 'dataInicio' || key === 'dataFim' || key === 'empresaID' || key === 'buscar') return
        form.append(key, action.payload[key]);
    });

    // form.append('filtros',' dataFinalizado >= DateTime(2024,09,01,14,58,00) && dataFinalizado <= DateTime(2024,09,18,14,58,00)');

    try {
        const response = yield call(api.post, `/Pedido/ListarFinanceiroPorEntregadorPorPeriodoSql?inputDataInicial=${action.payload?.dataInicio ?? ''}&inputDataFinal=${action.payload?.dataFim ?? ''}&empresaID=${action.payload?.empresaID ?? ''}&inputBuscar=${action.payload?.buscar ?? ''}`, form);
        if (response.data.sucesso !== true) {
            yield put(financeiroPorEntregadorFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(financeiroPorEntregadorSuccess(response.data));
    } catch (error) {
        yield put(financeiroPorEntregadorFailure(error.toString()));
    }
}

export function* invalidarSessoes(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, 'Usuario/InvalidarSessoes', form);
        if (response.data.sucesso !== true) {
            yield put(invalidarSessoesFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(invalidarSessoesSuccess(response.data));
    } catch (error) {
        yield put(invalidarSessoesFailure(error.toString()));
    }
}

function* desbloquearSenha(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, 'Usuario/DesbloquearSenha', form);
        if (response.data.sucesso !== true) {
            yield put(desbloquearSenhaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(desbloquearSenhaSuccess(response.data));
    } catch (error) {
        yield put(desbloquearSenhaFailure(error.toString()));
    }
}

export function* salvarContato(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, 'UsuarioContato/Salvar', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }

        yield put(salvarContatoSuccess(response.data));
    } catch (error) {
        yield put(salvarContatoFailure(error.toString()));
    }
}

export function* dispararPush(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Usuario/DispararPush', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }

        yield put(dispararPushSuccess(response.data));
    } catch (error) {
        yield put(dispararPushFailure(error.toString()));
    }
}

export function* usuarioPagina(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Usuario/Pagina', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }
        yield put(usuarioPaginaSuccess(response.data));
    } catch (error) {
        yield put(usuarioPaginaFailure(error.toString()));
    }
}

export function* listarPerformanceEntregador(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Pedido/TotalPedidosPorUsuario', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }
        yield put(listarPerformanceEntregadorSuccess(response.data));
    } catch (error) {
        yield put(listarPerformanceEntregadorFailure(error.toString()));
    }
}

export function* exportarPerformanceEntregador(action) {
    const queryParams = [
        `paginacao=${action.payload.paginacao || -1}`,
        `pagina=${action.payload.pagina || 1}`,
        `ordenacaoCampo=${action.payload.ordenacaoCampo || 'nomeUsuario'}`,
        `ordenacaoOrdem=${action.payload.ordenacaoOrdem || 'asc'}`,
        `filtros=${encodeURIComponent(action.payload.filtros || '')}`,
        `titulos=${encodeURIComponent(action.payload.titulos || 'Entregador,Qtd. Pedidos Aceitos,Qtd. Recusados Manualmente,Qtd. Recusados Automaticamente,Qtd. Pontuação,Tempo online')}`,
        `variaveis=${encodeURIComponent(action.payload.variaveis || 'nomeUsuario,totalPedidosAceitos,totalPedidosRecusadosManual,totalPedidosRecusadosAutomatico,totalPontos,tempoOnline')}`,
        `formato=${action.payload.formato || 'csv'}`,
        `dtInicial=${encodeURIComponent(action.payload.dtInicial || '')}`,
        `dtFinal=${encodeURIComponent(action.payload.dtFinal || '')}`,
        `usuarioID=${encodeURIComponent(action.payload.usuarioID || '')}`
    ].join('&');

    try {
        const response = yield call(api.get, `/Pedido/TotalPedidosPorUsuario?${queryParams}`, {
            responseType: 'blob'
        });

        // Cria o arquivo CSV para download
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Performance de entregadores.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        if (response.data.sucesso !== true) {
            yield put(exportarPerformanceEntregadorFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(exportarPerformanceEntregadorSuccess(response.data));
    } catch (error) {
        yield put(exportarPerformanceEntregadorFailure(error.toString()));
    }
}

export function* exportarFinanceiroEntregador(action) {
    const queryParams = [
        `inputDataInicial=${encodeURIComponent(action.payload.dtInicial || '')}`,
        `inputDataFinal=${encodeURIComponent(action.payload.dtFinal || '')}`,
        `empresaID=${encodeURIComponent(action.payload.empresaID || '')}`,
        `inputBuscar=${encodeURIComponent(action.payload.inputBuscar || '')}`,
        `paginacao=${action.payload.paginacao || -1}`,
        `pagina=${action.payload.pagina || 1}`,
        `ordenacaoCampo=${action.payload.ordenacaoCampo || ''}`,
        `ordenacaoOrdem=${action.payload.ordenacaoOrdem || ''}`,
        `filtros=${encodeURIComponent(action.payload.filtros || '')}`,
        `titulos=${encodeURIComponent(action.payload.titulos || 'Entregador,CPF,Telefone,E-mail,Chave PIX,Qtd. pedidos,Valor realizado,Valor total já antecipado,Valor total,Valor total programada')}`,
        `variaveis=${encodeURIComponent(action.payload.variaveis || 'nome,cpf,telefone,email,chavePix,qtdPedidos,taxaTotalEntregador,valorAntecipado,valorTotal,taxaTotalEntregadorProgramada')}`,
        `formato=${action.payload.formato || 'csv'}`
    ].join('&');

    try {
        const response = yield call(api.get, `/Pedido/ListarFinanceiroPorEntregadorPorPeriodoSql?${queryParams}`, {
            responseType: 'blob'
        });

        // Cria o arquivo CSV para download
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Financeiro por entregador por período.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        if (response.data.sucesso !== true) {
            yield put(exportarFinanceiroEntregadorFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(exportarFinanceiroEntregadorSuccess(response.data));
    } catch (error) {
        yield put(exportarFinanceiroEntregadorFailure(error.toString()));
    }
}



function* ListarEntregadores() {
    yield takeLatest(listaEntregadoresStart.type, listaEntregadores);
    yield takeLatest(exportarTabelaEntregadores.type, exportarEntregadores);
    yield takeLatest(financeiroPorEntregadorStart.type, financeiroPorEntregador);
    yield takeLatest(invalidarSessoesStart.type, invalidarSessoes);
    yield takeLatest(desbloquearSenhaStart.type, desbloquearSenha);
    yield takeLatest(salvarContatoStart.type, salvarContato);
    yield takeLatest(dispararPushStart.type, dispararPush);
    yield takeLatest(usuarioPaginaStart.type, usuarioPagina);
    yield takeLatest(listarPerformanceEntregadorStart.type, listarPerformanceEntregador);
    yield takeLatest(exportarPerformanceEntregadorStart.type, exportarPerformanceEntregador);
    yield takeLatest(exportarFinanceiroEntregadorStart.type, exportarFinanceiroEntregador);
}

export default ListarEntregadores;
