const dynamicMask = (tipo: string, valor: string = '') => {
    const numericValue = valor ? valor.replace(/\D/g, '') : '';

    switch (tipo) {
        case '1':
            if (numericValue.length <= 11) {
                return numericValue
                    .replace(/(\d{3})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                    .replace(/(-\d{2})\d+?$/, '$1');
            } else {
                return numericValue
                    .replace(/(\d{2})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d{1,4})/, '$1/$2')
                    .replace(/(\/\d{4})(\d)/, '$1-$2')
                    .replace(/(-\d{2})\d+?$/, '$1');
            }
        case '2':
            return numericValue
                .replace(/(\d{2})(\d)/, '($1) $2')
                .replace(/(\d{5})(\d)/, '$1-$2')
                .replace(/(-\d{4})\d+?$/, '$1');
        case '3':
            return valor;
        case '4':
            return valor;
        default:
            return valor;
    }
}

export default dynamicMask;
