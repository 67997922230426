import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from "./login";
import configReducer from "./config";
import criarPedidoReducer from "./criarPedido";
import rastreioReducer from "./rastreio";
import utilsReducer from "./utils";
import chatReducer from "./chat";
import escalasReducer from "./escalas";
import daRoboReducer from "./darobo";
import pedidosReducer from "./pedidos";
import TaxaExtra from "./taxaExtra";
import entregadoresReducer from "./entregador";
import clientesReducer from "./cliente";
import empresasReducer from "./empresa";
import tabelaCobrancaReducer from "./tabelaCobranca";
import tabelaPagamentoReducer from "./tabelaPagamento";
import botsReducer from "./bots";
import despachoAutomaticoReducer from "./despachoAutomatico"
import walletReducer from "./wallet";
import faturasReducer from "./faturas";

const appReducer = combineReducers({
  login: loginReducer.reducer,
  config: configReducer.reducer,
  criarPedido: criarPedidoReducer.reducer,
  rastreio: rastreioReducer.reducer,
  utils: utilsReducer.reducer,
  chat: chatReducer.reducer,
  escalas: escalasReducer.reducer,
  daRobo: daRoboReducer.reducer,
  pedidos: pedidosReducer.reducer,
  taxaExtra: TaxaExtra.reducer,
  entregadores: entregadoresReducer.reducer,
  clientes: clientesReducer.reducer,
  empresas: empresasReducer.reducer,
  tabelaCobranca: tabelaCobrancaReducer.reducer,
  tabelaPagamento: tabelaPagamentoReducer.reducer,
  bots: botsReducer.reducer,
  despachoAutomatico: despachoAutomaticoReducer.reducer,
  wallet: walletReducer.reducer,
  faturas: faturasReducer.reducer
});

const estadoInicial = appReducer(undefined, { type: '@@INIT' });

const rootReducer = (state: any, action: any) => {
  if (action.type === "RESETAR_STORE") {
    state = estadoInicial;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export interface DefaultAsyncState<T> {
  loading: boolean;
  data: T | null;
  error: string | null;
}

export default rootReducer;
