import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { TableRowLoading, TableRowNotFound, formatTime } from 'src/ds';
import TableCellOrdenado from 'src/ds/components/TableCellOrdenado';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { listarPerformancePedidosStart } from 'src/store/reducers/pedidos';

interface TabelaPerformanceProps {
    pedidos: any[] | undefined;
    loading: boolean;
    fezRequisicao?: boolean;
    hookForm: any;
}

const TabelaPerformance = ({ pedidos, loading, fezRequisicao, hookForm }: TabelaPerformanceProps) => {
    const navigate = useNavigate();

    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        tempoProducao: 'asc',
        tempoDespacho: 'asc',
        tempoAceiteMotoboy: 'asc',
        tempoChegadaMotoboy: 'asc',
        tempoRotaEntregador: 'asc',
    });

    const dispatch = useDispatch();

    const toggleSortDirection = (column: string) => {
        const newSortDirection: 'asc' | 'desc' = sortDirection[column] === 'asc' ? 'desc' : 'asc';

        const updatedSortDirection: { [key: string]: 'asc' | 'desc' } = {
            ...sortDirection,
            [column]: newSortDirection,
        };

        setSortDirection(updatedSortDirection);

        const formValues = hookForm.getValues();
        const filteredFormValues = Object.entries(formValues).reduce((acc, [key, value]) => {
            if (value !== undefined && value !== null && value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {} as Record<string, any>);

        const payload = {
            ...filteredFormValues,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
        };

        dispatch(listarPerformancePedidosStart(payload));
    };

    return (
        <TableContainer
            sx={{
                width: '100%',
                overflowX: 'auto',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellOrdenado
                            label="Empresa"
                            column="empresa"
                            sortDirection={sortDirection}
                            onSortChange={() => { }}
                            sx={{ width: '15%' }}
                        />
                        <TableCellOrdenado
                            label="Entregador"
                            column="entregador"
                            sortDirection={sortDirection}
                            onSortChange={() => { }}
                            sx={{ width: '15%' }}
                        />
                        <TableCellOrdenado
                            label="Código do pedido"
                            column="codigoPedido"
                            sortDirection={sortDirection}
                            onSortChange={() => { }}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Tempo de produção"
                            column="tempoProducao"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Tempo de despacho"
                            column="tempoDespacho"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '20%' }}
                        />
                        <TableCellOrdenado
                            label="Tempo de aceite motoboy"
                            column="tempoAceiteMotoboy"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Tempo de chegada motoboy"
                            column="tempoChegadaMotoboy"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Tempo de rota entregador"
                            column="tempoRotaEntregador"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Distância"
                            column="distancia"
                            sortDirection={sortDirection}
                            onSortChange={() => { }}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Tempo total pedido"
                            column="tempoTotalPedido"
                            sortDirection={sortDirection}
                            onSortChange={() => { }}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Tempo ETA pedido"
                            column="tempoETA"
                            sortDirection={sortDirection}
                            onSortChange={() => { }}
                            sx={{ width: '10%' }}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : pedidos && pedidos.length > 0 ? (
                        pedidos.map((pedido) => (
                            <TableRow key={pedido.pedidoID} hover>
                                <TableCell align="center">
                                    <Typography variant="body2" sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                        {pedido.nomeEmpresa}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {pedido.nomeUsuario}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Chip
                                        label={pedido.codigoExterno}
                                        color="success"
                                        onClick={() => navigate(`/Pedido/Index/${pedido.pedidoID}`)}
                                    />
                                </TableCell>
                                <TableCell align="center">{pedido.tempoProducao}s</TableCell>
                                <TableCell align="center">{formatTime(pedido.tempoDespacho)}</TableCell>
                                <TableCell align="center">{formatTime(pedido.tempoAceiteMotoboy)}</TableCell>
                                <TableCell align="center">{formatTime(pedido.tempoChegadaMotoboy)}</TableCell>
                                <TableCell align="center">{formatTime(pedido.tempoRotaEntregador)}</TableCell>
                                <TableCell align="center">{Number(pedido.distancia.toFixed(2))}</TableCell>
                                <TableCell align="center">{formatTime(pedido.tempoTotalPedido)}</TableCell>
                                <TableCell align="center">
                                    <Chip
                                        label={formatTime(pedido.tempoETA)}
                                        color={
                                            pedido.tempoETA > 0
                                                ? 'error'
                                                : pedido.tempoETA === 0
                                                    ? 'default'
                                                    : 'success'
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRowNotFound text="Nenhum resultado encontrado" fezRequisicao={fezRequisicao} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TabelaPerformance;
