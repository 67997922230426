import { InfoOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, TextField, Tooltip } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SelectToggleAtivo, useResponseSnackbar, useResponsive, converterDataCadastro} from 'src/ds'
import { DA } from 'src/dtos/DADTOS'
import { RootState } from 'src/store/reducers'
import { limparSalvarDa, salvarDespachoAutomaticoStart } from 'src/store/reducers/despachoAutomatico'

interface Props {
    modelo?: any
}

const DadosBasicos = ({ modelo }: Props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tabelas = useSelector((state: RootState) => state.despachoAutomatico?.listaDa?.data?.dados?.lista)
    const hookForm = useForm<DA>({
        defaultValues: {
            tabelaDespachoAutomaticoID: '',
            nome: '',
            ativo: true,
            distanciaMaximaEntregador: '',
            distanciaMaximaEntrePedidos: '',
            tempoParaRecusaAutomatica: '',
            tempoParaReconsiderarEntregadores: '',
            angulo: '',
            podeAgruparEmpresasDiferentes: false,
            podeDespacharRetornando: false,
            despacharParaTodos: false,
            distanciaInicialParaTodos: 500,
            distanciaFinalParaTodos: 3500,
            numeroMaximoPedidosAgrupados: '',
        },
    })
    const response = useSelector((state: RootState) => state.despachoAutomatico?.salvarDa)

    useResponseSnackbar([{ response, clearAction: () => { dispatch(limparSalvarDa()); } }])

    const { register, handleSubmit, formState: { errors }, setValue } = hookForm

    const onSubmit = (data: DA) => {
        dispatch(salvarDespachoAutomaticoStart(data))
    }


    useEffect(() => {

        if (Array.isArray(tabelas) && tabelas.length > 0) {
            const storedId = localStorage.getItem('lastEditedTabelaId');

            const cincoMinutosAtras = new Date(new Date().getTime() - 0.1 * 60 * 1000);

            const tabelasRecentes = tabelas?.filter((tabela: any) => {
                const dataCadastro = converterDataCadastro(tabela.dataCadastro);
                return dataCadastro > cincoMinutosAtras;
            });

            const tabelaMaisRecente = tabelasRecentes?.reduce((maisRecente: any, tabelaAtual: any) => {
                const dataMaisRecente = converterDataCadastro(maisRecente?.dataCadastro || '');
                const dataAtual = converterDataCadastro(tabelaAtual?.dataCadastro);
                return dataAtual > dataMaisRecente ? tabelaAtual : maisRecente;
            }, null);
            if (tabelaMaisRecente && tabelaMaisRecente.tabelaDespachoAutomaticoID !== storedId) {
                localStorage.setItem('lastEditedTabelaId', tabelaMaisRecente.tabelaDespachoAutomaticoID);
                navigate(`/TabelaDespachoAutomatico/Editar/${tabelaMaisRecente.tabelaDespachoAutomaticoID}`);
            }
        }
    }, [tabelas]);

    const { isMobile } = useResponsive();

    useEffect(() => {
        if (modelo) {
            setValue('tabelaDespachoAutomaticoID', modelo?.tabelaDespachoAutomaticoID)
            setValue('nome', modelo?.nome)
            setValue('ativo', modelo?.ativo)
            setValue('distanciaMaximaEntregador', modelo?.distanciaMaximaEntregador)
            setValue('distanciaMaximaEntrePedidos', modelo?.distanciaMaximaEntrePedidos)
            setValue('tempoParaRecusaAutomatica', modelo?.tempoParaRecusaAutomatica)
            setValue('tempoParaReconsiderarEntregadores', modelo?.tempoParaReconsiderarEntregadores)
            setValue('angulo', modelo?.angulo)
            setValue('podeAgruparEmpresasDiferentes', modelo?.podeAgruparEmpresasDiferentes)
            setValue('podeDespacharRetornando', modelo?.podeDespacharRetornando)
            setValue('despacharParaTodos', modelo?.despacharParaTodos)
            setValue('distanciaInicialParaTodos', modelo?.distanciaInicialParaTodos)
            setValue('distanciaFinalParaTodos', modelo?.distanciaFinalParaTodos)
        }
    }, [modelo])
    return (
        <Box p={1} sx={{ backgroundColor: 'background.paper', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Stack direction={isMobile ? "column" : "row"} flexWrap={'wrap'} spacing={2} sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}>
                        <TextField
                            label="Nome"
                            size="small"
                            {...register('nome', { required: 'Nome é obrigatório' })}
                            error={!!errors.nome}
                            helperText={errors.nome?.message}
                            slotProps={{
                                inputLabel: {
                                    shrink: String(hookForm.watch("nome")).trim() !== ''
                                }
                            }}
                        />
                        <SelectToggleAtivo hookForm={{ methods: hookForm, name: "ativo" }} label="Ativo" />

                        <TextField
                            label="Tempo máximo entre pedidos (segundos)"
                            size="small"
                            {...register('tempoMaximoEntrePedidos', { required: 'Tempo máximo entre pedidos é obrigatório' })}
                            error={!!errors.tempoMaximoEntrePedidos}
                            helperText={errors.tempoMaximoEntrePedidos?.message}
                            type='number'
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <Tooltip title="Tempo limite para agrupar um pedido ao outro.">
                                            <IconButton>
                                                <InfoOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                },
                                inputLabel: {
                                    shrink: String(hookForm.watch("tempoMaximoEntrePedidos")).trim() !== ''
                                }
                            }}
                        />

                        <TextField
                            label="Número máximo de pedidos agrupados"
                            size="small"
                            {...register('numeroMaximoPedidosAgrupados', { required: 'Número máximo de pedidos agrupados é obrigatório' })}
                            error={!!errors.numeroMaximoPedidosAgrupados}
                            helperText={errors.numeroMaximoPedidosAgrupados?.message}
                            type='number'
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <Tooltip title="Quantidade máxima de pedidos que podem ser enviados para um único motoboy de acordo com as configurações definidas.">
                                            <IconButton>
                                                <InfoOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                },
                                inputLabel: {
                                    shrink: String(hookForm.watch("numeroMaximoPedidosAgrupados")) !== undefined
                                }
                            }}
                        />

                        <TextField
                            label="Distância máxima para entregador (metros)"
                            size="small"
                            {...register('distanciaMaximaEntregador', { required: 'Distância máxima para entregador é obrigatória' })}
                            error={!!errors.distanciaMaximaEntregador}
                            helperText={errors.distanciaMaximaEntregador?.message}
                            type='number'
                            slotProps={{
                                inputLabel: {
                                    shrink: String(hookForm.watch("distanciaMaximaEntregador")).trim() !== ''
                                }
                            }}
                        />

                        <TextField
                            label="Distância máxima entre pedidos (metros)"
                            size="small"
                            {...register('distanciaMaximaEntrePedidos', { required: 'Distância máxima entre pedidos é obrigatória' })}
                            error={!!errors.distanciaMaximaEntrePedidos}
                            helperText={errors.distanciaMaximaEntrePedidos?.message}
                            type='number'
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <Tooltip
                                            title="Distância definida para poder agrupar pedidos. Caso o ângulo seja superior a 90º recomenda-se diminuir os valores da distância máxima entre pedidos. Caso o ângulo seja inferior a 90º recomenda-se aumentar os valores da distância máxima entre pedidos."
                                        >
                                            <IconButton>
                                                <InfoOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                },
                                inputLabel: {
                                    shrink: String(hookForm.watch("distanciaMaximaEntrePedidos")).trim() !== ''
                                }
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}>

                        <TextField
                            label="Tempo para recusa automática (minutos)"
                            size="small"
                            {...register('tempoParaRecusaAutomatica', { required: 'Tempo para recusa automática é obrigatório' })}
                            error={!!errors.tempoParaRecusaAutomatica}
                            helperText={errors.tempoParaRecusaAutomatica?.message}
                            type='number'
                            slotProps={{
                                inputLabel: {
                                    shrink: String(hookForm.watch("tempoParaRecusaAutomatica")).trim() !== ''
                                }
                            }}
                        />

                        <TextField
                            label="Tempo para reconsiderar entregadores (minutos)"
                            type='number'
                            size="small"
                            {...register('tempoParaReconsiderarEntregadores', { required: 'Tempo para reconsiderar entregadores é obrigatório' })}
                            error={!!errors.tempoParaReconsiderarEntregadores}
                            helperText={errors.tempoParaReconsiderarEntregadores?.message}
                            slotProps={{
                                inputLabel: {
                                    shrink: String(hookForm.watch("tempoParaReconsiderarEntregadores")).trim() !== ''
                                }
                            }}
                        />

                        <TextField
                            label="Ângulo de atuação"
                            size="small"
                            {...register('angulo', { required: 'Ângulo de atuação é obrigatório' })}
                            error={!!errors.angulo}
                            helperText={errors.angulo?.message}
                            type='number'
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <Tooltip title="Utilizado para determinar a angulação máxima entre dois ou mais pedidos utilizando a empresa como ponto zero.">
                                            <IconButton>
                                                <InfoOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                },
                                inputLabel: {
                                    shrink: String(hookForm.watch("angulo")).trim() !== ''
                                }
                            }}
                        />

                        <SelectToggleAtivo
                            hookForm={{ methods: hookForm, name: 'podeAgruparEmpresasDiferentes' }}
                            label="Pode agrupar empresas diferentes"
                            toggleValues={{ ativo: 'true', inativo: 'false' }}
                            toggleLabels={{ ativo: 'Sim', inativo: 'Não' }}
                        />

                        <SelectToggleAtivo
                            hookForm={{ methods: hookForm, name: 'podeDespacharRetornando' }}
                            label="Pode despachar retornando"
                            toggleValues={{ ativo: 'true', inativo: 'false' }}
                            toggleLabels={{ ativo: 'Sim', inativo: 'Não' }}
                        />
                    </Box>
                </Stack>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button type="submit" variant='containedFilled' sx={{ width: 'fit-content' }}>Salvar</Button>
                </Box>
            </form>
        </Box>
    )
}

export default DadosBasicos
