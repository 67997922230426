import { AddCircleOutline, Delete } from '@mui/icons-material';
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteEmpresas, ModalBase, ModalOpcoes, TableNavigation, TableRowLoading, TableRowNotFound, useResponseSnackbar } from 'src/ds';
import { RootState } from 'src/store/reducers';
import { excluirEmpresaDAStart, limparEmpresaDA, listarEmpresasDAStart, salvarEmpresaDAStart } from 'src/store/reducers/despachoAutomatico';
import { Box, Typography } from '@mui/material'; 

interface Props {
    data: any;
}

const Empresas = ({ data }: Props) => {
    const dispatch = useDispatch();
    const [showSelectorModal, setShowSelectorModal] = React.useState(false);
    const [openDeletar, setOpenDeletar] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState<any>(null);
    const empresas = useSelector((state: RootState) => state.despachoAutomatico.empresasDA.data?.dados);
    const empresasDados = empresas?.lista;
    const response = useSelector((state: RootState) => state.despachoAutomatico.salvarEmpresaDA);
    const loading = useSelector((state: RootState) => state.despachoAutomatico.empresasDA.loading);

    useResponseSnackbar([{ response, clearAction: () => { dispatch(limparEmpresaDA()); } }]);

    const hookForm = useForm({
        defaultValues: {
            tabelaDespachoAutomaticoID: data?.tabelaDespachoAutomaticoID,
            empresaID: '',
            pagina: 1,
            paginacao: 20
        }
    });

    useEffect(() => {
        dispatch(listarEmpresasDAStart({
            pagina: hookForm.watch('pagina'),
            paginacao: hookForm.watch('paginacao'),
            filtros: `tabelaDespachoAutomaticoID.toString() = "${data?.tabelaDespachoAutomaticoID}"`
        }));
    }, [response.data, hookForm.watch('pagina'), hookForm.watch('paginacao')]);

    const handleSaveEmpresa = () => {
        dispatch(salvarEmpresaDAStart(hookForm.getValues()));
        setShowSelectorModal(false);  // Fecha o modal após salvar
    };

    const handleDeletar = (item: any) => {
        setSelectedItem(item);
        setOpenDeletar(true);
    };

    const handleOpenModal = () => {
        setShowSelectorModal(true);
    };

    const handleCloseModal = () => {
        setShowSelectorModal(false);
    };

    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Button
                variant="containedFilled"
                sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                onClick={handleOpenModal}
                startIcon={<AddCircleOutline />}
            >
                Adicionar nova empresa
            </Button>

            <ModalBase
                open={showSelectorModal}
                onClose={handleCloseModal}
                headerModal={{
                    title: 'Selecionar empresa',
                }}
                size='sm'
            >
                <Box p={2}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                        Selecionar Empresa
                    </Typography>
                    <Stack spacing={2}>
                        <AutocompleteEmpresas
                            hookForm={{
                                methods: hookForm,
                                name: 'empresaID',
                            }}
                            sx={{ width: '100%' }}
                            label='Selecionar empresa'
                        />
                        <Stack direction="row" sx={{ justifyContent: 'flex-end', mt: 2, gap: 1 }}>
                            <Button
                                color='secondary'
                                variant="containedFilled"
                                sx={{ width: 'fit-content' }}
                                onClick={handleCloseModal}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="containedFilled"
                                sx={{ width: 'fit-content' }}
                                onClick={handleSaveEmpresa}
                            >
                                Salvar
                            </Button>
                        </Stack>
                    </Stack>
                </Box>

            </ModalBase>

            <Stack sx={{ flex: 1, gap: 2 }}>
                {empresas?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={empresas.indiceInicial}
                        indiceFinal={empresas.indiceFinal}
                        indiceTotal={empresas.total}
                        ultimaPagina={empresas?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Nome</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowLoading />
                        ) : Array.isArray(empresasDados) && empresasDados?.length > 0 ? (
                            empresasDados.map((item: any) => (
                                <TableRow key={item.tabelaDespachoAutomaticoID}>
                                    <TableCell align="left">{item.empresa?.nome}</TableCell>
                                    <TableCell align="right">
                                        <IconButton aria-label="delete" size="small">
                                            <Delete color="error" onClick={() => { handleDeletar(item) }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRowNotFound text="Nenhum resultado encontrado." fezRequisicao={true} />
                        )}
                    </TableBody>
                </Table>
            </Stack>

            <ModalOpcoes
                descricao={(
                    <Typography variant="body1" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        Remover <Typography component="span" fontWeight="bold" sx={{ display: 'inline' }}>{selectedItem?.empresa?.nome}</Typography> da tabela
                    </Typography>

                )}
                acao="Excluir"
                open={openDeletar}
                onClose={() => { setOpenDeletar(false) }}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => setOpenDeletar(false)
                    },
                    {
                        label: 'Excluir',
                        action: () => {
                            dispatch(excluirEmpresaDAStart({
                                id: selectedItem.tabelaDespachoAutomaticoEmpresaID,
                                tabelaDespachoAutomaticoID: data?.tabelaDespachoAutomaticoID
                            }));
                            setOpenDeletar(false);
                        }
                    }
                ]}
            />
        </Stack>
    );
};

export default Empresas;
