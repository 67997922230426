import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClienteDTOS } from "../../dtos/ClientesDTO";
import { paginaClientes, salvarCliente } from "../sagas/cliente";

interface ListaClientesParams {
    pagina: number;
    paginacao: number;
    filtros?: string;
    empresaID?: string;
    dtInicial?: string;
    dtFinal?: string;
    empresaClienteID?: string;
    empresaNome?: string;
    dataCadastro?: string;
    nome?: string;
    ordenacaoCampo?: string;
    ordenacaoOrdem?: string;
}

interface Modelo {
    modelo: {
        id: string;
        empresaID: string;
        empresaNome: string;
        dataCadastro: string;
        nome: string;
        telefone: string;
        cpf: string;
        enderecoStr: string;
        qtdPedidos: number;
    }
}



interface ClienteState<T> {
    loading: boolean;
    data: T | null;
    error: string | null;
}

interface ClientesState {
    listar: ClienteState<ClienteDTOS>;
    pagina: ClienteState<Modelo>;
    salvar: ClienteState<ClienteDTOS>;
}

const initialState: ClientesState = {
    listar: {
        loading: false,
        data: null,
        error: null,
    },
    pagina: {
        loading: false,
        data: null,
        error: null
    },
    salvar: {
        loading: false,
        data: null,
        error: null
    }
};

const clientesSlice = createSlice({
    name: 'Clientes',
    initialState: initialState,
    reducers: {
        listaClientesStart(state, action: PayloadAction<ListaClientesParams>) {
            state.listar.loading = true;
            state.listar.error = null;
        },
        listaClientesFailure(state, action: PayloadAction<string>) {
            state.listar.loading = false;
            state.listar.error = action.payload;
        },
        listaClientesSuccess(state, action: PayloadAction<any>) {
            state.listar.loading = false;
            state.listar.data = action.payload;
        },
        paginaClientesStart(state, action: PayloadAction<{ id: string | undefined }>) {
            state.pagina.loading = true;
            state.pagina.error = null;
        },
        paginaClientesSuccess(state, action: PayloadAction<any>) {
            state.pagina.loading = false;
            state.pagina.data = action.payload;
        },
        paginaClientesFailure(state, action: PayloadAction<string>) {
            state.pagina.loading = false;
            state.pagina.error = action.payload;
        },
        salvarClienteStart(state, action: PayloadAction<ClienteDTOS>) {
            state.salvar.loading = true;
            state.salvar.error = null;
        },
        salvarClienteSuccess(state, action: PayloadAction<ClienteDTOS>) {
            state.salvar.loading = false;
            state.salvar.data = action.payload;
        },
        salvarClienteFailure(state, action: PayloadAction<string>) {
            state.salvar.loading = false;
            state.salvar.error = action.payload;
        }
    },
});

export const {
    listaClientesStart,
    listaClientesFailure,
    listaClientesSuccess,
    paginaClientesFailure,
    paginaClientesStart,
    paginaClientesSuccess,
    salvarClienteFailure,
    salvarClienteStart,
    salvarClienteSuccess
} = clientesSlice.actions;

export default clientesSlice;
