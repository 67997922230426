import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import { enviarMensagemFailure, enviarMensagemRequest, enviarMensagemSuccess } from '../reducers/chat';

function* enviarMensagem(action) {
    const mensagem = action.payload.newMessage;
    const chatId = action.payload.chatIDSelector;
    const destID = action.payload.destIdSelector;
    const destEmpresaID = action.payload?.destEmpresaIDSelector;
    const form = new FormData();
    if(destEmpresaID) form.append('destinatarioEmpresaID', destEmpresaID);
    form.append('destinatarioUsuarioID', destID);
    form.append('chatIdentificador', chatId);
    form.append('mensagem', mensagem);
    try {
        const response = yield call(api.post, '/Chat/EnviarMensagem', form);
        yield put(enviarMensagemSuccess(response.data));
    } catch (error) {
        yield put(enviarMensagemFailure(error));
    }

}

export function* chatSaga() {
    yield takeLatest(enviarMensagemRequest.type, enviarMensagem);
}

export default chatSaga;
