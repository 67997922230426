import FullCalendar from '@fullcalendar/react'
import dayGridMonth from '@fullcalendar/daygrid'
import allLocales from '@fullcalendar/core/locales-all';
import { DatesSetArg, EventClickArg, EventInput, EventMountArg } from '@fullcalendar/core';
import interactionPlugin, { DateClickArg, EventDragStopArg } from "@fullcalendar/interaction"
import listPlugin from '@fullcalendar/list';
import dayjs from 'dayjs';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alterarDataClear, alterarDataStart, escalasStart } from 'src/store/reducers/escalas';
import { RootState } from 'src/store/reducers';
import { BoxLoading, useResponsive} from 'src/ds';
import AbrirVagas, { AbrirVagasFormValues } from './AbrirVagas';
import { Escala } from 'src/dtos/EscalasDTO';
import { useTheme } from '@mui/material';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface CalendarioEscalasProps {
    empresaID: string;
    callback?: () => void;
    setDateInterval: Dispatch<React.SetStateAction<{ dataInicio: string; dataFim: string }>>
}

const CalendarioEscalas = (
    { empresaID, setDateInterval, callback }: CalendarioEscalasProps
) => {
    const { isMobile } = useResponsive()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { showSuccess, showError } = useSnackbarContext();
    const calendarRef = useRef<FullCalendar | null>(null)
    const escalasStore = useSelector((state: RootState) => state.escalas.listaEscalas)
    const [dataInicio, setDataInicio] = useState('')
    const [dataFim, setDataFim] = useState('')
    const [listaEventos, setListaEventos] = useState<EventInput[]>([])
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedVaga, setSelectedVaga] = useState<AbrirVagasFormValues | null>(null)
    const SEM_ENTREGADOR = '00000000-0000-0000-0000-000000000000'
    const [editarVaga, setEditarVaga] = useState(false)
    const [pedidoID, setPedidoID] = useState('')
    const alterarDataStore = useSelector((state: RootState) => state.escalas.alterarData)

    const defaultValues: AbrirVagasFormValues = {
        tipoVeiculo: { tipoVeiculoID: '' },
        entregador: {
            usuarioID: '',
            nome: ''
        },
        entregadorUsuarioID: '',
        dataAgendamento: dayjs().hour(9).minute(0).format('DD/MM/YYYY HH:mm'),
        dataFimDiaria: dayjs().hour(18).minute(0).format('DD/MM/YYYY HH:mm'),
        taxaTotalCobrada: '0',
        taxaTotalEntregador: '0',
        minimoGarantidoCobrado: false,
        minimoGarantidoEntregador: false,
        qtdPedidosMinimos: '0',
        minimoGarantidoPorPedido: 'false',
        detalhesVaga: '',
        datasReplicacaoAux: ''
    };

    const prepareInitialData = (vaga: Escala): AbrirVagasFormValues => {
        const entregadorUsuarioID = vaga.entregadorUsuarioID === SEM_ENTREGADOR
            ? defaultValues.entregadorUsuarioID
            : vaga.entregadorUsuarioID || defaultValues.entregadorUsuarioID

        return {
            tipoVeiculo: {
                tipoVeiculoID: vaga.tipoVeiculoID || defaultValues.tipoVeiculo?.tipoVeiculoID,
            },
            entregador: {
                usuarioID: entregadorUsuarioID,
                nome: vaga.entregadorUsuario?.nome || defaultValues.entregador?.nome,
            },
            entregadorUsuarioID: entregadorUsuarioID,
            dataAgendamento: vaga.dataAgendamento || defaultValues.dataAgendamento,
            dataFimDiaria: vaga.dataFim || defaultValues.dataFimDiaria,
            taxaTotalCobrada: vaga.taxaTotalCobrada || defaultValues.taxaTotalCobrada,
            taxaTotalEntregador: vaga.taxaTotalEntregador || defaultValues.taxaTotalEntregador,
            minimoGarantidoCobrado: vaga.taxaMinimaGarantidaCobrada > 0 || defaultValues.minimoGarantidoCobrado,
            minimoGarantidoEntregador: vaga.taxaMinimaGarantidaEntregador > 0 || defaultValues.minimoGarantidoEntregador,
            qtdPedidosMinimos: vaga.qtdPedidosMinimoGarantidoCobrado.toString() || defaultValues.qtdPedidosMinimos,
            minimoGarantidoPorPedido: vaga.qtdPedidosMinimoGarantidoEntregador.toString() || defaultValues.minimoGarantidoPorPedido,
            detalhesVaga: vaga.detalhes || defaultValues.detalhesVaga,
            datasReplicacaoAux: '',
        };
    };

    const listarVagas = () => {
        dispatch(escalasStart({ data: { inicio: dataInicio, fim: dataFim }, empresaID }));
    }

    useEffect(() => {
        if (!dataInicio || !dataFim) return;
        listarVagas()
    }, [dataInicio, dataFim, dispatch, empresaID])

    useEffect(() => {
        if (!escalasStore.data) return;
        const lista = escalasStore.data.dados?.lista.map((item): EventInput => {
            const startDate = item?.dataInicio ? dayjs(item?.dataInicio, 'DD/MM/YYYY HH:mm') : null;
            const endDate = item?.dataFim ? dayjs(item?.dataFim, 'DD/MM/YYYY HH:mm') : null;
            const hasEntregador = item.entregadorUsuarioID !== SEM_ENTREGADOR
            return {
                title: item?.entregadorUsuario?.nome || '',
                start: startDate ? startDate.format('YYYY-MM-DD HH:mm') : '',
                end: endDate ? endDate.format('YYYY-MM-DD HH:mm') : '',
                id: item.pedidoID,
                interactive: true,
                extendedProps: { hasEntregador }
            }
        })
        setListaEventos(lista || [])
    }, [escalasStore.data])

    const handleEventClick = (info: EventClickArg) => {
        const { event } = info;
        const eventId = event.id;

        const vaga = escalasStore.data?.dados?.lista.find((item) => item.pedidoID === eventId);

        if (vaga) {
            setEditarVaga(true);
            const initialData = prepareInitialData(vaga)
            setPedidoID(vaga.pedidoID)
            setSelectedVaga(initialData);
            setModalOpen(true);
        } else {
            console.error('Vaga não encontrada: ', vaga)
        }
    };

    const handleDatesSet = (arg: DatesSetArg) => {
        const { startStr, endStr } = arg
        const dtInicio = dayjs(startStr).format('DD/MM/YYYY')
        const dtFim = dayjs(endStr).format('DD/MM/YYYY')
        setDataInicio(dtInicio)
        setDataFim(dtFim)
        setDateInterval({ dataInicio: dtInicio, dataFim: dtFim })
    }

    const handleDateClick = (arg: DateClickArg) => {
        setSelectedVaga({
            ...defaultValues,
            dataAgendamento: dayjs(arg.dateStr).hour(9).minute(0).format('DD/MM/YYYY HH:mm'),
            dataFimDiaria: dayjs(arg.dateStr).hour(18).minute(0).format('DD/MM/YYYY HH:mm')
        })
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
        setSelectedVaga(null)
    }

    const handleEventDrop = (info: EventDragStopArg) => {
        const { event } = info;
        const start = event.start;
        let end = event.end;

        if (!end) {
            end = start;
        }

        const dataAgendamento = dayjs(start).format('DD/MM/YYYY HH:mm');
        const dataConcluido = dayjs(end).format('DD/MM/YYYY HH:mm');


        dispatch(alterarDataStart({ dataAgendamento, dataConcluido, pedidoID: event.id }));
    }

    useEffect(() => {
        if (alterarDataStore.data?.sucesso) {
            showSuccess({
                message: alterarDataStore.data.mensagem,
                duration: 5000
            });
        } else if (alterarDataStore.error) {
            showError({
                message: alterarDataStore.error,
                duration: 5000
            });
        }
        callback && callback();

        return () => {
            dispatch(alterarDataClear())
        };
    }, [alterarDataStore.data, alterarDataStore.error])

    useEffect(() => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const newView = isMobile ? 'listMonth' : 'dayGridMonth';
            calendarApi.changeView(newView);
        }
    }, [isMobile]);

    const handleEventDidMount = useCallback((info: EventMountArg) => {
        const { hasEntregador } = info.event.extendedProps;
        const dotEl = info.el.querySelector('.fc-daygrid-event-dot') as HTMLElement | null;
        if (dotEl) dotEl.style.display = 'none';
        const tableDot = info.el.querySelector('.fc-list-event-graphic') as HTMLElement | null;
        if (tableDot) tableDot.style.display = 'none';
        info.el.style.backgroundColor = hasEntregador
            ? theme.palette.primary.main
            : theme.palette.secondary.main;
        info.el.style.borderColor = hasEntregador
            ? theme.palette.primary.main
            : theme.palette.secondary.main;
        info.el.style.color = hasEntregador
            ? theme.palette.getContrastText(theme.palette.primary.main)
            : theme.palette.getContrastText(theme.palette.secondary.main);
        info.el.style.fontWeight = '900';
    }, [theme.palette.primary.main, theme.palette.secondary.main, listaEventos]);

    useEffect(() => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.removeAllEvents(); // Limpa eventos antigos
            calendarApi.addEventSource(listaEventos); // Adiciona novos eventos
        }
    }, [listaEventos]);

    return (
        <>
            <BoxLoading loading={escalasStore.loading || alterarDataStore.loading} sx={{ height: '100%', pb: 2 }}>
                <FullCalendar
                    ref={calendarRef}
                    initialView={isMobile ? 'listMonth' : 'dayGridMonth'}
                    viewHeight={'100%'}
                    plugins={[dayGridMonth, interactionPlugin, listPlugin]}
                    locales={allLocales}
                    locale={'pt-br'}
                    datesSet={handleDatesSet}
                    events={listaEventos}
                    eventClick={handleEventClick}
                    dateClick={(e) => handleDateClick(e)}
                    editable={true}
                    droppable={true}
                    eventDrop={(e) => handleEventDrop(e)}
                    eventDidMount={handleEventDidMount}
                />
            </BoxLoading>
            {modalOpen &&
                <AbrirVagas
                    open={modalOpen}
                    onClose={handleModalClose}
                    empresaID={empresaID}
                    initialData={selectedVaga}
                    callback={callback}
                    editar={editarVaga}
                    pedidoID={pedidoID}
                    setPedidoID={setPedidoID}
                />}
        </>
    )
}

export default CalendarioEscalas