// TextFieldMoney.tsx
import React, { useEffect, useState, useCallback } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';

// Função de máscara para valor monetário no formato brasileiro
function formatMoney(value: number): string {
  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

// Função que converte string para número decimal (base em centavos)
function parseToNumber(value: string): number {
  const cleanedValue = value.replace(/\D/g, "");
  return Number(cleanedValue) / 100;
}

// Função que aplica máscara condicionalmente dependendo do input ou formatação final
function liveMaskMoney(value: string | number, onInput: boolean = true): string {
  if (value === null || value === undefined) return "";

  let numericValue: number;
  if (onInput) {
    numericValue = typeof value === "string" ? parseToNumber(value) : value / 100;
  } else {
    numericValue = typeof value === "number" ? value : parseFloat(value.replace(/[^0-9.,]/g, "").replace(",", "."));
  }

  return formatMoney(numericValue);
}

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name: string;
  defaultValue?: string | number | null;
}

interface TextFieldMoneyProps {
  hookForm: HookForm;
  label?: string;
  size?: 'small' | 'medium';
  sx?: SxProps;
  slotProps?: TextFieldProps['slotProps'];
  disabled?: boolean
}

const TextFieldMoney: React.FC<TextFieldMoneyProps> = ({
  size = 'small',
  sx,
  label,
  hookForm: { methods, rules, name, defaultValue },
  slotProps,
  disabled,
}) => {
  const { setValue, formState: { errors }, watch, control } = methods;
  const inputValue = watch(name);
  const [displayValue, setDisplayValue] = useState<string>('');

  // Atualiza displayValue com o valor formatado ao montar ou quando defaultValue muda
  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null) {
      const formattedValue = liveMaskMoney(defaultValue, false);
      setDisplayValue(formattedValue);

      const newValue = parseToNumber(formattedValue).toFixed(2).replace(".", ",");
      setTimeout(() => setValue(name, newValue, { shouldValidate: true }), 0);
    }
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    if (inputValue) {
      const formattedValue = liveMaskMoney(inputValue);
      setDisplayValue(formattedValue);
    }
  }, [inputValue]);

  // Função de atualização do valor formatado e armazenamento como decimal
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = liveMaskMoney(event.target.value);
    const rawValue = parseToNumber(event.target.value).toFixed(2).replace(".", ",");

    setValue(name, rawValue, { shouldValidate: true });
    setDisplayValue(formattedValue);
  }, [name, setValue]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      disabled={disabled}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          value={displayValue}
          onChange={handleChange}
          error={!!errors[name]}
          helperText={errors[name] ? String(errors[name]?.message ?? '') : ''}
          size={size}
          sx={sx}
          slotProps={slotProps}
        />
      )}
    />
  );
};

export default TextFieldMoney;
