import React from 'react';
import Slide from '@mui/material/Slide';
import { GridPainelLateral } from 'src/ds';

// Tipos para as props do componente
interface SlidePanelProps {
    isOpen: boolean;
    refProp?: React.Ref<HTMLDivElement>;
    component: React.ComponentType<{ close: () => void }>;
    close: () => void;
    props?: any;
}

const timeAnimation = 300;


const SlidePanel: React.FC<SlidePanelProps> = ({ isOpen, refProp, component: Component, close, props }) => {
    return (
        <Slide in={isOpen} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
            <GridPainelLateral
                ref={refProp}
                sx={{ minHeight: isOpen ? '100%' : '0' }}
            >
                <Component close={close} {...props} />
            </GridPainelLateral>
        </Slide>
    );
};

export default SlidePanel;
