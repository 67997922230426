import { Box, Card } from "@mui/material";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/components/CardFormFiltros";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { RootState } from "src/store/reducers";
import { BoxTableMinHeight, MenuTabelas, TableNavigation } from "src/ds";
import TabelaDA from "src/components/DespachoAutomatico/TabelaDespachoAutomatico";
import { listarDaStart } from "src/store/reducers/despachoAutomatico";
import { useNavigate } from "react-router-dom";

const DespachoAutomatico = () => {
    const daDados = useSelector((state: RootState) => state.despachoAutomatico.listaDa.data?.dados);
    const daLista = daDados?.lista;
    const loading = useSelector((state: RootState) => state.despachoAutomatico.listaDa.loading);
    const hookForm = useForm({
        defaultValues: {
            filtros: '',
            empresaID: '',
            dtInicial: '',
            dtFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });
    const dispatch = useDispatch();
    const primeiraRenderizacao = useRef(true);
    const cardRef = useRef<CardFormFiltrosRef>(null)
    const { watch } = hookForm
    useEffect(() => {
        if (primeiraRenderizacao.current === true) {
            cardRef.current?.onSubmit();
            primeiraRenderizacao.current = false;
            return
        }
    }, [primeiraRenderizacao]);

    const navigate = useNavigate();

    const menuOptions = [{
        label: "Inserir",
        action: () => navigate('/TabelaDespachoAutomatico/Inserir'),
    }]

    useEffect(() => {
        dispatch(listarDaStart({
            ...hookForm.getValues(),
        }));
    }, [watch("paginacao"), watch("pagina")]);

    return (
        <Box >
            <Box sx={{ p: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    formMethods={hookForm}
                    dispatchMethods={{
                        actionName: 'listarDaStart'
                    }}
                    fields={[
                        { name: "Buscar", value: "nome", isFiltro: true, tipo: 'TEXTO' },
                        { name: 'Empresas', value: 'empresaID', type: 'autocompleteEmpresa' },
                    ]}
                    loading={loading}
                />
            </Box>
            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, alignItems: "center" }}>
                    {daDados?.ultimaPagina ? (
                        <TableNavigation
                            indiceInicial={daDados.indiceInicial}
                            indiceFinal={daDados.indiceFinal}
                            indiceTotal={daDados.total}
                            ultimaPagina={daDados?.ultimaPagina}
                            hookForm={hookForm}
                        />
                    ) : null}
                    <MenuTabelas options={menuOptions} />
                </Box>
                <BoxTableMinHeight>
                    <TabelaDA
                        dados={daLista}
                        loading={loading}
                        fezRequisicao={cardRef.current?.fezRequisicao}
                    />
                </BoxTableMinHeight>
            </Card>
        </Box>
    );
};

export default DespachoAutomatico;
