import { Box, TableCell, TableRow, Typography, IconButton, Tooltip, Collapse, Stack, Chip } from '@mui/material';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import StepperAndamento from './StepperAndamento';
import { MeusPedidos, Pedido } from '../../dtos/PedidosDTO';
import { dinheiro } from '../../ds/common';
import { ChipSituacao, findOrigemImg } from 'src/ds';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from 'react-router-dom';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

interface PedidoRowRepresentante {
    pedido: MeusPedidos;
    onToggleStepper: (pedidoID: string) => void;
    stepperVisible: boolean;
    tipoUsuario: 'Representante';
};

interface PedidoRowGenerico {
    pedido: Pedido;
    onToggleStepper: (pedidoID: string) => void;
    stepperVisible: boolean;
    tipoUsuario: 'SAAS' | 'Licenciado';
};

type PedidoRowProps = PedidoRowRepresentante | PedidoRowGenerico;

const PedidoRow = ({ pedido, onToggleStepper, stepperVisible, tipoUsuario }: PedidoRowProps) => {
    const navigate = useNavigate();


    return (
        <>
            {tipoUsuario === 'Representante' ? (
                <TableRow hover>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        <Box
                            component="img"
                            src={findOrigemImg(pedido?.aplicacaoOrigemNome)}
                            alt={pedido.aplicacaoOrigemNome || 'Logo'}
                            sx={{ width: 32, height: 32 }}
                        />
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.codigoExterno ? <Chip label={isNaN(Number(pedido.codigoExterno)) ? pedido.codigoExterno : `#${pedido.codigoExterno}`} /> : '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        <ChipSituacao situacao={pedido?.situacao} label={pedido.situacao} />
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido?.avaliacaoNotaCliente || '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        <Typography variant="body2">
                            {pedido.origemEndereco?.enderecoStr ? (
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="body2" sx={{ mb: '8px', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                        <strong>Origem:</strong> {pedido.origemEndereco?.enderecoStr}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: '8px', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                        <strong>Entrega:</strong> {pedido.entregaEndereco?.enderecoStr}
                                    </Typography>
                                </Box>
                            ) : <Typography variant="body2">{pedido.entregaEndereco?.enderecoStr} </Typography>}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.entregadorUsuario?.nome || '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.distanciaCalculadaKm ? pedido.distanciaCalculadaKm.toFixed(2) : '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.distanciaCalculadaKmPorRota ? pedido.distanciaCalculadaKmPorRota.toFixed(2) : '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.taxaTotalCobrada ? dinheiro(pedido.taxaTotalCobrada) : '-'}
                    </TableCell>
                    <TableCell align='center' sx={{ borderBottom: 'none' }}>
                        <Stack direction='row' alignItems='center' justifyContent='center' sx={{ gap: '.5rem' }}>
                            <Tooltip title={stepperVisible ? "Esconder andamento" : "Ver andamento"} arrow placement='top'>
                                <IconButton aria-label="Ver histórico em andamento" onClick={() => onToggleStepper(pedido.pedidoID)}>
                                    {stepperVisible ? <VisibilityOffRoundedIcon color='disabled' /> : <VisibilityRoundedIcon color='primary' />}
                                </IconButton>
                            </Tooltip>
                            {pedido.formularioEntregaID && (
                                <Tooltip title={"Formulário de entrega"} arrow placement='top'>
                                    <IconButton aria-label="Formulário de entrega" onClick={() => alert('Formulário de entrega')}>
                                        <ContentPasteIcon color='info' />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {pedido.formularioColetaID && (
                                <Tooltip title={"Formulário de coleta"} arrow placement='top'>
                                    <IconButton aria-label="Formulário de coleta" onClick={() => alert('Formulário de coleta')}>
                                        <ContentPasteSearchIcon color='success' />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>
            ) : (
                <TableRow hover>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        <Box
                            component="img"
                            src={findOrigemImg(pedido?.aplicacaoOrigemNome)}
                            alt={pedido.aplicacaoOrigemNome || 'Logo'}
                            sx={{ width: 32, height: 32 }}
                        />
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        <TableCell align="center" sx={{ borderBottom: 'none' }}>
                            {pedido.codigoExterno ? <Chip label={isNaN(Number(pedido.codigoExterno)) ? pedido.codigoExterno : `#${pedido.codigoExterno}`} /> : '-'}
                        </TableCell>
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        <ChipSituacao situacao={pedido?.situacao} label={pedido.situacao} />
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido?.empresa?.nome || '-'}
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: 'none' }}>
                        <Typography variant="body2" sx={{ marginBottom: '8px', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                            <Box component="span" fontWeight="bold">Origem: </Box>
                            {pedido.origemEndereco?.enderecoStr}
                        </Typography>
                        <Typography variant="body2" sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                            <Box component="span" fontWeight="bold">Entrega: </Box>
                            {pedido.entregaEndereco?.enderecoStr}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.entregadorUsuario?.nome || '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.clienteNome || '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.formaPagamento?.nome || '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.valor ? dinheiro(pedido.valor) : '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.taxaTotalCobrada ? dinheiro(pedido.taxaTotalCobrada) : '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        {pedido.taxaTotalEntregador ? dinheiro(pedido.taxaTotalEntregador) : '-'}
                    </TableCell>
                    <TableCell align='center' sx={{ borderBottom: 'none' }}>
                        <Stack direction='row' alignItems='center' justifyContent='center' sx={{ gap: '.5rem' }}>
                            <Tooltip title={stepperVisible ? "Esconder andamento" : "Ver andamento"} arrow placement='top'>
                                <IconButton aria-label="Ver histórico em andamento" onClick={() => onToggleStepper(pedido.pedidoID)}>
                                    {stepperVisible ? <VisibilityOffRoundedIcon color='disabled' /> : <VisibilityRoundedIcon color='primary' />}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Editar Pedido"} arrow placement='top'>
                                <IconButton aria-label="Editar pedido" onClick={() => navigate(`/pedido/Index/${pedido.pedidoID}`)}>
                                    <EditNoteIcon color='secondary' />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </TableCell>
                </TableRow>
            )}
            <TableRow>
                <TableCell sx={{ borderTop: 'none', paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={stepperVisible} timeout={300} unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <StepperAndamento
                                historicoAndamento={{
                                    recebido: pedido.dataCadastro,
                                    agendamento: pedido.dataAgendamento,
                                    pronto: pedido.dataPronto,
                                    despacho: pedido.dataDespachado,
                                    aceito: pedido.dataAceito,
                                    noLocal: pedido.dataChegouEstabelecimento,
                                    emRota: pedido.dataEmRota,
                                    retorno: pedido.dataRetorno,
                                    finalizado: pedido.dataFinalizado,
                                    cancelado: pedido.dataCancelamento,
                                }}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PedidoRow;
