import { Box, TextField, Button, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { cleanMoneyInput, replaceDecimalSeparator } from 'src/ds/common';
import { HeaderModal, ModalBase, TextFieldMoney } from 'src/ds';
import { liveMaskMoney } from 'src/ds';
import { salvarTabelaPorBairroStart } from 'src/store/reducers/tabelaPagamento';

interface DadosValores {
    bairro: string;
    valor: string;
    taxaRetorno: string;
    tabelaValorBairroID: string | undefined;
}

interface ModalEditarValoresPorBairroProps {
    open: boolean;
    handleClose: () => void;
    dados?: DadosValores;
}

const ModalEditarValoresPorBairro = ({ open, handleClose, dados }: ModalEditarValoresPorBairroProps) => {
    const hookForm = useForm({
        defaultValues: {
            tabelaValorBairroID: dados?.tabelaValorBairroID,
            bairro: '',
            valor: '',
            taxaRetorno: '',
        },
    });

    const { register, setValue, handleSubmit, watch, reset, formState: { errors } } = hookForm

    useEffect(() => {
        if (dados) {
            setValue("tabelaValorBairroID", dados.tabelaValorBairroID);
            setValue("bairro", dados.bairro);
            setValue("valor", dados.valor);
            setValue("taxaRetorno", dados.taxaRetorno);
        }
    }, [dados, setValue]);

    const dispatch = useDispatch();

    const onSubmit = (data: DadosValores) => {
        const payload = {
            ...data,
            valor: cleanMoneyInput(replaceDecimalSeparator(data.valor)),
            taxaRetorno: cleanMoneyInput(replaceDecimalSeparator(data.taxaRetorno)),
            tabelaValorBairroID: data.tabelaValorBairroID || '',
        };
        dispatch(salvarTabelaPorBairroStart(payload));
        handleClose();
    };

    React.useEffect(() => {
        if (dados) {
            reset(dados);
        } else {
            reset({
                tabelaValorBairroID: '',
                bairro: '',
                valor: '',
                taxaRetorno: '',
            });
        }
    }, [dados, reset]);

    return (
        <ModalBase open={open} onClose={handleClose} aria-labelledby="ModalBase-title" aria-describedby="modal-description" size='sm'>
            <HeaderModal title="Valores por Bairro" onClose={handleClose} />
            <Box p={3}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                        <TextField
                            label="Bairro"
                            fullWidth
                            size="small"
                            {...register('bairro', { required: "Bairro é obrigatório" })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!errors.bairro}
                            helperText={errors.bairro ? errors.bairro.message : ""}
                        />

                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'valor',
                                defaultValue: watch('valor'),
                                rules: { required: "Valor é obrigatório", min: { value: 0, message: "O valor deve ser maior que 0" } },
                            }}
                            label="Valor"

                        />

                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'taxaRetorno',
                                defaultValue: watch('taxaRetorno'),
                                rules: { required: "Taxa de retorno é obrigatória", min: { value: 0, message: "A taxa deve ser maior que 0" } },
                            }}
                            label="Taxa de Retorno"

                        />

                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="contained" color="secondary" onClick={handleClose} sx={{ width: 'fit-content' }}>
                                Cancelar
                            </Button>
                            <Button variant="containedFilled" color="primary" type="submit" sx={{ width: 'fit-content' }}>
                                Salvar
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </ModalBase>
    );
};

export default ModalEditarValoresPorBairro;
