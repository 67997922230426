import accon from "../../assets/integracoes/accon.png";
import alloy from "../../assets/integracoes/alloy.png";
import anotaai from "../../assets/integracoes/anotaai.png";
import deliverydireto from "../../assets/integracoes/deliverydireto.png";
import ifood from "../../assets/integracoes/ifood.png";
import neemo from "../../assets/integracoes/neemo.png";

const findOrigemImg = (origemNome?: string | null, defaultValue?: string) => {

  switch (origemNome) {
    case "ACCON":
      return accon;
    case "ALLOY":
      return alloy;
    case "ANOTAAI":
      return anotaai;
    case "DELIVERYDIRETO":
      return deliverydireto;
    case "IFOOD":
      return ifood;
    case "NEEMO":
      return neemo;
    default:
      return defaultValue || "https://daouupqyghflu.cloudfront.net/Pick/Logo/28fa0cae-7fe6-11ed-ab9d-12d2147dce0f/icon-app.png";
  }
};

export default findOrigemImg;
