import { cleanInput } from "../common";

const validateCPF = (cpf?: string | null) => {
  if (cpf) {
    const cleanedCNPJ = cleanInput(cpf);
    if (cleanedCNPJ.length !== 11) return false;

    let sum = 0,
      remainder;
    for (let i = 1; i <= 9; i++)
      sum += parseInt(cleanedCNPJ.substring(i - 1, i)) * (11 - i);
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cleanedCNPJ.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++)
      sum += parseInt(cleanedCNPJ.substring(i - 1, i)) * (12 - i);
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cleanedCNPJ.substring(10, 11))) return false;

    return true;
  }
  return false;
};

export default validateCPF;
