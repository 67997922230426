import { createSlice } from "@reduxjs/toolkit";
import { DefaultAsyncState } from ".";
import {
  BotQrCodeDTO,
  BotsDTO,
  CriarBotDTO,
  PayloadCriarBot,
  ExcluirBotDTO,
  ListaMensagensBotDTO,
  PayloadTiposFuncoes,
  TiposFuncaoDTO,
  PayloadListarEmpresasVinculadas,
  EmpresasVinculadasDTO,
  ExcluirEmpresaVinculadaDTO,
  PayloadExcluirEmpresaVinculada,
  SalvarEmpresaVinculadaDTO,
  PayloadSalvarEmpresaVinculada,
  PayloadListarBots,
  PayloadListarMensagensBot,
  MensagensPadroesDTO,
  PayloadListarMensagensPadroes,
  MensagemTesteDTO,
  PayloadEnviarMensagemTeste,
  FuncoesMensagemDTO,
  PayloadListarFuncoesMensagem,
  PalavrasChaveDTO,
  PayloadListarPalavrasChave,
  PayloadSalvarMensagemPadrao,
  SalvarMensagemPadraoDTO,
  ExcluirMensagemPadraoDTO,
  PayloadExcluirMensagemPadrao,
} from "src/dtos/BotsDTO";
//TODO dto de bot
interface InitialState {
  lista: DefaultAsyncState<BotsDTO>;
  listaMensagens: DefaultAsyncState<ListaMensagensBotDTO>;
  listaFuncoes: DefaultAsyncState<TiposFuncaoDTO>;
  listaQrCode: DefaultAsyncState<BotQrCodeDTO>;
  excluirBot: DefaultAsyncState<ExcluirBotDTO>;
  criarBot: DefaultAsyncState<CriarBotDTO>;
  listaEmpresasVinculadas: DefaultAsyncState<EmpresasVinculadasDTO>;
  excluirEmpresaVinculada: DefaultAsyncState<ExcluirEmpresaVinculadaDTO>;
  salvarEmpresaVinculada: DefaultAsyncState<SalvarEmpresaVinculadaDTO>;
  listaMensagensPadroes: DefaultAsyncState<MensagensPadroesDTO>;
  enviarMensagemTeste: DefaultAsyncState<MensagemTesteDTO>;
  funcoesMensagem: DefaultAsyncState<FuncoesMensagemDTO>;
  palavrasChave: DefaultAsyncState<PalavrasChaveDTO>;
  salvarMensagemPadrao: DefaultAsyncState<SalvarMensagemPadraoDTO>;
  excluirMensagemPadrao: DefaultAsyncState<ExcluirMensagemPadraoDTO>;
}

const initialState: InitialState = {
  lista: {
    loading: false,
    data: null,
    error: null,
  },
  listaMensagens: {
    loading: false,
    data: null,
    error: null,
  },
  listaFuncoes: {
    loading: false,
    data: null,
    error: null,
  },
  listaQrCode: {
    loading: false,
    data: null,
    error: null,
  },
  excluirBot: {
    loading: false,
    data: null,
    error: null,
  },
  criarBot: {
    loading: false,
    data: null,
    error: null,
  },
  listaEmpresasVinculadas: {
    loading: false,
    data: null,
    error: null,
  },
  excluirEmpresaVinculada: {
    loading: false,
    data: null,
    error: null,
  },
  salvarEmpresaVinculada: {
    loading: false,
    data: null,
    error: null,
  },
  listaMensagensPadroes: {
    loading: false,
    data: null,
    error: null,
  },
  enviarMensagemTeste: {
    loading: false,
    data: null,
    error: null,
  },
  funcoesMensagem: {
    loading: false,
    data: null,
    error: null,
  },
  palavrasChave: {
    loading: false,
    data: null,
    error: null,
  },
  salvarMensagemPadrao: {
    loading: false,
    data: null,
    error: null,
  },
  excluirMensagemPadrao: {
    loading: false,
    data: null,
    error: null,
  },
};

const botsSlice = createSlice({
  name: "botsReducer",
  initialState: initialState,
  reducers: {
    botsStart(state, action: { payload: PayloadListarBots }) {
      state.lista.loading = true;
      state.lista.data = null;
      state.lista.error = null;
    },
    botsSuccess(state, action) {
      state.lista.loading = false;
      state.lista.data = action.payload;
      state.lista.error = null;
    },
    botsFailure(state, action) {
      state.lista.loading = false;
      state.lista.data = null;
      state.lista.error = action.payload;
    },
    botsClear(state) {
      state.lista = { ...initialState.lista };
    },
    listarMensagensStart(
      state,
      action: { payload: PayloadListarMensagensBot }
    ) {
      state.listaMensagens.loading = true;
      state.listaMensagens.data = null;
      state.listaMensagens.error = null;
    },
    listarMensagensSuccess(state, action) {
      state.listaMensagens.loading = false;
      state.listaMensagens.data = action.payload;
      state.listaMensagens.error = null;
    },
    listarMensagensFailure(state, action) {
      state.listaMensagens.loading = false;
      state.listaMensagens.data = null;
      state.listaMensagens.error = action.payload;
    },
    listarMensagensClear(state) {
      state.listaMensagens = { ...initialState.listaMensagens };
    },
    listarFuncoesStart(state, action: { payload: PayloadTiposFuncoes }) {
      state.listaFuncoes.loading = true;
      state.listaFuncoes.data = null;
      state.listaFuncoes.error = null;
    },
    listarFuncoesSuccess(state, action) {
      state.listaFuncoes.loading = false;
      state.listaFuncoes.data = action.payload;
      state.listaFuncoes.error = null;
    },
    listarFuncoesFailure(state, action) {
      state.listaFuncoes.loading = false;
      state.listaFuncoes.data = null;
      state.listaFuncoes.error = action.payload;
    },
    listarFuncoesClear(state) {
      state.listaFuncoes = { ...initialState.listaFuncoes };
    },
    botQrCodeStart(state, action: { payload: { id: string } }) {
      state.listaQrCode.loading = true;
      state.listaQrCode.data = null;
      state.listaQrCode.error = null;
    },
    botQrCodeSuccess(state, action) {
      state.listaQrCode.loading = false;
      state.listaQrCode.data = action.payload;
      state.listaQrCode.error = null;
    },
    botQrCodeFailure(state, action) {
      state.listaQrCode.loading = false;
      state.listaQrCode.data = null;
      state.listaQrCode.error = action.payload;
    },
    excluirBotStart(state, action: { payload: { id: string } }) {
      state.excluirBot.loading = true;
      state.excluirBot.data = null;
      state.excluirBot.error = null;
    },
    excluirBotSuccess(state, action) {
      state.excluirBot.loading = false;
      state.excluirBot.data = action.payload;
      state.excluirBot.error = null;
    },
    excluirBotFailure(state, action) {
      state.excluirBot.loading = false;
      state.excluirBot.data = null;
      state.excluirBot.error = action.payload;
    },
    excluirBotClear(state) {
      state.excluirBot = { ...initialState.excluirBot };
    },
    criarBotStart(state, action: { payload: PayloadCriarBot }) {
      state.criarBot.loading = true;
      state.criarBot.data = null;
      state.criarBot.error = null;
    },
    criarBotSuccess(state, action) {
      state.criarBot.loading = false;
      state.criarBot.data = action.payload;
      state.criarBot.error = null;
    },
    criarBotFailure(state, action) {
      state.criarBot.loading = false;
      state.criarBot.data = null;
      state.criarBot.error = action.payload;
    },
    criarBotClear(state) {
      state.criarBot = { ...initialState.criarBot };
    },
    listarEmpresasVinculadasStart(
      state,
      action: { payload: PayloadListarEmpresasVinculadas }
    ) {
      state.listaEmpresasVinculadas.loading = true;
      state.listaEmpresasVinculadas.data = null;
      state.listaEmpresasVinculadas.error = null;
    },
    listarEmpresasVinculadasSuccess(state, action) {
      state.listaEmpresasVinculadas.loading = false;
      state.listaEmpresasVinculadas.data = action.payload;
      state.listaEmpresasVinculadas.error = null;
    },
    listarEmpresasVinculadasFailure(state, action) {
      state.listaEmpresasVinculadas.loading = false;
      state.listaEmpresasVinculadas.data = null;
      state.listaEmpresasVinculadas.error = action.payload;
    },
    excluirEmpresaVinculadaStart(
      state,
      action: { payload: PayloadExcluirEmpresaVinculada }
    ) {
      state.excluirEmpresaVinculada.loading = true;
      state.excluirEmpresaVinculada.data = null;
      state.excluirEmpresaVinculada.error = null;
    },
    excluirEmpresaVinculadaSuccess(state, action) {
      state.excluirEmpresaVinculada.loading = false;
      state.excluirEmpresaVinculada.data = action.payload;
      state.excluirEmpresaVinculada.error = null;
    },
    excluirEmpresaVinculadaFailure(state, action) {
      state.excluirEmpresaVinculada.loading = false;
      state.excluirEmpresaVinculada.data = null;
      state.excluirEmpresaVinculada.error = action.payload;
    },
    excluirEmpresaVinculadaClear(state) {
      state.excluirEmpresaVinculada = {
        ...initialState.excluirEmpresaVinculada,
      };
    },
    salvarEmpresaVinculadaStart(
      state,
      action: { payload: PayloadSalvarEmpresaVinculada }
    ) {
      state.salvarEmpresaVinculada.loading = true;
      state.salvarEmpresaVinculada.data = null;
      state.salvarEmpresaVinculada.error = null;
    },
    salvarEmpresaVinculadaSuccess(state, action) {
      state.salvarEmpresaVinculada.loading = false;
      state.salvarEmpresaVinculada.data = action.payload;
      state.salvarEmpresaVinculada.error = null;
    },
    salvarEmpresaVinculadaFailure(state, action) {
      state.salvarEmpresaVinculada.loading = false;
      state.salvarEmpresaVinculada.data = null;
      state.salvarEmpresaVinculada.error = action.payload;
    },
    salvarEmpresaVinculadaClear(state) {
      state.salvarEmpresaVinculada = { ...initialState.salvarEmpresaVinculada };
    },
    listarMensagensPadroesStart(
      state,
      action: { payload: PayloadListarMensagensPadroes }
    ) {
      state.listaMensagensPadroes.loading = true;
      state.listaMensagensPadroes.data = null;
      state.listaMensagensPadroes.error = null;
    },
    listarMensagensPadroesSuccess(state, action) {
      state.listaMensagensPadroes.loading = false;
      state.listaMensagensPadroes.data = action.payload;
      state.listaMensagensPadroes.error = null;
    },
    listarMensagensPadroesFailure(state, action) {
      state.listaMensagensPadroes.loading = false;
      state.listaMensagensPadroes.data = null;
      state.listaMensagensPadroes.error = action.payload;
    },
    listarMensagensPadroesClear(state) {
      state.listaMensagensPadroes = { ...initialState.listaMensagensPadroes };
    },
    enviarMensagemTesteStart(
      state,
      action: { payload: PayloadEnviarMensagemTeste }
    ) {
      state.enviarMensagemTeste.loading = true;
      state.enviarMensagemTeste.data = null;
      state.enviarMensagemTeste.error = null;
    },
    enviarMensagemTesteSuccess(state, action) {
      state.enviarMensagemTeste.loading = false;
      state.enviarMensagemTeste.data = action.payload;
      state.enviarMensagemTeste.error = null;
    },
    enviarMensagemTesteFailure(state, action) {
      state.enviarMensagemTeste.loading = false;
      state.enviarMensagemTeste.data = null;
      state.enviarMensagemTeste.error = action.payload;
    },
    enviarMensagemTesteClear(state) {
      state.enviarMensagemTeste = { ...initialState.enviarMensagemTeste };
    },
    listarFuncoesMensagemStart(
      state,
      action: { payload: PayloadListarFuncoesMensagem }
    ) {
      state.funcoesMensagem.loading = true;
      state.funcoesMensagem.data = null;
      state.funcoesMensagem.error = null;
    },
    listarFuncoesMensagemSuccess(state, action) {
      state.funcoesMensagem.loading = false;
      state.funcoesMensagem.data = action.payload;
      state.funcoesMensagem.error = null;
    },
    listarFuncoesMensagemFailure(state, action) {
      state.funcoesMensagem.loading = false;
      state.funcoesMensagem.data = null;
      state.funcoesMensagem.error = action.payload;
    },
    listarFuncoesMensagemClear(state) {
      state.funcoesMensagem = { ...initialState.funcoesMensagem };
    },
    listarPalavrasChaveStart(
      state,
      action: { payload: PayloadListarPalavrasChave }
    ) {
      state.palavrasChave.loading = true;
      state.palavrasChave.data = null;
      state.palavrasChave.error = null;
    },
    listarPalavrasChaveSuccess(state, action) {
      state.palavrasChave.loading = false;
      state.palavrasChave.data = action.payload;
      state.palavrasChave.error = null;
    },
    listarPalavrasChaveFailure(state, action) {
      state.palavrasChave.loading = false;
      state.palavrasChave.data = null;
      state.palavrasChave.error = action.payload;
    },
    listarPalavrasChaveClear(state) {
      state.salvarMensagemPadrao = { ...initialState.salvarMensagemPadrao };
    },
    salvarMensagemPadraoStart(
      state,
      action: { payload: PayloadSalvarMensagemPadrao }
    ) {
      state.salvarMensagemPadrao.loading = true;
      state.salvarMensagemPadrao.data = null;
      state.salvarMensagemPadrao.error = null;
    },
    salvarMensagemPadraoSuccess(state, action) {
      state.salvarMensagemPadrao.loading = false;
      state.salvarMensagemPadrao.data = action.payload;
      state.salvarMensagemPadrao.error = null;
    },
    salvarMensagemPadraoFailure(state, action) {
      state.salvarMensagemPadrao.loading = false;
      state.salvarMensagemPadrao.data = null;
      state.salvarMensagemPadrao.error = action.payload;
    },
    salvarMensagemPadraoClear(state) {
      state.salvarMensagemPadrao = { ...initialState.salvarMensagemPadrao };
    },
    excluirMensagemPadraoStart(
      state,
      action: { payload: PayloadExcluirMensagemPadrao }
    ) {
      state.excluirMensagemPadrao.loading = true;
      state.excluirMensagemPadrao.data = null;
      state.excluirMensagemPadrao.error = null;
    },
    excluirMensagemPadraoSuccess(state, action) {
      state.excluirMensagemPadrao.loading = false;
      state.excluirMensagemPadrao.data = action.payload;
      state.excluirMensagemPadrao.error = null;
    },
    excluirMensagemPadraoFailure(state, action) {
      state.excluirMensagemPadrao.loading = false;
      state.excluirMensagemPadrao.data = null;
      state.excluirMensagemPadrao.error = action.payload;
    },
    excluirMensagemPadraoClear(state) {
      state.excluirMensagemPadrao = { ...initialState.excluirMensagemPadrao };
    },
  },
});

export const {
  botsStart,
  botsSuccess,
  botsFailure,
  botsClear,
  listarMensagensStart,
  listarMensagensSuccess,
  listarMensagensFailure,
  listarMensagensClear,
  listarFuncoesStart,
  listarFuncoesSuccess,
  listarFuncoesFailure,
  listarFuncoesClear,
  botQrCodeStart,
  botQrCodeSuccess,
  botQrCodeFailure,
  excluirBotStart,
  excluirBotSuccess,
  excluirBotFailure,
  excluirBotClear,
  criarBotStart,
  criarBotSuccess,
  criarBotFailure,
  criarBotClear,
  listarEmpresasVinculadasStart,
  listarEmpresasVinculadasSuccess,
  listarEmpresasVinculadasFailure,
  excluirEmpresaVinculadaStart,
  excluirEmpresaVinculadaSuccess,
  excluirEmpresaVinculadaFailure,
  excluirEmpresaVinculadaClear,
  salvarEmpresaVinculadaStart,
  salvarEmpresaVinculadaSuccess,
  salvarEmpresaVinculadaFailure,
  salvarEmpresaVinculadaClear,
  listarMensagensPadroesStart,
  listarMensagensPadroesSuccess,
  listarMensagensPadroesFailure,
  listarMensagensPadroesClear,
  enviarMensagemTesteStart,
  enviarMensagemTesteSuccess,
  enviarMensagemTesteFailure,
  enviarMensagemTesteClear,
  listarFuncoesMensagemStart,
  listarFuncoesMensagemSuccess,
  listarFuncoesMensagemFailure,
  listarFuncoesMensagemClear,
  listarPalavrasChaveStart,
  listarPalavrasChaveSuccess,
  listarPalavrasChaveFailure,
  listarPalavrasChaveClear,
  salvarMensagemPadraoStart,
  salvarMensagemPadraoSuccess,
  salvarMensagemPadraoFailure,
  salvarMensagemPadraoClear,
  excluirMensagemPadraoStart,
  excluirMensagemPadraoSuccess,
  excluirMensagemPadraoFailure,
  excluirMensagemPadraoClear,
} = botsSlice.actions;

export default botsSlice;
