import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { BoxLoading } from "src/ds";
import { forcarLogin } from "src/store/reducers/login";
import { xtokenMasterpageStart } from "src/store/reducers/utils";

const RootRedirect = () => {
    const { token } = useParams();
    const xtoken = localStorage.getItem('@token');
    const dispatch = useDispatch();
    const comunicados = useSelector((state: any) => state.utils?.xtokenMasterpage?.data);
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            localStorage.setItem('@token', token);
            dispatch(xtokenMasterpageStart({
                payload: token,
                navigation: navigate
            }));
        }
    }, []);

    if (xtoken) {
        return <Navigate to="/Painel" replace />;
    } else {

        return <Navigate to="/Login" replace />;
    }

    return <BoxLoading />;
};

export default RootRedirect;
