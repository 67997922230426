// SelectToggleAtivo.tsx

import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps, SxProps } from "@mui/material";
import { Controller, RegisterOptions, UseFormReturn } from "react-hook-form";

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name: string;
}

type ToggleValues = {
  ativo: string;
  inativo: string
}

type ToggleLabels = {
  ativo: string;
  inativo: string
}

type Props = SelectProps & {
  hookForm: HookForm;
  toggleValues?: ToggleValues;
  toggleLabels?: ToggleLabels;
  sx?: SxProps;
};

/**
 * Componente `SelectToggleAtivo`
 *
 * Um seletor de opções (ativo/inativo) integrado ao React Hook Form e Material-UI, utilizado para alternar entre
 * dois estados (ativo e inativo) com valores e rótulos configuráveis.
 *
 * @param {Object} props - As propriedades do componente.
 * @param {HookForm} props.hookForm - As configurações do React Hook Form, contendo o método de controle e o nome do campo.
 * @param {string} props.hookForm.name - O nome do campo no formulário.
 * @param {UseFormReturn} props.hookForm.methods - Os métodos do formulário retornados pelo `useForm`.
 * @param {Object} [props.toggleValues] - Valores dinâmicos a serem usados para os estados de ativo/inativo.
 * @param {string} [props.toggleValues.ativo="true"] - Valor que representa o estado "ativo".
 * @param {string} [props.toggleValues.inativo="false"] - Valor que representa o estado "inativo".
 * @param {Object} [props.toggleLabels] - Rótulos dinâmicos a serem usados para os estados de ativo/inativo.
 * @param {string} [props.toggleLabels.ativo="Ativo"] - Rótulo exibido para o estado "ativo".
 * @param {string} [props.toggleLabels.inativo="Inativo"] - Rótulo exibido para o estado "inativo".
 * @param {SelectProps} props - Propriedades adicionais do Material-UI `Select` que podem ser repassadas.
 *
 * @example
 * <SelectToggleAtivo
 *   hookForm={{ methods: hookForm, name: "situacao" }}
 *   label="Situação"
 *   toggleValues={{ ativo: 'ativo', inativo: 'inativo' }}
 *   toggleLabels={{ ativo: 'Ativo', inativo: 'Inativo' }}
 * />
 *
 * @returns {JSX.Element} O componente `SelectToggleAtivo`.
 */

const SelectToggleAtivo = ({
  hookForm, toggleValues = { ativo: 'true', inativo: 'false' }, toggleLabels = { ativo: 'Ativo', inativo: 'Inativo' }, label = 'Situação', sx, ...props
}: Props) => {
  const { control, formState: { errors } } = hookForm.methods;

  return (
    <FormControl size={props.size ?? 'small'} error={!!errors[hookForm.name]} sx={sx}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={hookForm.name}
        control={control}
        rules={hookForm?.rules}
        render={({ field }) => (
          <Select
            label={label}
            size="small"
            {...field}
            value={String(field.value)}
            {...props}
          >
            <MenuItem value={toggleValues.ativo}>{toggleLabels.ativo}</MenuItem>
            <MenuItem value={toggleValues.inativo}>{toggleLabels.inativo}</MenuItem>
          </Select>
        )}
      />
      {errors[hookForm.name] && <FormHelperText>{String(errors[hookForm.name]?.message ?? '')}</FormHelperText>}
    </FormControl>

  );
};

export default SelectToggleAtivo;
