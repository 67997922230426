// SelectBotFuncaoMsg.tsx
import { CircularProgress, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { listarFuncoesMensagemClear, listarFuncoesMensagemStart } from 'src/store/reducers/bots';

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name: string;
}

interface SelectBotFuncaoMsgProps {
  funcaoBot: number | string;
  hookForm: HookForm;
  label?: string;
  size?: 'small' | 'medium';
  defaultValue?: string;
}

const NoIcon: React.FC = () => <span />;

const SelectBotFuncaoMsg = (
  { funcaoBot, hookForm, label = 'Função da Mensagem', size = 'small', defaultValue }: SelectBotFuncaoMsgProps
) => {
  const dispatch = useDispatch();
  const funcoesMensagem = useSelector((state: RootState) => state.bots.funcoesMensagem);
  const loading = useSelector((state: RootState) => state.bots.funcoesMensagem.loading);
  const [shouldClear, setShouldClear] = React.useState(defaultValue ? false : true);
  const { control, setValue, formState: { errors } } = hookForm.methods;

  useEffect(() => {
    if (!funcaoBot) {
      dispatch(listarFuncoesMensagemClear());
    } else {
      if(shouldClear) {
        setValue(hookForm.name, '');
      } 
      setShouldClear(true);
      dispatch(listarFuncoesMensagemStart({
        paginacao: -1,
        pagina: 1,
        filtros: '',
        ordenacaoOrdem: '',
        ordenacaoCampo: '',
        funcaoBot: funcaoBot,
        all: true,
      }));
    };
    return () => {
      dispatch(listarFuncoesMensagemClear());
    };
  }, [funcaoBot]);

  return (
    <FormControl
      error={!!errors[hookForm.name]}
      size={size}
    >
      <InputLabel>{label}</InputLabel>
      <Controller
        name={hookForm.name}
        control={control}
        rules={hookForm?.rules}
        render={({ field }) => (
          <Select
            {...field}
            defaultValue={""}
            label={label}
            error={!!errors[hookForm.name]}
            endAdornment={(
              loading ? (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ) : null
            )}
            disabled={loading}
            IconComponent={loading ? NoIcon : undefined}
          >
            {!funcaoBot
              ? <MenuItem value="" disabled>Selecione o Bot...</MenuItem>
              : <MenuItem value="">Selecione...</MenuItem>
            }
            {funcoesMensagem.data?.dados.lista?.map((funcao) => (
              <MenuItem key={funcao.valor} value={funcao.valor}>
                {funcao.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {!!errors[hookForm.name]
        ? <FormHelperText>{String(errors[hookForm.name]?.message ?? '')}</FormHelperText>
        : null
      }
    </FormControl>
  );
};

export default SelectBotFuncaoMsg;
