// AutocompleteTipoVeiculo.tsx
import { Autocomplete, SxProps, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, RegisterOptions, UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { TipoVeiculo } from "src/dtos/TabelaCobrancaDTO";
import { RootState } from "src/store/reducers";
import { listarVeiculosStart } from "src/store/reducers/escalas";

interface HookForm {
  name: string;
  label?: string
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
}

interface AutocompleteTipoVeiculoProps {
  hookForm: HookForm;
  label: string;
  sx?: SxProps;
  size?: 'small' | 'medium';
}

const AutocompleteTipoVeiculo = ({
  hookForm,
  label,
  sx,
  size = 'small',
}: AutocompleteTipoVeiculoProps) => {
  const dispatch = useDispatch();
  const listaVeiculos = useSelector((state: RootState) => state.escalas.listarVeiculos?.data?.dados?.lista);
  const [options, setOptions] = useState<TipoVeiculo[]>([]);
  const keyValue = 'tipoVeiculoID'
  const keyLabel = 'nome'
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = hookForm.methods;

  useEffect(() => {
    dispatch(listarVeiculosStart())
  }, [dispatch])

  useEffect(() => {
    setOptions(listaVeiculos || [])
    const selectedValue = watch(hookForm.name)?.[keyValue]
    if (selectedValue && listaVeiculos) {
      const newValue = listaVeiculos?.find(item => item[keyValue] === selectedValue)
      if (newValue) {
        setValue(hookForm.name, newValue)
      }
      // console.log('valor atual input', watch(hookForm.name)?.[keyValue])
      // console.log('valor encontrado', newValue)
      // console.log('listaVeiculos', listaVeiculos)
      // setValue(hookForm.name, newValue)
    }
  }, [listaVeiculos])

  return (
    <Controller
      name={hookForm.name}
      rules={hookForm.rules}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={options}
          getOptionLabel={(option) =>
            (option[keyLabel] as string) || ""
          }
          isOptionEqualToValue={(option, value) =>
            option[keyValue] === value[keyValue]
          }
          onChange={(_, value) => {
            field.onChange(value ? value : null);
          }}
          sx={sx}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={!!errors[hookForm.name]}
              helperText={
                errors[hookForm.name]?.message
                  ? String(errors[hookForm.name]?.message)
                  : ""
              }
              size={size}
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteTipoVeiculo;
