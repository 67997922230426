import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultDTO, DefaultState } from "src/dtos/DefaultDTO";
import { FaturasRepresentante } from "src/dtos/FaturasRepresentante";


interface FaturasState {
    listarMinhasFaturas: DefaultState<any>;
    listarTransacoes: DefaultState<any>;
    salvarTransacao: DefaultState<any>;
    pagarTransacaoCartao: DefaultState<any>;
    listarMinhasRepresentante: DefaultState<FaturasRepresentante>;
    listarMinhasTransacoes: DefaultState<any>;
}

const initialState: FaturasState = {
    listarMinhasFaturas: {
        loading: false,
        data: null,
        error: null
    },
    listarTransacoes: {
        loading: false,
        data: null,
        error: null
    },
    salvarTransacao: {
        loading: false,
        data: null,
        error: null
    },
    pagarTransacaoCartao: {
        loading: false,
        data: null,
        error: null
    },
    listarMinhasRepresentante: {
        loading: false,
        data: null,
        error: null
    },
    listarMinhasTransacoes: {
        loading: false,
        data: null,
        error: null
    },
};

const faturasSlice = createSlice({
    name: "Faturas",
    initialState: initialState,
    reducers: {
        listarMinhasFaturasStart: (state, action: PayloadAction<any>) => {
            state.listarMinhasFaturas.loading = true;
            state.listarMinhasFaturas.data = null;
            state.listarMinhasFaturas.error = null;
        },
        listarMinhasFaturasSuccess: (state, action: PayloadAction<any>) => {
            state.listarMinhasFaturas.loading = false;
            state.listarMinhasFaturas.data = action.payload;
            state.listarMinhasFaturas.error = null;
        },
        listarMinhasFaturasFailure: (state, action: PayloadAction<string>) => {
            state.listarMinhasFaturas.loading = false;
            state.listarMinhasFaturas.data = null;
            state.listarMinhasFaturas.error = action.payload;
        },
        listarTransacoesStart: (state, action: PayloadAction<any>) => {
            state.listarTransacoes.loading = true;
            state.listarTransacoes.data = null;
            state.listarTransacoes.error = null;
        },
        listarTransacoesSuccess: (state, action: PayloadAction<any>) => {
            state.listarTransacoes.loading = false;
            state.listarTransacoes.data = action.payload;
            state.listarTransacoes.error = null;
        },
        listarTransacoesFailure: (state, action: PayloadAction<string>) => {
            state.listarTransacoes.loading = false;
            state.listarTransacoes.data = null;
            state.listarTransacoes.error = action.payload;
        },
        salvarTransacaoStart: (state, action: PayloadAction<any>) => {
            state.salvarTransacao.loading = true;
            state.salvarTransacao.data = null;
            state.salvarTransacao.error = null;
        },
        salvarTransacaoSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTransacao.loading = false;
            state.salvarTransacao.data = action.payload;
            state.salvarTransacao.error = null;
        },
        salvarTransacaoFailure: (state, action: PayloadAction<string>) => {
            state.salvarTransacao.loading = false;
            state.salvarTransacao.data = null;
            state.salvarTransacao.error = action.payload;
        },
        pagarTransacaoCartaoStart: (state) => {
            state.pagarTransacaoCartao.loading = true;
            state.pagarTransacaoCartao.data = null;
            state.pagarTransacaoCartao.error = null;
        },
        pagarTransacaoCartaoSuccess: (state, action: PayloadAction<any>) => {
            state.pagarTransacaoCartao.loading = false;
            state.pagarTransacaoCartao.data = action.payload;
            state.pagarTransacaoCartao.error = null;
        },
        pagarTransacaoCartaoFailure: (state, action) => {
            state.pagarTransacaoCartao.loading = false;
            state.pagarTransacaoCartao.data = null;
            state.pagarTransacaoCartao.error = action.payload;
        },
        pagarTransacaoCartaoClear: (state) => {
            state.pagarTransacaoCartao.loading = false;
            state.pagarTransacaoCartao.data = null;
            state.pagarTransacaoCartao.error = null;
        },
        listarMinhasStart(state, action: PayloadAction<any>) {
            state.listarMinhasRepresentante.loading = true;
            state.listarMinhasRepresentante.data = null;
            state.listarMinhasRepresentante.error = null;
        },
        listarMinhasSuccess(state, action: PayloadAction<any>) {
            state.listarMinhasRepresentante.loading = false;
            state.listarMinhasRepresentante.data = action.payload;
            state.listarMinhasRepresentante.error = null;
        },
        listarMinhasFailure(state, action: PayloadAction<string>) {
            state.listarMinhasRepresentante.loading = false;
            state.listarMinhasRepresentante.data = null;
            state.listarMinhasRepresentante.error = action.payload;
        },
        listarMinhasTransacoesStart(state, action: PayloadAction<any>) {
            state.listarMinhasTransacoes.loading = true;
            state.listarMinhasTransacoes.data = null;
            state.listarMinhasTransacoes.error = null;
        },
        listarMinhasTransacoesSuccess(state, action: PayloadAction<any>) {
            state.listarMinhasTransacoes.loading = false;
            state.listarMinhasTransacoes.data = action.payload;
            state.listarMinhasTransacoes.error = null;
        },
        listarMinhasTransacoesFailure(state, action: PayloadAction<string>) {
            state.listarMinhasTransacoes.loading = false;
            state.listarMinhasTransacoes.data = null;
            state.listarMinhasTransacoes.error = action.payload;
        },
    },
});

export const {
    listarMinhasFaturasStart,
    listarMinhasFaturasSuccess,
    listarMinhasFaturasFailure,
    listarTransacoesFailure,
    listarTransacoesStart,
    listarTransacoesSuccess,
    salvarTransacaoFailure,
    salvarTransacaoStart,
    salvarTransacaoSuccess,
    pagarTransacaoCartaoFailure,
    pagarTransacaoCartaoStart,
    pagarTransacaoCartaoSuccess,
    pagarTransacaoCartaoClear,
    listarMinhasFailure,
    listarMinhasStart,
    listarMinhasSuccess,
    listarMinhasTransacoesFailure,
    listarMinhasTransacoesStart,
    listarMinhasTransacoesSuccess
} = faturasSlice.actions;

export default faturasSlice;
