import { AddCircleOutline, Delete, Edit } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonChip } from 'src/ds/components/ButtonChip';
import TipoDeVeiculo from 'src/ds/components/TipoDeVeiculo';
import { ModalBase, ModalOpcoes, TableNavigation, TableRowLoading, TableRowNotFound, useResponseSnackbar } from 'src/ds';
import { excluirTipoDeVeiculoDAStart, limparTipoDeVeiculoDA, listarFormasDePagamentoDAStart, listarTiposDeVeiculoDAStart, salvarTipoDeVeiculoDAStart } from 'src/store/reducers/despachoAutomatico';
import ModalFormasDePagamento from './ModalFormasDePagamento';

interface Props {
    data: any
}

const TiposDeVeiculo = ({ data }: Props) => {

    const [showSelector, setShowSelector] = React.useState(false);
    const [openDeletar, setOpenDeletar] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState<any>(null);
    const response = useSelector((state: any) => state.despachoAutomatico.salvarTipoDeVeiculoDA);
    const tiposVeiculo = useSelector((state: any) => state.despachoAutomatico.tiposDeVeiculoDA?.data?.dados);
    const loadingSalvar = useSelector((state: any) => state.despachoAutomatico.salvarTipoDeVeiculoDA.loading);
    const loading = useSelector((state: any) => state.despachoAutomatico.tiposDeVeiculoDA.loading);
    const [showFormasPagamento, setShowFormasPagamento] = useState(false);

    useResponseSnackbar([{ response, clearAction: () => dispatch(limparTipoDeVeiculoDA()) }]);

    const hookForm = useForm({
        defaultValues: {
            tabelaDespachoAutomaticoTipoVeiculoID: '',
            tabelaDespachoAutomaticoID: data?.tabelaDespachoAutomaticoID,
            tipoVeiculoID: '',
            distanciaMaximaColeta: '',
            distanciaMaximaEntrega: '',
            tempoPorKm: '',
            tempoMinimoColeta: '',
            distanciaInicialDespachoGeral: '',
            pagina: 1,
            paginacao: 20,
        }
    })

    const { register, handleSubmit, formState: { errors } } = hookForm

    const dispatch = useDispatch();

    const timeoutLista = () => {
        setTimeout(() => {
            dispatch(listarTiposDeVeiculoDAStart({
                pagina: 1,
                paginacao: -1,
                filtros: `tabelaDespachoAutomaticoID.toString() = "${data?.tabelaDespachoAutomaticoID}"`
            }))
        }, 1000)
    }

    const handleSave = () => {
        dispatch(salvarTipoDeVeiculoDAStart(hookForm.getValues()))
        timeoutLista()
    }

    useEffect(() => {
        dispatch(listarTiposDeVeiculoDAStart({
            pagina: 1,
            paginacao: -1,
            filtros: `tabelaDespachoAutomaticoID.toString() = "${data?.tabelaDespachoAutomaticoID}"`
        }))
    }, [response.data, hookForm.watch('pagina'), hookForm.watch('paginacao')]);

    useEffect(() => {
        if (selectedItem) {
            hookForm.setValue('tabelaDespachoAutomaticoTipoVeiculoID', selectedItem.tabelaDespachoAutomaticoTipoVeiculoID || '');
            hookForm.setValue('tipoVeiculoID', selectedItem.tipoVeiculoID || '');
            hookForm.setValue('distanciaMaximaColeta', selectedItem.distanciaMaximaColeta || '');
            hookForm.setValue('distanciaMaximaEntrega', selectedItem.distanciaMaximaEntrega || '');
            hookForm.setValue('tempoPorKm', selectedItem.tempoPorKm || '');
            hookForm.setValue('tempoMinimoColeta', selectedItem.tempoMinimoColeta || '');
            hookForm.setValue('distanciaInicialDespachoGeral', selectedItem.distanciaInicialDespachoGeral || '');
        }
    }, [selectedItem, hookForm]);

    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Button
                variant="containedFilled"
                sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                onClick={() => setShowSelector(true)}
                startIcon={<AddCircleOutline />}
            >
                Adicionar novo tipo de veículo
            </Button>

            {showSelector && (
                <ModalBase open={showSelector} onClose={() => setShowSelector(false)} headerModal={{ title: 'Tipos de veículos' }} size='sm'>
                    <Box p={2}>
                        <form onSubmit={handleSubmit(handleSave)}>
                            <Stack spacing={2} >
                                <TipoDeVeiculo hookForm={hookForm} />
                                <TextField
                                    label="Distância máxima de coleta (metros)"
                                    {...register('distanciaMaximaColeta', { required: 'Distância máxima de coleta é obrigatório' })}
                                    size='small'
                                    error={!!errors.distanciaMaximaColeta}
                                    helperText={errors.distanciaMaximaColeta?.message}
                                    type='number'
                                />

                                <TextField
                                    label="Distância máxima de entrega (metros)"
                                    {...register('distanciaMaximaEntrega', { required: 'Distância máxima de entrega é obrigatório' })}
                                    size='small'
                                    error={!!errors.distanciaMaximaEntrega}
                                    helperText={errors.distanciaMaximaEntrega?.message}
                                    type='number'
                                />

                                <TextField
                                    label="Tempo por km (minutos)"
                                    {...register('tempoPorKm', { required: 'Tempo por km é obrigatório' })}
                                    size='small'
                                    error={!!errors.tempoPorKm}
                                    helperText={errors.tempoPorKm?.message}
                                    type='number'
                                />

                                <TextField
                                    label="Tempo mínimo para coleta após despacho (minutos)"
                                    {...register('tempoMinimoColeta', { required: 'Tempo minimo de coleta é obrigatório' })}
                                    size='small'
                                    error={!!errors.tempoMinimoColeta}
                                    helperText={errors.tempoMinimoColeta?.message}
                                    type='number'
                                />

                                <TextField
                                    label="Distância mínima para despachar no tipo de despacho geral (metros)"
                                    {...register('distanciaInicialDespachoGeral', { required: 'Distância inicial para despacho geral é obrigatório' })}
                                    size='small'
                                    error={!!errors.distanciaInicialDespachoGeral}
                                    helperText={errors.distanciaInicialDespachoGeral?.message}
                                    type='number'
                                />

                                <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="containedFilled"
                                        color='secondary'
                                        sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                                        disabled={loadingSalvar}
                                        type='button'
                                        onClick={() => {
                                            hookForm.reset();
                                            setShowSelector(false)
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="containedFilled"
                                        sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                                        disabled={loadingSalvar}
                                        type='submit'
                                    >
                                        Salvar
                                    </Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Box>
                </ModalBase>
            )
            }

            <Stack sx={{ flex: 1, gap: 2 }}>
                {tiposVeiculo?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={tiposVeiculo.indiceInicial}
                        indiceFinal={tiposVeiculo.indiceFinal}
                        indiceTotal={tiposVeiculo.total}
                        ultimaPagina={tiposVeiculo?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <TableContainer component={Paper} sx={{ width: '100%', overflowY: 'auto', margin: '0 auto' }}>


                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Tipo de veículo</TableCell>
                                <TableCell align="center">Distância máxima de coleta</TableCell>
                                <TableCell align="center">Distância máxima de entrega</TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRowLoading />
                            ) : tiposVeiculo?.lista?.length > 0 ? (
                                tiposVeiculo?.lista.map((item: any) => (
                                    <TableRow key={item.id}>
                                        <TableCell align="center">{item.tipoVeiculo?.nome}</TableCell>
                                        <TableCell align="center">{item.distanciaMaximaColeta}</TableCell>
                                        <TableCell align="center">{item.distanciaMaximaEntrega}</TableCell>
                                        <TableCell align="center">
                                            <ButtonChip
                                                label="Formas de pagamento excluídas"
                                                color="success"
                                                onClick={() => {
                                                    setSelectedItem(item);
                                                    dispatch(listarFormasDePagamentoDAStart({
                                                        paginacao: 20,
                                                        pagina: 1,
                                                        filtros: `tabelaDespachoAutomaticoTipoVeiculoID.toString() == "${item.tabelaDespachoAutomaticoTipoVeiculoID}"`,
                                                    }))
                                                    setShowFormasPagamento(true)
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell align="right">
                                            <IconButton aria-label="edit" size="small">
                                                <Edit color="warning"
                                                    onClick={() => {
                                                        setSelectedItem(item);
                                                        setShowSelector(true)
                                                    }}
                                                />
                                            </IconButton>
                                            <IconButton aria-label="delete" size="small">
                                                <Delete color="error" onClick={() => {
                                                    setSelectedItem(item);
                                                    setOpenDeletar(true)
                                                }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRowNotFound text="Nenhum resultado encontrado." fezRequisicao={true} />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>

            <ModalOpcoes
                descricao={(
                    <Typography>
                        Deseja realmente excluir o tipo de veículo <strong>{selectedItem?.tipoVeiculo?.nome}</strong>?
                    </Typography>
                )}
                acao="Excluir"
                open={openDeletar}
                onClose={() => { setOpenDeletar(false) }}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => setOpenDeletar(false)
                    },
                    {
                        label: 'Excluir',
                        action: () => {
                            dispatch(excluirTipoDeVeiculoDAStart(selectedItem.tabelaDespachoAutomaticoTipoVeiculoID))
                            setOpenDeletar(false)
                            timeoutLista()
                        }
                    }
                ]}
            />
            <ModalFormasDePagamento open={showFormasPagamento} setOpen={setShowFormasPagamento} data={selectedItem} />
        </Stack >
    );
}

export default TiposDeVeiculo