import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    resposta: null,
    loading: false,
    error: null,
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        enviarMensagemRequest: (state, action) => {
            state.loading = true;
        },

        enviarMensagemSuccess: (state, action) => {
            state.loading = false;
            state.resposta = action.payload;
        },

        enviarMensagemFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    enviarMensagemRequest,
    enviarMensagemSuccess,
    enviarMensagemFailure
 } = chatSlice.actions;
export default chatSlice;
