// AutocompleteMultiple.tsx
import { Autocomplete, Chip, SxProps, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';

interface HookForm {
  name: string;
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  defaultValue?: Option[] | null;
}

interface Option {
  [key: string]: any;
}

interface AutocompleteMultipleProps {
  hookForm: HookForm;
  options: Option[];
  labelKey?: string;
  valueKey?: string;
  sx?: SxProps;
  size?: 'small' | 'medium';
  label?: string;
  chipColor?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

/**
 * Componente `AutocompleteMultiple`
 * 
 * Um componente de campo de formulário para seleção múltipla, utilizando `react-hook-form` e o componente `Autocomplete` do Material-UI.
 * Suporta a personalização de chaves para rótulos (`labelKey`) e valores (`valueKey`), além de estilos e validação.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.hookForm - Configuração do React Hook Form.
 * @param {string} props.hookForm.name - Nome do campo no formulário.
 * @param {Object} props.hookForm.methods - Métodos do React Hook Form.
 * @param {Object} [props.hookForm.rules] - Regras de validação do campo.
 * @param {Array.<Option>} [props.hookForm.defaultValue] - Valor padrão do campo.
 * @param {Array.<Option>} props.options - Lista de opções disponíveis para seleção.
 * @param {string} [props.labelKey="label"] - Nome da propriedade que define o rótulo das opções.
 * @param {string} [props.valueKey="value"] - Nome da propriedade que define o valor das opções.
 * @param {Object} [props.sx] - Estilos adicionais aplicados ao componente.
 * @param {'small' | 'medium'} [props.size="small"] - Tamanho do componente.
 * @param {string} [props.label] - Rótulo do campo.
 * @param {'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'} [props.chipColor="default"] - Cor dos chips.
 *
 * @example
 * ```jsx
 * return (
 *   <AutocompleteMultiple
 *     hookForm={{
 *       name: 'tags',
 *       methods,
 *       defaultValue: [],
 *     }}
 *     valueKey="valor"
 *     labelKey="titulo"
 *     options={[
 *       { valor: '1', titulo: 'Opção 1' },
 *       { valor: '2', titulo: 'Opção 2' },
 *       { valor: '3', titulo: 'Opção 3' },
 *     ]}
 *     label="Escolha suas tags"
 *   />
 * );
 * ```
 */
const AutocompleteMultiple: React.FC<AutocompleteMultipleProps> = ({
  hookForm: { name, methods, rules, defaultValue },
  options,
  labelKey = 'label',
  valueKey = 'value',
  size = 'small',
  sx,
  label,
  chipColor = 'default',
}) => {
  const { setValue, control, formState: { errors }, watch } = methods;

  const tags = watch(name) || defaultValue || [];

  useEffect(() => {
    setValue(name, defaultValue || []);
  }, [defaultValue, setValue, name]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          disableCloseOnSelect
          size={size}
          options={options}
          value={tags}
          onChange={(event, newTags) => setValue(name, newTags)}
          getOptionLabel={(option) => option[labelKey] || ''}
          isOptionEqualToValue={(option, value) =>
            option[valueKey] === value[valueKey]
          }
          sx={sx}
          renderTags={(options, getTagProps) =>
            options.map((option: Option, index: number) => (
              <Chip
                {...getTagProps({ index })}
                key={option[valueKey]}
                label={option[labelKey]}
                size={size}
                color={chipColor}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!errors[name]}
              helperText={errors[name]?.message ? String(errors[name]?.message) : ''}
              variant="outlined"
              fullWidth
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteMultiple;
