import axios from 'axios';
import { store } from '../store';

const apiSecret = localStorage.getItem('@api');

const api = axios.create({
    baseURL: apiSecret || 'https://acesso.pickngo.online/',
});

export function isAutenticado() {
    return !!localStorage.getItem('@token');
}

function limparTodosOsCookies() {
    const cookies = document.cookie.split(";");

    cookies.forEach((cookie) => {
        const nome = cookie.split("=")[0].trim();

        document.cookie = nome + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = nome + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=";

        const dominio = window.location.hostname;
        document.cookie = nome + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${dominio}`;
        document.cookie = nome + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=; domain=${dominio}`;
    });
}


export function logout() {
    if (store) {
        store.dispatch({ type: 'RESETAR_STORE' })
    }
    localStorage.clear()
    limparTodosOsCookies()
    window.location.href = '/';
}

api.interceptors.request.use(
    function (config) {
        if (config.url.includes('/Login')
            || config.url.includes('/AssinarV2')
            || config.url.includes('cidades')
            || config.url.includes('estados')
            || config.url.includes('/Utils/ConsultarCNPJ')
            || config.url.includes('/Usuario/ResetarSenhaLogin')
            || config.url.includes('/Home/MasterPage')
            || config.url.includes('/Pick/Template')
        ) {
            return config;
        }

        const token = localStorage.getItem('@token');
        const xpick = localStorage.getItem('@xpick');
        const xwl = window.location.origin.replace(/^https:\/\/|^http:\/\//, '');

        if (token) {
            config.headers['x-token'] = token;
            config.headers['x-pick'] = xpick;
            config.headers['x-wl'] = xwl;
        } else {
            return Promise.reject(new Error("No valid token/xpick found in local storage."));
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export { api };
