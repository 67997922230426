// TextFieldChip.tsx
import React, { useEffect, useState } from 'react';
import { TextField, Chip, Autocomplete, SxProps } from '@mui/material';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name: string;
  defaultValue?: string[] | null;
}

interface TextFieldChipProps {
  hookForm: HookForm;
  sx?: SxProps;
  size?: 'small' | 'medium';
  label?: string;
  chipColor?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

/**
 * `TextFieldChip` é um componente que permite a criação de tags (chips) dinâmicas em um campo de entrada. Ele usa `react-hook-form` para gerenciar o estado do formulário e validação, e o `Autocomplete` do Material-UI para criar um campo de entrada de múltiplas tags.
 * 
 * ## Funcionalidades:
 * - Adicionar tags ao pressionar "Enter" ou "Espaço".
 * - Remover tags clicando no ícone de deletar de cada chip.
 * - Sincronização automática com o estado do formulário usando o `react-hook-form`.
 * - Suporte para valores padrões e validação de campos.
 * 
 * @component
 * @example
 * const { control, handleSubmit, formState: { errors }, setValue } = useForm();
 * 
 * <TextFieldChip
 *   hookForm={{ methods: { control, setValue }, name: 'tags' }}
 *   size="small"
 *   label="Tags"
 *   sx={{ width: '100%' }}
 * />
 * 
 * @param {Object} hookForm - Objeto contendo os métodos do `react-hook-form` e as configurações do campo.
 * @param {UseFormReturn} hookForm.methods - Métodos retornados pelo `useForm` do `react-hook-form`.
 * @param {string} hookForm.name - O nome do campo no formulário.
 * @param {RegisterOptions} [hookForm.rules] - Regras de validação a serem aplicadas ao campo.
 * @param {string[] | null} [hookForm.defaultValue] - Valor padrão das tags no campo.
 * @param {SxProps} [sx] - Propriedades de estilo do Material-UI.
 * @param {'small' | 'medium'} [size='small'] - Tamanho do componente (chip).
 * @param {'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'} [chipColor='primary'] - Cor do chip.
 * @param {string} [label] - Rótulo do campo de entrada.
 * 
 * @returns {JSX.Element} Componente que exibe um campo de entrada com chips (tags) interativos.
 */
const TextFieldChip: React.FC<TextFieldChipProps> = ({
  hookForm: { name, methods, rules, defaultValue },
  sx,
  size = 'small',
  label,
  chipColor = 'default',
}) => {
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const { setValue, control, formState: { errors }, watch } = methods;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === ' ' || e.key === 'Enter') && inputValue.trim() !== '') {
      e.preventDefault();
      addTag(inputValue);
    }
  };

  const handleDelete = (tagToDelete: string) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const addTag = (newTag: string) => {
    const trimmedTag = newTag.trim();
    if (trimmedTag && !tags.includes(trimmedTag)) {
      setTags([...tags, trimmedTag]);
    }
    setInputValue('');
  };

  useEffect(() => {
    setValue(name, tags);
  }, [tags]);

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null) {
      setTags(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          multiple
          size={size}
          freeSolo
          options={[]}
          value={tags}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={(event, newTags) => {
            const validTags = (newTags as string[]).filter(tag => tag.trim() !== '');
            setTags(validTags);
          }}
          sx={sx}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                {...getTagProps({ index })}
                key={option}
                label={option}
                onDelete={() => handleDelete(option)}
                size={size}
                color={chipColor}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!errors[name]}
              helperText={errors[name]?.message ? String(errors[name]?.message) : ''}
              placeholder={tags.length === 0 ? 'Digite e aperte espaço ou enter para adicionar' : ""}
              variant="outlined"
              onKeyDown={handleKeyDown}
              fullWidth
            />
          )}
        />
      )}
    />
  );
};

export default TextFieldChip;
