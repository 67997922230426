import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tokenUsuario: '',
    usuarioID: '',
    usuario: {},
    loading: false,
    error: null,
    freemiumResponse: null,
    cadastroFreemiumResponse: null,
    resposta: null,
    recuperarSenha: {
        loading: false,
        error: null,
        data: null,
    },
    navigation: '',
};

export const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        fetchLoginStart: (state, action) => {
            state.loading = true;
            state.error = null;
            const { email, senha, doisfa } = action.payload;
        },
        forcarLogin: (state, action) => {
            state.tokenUsuario = action.payload.token;
        },
        fetchLoginSuccess: (state, action) => {
            state.tokenUsuario = action.payload.token;
            state.usuarioID = action.payload.usuarioID;
            state.navigation = action.payload.callback;
            state.usuario = action.payload.usuario;
            state.loading = false;
        },
        fetchLoginFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        loginFreemium: (state, action) => {
            state.loading = true;
            state.freemiumResponse = null;
            state.error = null;
        },
        loginFreemiumSuccess: (state, action) => {
            state.loading = false;
            state.freemiumResponse = action.payload;
            state.tokenUsuario = action.payload.token;
            state.usuarioID = action.payload.usuarioID;
            state.usuario = action.payload.usuario;
        },
        loginFreemiumFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.freemiumResponse = null;
        },
        limparLogin: (state) => {
            state.tokenUsuario = '';
            state.usuario = {};
            state.loading = false;
            state.error = null;
        },
        limparError: (state) => {
            state.error = null;
        },
        enviarDadosReq: (state) => {
            state.loading = true;
            state.error = null;
            state.resposta = null;
            state.cadastroFreemiumResponse = null;
        },
        enviarDadosSuccess: (state, action) => {
            state.loading = false;
            state.cadastroFreemiumResponse = action.payload;
        },
        enviarDadosFailure: (state, action) => {
            state.loading = false;
            state.cadastroFreemiumResponse = action.payload;
            state.resposta = null;
        },
        limparLoginFreemium: (state) => {
            state.freemiumResponse = null;
            state.cadastroFreemiumResponse = null;
        },
        recuperarSenhaStart: (state, action) => {
            state.recuperarSenha.loading = true;
            state.recuperarSenha.error = null;
            state.recuperarSenha.data = null;
        },
        recuperarSenhaSuccess: (state, action) => {
            state.recuperarSenha.loading = false;
            state.recuperarSenha.error = null;
            state.recuperarSenha.data = action.payload;
        },
        recuperarSenhaFailure: (state, action) => {
            state.recuperarSenha.loading = false;
            state.recuperarSenha.error = action.payload;
            state.recuperarSenha.data = null;
        },
        limparRecuperarSenha: (state) => {
            state.recuperarSenha.loading = false;
            state.recuperarSenha.error = null;
            state.recuperarSenha.data = null;
        }
    },
});

export const {
    fetchLoginStart,
    forcarLogin,
    fetchLoginSuccess,
    fetchLoginFailure,
    limparLogin,
    limparError,
    loginFreemium,
    loginFreemiumSuccess,
    loginFreemiumFailure,
    enviarDadosReq,
    enviarDadosSuccess,
    enviarDadosFailure,
    limparLoginFreemium,
    recuperarSenhaStart,
    recuperarSenhaSuccess,
    recuperarSenhaFailure,
    limparRecuperarSenha
} = loginSlice.actions;

export default loginSlice;
