import {
    Box,
    Card,
    Stack,
    Tab,
    Tabs,
    Typography,
    useTheme,
    IconButton,
    Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import circleDollar from "../../assets/icons/circle-dollar.svg";
import handCircleDollar from "../../assets/icons/hand-holding-circle-dollar.svg";
import sackDollar from "../../assets/icons/sack-dollar.svg";
import motorcycle from "../../assets/icons/motorcycle.svg";
import TabelaPedidos from "../../components/Pedidos/TabelaPedidos";
import DashboardPedidos from "../../components/Pedidos/DashboardPedidos";
import dayjs from "dayjs";
import { CardFormFiltros, CardFormFiltrosRef, Field } from "../../ds/components/CardFormFiltros";
import { RootState } from "../../store/reducers";
import { dinheiro } from "../../ds/common";
import { listarVeiculosStart } from "src/store/reducers/escalas";
import { BoxTableMinHeight, ListCardTabelaTotal, MenuTabelas, TableNavigation } from "src/ds";
import { listarTiposEmpresas } from "src/store/reducers/empresa";
import { listarTipoDeVeiculoStart } from "src/store/reducers/config";
import { listaEntregadoresStart } from "src/store/reducers/entregador";
import { taxaExtraStart } from "src/store/reducers/taxaExtra";
import { useForm } from "react-hook-form";
import { FiltrosCampo } from "src/dtos/getFiltros";
import { exportarMeusPedidosStart, exportarRelatorioGeralStart } from "src/store/reducers/pedidos";
import { useSnackbarContext } from "src/context/SnackbarContext";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const RelatorioGeral = ({ openDash }: { openDash?: boolean }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { showError } = useSnackbarContext();
    const [activeSection, setActiveSection] = useState(openDash ? 'dashboard' : 'pedidos');
    const [isAllOpen, setIsAllOpen] = useState(false);
    const hookForm = useForm({
        defaultValues: {
            empresaID: '',
            tipoEmpresaID: '',
            paginacao: 20,
            pagina: 1,
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
            considerados: '',
            reentrega: '',
            dtInicialFinalizado: dayjs().subtract(1, 'day').format('DD/MM/YYYY 00:00'),
            dtFinalFinalizado: dayjs().format('DD/MM/YYYY 23:59'),
        }
    });
    const { watch, getValues } = hookForm;
    const cardRef = useRef<CardFormFiltrosRef>(null)

    const tipoUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario);
    const ehRepresentante = tipoUsuario === 'Representante';
    const entregadores = useSelector((state: RootState) => state.entregadores?.listaEntregadores?.data?.dados?.lista);
    const tiposVeiculo = useSelector((state: RootState) => state.config?.tiposDeVeiculos?.dados?.lista);
    const tipoEmpresas = useSelector((state: RootState) => state.empresas.tiposEmpresas?.data?.lista);
    const taxasExtra = useSelector((state: RootState) => state.taxaExtra?.lista?.data?.dados?.lista);
    const formasPagamento = useSelector((state: RootState) => state.config?.formaDePagamento);
    const pedidosDados = useSelector((state: RootState) => state.pedidos?.listaPedidos?.data?.dados);
    const pedidosError = useSelector((state: RootState) => state.pedidos?.listaPedidos?.error);
    const loadingPedidos = useSelector((state: RootState) => state.pedidos?.listaPedidos.loading);
    const meusPedidos = useSelector((state: RootState) => state.pedidos?.meusPedidos?.data?.dados);
    const meusPedidosError = useSelector((state: RootState) => state.pedidos?.meusPedidos.error);
    const loadingMeusPedidos = useSelector((state: RootState) => state.pedidos?.meusPedidos.loading);
    const loadingExportarRelatorioGeral = useSelector((state: RootState) => state.pedidos.exportarRelatorioGeral.loading);
    const loadingExportarMeusPedidos = useSelector((state: RootState) => state.pedidos.exportarMeusPedidos.loading);

    const primeiraRenderizacao = useRef(true);

    useEffect(() => {
        if (primeiraRenderizacao.current === true) {
            dispatch(listarVeiculosStart());
            dispatch(listarTiposEmpresas({
                paginacao: -1,
                pagina: -1,
                ordenacaoCampo: '',
                ordenacaoOrdem: '',
                filtros: ''
            }))
            dispatch(listarTipoDeVeiculoStart({
                paginacao: -1,
                pagina: -1,
                ordenacaoCampo: '',
                ordenacaoOrdem: '',
                filtros: ''
            }))
            dispatch(listaEntregadoresStart({
                paginacao: -1,
                pagina: -1,
                ordenacaoCampo: '',
                ordenacaoOrdem: '',
                filtros: ''
            })
            )
            primeiraRenderizacao.current = false; return

        }
        dispatch(taxaExtraStart({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }))

    }, [primeiraRenderizacao]);

    useEffect(() => {
        if (pedidosError) {
            showError({
                message: pedidosError,
                duration: 5000
            })
        }
    }, [pedidosError])

    const pagina = watch("pagina");
    const paginacao = watch("paginacao");

    useEffect(() => {
        if (ehRepresentante && !watch('empresaID')) return
        cardRef.current?.onSubmit()
    }, [pagina, paginacao]);

    const atualizarDashboard = cardRef.current?.atualizarDispatch ?? false;

    const handleSectionChange = (section: string | null) => {
        if (section === null) return;
        if (section === 'dashboard') {
            cardRef.current && (cardRef.current.atualizarDispatch = true);
        }
        setActiveSection(section);
    }

    useEffect(() => {
        if (meusPedidosError) {
            showError({
                message: meusPedidosError,
                duration: 5000
            });
        }
    }, [meusPedidosError])

    const formFieldsGenerico: Field[] = [
        { name: 'Código do pedido', value: 'codigoExterno' },
        { name: 'Entregador', value: 'entregadorUsuarioID', type: 'customSelect', options: entregadores, valueKey: 'usuarioID', labelKey: 'nome' },
        { name: 'Data inicial (Finalizado)', value: 'dtInicialFinalizado', type: 'dateTime' },
        { name: 'Data final (Finalizado)', value: 'dtFinalFinalizado', type: 'dateTime' },
        //* Apenas Licenciado
        // { 
        //     name: 'Tipo de empresa',
        //     value: 'tipoEmpresaID',
        //     type: 'customSelect',
        //     options: tipoEmpresas,
        //     valueKey: 'tipoEmpresaID',
        //     labelKey: 'nome',
        // },
        { name: 'Data inicial', value: 'dtInicial', type: 'dateTime' },
        { name: 'Data final', value: 'dtFinal', type: 'dateTime' },
        { name: 'Aplicação de origem', value: 'aplicacaoOrigemNome' },
        { name: 'Taxa extra aplicada', value: 'taxaExtraID', type: 'customSelect', options: taxasExtra, valueKey: 'taxaExtraID', labelKey: 'nome' },
        {
            name: 'Tipo de veículo',
            value: 'tipoVeiculoID',
            type: 'customSelect',
            label: 'Tipo de veículo',
            options: tiposVeiculo,
            valueKey: 'tipoVeiculoID',
            labelKey: 'nome',
        },
        { name: 'Forma de pagamento', value: 'formaPagamentoID', type: 'customSelect', options: formasPagamento, valueKey: 'formaPagamentoID', labelKey: 'nome' },
        {
            name: 'Tipo de fatura', value: 'tipoFatura', type: 'select', options: [
                { label: 'Pré-pago', value: 'prePago' },
                { label: 'Pós-pago', value: 'posPago' },
            ]
        },
        { name: 'Empresa', value: 'empresaID', type: 'autocompleteEmpresa' },
        {
            name: 'Tipo de pedido',
            value: 'tipoPedidoCodigo',
            type: 'autocompleteMultiple',
            options: [
                { label: 'Pedido', value: '0' },
                { label: 'Diária', value: '1' },
                { label: 'Vale', value: '2' },
                { label: 'Bônus', value: '3' },
                { label: 'Antecipação', value: '4' },
                { label: 'Administrativo', value: '5' },
                { label: 'Outros', value: '99' },
            ],
            fullWidth: true,
        },
        {
            name: 'Situação',
            value: 'situacao',
            type: 'autocompleteMultiple',
            labelKey: 'label',
            valueKey: 'value',
            options: [
                { label: 'Recebido', value: 'RECEBIDO' },
                { label: 'Pronto', value: 'PRONTO' },
                { label: 'Despachado', value: 'DESPACHADO' },
                { label: 'Aceito', value: 'ACEITO' },
                { label: 'No estabelecimento', value: 'NO_ESTABELECIMENTO' },
                { label: 'Em rota', value: 'EM_ROTA' },
                { label: 'Retornando', value: 'RETORNANDO' },
                { label: 'Finalizado', value: 'FINALIZADO' },
                { label: 'Cancelado', value: 'CANCELADO' },
            ],
            fullWidth: true,
        },
        // { name: 'Tags no pedido', value: 'tagsFiltro' },
        // { name: 'Tags na empresa', value: 'tagsFiltroEmpresas' },
    ]

    const formFieldsRpresentante: Field[] = [
        {
            name: 'Empresa',
            value: 'empresaID',
            type: 'autocompleteEmpresa',
            rules: { required: 'Empresa obrigatória' }
        },
        { name: 'Código do pedido', value: 'codigoExterno', isFiltro: true, tipo: 'TEXTO', igual: true },
        {
            name: 'Entregador',
            value: 'entregadorUsuario.nome',
            isFiltro: true,
            filtroCustomizado(nome, valor) {
                return `${nome}.ToLower().Contains("${valor}")`
            },
            tipo: 'TEXTO'
        },
        {
            name: 'Data inicial (Finalizado)',
            value: 'dtInicialFinalizado',
            type: 'dateTime',
            isFiltro: true,
            tipo: 'DATA_HORA',
            nameFiltro: 'dataFinalizado',
            maiorQue: true,
            igual: true
        },
        {
            name: 'Data final (Finalizado)',
            value: 'dtFinalFinalizado',
            type: 'dateTime',
            isFiltro: true,
            tipo: 'DATA_HORA',
            nameFiltro: 'dataFinalizado',
            menorQue: true
        },
        {
            name: 'Data inicial',
            value: 'dtInicialCadastro',
            type: 'dateTime',
            isFiltro: true,
            tipo: 'DATA_HORA',
            nameFiltro: 'dataCadastro',
            maiorQue: true,
            igual: true
        },
        {
            name: 'Data final',
            value: 'dtFinalCadastro',
            type: 'dateTime',
            isFiltro: true,
            tipo: 'DATA_HORA',
            nameFiltro: 'dataCadastro',
            menorQue: true
        },
        {
            name: 'Tipo',
            value: 'tipoPedidoCodigo',
            type: 'autocompleteMultiple',
            labelKey: 'label',
            valueKey: 'value',
            options: [
                { label: 'Pedido', value: 'PEDIDO' },
                { label: 'Diária', value: 'DIÁRIA' },
                { label: 'Vale', value: 'VALE' },
                { label: 'Bônus', value: 'BÔNUS' },
                { label: 'Antecipação', value: 'ANTECIPAÇÃO' },
                { label: 'Administrativo', value: 'ADMINISTRATIVO' },
                { label: 'Outros', value: 'OUTROS' },
            ],
            isFiltro: true,
            tipo: 'SELECT_MULTIPLE',
        },
        {
            name: 'Situação',
            value: 'situacao',
            type: 'autocompleteMultiple',
            labelKey: 'label',
            valueKey: 'value',
            options: [
                { label: 'Finalizado', value: 'FINALIZADO' },
                { label: 'Cancelado', value: 'CANCELADO' },
            ],
            isFiltro: true,
            tipo: "SELECT_MULTIPLE"
        },
    ]

    const consideradosFiltro: FiltrosCampo = {
        nome: 'entraFatura',
        valor: watch('considerados'),
        filtroCustomizado(_nome, _valor) {
            return `entraFatura`
        },
    }

    const reentregaFiltro: FiltrosCampo = {
        nome: 'reentrega',
        valor: watch('reentrega'),
        filtroCustomizado(_nome, _valor) {
            return `origemPedido != null`
        },
    }

    const payloadExportar = getValues()

    const menuOptions = [
        {
            label: isAllOpen ? "Minimizar todos os pedidos" : "Expandir todos os pedidos",
            action: () => { setIsAllOpen((prev) => !prev) }
        },
        {
            label: 'Exportar',
            action: () => {
                ehRepresentante
                    ? dispatch(exportarMeusPedidosStart(payloadExportar))
                    : dispatch(exportarRelatorioGeralStart(payloadExportar))
            }
        }
    ]

    return (
        <>
            <Box sx={{ p: 2, backgroundColor: theme.palette.background.paperDark }}>
                {ehRepresentante ? (
                    <CardFormFiltros
                        ref={cardRef}
                        fields={formFieldsRpresentante}
                        formMethods={hookForm}
                        reentrega
                        reentregaFiltro={reentregaFiltro}
                        considerados
                        consideradosFiltro={consideradosFiltro}
                        dispatchMethods={{ actionName: 'listarMeusPedidosStart' }}
                        loading={loadingMeusPedidos}
                    />
                ) : (
                    <CardFormFiltros
                        ref={cardRef}
                        fields={formFieldsGenerico}
                        formMethods={hookForm}
                        reentrega
                        considerados
                        solicitadoParceiro
                        alocados
                        dispatchMethods={{ actionName: 'listarPedidosGeral' }}
                        loading={loadingPedidos}
                    />
                )}

                <ListCardTabelaTotal
                    cardData={[{
                        srcIcon: circleDollar,
                        altIcon: ehRepresentante ? 'Ícone Valor Total de Taxas' : "Ícone Valor Total de Cobrado",
                        bgcolor: theme.palette.green[600],
                        texts: ehRepresentante ? {
                            title1: 'VALOR TOTAL DE TAXAS',
                            subtitle1: meusPedidos?.totalCobrado ? dinheiro(meusPedidos?.totalCobrado) : 'R$0,00',
                        } : {
                            title1: 'VALOR TOTAL COBRADO',
                            subtitle1: pedidosDados?.totalCobrado ? dinheiro(pedidosDados?.totalCobrado) : 'R$0,00',
                            title2: 'VALOR MÉDIO POR PEDIDO',
                            subtitle2: pedidosDados?.totalCobrado ? dinheiro((pedidosDados?.totalCobrado / pedidosDados?.total).toFixed(2)) : 'R$0,00',
                        }
                    }, {
                        srcIcon: ehRepresentante ? sackDollar : handCircleDollar,
                        altIcon: ehRepresentante ? 'Ícone Total Faturado' : "Ícone Valor Total de Custo",
                        bgcolor: ehRepresentante ? theme.palette.yellow[600] : theme.palette.red[600],
                        texts: ehRepresentante ? {
                            title1: 'VALOR TOTAL DE VENDAS',
                            subtitle1: meusPedidos?.totalFaturado ? dinheiro(meusPedidos?.totalFaturado) : 'R$0,00',
                        } : {
                            title1: 'VALOR TOTAL DE CUSTO',
                            subtitle1: meusPedidos?.totalCusto ? dinheiro(meusPedidos?.totalCusto) : 'R$0,00',
                            title2: 'VALOR MÉDIO POR PEDIDO',
                            subtitle2: pedidosDados?.totalCusto ? dinheiro((pedidosDados?.totalCusto / pedidosDados?.total).toFixed(2)) : 'R$0,00',
                        }
                    }, {
                        srcIcon: ehRepresentante ? motorcycle : sackDollar,
                        altIcon: ehRepresentante ? 'Ícone Pedidos Finalizados' : "Ícone Valor Total de Vendas",
                        bgcolor: ehRepresentante ? theme.palette.blue[600] : theme.palette.yellow[600],
                        texts: ehRepresentante ? {
                            title1: 'PEDIDOS FINALIZADOS',
                            subtitle1: meusPedidos?.pedidosFinalizados ? meusPedidos?.pedidosFinalizados : '0',
                        } : {
                            title1: 'VALOR TOTAL DE VENDAS',
                            subtitle1: pedidosDados?.totalFaturado ? dinheiro(pedidosDados?.totalFaturado) : 'R$0,00',
                            title2: 'TICKET MÉDIO',
                            subtitle2: pedidosDados?.totalFaturado ? dinheiro((pedidosDados?.totalFaturado / pedidosDados?.total).toFixed(2)) : 'R$0,00',
                        }
                    }, {
                        srcIcon: ehRepresentante ? handCircleDollar : motorcycle,
                        altIcon: ehRepresentante ? 'Ícone Pedidos Cancelados' : "Ícone Valor Total de Pedidos",
                        bgcolor: ehRepresentante ? theme.palette.red[600] : theme.palette.blue[600],
                        texts: ehRepresentante ? {
                            title1: 'PEDIDOS CANCELADOS',
                            subtitle1: meusPedidos?.pedidosCancelados ? meusPedidos.pedidosCancelados : '0',
                        } : {
                            title1: 'PEDIDOS',
                            subtitle1: pedidosDados?.total ? pedidosDados?.total : '0',
                        }
                    }]}
                />

                <Stack sx={{ mt: 4, gap: 2, flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }} direction="row">
                    <Tabs
                        value={activeSection}
                        onChange={(_, value) => handleSectionChange(value)}
                        textColor="inherit"
                        variant="fullWidth"
                    >
                        <Tab label="Pedidos" value="pedidos" />
                        <Tab label="Dashboard" value="dashboard" />
                    </Tabs>
                </Stack>

                <Card elevation={1} sx={{ mt: 2, borderRadius: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, alignItems: 'center' }}>
                        {!!(activeSection === 'pedidos')
                            ? ehRepresentante
                                ? (
                                    meusPedidos?.ultimaPagina ? (
                                        <TableNavigation
                                            indiceInicial={meusPedidos?.indiceInicial}
                                            indiceFinal={meusPedidos?.indiceFinal}
                                            indiceTotal={meusPedidos?.total}
                                            ultimaPagina={meusPedidos.ultimaPagina}
                                            hookForm={hookForm}
                                            requiredFields={['empresaID']}
                                            podeTodos={false}
                                        />
                                    ) : null
                                ) : (pedidosDados?.ultimaPagina ? (
                                    <TableNavigation
                                        indiceInicial={pedidosDados?.indiceInicial}
                                        indiceFinal={pedidosDados?.indiceFinal}
                                        indiceTotal={pedidosDados?.total}
                                        ultimaPagina={pedidosDados.ultimaPagina}
                                        hookForm={hookForm}
                                        requiredFields={['dtInicialFinalizado']}
                                        podeTodos={false}
                                    />
                                ) : null
                                )
                            : null}
                        {((ehRepresentante ? meusPedidos?.lista : pedidosDados?.lista)?.length || 0) > 0 && (
                            <Tooltip arrow placement='top' title={isAllOpen ? "Minimizar todos os pedidos" : "Expandir todos os pedidos"}>
                                <IconButton
                                    color={isAllOpen ? 'secondary' : 'primary'}
                                    onClick={() => { setIsAllOpen((prev) => !prev) }}
                                >
                                    {isAllOpen ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </Tooltip>
                        )}
                        <MenuTabelas options={menuOptions} loading={ehRepresentante ? loadingExportarMeusPedidos : loadingExportarRelatorioGeral} />
                    </Box>
                    {activeSection === 'pedidos'
                        ? (
                            <BoxTableMinHeight>
                                <TabelaPedidos
                                    pedidos={ehRepresentante ? meusPedidos?.lista : pedidosDados?.lista}
                                    loading={ehRepresentante ? loadingMeusPedidos : loadingPedidos}
                                    fezRequisicao={cardRef.current?.fezRequisicao}
                                    tipoUsuario={tipoUsuario}
                                    hookForm={hookForm}
                                    onSubmit={cardRef.current?.onSubmit}
                                    isAllOpen={isAllOpen}
                                />
                            </BoxTableMinHeight>
                        ) : ehRepresentante
                            ? watch('empresaID') === ''
                                ? (
                                    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                                        <Typography variant="notFound" > Selecione uma empresa para visualizar o dashboard. </Typography>
                                    </Box>
                                ) : (
                                    <DashboardPedidos
                                        formMethods={hookForm}
                                        loading={loadingMeusPedidos}
                                        atualizar={atualizarDashboard}
                                        fezRequisicao={cardRef.current?.fezRequisicao}
                                    />
                                )
                            : (
                                <DashboardPedidos
                                    formMethods={hookForm}
                                    loading={loadingPedidos}
                                    atualizar={atualizarDashboard}
                                    fezRequisicao={cardRef.current?.fezRequisicao}
                                />
                            )
                    }
                </Card>
            </Box>
        </>
    );
};

export default RelatorioGeral;
