const converterDataCadastro = (dataCadastroStr?: string | null) => {
  if (!dataCadastroStr) {
    return 0;
  }

  const [datePart, timePart] = dataCadastroStr.split(" ");
  const [day, month, year] = datePart.split("/").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);

  return new Date(year, month - 1, day, hours, minutes, seconds);
};

export default converterDataCadastro;
