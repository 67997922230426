import { TextField, Button, Box, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TipoDeVeiculo from 'src/ds/components/TipoDeVeiculo';
import { BoxLoading, ModalBase } from 'src/ds';
import { dispararPushStart } from 'src/store/reducers/entregador';

const EnviarPush = ({ open, setOpen, entregador }: any) => {
    const hookForm = useForm({});
    const { register, handleSubmit, setValue, watch } = hookForm;
    const dispatch = useDispatch();
    const onSubmit = (data: any) => {
        dispatch(dispararPushStart({
            usuarioID: entregador.usuarioID,
            titulo: watch('title'),
            mensagem: watch('message'),
            situacao: watch('situacao'),
            tipoVeiculoID: watch('tipoVeiculoID')
        }))
        setOpen(false);
    };


    return (
        <ModalBase
            open={open}
            onClose={() => { setOpen(false) }}
            headerModal={{ title: 'Enviar Push' }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDirection="column" gap={2} p={2}>
                    <TextField
                        size="small"
                        fullWidth
                        label="Título"
                        {...register('title')}
                    />
                    <TextField
                        size="small"
                        fullWidth
                        label="Mensagem"
                        {...register('message')}
                        multiline
                        rows={3}
                    />
                    <TipoDeVeiculo hookForm={hookForm} />
                    <FormControl fullWidth>
                        <InputLabel size='small' id="situacao-label">Situação</InputLabel>
                        <Select
                            size='small'
                            labelId="situacao-label"
                            id="situacao"
                            {...register('situacao')}
                            value={watch('situacao') || ''}
                            onChange={(e) => setValue('situacao', e.target.value)}
                            label="Situação"
                        >
                            <MenuItem value="">
                                <em>Selecione...</em>
                            </MenuItem>
                            <MenuItem value="ativa">Ativa</MenuItem>
                            <MenuItem value="inativa">Inativa</MenuItem>
                            <MenuItem value="pendente">Pendente</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        variant="containedFilled"
                        color="primary"
                        sx={{ width: 'min-content', alignSelf: 'flex-end' }}
                    >
                        Enviar
                    </Button>
                </Box>
            </form>
        </ModalBase>
    );
}

export default EnviarPush;
