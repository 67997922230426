import { Box, TextField, Button, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { cleanMoneyInput, replaceDecimalSeparator } from 'src/ds/common';
import { HeaderModal, ModalBase, TextFieldMoney } from 'src/ds';
import { liveMaskMoney, maskDecimal } from 'src/ds';
import { salvarTabelaPorKmStart } from 'src/store/reducers/tabelaPagamento';

interface DadosValores {
    kmInicial: string;
    kmFinal: string;
    valor: string;
    taxaRetorno: string;
    tabelaValorKmID: string;
}

interface ModalEditarKmCalculadoProps {
    open: boolean;
    handleClose: () => void;
    dados?: DadosValores;
}

const ModalEditarKmCalculado = ({ open, handleClose, dados }: ModalEditarKmCalculadoProps) => {
    const hookForm= useForm({
        defaultValues: {
            tabelaValorKmID: '',
            kmInicial: '',
            kmFinal: '',
            valor: '',
            taxaRetorno: '',
        },
    });

    const { register, setValue, handleSubmit, watch, reset, formState: { errors } } = hookForm;

    const dispatch = useDispatch();

    useEffect(() => {
        if (dados) {
            setValue("tabelaValorKmID", dados.tabelaValorKmID);
            setValue("kmInicial", dados.kmInicial);
            setValue("kmFinal", dados.kmFinal);
            setValue("valor", dados.valor);
            setValue("taxaRetorno", dados.taxaRetorno);
        }
    }, [dados, setValue]);

    const onSubmit = (data: DadosValores) => {
        const payload = {
            ...data,
            kmInicial: String(data.kmInicial || '').replace(".", ","),
            kmFinal: String(data.kmFinal || '').replace(".", ","),
            valor: cleanMoneyInput(replaceDecimalSeparator(data.valor)),
            taxaRetorno: cleanMoneyInput(replaceDecimalSeparator(data.taxaRetorno)),
            tabelaValorKmID: data.tabelaValorKmID || '',
        };
        dispatch(salvarTabelaPorKmStart(payload));
        handleClose();
    };

    useEffect(() => {
        if (dados) {
            reset(dados);
        } else {
            reset({
                tabelaValorKmID: '',
                kmInicial: '',
                kmFinal: '',
                valor: '',
                taxaRetorno: '',
            });
        }
    }, [dados, reset]);

    return (
        <ModalBase open={open} onClose={handleClose} aria-labelledby="ModalBase-title" aria-describedby="modal-description" size='sm'>
            <HeaderModal title="Valores por KM Calculado" onClose={handleClose} />
            <Box p={3}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                        <TextField
                            label="KM Inicial"
                            fullWidth
                            size='small'
                            value={maskDecimal(watch("kmInicial") || "")}
                            {...register('kmInicial', { required: "KM Inicial é obrigatório" })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!errors.kmInicial}
                            helperText={errors.kmInicial ? errors.kmInicial.message : ""}
                        />

                        <TextField
                            label="KM Final"
                            size='small'
                            fullWidth
                            value={maskDecimal(watch("kmFinal") || "")}
                            {...register('kmFinal', { required: "KM Final é obrigatório" })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!errors.kmFinal}
                            helperText={errors.kmFinal ? errors.kmFinal.message : ""}
                        />
                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'Valor',
                                defaultValue: hookForm.watch('valor'),
                                rules: {
                                    required: 'Valor é obrigatório',
                                }
                            }}
                            label='Taxa de Retorno'
                        />
                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'taxaRetorno',
                                defaultValue: hookForm.watch('taxaRetorno'),
                                rules: {
                                    required: 'Taxa de Retorno é obrigatório',
                                }
                            }}
                            label='Taxa de Retorno'
                        />
                        

                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="contained" color="secondary" onClick={handleClose} sx={{ width: 'fit-content' }}>
                                Cancelar
                            </Button>
                            <Button variant="containedFilled" color="primary" type="submit" sx={{ width: 'fit-content' }}>
                                Salvar
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </ModalBase>
    );
};

export default ModalEditarKmCalculado;
