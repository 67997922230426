import { Box, Button } from '@mui/material'
import { ContainerBodyModal, ModalBase, useResponsive} from 'src/ds'

const ModalVideos = ({ open, onClose }: any) => {
    const { isMobile } = useResponsive()
    return (
        <ModalBase open={open} onClose={onClose}>
            <ContainerBodyModal sx={{ height: 'min-content', maxHeight: '95vh' }}>
                <iframe
                    style={{
                        aspectRatio: '16/8',
                        width: '100%',
                    }}
                    src={isMobile ? "https://www.youtube.com/embed/k_r5Y60pZfA" : "https://www.youtube.com/embed/6Xgfqp-HaX4"}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button
                        onClick={onClose}
                        variant='containedFilled'
                        sx={{ width: 'fit-content', marginTop: '1rem' }}
                    >
                        Comece a utilizar
                    </Button>
                </Box>
            </ContainerBodyModal>
        </ModalBase>
    )
}

export default ModalVideos