import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DA, DADTOS } from "src/dtos/DADTOS";
import { listarEmpresasDaSuccess } from "./darobo";
import { limparExcluir } from "./taxaExtra";

export interface ParamsDA {
    pagina: number;
    paginacao: number;
    filtros?: string;
    empresaID?: string;
    dtInicial?: string;
    dtFinal?: string;
    empresaClienteID?: string;
    empresaNome?: string;
    dataCadastro?: string;
    nome?: string;
    ordenacaoCampo?: string;
    ordenacaoOrdem?: string;

}

interface State {
    loading: boolean;
    data: DADTOS | null;
    error: string | null;
}

interface DAState {
    listaDa: State;
    salvarDa: State;
    empresasDA: State;
    salvarEmpresaDA: State;
    tiposDeVeiculoDA: State;
    salvarTipoDeVeiculoDA: State;
    formasDePagamentoDA: State;
    salvarFormaDePagamentoDA: State;
    pagina: any;
}

const initialState: DAState = {
    listaDa: {
        loading: false,
        data: null,
        error: null,
    },
    salvarDa: {
        loading: false,
        data: null,
        error: null,
    },
    empresasDA: {
        loading: false,
        data: null,
        error: null,
    },
    salvarEmpresaDA: {
        loading: false,
        data: null,
        error: null
    },
    tiposDeVeiculoDA: {
        loading: false,
        data: null,
        error: null
    },
    salvarTipoDeVeiculoDA: {
        loading: false,
        data: null,
        error: null
    },
    formasDePagamentoDA: {
        loading: false,
        data: null,
        error: null
    },
    salvarFormaDePagamentoDA: {
        loading: false,
        data: null,
        error: null
    },
    pagina: {
        loading: false,
        data: null,
        error: null
    }
};

const despachoAutomaticoSlice = createSlice({
    name: 'despachoAutomatico',
    initialState: initialState,
    reducers: {
        listarDaStart(state, action: PayloadAction<ParamsDA>) {
            state.listaDa.loading = true;
            state.listaDa.error = null;
        },
        listarDaFailure(state, action: PayloadAction<string>) {
            state.listaDa.loading = false;
            state.listaDa.error = action.payload;
        },
        listarDaSuccess(state, action: PayloadAction<DADTOS>) {
            state.listaDa.loading = false;
            state.listaDa.data = action.payload;
        },
        salvarDespachoAutomaticoStart(state, action: PayloadAction<DA>) {
            state.salvarDa.loading = true;
            state.salvarDa.error = null;
        },
        salvarDespachoAutomaticoFailure(state, action: PayloadAction<string>) {
            state.salvarDa.loading = false;
            state.salvarDa.error = action.payload;
        },
        salvarDespachoAutomaticoSuccess(state, action: PayloadAction<any>) {
            state.salvarDa.loading = false;
            state.salvarDa.data = action.payload;
        },
        excluirDespachoAutomaticoStart(state, action: PayloadAction<string>) {
            state.salvarDa.loading = true;
            state.salvarDa.error = null;
        },
        excluirDespachoAutomaticoFailure(state, action: PayloadAction<string>) {
            state.salvarDa.loading = false;
            state.salvarDa.error = action.payload;
        },
        excluirDespachoAutomaticoSuccess(state, action: PayloadAction<any>) {
            state.salvarDa.loading = false;
            state.salvarDa.data = action.payload;
        },
        limparSalvarDa(state) {
            state.salvarDa.loading = false;
            state.salvarDa.data = null;
            state.salvarDa.error = null;
        },
        listarEmpresasDAStart(state, action: PayloadAction<ParamsDA>) {
            state.empresasDA.loading = true;
            state.empresasDA.error = null;
        },
        listarEmpresasDASuccess(state, action: PayloadAction<any>) {
            state.empresasDA.loading = false;
            state.empresasDA.data = action.payload;
        },
        listarEmpresasDAFailure(state, action: PayloadAction<string>) {
            state.empresasDA.loading = false;
            state.empresasDA.error = action.payload;
        },
        salvarEmpresaDAStart(state, action: PayloadAction<any>) {
            state.salvarEmpresaDA.loading = true;
            state.salvarEmpresaDA.error = null;
        },
        salvarEmpresaDASuccess(state, action: PayloadAction<any>) {
            state.salvarEmpresaDA.loading = false;
            state.salvarEmpresaDA.data = action.payload;
        },
        salvarEmpresaDAFailure(state, action: PayloadAction<string>) {
            state.salvarEmpresaDA.loading = false;
            state.salvarEmpresaDA.error = action.payload;
        },
        limparEmpresaDA(state) {
            state.salvarEmpresaDA.loading = false;
            state.salvarEmpresaDA.data = null;
            state.salvarEmpresaDA.error = null;
        },
        excluirEmpresaDAStart(state, action: PayloadAction<any>) {
            state.salvarEmpresaDA.loading = true;
            state.salvarEmpresaDA.error = null;
        },
        excluirEmpresaDAFailure(state, action: PayloadAction<string>) {
            state.salvarEmpresaDA.loading = false;
            state.salvarEmpresaDA.error = action.payload;
        },
        excluirEmpresaDASuccess(state, action: PayloadAction<any>) {
            state.salvarEmpresaDA.loading = false;
            state.salvarEmpresaDA.data = action.payload;
        },
        limparExcluirEmpresaDA(state) {
            state.salvarEmpresaDA.loading = false;
            state.salvarEmpresaDA.data = null;
            state.salvarEmpresaDA.error = null;
        },
        listarTiposDeVeiculoDAStart(state, action: PayloadAction<ParamsDA>) {
            state.tiposDeVeiculoDA.loading = true;
            state.tiposDeVeiculoDA.error = null;
        },
        listarTiposDeVeiculoDASuccess(state, action: PayloadAction<any>) {
            state.tiposDeVeiculoDA.loading = false;
            state.tiposDeVeiculoDA.data = action.payload;
        },
        listarTiposDeVeiculoDAFailure(state, action: PayloadAction<string>) {
            state.tiposDeVeiculoDA.loading = false;
            state.tiposDeVeiculoDA.error = action.payload;
        },
        salvarTipoDeVeiculoDAStart(state, action: PayloadAction<any>) {
            state.salvarTipoDeVeiculoDA.loading = true;
            state.salvarTipoDeVeiculoDA.error = null;
        },
        salvarTipoDeVeiculoDASuccess(state, action: PayloadAction<any>) {
            state.salvarTipoDeVeiculoDA.loading = false;
            state.salvarTipoDeVeiculoDA.data = action.payload;
        },
        salvarTipoDeVeiculoDAFailure(state, action: PayloadAction<string>) {
            state.salvarTipoDeVeiculoDA.loading = false;
            state.salvarTipoDeVeiculoDA.error = action.payload;
        },
        limparTipoDeVeiculoDA(state) {
            state.salvarTipoDeVeiculoDA.loading = false;
            state.salvarTipoDeVeiculoDA.data = null;
            state.salvarTipoDeVeiculoDA.error = null;
        },
        excluirTipoDeVeiculoDAStart(state, action: PayloadAction<any>) {
            state.salvarTipoDeVeiculoDA.loading = true;
            state.salvarTipoDeVeiculoDA.error = null;
        },
        excluirTipoDeVeiculoDAFailure(state, action: PayloadAction<string>) {
            state.salvarTipoDeVeiculoDA.loading = false;
            state.salvarTipoDeVeiculoDA.error = action.payload;
        },
        excluirTipoDeVeiculoDASuccess(state, action: PayloadAction<any>) {
            state.salvarTipoDeVeiculoDA.loading = false;
            state.salvarTipoDeVeiculoDA.data = action.payload;
        },
        listarFormasDePagamentoDAStart(state, action: PayloadAction<ParamsDA>) {
            state.formasDePagamentoDA.loading = true;
            state.formasDePagamentoDA.error = null;
        },
        listarFormasDePagamentoDASuccess(state, action: PayloadAction<any>) {
            state.formasDePagamentoDA.loading = false;
            state.formasDePagamentoDA.data = action.payload;
        },
        listarFormasDePagamentoDAFailure(state, action: PayloadAction<string>) {
            state.formasDePagamentoDA.loading = false;
            state.formasDePagamentoDA.error = action.payload;
        },
        salvarFormaDePagamentoDAStart(state, action: PayloadAction<any>) {
            state.salvarFormaDePagamentoDA.loading = true;
            state.salvarFormaDePagamentoDA.error = null;
        },
        salvarFormaDePagamentoDASuccess(state, action: PayloadAction<any>) {
            state.salvarFormaDePagamentoDA.loading = false;
            state.salvarFormaDePagamentoDA.data = action.payload;
        },
        salvarFormaDePagamentoDAFailure(state, action: PayloadAction<string>) {
            state.salvarFormaDePagamentoDA.loading = false;
            state.salvarFormaDePagamentoDA.error = action.payload;
        },
        limparFormaDePagamentoDA(state) {
            state.salvarFormaDePagamentoDA.loading = false;
            state.salvarFormaDePagamentoDA.data = null;
            state.salvarFormaDePagamentoDA.error = null;
        },
        excluirFormaDePagamentoDAStart(state, action: PayloadAction<any>) {
            state.salvarFormaDePagamentoDA.loading = true;
            state.salvarFormaDePagamentoDA.error = null;
        },
        excluirFormaDePagamentoDAFailure(state, action: PayloadAction<string>) {
            state.salvarFormaDePagamentoDA.loading = false;
            state.salvarFormaDePagamentoDA.error = action.payload;
        },
        excluirFormaDePagamentoDASuccess(state, action: PayloadAction<any>) {
            state.salvarFormaDePagamentoDA.loading = false;
            state.salvarFormaDePagamentoDA.data = action.payload;
        },
        paginaStart(state, action: PayloadAction<any>) {
            state.pagina.loading = true;
            state.pagina.error = null;
        },
        paginaSuccess(state, action: PayloadAction<any>) {
            state.pagina.loading = false;
            state.pagina.data = action.payload;
        },
        paginaFailure(state, action: PayloadAction<string>) {
            state.pagina.loading = false;
            state.pagina.error = action.payload;
        },
    }
});


export const {
    listarDaStart,
    listarDaFailure,
    listarDaSuccess,
    salvarDespachoAutomaticoStart,
    salvarDespachoAutomaticoFailure,
    salvarDespachoAutomaticoSuccess,
    excluirDespachoAutomaticoFailure,
    excluirDespachoAutomaticoStart,
    excluirDespachoAutomaticoSuccess,
    limparSalvarDa,
    limparEmpresaDA,
    listarEmpresasDAStart,
    listarEmpresasDAFailure,
    listarEmpresasDASuccess,
    salvarEmpresaDAFailure,
    salvarEmpresaDAStart,
    salvarEmpresaDASuccess,
    excluirEmpresaDAFailure,
    excluirEmpresaDAStart,
    excluirEmpresaDASuccess,
    limparExcluirEmpresaDA,
    listarTiposDeVeiculoDAStart,
    limparTipoDeVeiculoDA,
    listarTiposDeVeiculoDAFailure,
    listarTiposDeVeiculoDASuccess,
    salvarTipoDeVeiculoDAFailure,
    salvarTipoDeVeiculoDAStart,
    salvarTipoDeVeiculoDASuccess,
    excluirTipoDeVeiculoDAFailure,
    excluirTipoDeVeiculoDAStart,
    excluirTipoDeVeiculoDASuccess,
    listarFormasDePagamentoDAFailure,
    listarFormasDePagamentoDAStart,
    listarFormasDePagamentoDASuccess,
    limparFormaDePagamentoDA,
    salvarFormaDePagamentoDAFailure,
    salvarFormaDePagamentoDAStart,
    salvarFormaDePagamentoDASuccess,
    excluirFormaDePagamentoDAFailure,
    excluirFormaDePagamentoDAStart,
    excluirFormaDePagamentoDASuccess,
    paginaFailure,
    paginaStart,
    paginaSuccess
} = despachoAutomaticoSlice.actions;

export default despachoAutomaticoSlice;
