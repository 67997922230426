import { Button, useTheme } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const CardFooter = ({ pedido, toggleCollapse, collapsed }: { pedido: any, toggleCollapse: () => void, collapsed: boolean }) => {
    const theme = useTheme();
    return(
    < Button onClick={toggleCollapse} sx={{
        position: 'absolute',
        width: '20px',
        height: '20px',
        bottom: -15,
        zIndex: 10000000,
        left: '50%',
        transform: 'translateX(-50%)',
        borderRadius: '50%',
        bgcolor: 'primary.main',
        color: 'white',
        border: `1px solid ${theme.palette.primary.main}`,
        aspectRatio: '1',
        '&:hover': {
            bgcolor: 'primary.dark',
            borderColor: 'primary.dark',
        },
    }}>
        {collapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
    </Button>
)};

export default CardFooter;
