const maskTelefone = (input: string): string => {
    if (!input) return '';
    // Remove todos os caracteres não numéricos
    const cleaned = input.replace(/\D/g, '');

    // Verifica se o número começa com 55 (código do Brasil)
    if (cleaned.startsWith('55')) {
        const countryCode = cleaned.slice(0, 2); // Código do país
        const localNumber = cleaned.slice(2);   // Número local sem o código do país

        // Verifica e formata o número com 9 dígitos
        const match9 = localNumber.match(/^(\d{2})(\d{5})(\d{4})$/);
        // Verifica e formata o número com 8 dígitos
        const match8 = localNumber.match(/^(\d{2})(\d{4})(\d{4})$/);

        if (match9) {
            return `+${countryCode} (${match9[1]}) ${match9[2]}-${match9[3]}`;
        } else if (match8) {
            return `+${countryCode} (${match8[1]}) ${match8[2]}-${match8[3]}`;
        }
    } else {
        // Caso não comece com 55, formata como um número local
        const match9 = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
        const match8 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

        if (match9) {
            return `(${match9[1]}) ${match9[2]}-${match9[3]}`;
        } else if (match8) {
            return `(${match8[1]}) ${match8[2]}-${match8[3]}`;
        }
    }

    return cleaned; // Retorna o número limpo se não se encaixar em nenhum formato
};

export default maskTelefone;
