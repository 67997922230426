import { Box, Typography, TextField, Button, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { cleanInput } from 'src/ds/common';
import { HeaderModal, ModalBase, TextFieldMoney } from 'src/ds';
import { liveMaskMoney, maskDecimal } from 'src/ds';
import { salvarTabelaPorRaioStart } from 'src/store/reducers/tabelaCobranca';

interface DadosValores {
    kmInicial: string;
    kmFinal: string;
    valor: string;
    taxaRetorno: string;
    tabelaCobrancaKmID: string;
    tabelaCobrancaID: string;
}

interface ModalEditarValoresPorRaioProps {
    open: boolean;
    handleClose: () => void;
    dados?: DadosValores;
}

const ModalEditarValoresPorRaio = ({ open, handleClose, dados }: ModalEditarValoresPorRaioProps) => {
    const hookForm = useForm<DadosValores>({
        defaultValues: {
            tabelaCobrancaKmID: '',
            kmInicial: '',
            kmFinal: '',
            valor: '',
            taxaRetorno: '',
            tabelaCobrancaID: '',
        },
    });


    const { register, handleSubmit, reset, setValue, watch } = hookForm
    useEffect(() => {
        if (dados) {
            setValue("tabelaCobrancaKmID", dados.tabelaCobrancaKmID);
            setValue("kmInicial", dados.kmInicial);
            setValue("kmFinal", dados.kmFinal);
            setValue("valor", dados.valor);
            setValue("taxaRetorno", dados.taxaRetorno);
            setValue("tabelaCobrancaID", dados.tabelaCobrancaID);
        }
    }, [dados, setValue]);

    const dispatch = useDispatch();

    const onSubmit = (data: DadosValores) => {
        const payload = {
            ...data,
            kmInicial: String(data.kmInicial || '').replace(".", ","),
            kmFinal: String(data.kmFinal || '').replace(".", ","),
            valor: cleanInput(String(data.valor || '')),
            taxaRetorno: cleanInput(String(data.taxaRetorno || '')),
            tabelaCobrancaKmID: dados?.tabelaCobrancaKmID || '',
            tabelaCobrancaID: dados?.tabelaCobrancaID || '',
        };
        dispatch(salvarTabelaPorRaioStart(payload));
        handleClose();
    };


    useEffect(() => {
        if (dados) {
            reset(dados);
        } else {
            reset({
                tabelaCobrancaKmID: '',
                kmInicial: '',
                kmFinal: '',
                valor: '',
                taxaRetorno: '',
                tabelaCobrancaID: '',
            });
        }
    }, [dados, reset]);

    return (
        <ModalBase open={open} onClose={handleClose} aria-labelledby="ModalBase-title" aria-describedby="modal-description" size='sm'>
            <HeaderModal title="Valores por raio" onClose={handleClose} />
            <Box p={3}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                        <TextField
                            label="KM Inicial"
                            fullWidth
                            size='small'
                            value={maskDecimal(watch("kmInicial") || "")}
                            {...register('kmInicial')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            label="KM Final"
                            size='small'
                            fullWidth
                            value={maskDecimal(watch("kmFinal") || "")}
                            {...register('kmFinal')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'valor',
                                defaultValue: watch('valor'),
                                rules: { required: 'Obrigatório' },
                            }}

                        />

                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'taxaRetorno',
                                defaultValue: watch('taxaRetorno'),
                                rules: { required: 'Obrigatório' },
                            }}

                        />

                        <input type="hidden" {...register('tabelaCobrancaID')} />

                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="contained" color="secondary" onClick={handleClose} sx={{ width: 'fit-content' }}>
                                Cancelar
                            </Button>
                            <Button variant="containedFilled" color="primary" type="submit" sx={{ width: 'fit-content' }}>
                                Salvar
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </ModalBase>
    );
};

export default ModalEditarValoresPorRaio;
