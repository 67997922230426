import { Grid2, styled, Theme } from "@mui/material";

export const GridPainelLateral = styled(Grid2)(({ theme }: { theme: Theme }) => ({
  zIndex: 1001,
  backgroundColor: theme.palette.background.default,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflowY: 'auto',
  height: '100%',
  width: '100%',
  top: 0,
  position: 'absolute',
}));

export const GridColumn = styled(Grid2)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:first-of-type': {
    marginTop: 0,
  },
  '&:last-child': {
    marginBottom: 0,
  },
}));
