import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ModalOpcoes } from 'src/ds'
import { RootState } from 'src/store/reducers';
import { excluirMensagemPadraoStart } from 'src/store/reducers/bots';

interface ModalOpcoesProps {
    open: boolean;
    onClose: () => void;
    pickWppBotMensagemPadraoID: string;
}

const ModalExcluirMsgPadrao: React.FC<ModalOpcoesProps> = ({
    open,
    onClose,
    pickWppBotMensagemPadraoID,
}) => {
    const dispatch = useDispatch();
    const excluirMensagemPadraoStore = useSelector((state: RootState) => state.bots.excluirMensagemPadrao);

    const handleConfirm = () => {
        dispatch(excluirMensagemPadraoStart({ pickWppBotMensagemPadraoID }))
    }

    return (
        <ModalOpcoes
            open={open}
            onClose={onClose}
            acao={`Excluir Mensagem Padrão`}
            descricao={'Você deseja excluir esta mensagem?'}
            opcoes={[
                {
                    label: 'Cancelar',
                    action() { onClose() },
                },
                {
                    label: 'Confirmar',
                    action: handleConfirm,
                    loading: excluirMensagemPadraoStore.loading
                },
            ]}
        />
    )
}

export default ModalExcluirMsgPadrao