import React from 'react'
import { Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ModalOpcoes } from 'src/ds'
import { RootState } from 'src/store/reducers';
import { enviarMensagemTesteStart } from 'src/store/reducers/bots';
import { maskTelefone } from 'src/ds';

interface ModalOpcoesProps {
    open: boolean;
    onClose: () => void;
    pickWppBotMensagemPadraoID: string;
}

interface FormData {
    numeroTeste: string
}

const ModalEnviarMsgTeste: React.FC<ModalOpcoesProps> = ({
    open,
    onClose,
    pickWppBotMensagemPadraoID,
}) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>({
        defaultValues: {
            numeroTeste: '',
        },
        mode: 'onChange',
       
    })
    const mensagemTesteStore = useSelector((state: RootState) => state.bots.enviarMensagemTeste)

    const onSubmit = (data: FormData) => {
        dispatch(enviarMensagemTesteStart({
            numeroTeste: data?.numeroTeste,
            pickWppBotMensagemPadraoID,
        }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maskedValue = maskTelefone(e.target.value);
        setValue('numeroTeste', maskedValue, { shouldValidate: true });
    };

    return (
        <ModalOpcoes
            open={open}
            onClose={onClose}
            acao={`Enviar Mensagem de Teste`}
            descricao={(
                <Stack sx={{ mt: 2 }}>
                    <TextField
                        size='small'
                        label="Número de celular"
                        {...register('numeroTeste', {
                            required: 'Campo obrigatório',
                            minLength: {
                                value: 10,
                                message: 'Telefone inválido',
                            }
                        })}
                        onChange={handleChange}
                        error={!!errors?.numeroTeste}
                        helperText={!!errors?.numeroTeste ? String(errors?.numeroTeste?.message) : ''}
                        slotProps={{
                            htmlInput: {
                                maxLength: 15
                            }
                        }}
                    />
                </Stack>
            )}
            opcoes={[
                {
                    label: 'Cancelar',
                    action() { onClose() },
                },
                {
                    label: 'Enviar',
                    action: handleSubmit(onSubmit),
                    disabled: Object.keys(errors).length > 0,
                    loading: mensagemTesteStore.loading
                },
            ]}
        />
    )
}

export default ModalEnviarMsgTeste