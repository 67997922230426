import { InfoOutlined } from "@mui/icons-material";
import { Box, IconButton, Tooltip, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { limparSalvarTabela, listaTabelaPagamentoStart, salvarTabelaStart } from "src/store/reducers/tabelaPagamento";
import { cleanInput } from "src/ds/common";
import { SelectToggleAtivo, TextFieldMoney, useResponseSnackbar } from "src/ds";
import TipoDeVeiculo from "src/ds/components/TipoDeVeiculo";

interface FormValues {
    tabelaValorID: string;
    nome: string;
    ativo: string;
    valorPontoAdicional: string;
    tipoVeiculoID: string;
}

const DadosBasicos = ({ dados }: any) => {
    const response = useSelector((state: any) => state.tabelaPagamento.salvarTabela);

    useResponseSnackbar([{ response, clearAction: () => { limparSalvarTabela(); } }]);

    const hookForm = useForm<FormValues>({
        defaultValues: {
            tabelaValorID: "",
            nome: "",
            ativo: "",
            valorPontoAdicional: "",
            tipoVeiculoID: "",
        },
    });

    const {
        register,
        control,
        setValue,
        handleSubmit,
        watch,
        formState: { errors },
    } = hookForm;

    useEffect(() => {
        if (dados) {
            setValue("tabelaValorID", dados.tabelaValorID || "");
            setValue("nome", dados.nome || "");
            setValue("ativo", dados.ativo ? "true" : "false");
            setValue("valorPontoAdicional", dados.valorPontoAdicional || "");
            setValue("tipoVeiculoID", dados.tipoVeiculoID || "");
        }
    }, [dados, setValue]);

    const dispatch = useDispatch();
    const onSubmit = (data: FormValues) => {
        dispatch(salvarTabelaStart({
            ...data,
            valorPontoAdicional: cleanInput(data.valorPontoAdicional)
        }));
        setTimeout(() => {
            dispatch(listaTabelaPagamentoStart({
                pagina: 1,
                paginacao: -1,
            }))
        }, 1000)
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component="form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
                size="small"
                fullWidth
                label="Nome"
                {...register("nome", { required: "Nome é obrigatório" })}
                error={!!errors.nome}
                helperText={errors.nome ? errors.nome.message : ""}
                slotProps={{
                    inputLabel: {
                        shrink: !!watch("nome"),
                    }
                }}
            />

            <SelectToggleAtivo
                hookForm={{ methods: hookForm, name: "ativo" }}
                label="Situação"
            />

            <TextFieldMoney
                hookForm={{
                    methods: hookForm,
                    name: 'valorPontoAdicional',
                    defaultValue: hookForm.watch('valorPontoAdicional'),
                    rules: {
                        required: 'Valor por ponto adicional é obrigatório',
                    }
                }}
                label='Valor por ponto adicional'
                slotProps={{
                    input: {
                        endAdornment: (
                            <Tooltip
                                arrow
                                placement="top"
                                title="Valor cobrado quando os pedidos são agrupados pela empresa. O ponto adicional é contabilizado a partir do 2º, o primeiro não é considerado adicional."
                            >
                                <IconButton>
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    }
                }}
            />
            <TipoDeVeiculo hookForm={hookForm} />
            <Button variant="containedFilled" color="primary" sx={{ alignSelf: 'flex-end', width: 'fit-content' }} onClick={handleSubmit(onSubmit)} type="submit">
                Salvar
            </Button>
        </Box>
    );
};

export default DadosBasicos;
