import loginSlice from "./reducers/login";
import configSlice from "./reducers/config";
import criarPedidoSlice from "./reducers/criarPedido";
import rastreioSlice from "./reducers/rastreio";
import utilsSlice from "./reducers/utils";
import chatSlice from "./reducers/chat";
import daRoboSlice from "./reducers/darobo";
import escalasSlice from "./reducers/escalas";
import pedidosSlice from "./reducers/pedidos";
import taxaExtraSlice from "./reducers/taxaExtra";
import tabelaPagamentoSlice from "./reducers/tabelaPagamento";
import tabelaCobrancaSlice from "./reducers/tabelaCobranca";
import entregadoresSlice from "./reducers/entregador";
import clientesSlice from "./reducers/cliente";
import empresasSlice from "./reducers/empresa";
import botsSlice from "./reducers/bots";
import despachoAutomaticoSlice from "./reducers/despachoAutomatico";
import faturasSlice from "./reducers/faturas";

export const actions = {
  ...loginSlice.actions,
  ...configSlice.actions,
  ...criarPedidoSlice.actions,
  ...rastreioSlice.actions,
  ...utilsSlice.actions,
  ...chatSlice.actions,
  ...daRoboSlice.actions,
  ...escalasSlice.actions,
  ...pedidosSlice.actions,
  ...taxaExtraSlice.actions,
  ...tabelaPagamentoSlice.actions,
  ...tabelaCobrancaSlice.actions,
  ...entregadoresSlice.actions,
  ...clientesSlice.actions,
  ...empresasSlice.actions,
  ...botsSlice.actions,
  ...despachoAutomaticoSlice.actions,
  ...faturasSlice.actions
};

export type StoreActionCreator = typeof actions
