// @ts-nocheck
import { Box, Card, Typography } from "@mui/material";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/components/CardFormFiltros";
import { useForm } from "react-hook-form";
import TabelaClientes from "../../components/Clientes/TabelaClientes";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { RootState } from "src/store/reducers";
import { BoxTableMinHeight, TableNavigation } from "src/ds";
import { listaClientesStart } from "src/store/reducers/cliente";

const Clientes = () => {
    const clientesDados = useSelector((state: RootState) => state.clientes.listar?.data);
    const clientes = clientesDados?.lista;
    const loadingClientes = useSelector((state: RootState) => state.clientes.listar?.loading);
    const dispatch = useDispatch();

    const hookForm = useForm({
        defaultValues: {
            filtros: '',
            empresaID: '',
            dtInicial: '',
            dtFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const primeiraRenderizacao = useRef(true);

    const cardRef = useRef<CardFormFiltrosRef>(null)
    const { watch } = hookForm
    useEffect(() => {
        if (primeiraRenderizacao.current === true) {
            cardRef.current?.onSubmit();
            primeiraRenderizacao.current = false;
            return
        }
    }, [primeiraRenderizacao]);

    useEffect(() => {
        dispatch(listaClientesStart({
            ...hookForm.getValues(),
        }));
    }, [watch("paginacao"), watch("pagina")]);

    return (
        <Box >
            <Box sx={{ p: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    formMethods={hookForm}
                    dispatchMethods={{
                        actionName: 'listaClientesStart'
                    }}
                    fields={[
                        { name: "Buscar", value: "filtros" },
                        { name: 'Empresas', value: 'empresaID', type: 'autocompleteEmpresa' },
                    ]}
                    loading={loadingClientes}
                />
            </Box>

            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                </Box>
                {clientesDados?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={clientesDados.indiceInicial}
                        indiceFinal={clientesDados.indiceFinal}
                        indiceTotal={clientesDados.total}
                        ultimaPagina={clientesDados?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <BoxTableMinHeight>
                    <TabelaClientes clientes={clientes} loading={loadingClientes} fezRequisicao={cardRef.current?.fezRequisicao} />
                </BoxTableMinHeight>
            </Card>
        </Box>
    );
};

export default Clientes;
