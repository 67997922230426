import React, { useEffect, useState } from 'react';
import { CircularProgress, Stack, TableCell, TableRow, Typography } from '@mui/material';

const TableRowLoading = () => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
        }, 500);
        return () => clearInterval(interval); // Limpa o intervalo quando o componente desmonta
    }, []);

    return (
        <TableRow>
            <TableCell colSpan={12}>
                <Stack alignItems="center" justifyContent="center" direction="row" sx={{ gap: '.5rem' }}>
                    <CircularProgress size="1rem" />
                    <Typography variant="notFound" sx={{ width: 'fit-content', position: 'relative' }}>
                        Carregando <span style={{ position: 'absolute', right: '0', transform: 'translateX(100%)' }}>{dots}</span>
                    </Typography>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default TableRowLoading;
