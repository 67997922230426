// SelectBotFuncao.tsx
import { CircularProgress, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { listarFuncoesClear, listarFuncoesStart } from 'src/store/reducers/bots';

interface HookForms {
  methods: UseFormReturn<any>;
  name: string;
  rules?: RegisterOptions;
}

interface SelectBotFuncaoProps {
  hookForm: HookForms;
  label?: string;
  size?: 'small' | 'medium';
}

const NoIcon: React.FC = () => <span />;

const SelectBotFuncao = ({ hookForm, label = 'Função', size = 'small' }: SelectBotFuncaoProps) => {
  const dispatch = useDispatch();
  const pickID = useSelector((state: RootState) => state.config.masterPage?.pickSelecionada?.pickID) || '';
  const listaFuncoes = useSelector((state: RootState) => state.bots.listaFuncoes);
  const loading = useSelector((state: RootState) => state.bots.listaFuncoes.loading);

  const { control, formState: { errors } } = hookForm.methods;

  useEffect(() => {
    dispatch(listarFuncoesStart({
      paginacao: -1,
      pagina: 1,
      filtros: '',
      ordenacaoOrdem: '',
      ordenacaoCampo: '',
      id: pickID,
    }))
    return () => { 
      dispatch(listarFuncoesClear());
    };
  }, [pickID, dispatch]);

  return (
    <FormControl
      error={!!errors[hookForm.name]}
      size={size}
    >
      <InputLabel>{label}</InputLabel>
      <Controller
        name={hookForm.name}
        control={control}
        rules={hookForm?.rules}
        render={({ field }) => (
          <Select
            {...field}
            defaultValue={""}
            label={label}
            error={!!errors[hookForm.name]}
            endAdornment={(
              loading ? (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ) : null
            )}
            disabled={loading}
            IconComponent={loading ? NoIcon : undefined}
          >
            <MenuItem value="">
              Selecione...
            </MenuItem>
            {listaFuncoes.data?.dados.lista?.map((funcao) => (
              <MenuItem key={funcao.valor} value={funcao.valor}>
                {funcao.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {!!errors[hookForm.name]
        ? <FormHelperText>{String(errors[hookForm.name]?.message ?? '')}</FormHelperText>
        : null
      }
    </FormControl>
  );
};

export default SelectBotFuncao;
