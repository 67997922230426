import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const BoxScroll = styled(Box)(({ }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowY: 'auto',
    height: '100%',
    width: '100%',
}));

export const BoxLine = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    boxSizing: 'border-box'
}));

export const BoxTableMinHeight = styled(Box)(({ theme }) => ({
    width: '100%',
    minHeight: '60vh',
}))