import { AddCircleOutline, Delete, Edit } from "@mui/icons-material";
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Autocomplete, TextField, Box, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ModalOpcoes, TableNavigation, TableRowNotFound, TableRowLoading, ModalBase, useResponseSnackbar } from "src/ds";
import { RootState } from "src/store/reducers";
import { listaEmpresasStart } from "src/store/reducers/empresa";
import { adicionarEmpresaTabelaStart, deletarEmpresaTabelaStart, limparEmpresaTabela, listarEmpresaTabelaCobrancaStart } from "src/store/reducers/tabelaCobranca";

const EditarEmpresa = ({ dados }: any) => {
    const dispatch = useDispatch();
    const [showSelector, setShowSelector] = useState(false);
    const [selectedEmpresa, setSelectedEmpresa] = useState({ nome: 'Selecione ou digite' });
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [openDeletar, setOpenDeletar] = useState(false);

    const hookForm = useForm({
        defaultValues: {
            paginacao: 20,
            pagina: 1,
            filtros: `tabelaCobrancaID.toString() = "${dados?.tabelaCobrancaID}"`,
        },
    });

    const empresas = useSelector((state: any) => state.tabelaCobranca.empresaTabelaCobranca.data?.dados);
    const empresasDados = empresas?.lista;
    const empresasSelector = useSelector((state: any) => state.empresas.listaEmpresas?.data?.lista);

    const response = useSelector((state: any) => state.tabelaCobranca.adicionarEmpresaTabela);
    const response2 = useSelector((state: any) => state.tabelaCobranca.deletarEmpresaTabela);
    const loading = useSelector((state: RootState) => state.tabelaCobranca.adicionarEmpresaTabela.loading || state.tabelaCobranca.deletarEmpresaTabela.loading || state.tabelaCobranca.empresaTabelaCobranca.loading);

    const loadedRef = useRef({ empresasTabela: false, empresas: false });

    const listarEmpresasTabela = () => {
        const valores = hookForm.getValues();
        dispatch(listarEmpresaTabelaCobrancaStart({
            pagina: valores.pagina,
            paginacao: valores.paginacao,
            filtros: valores.filtros,
        }));
    };

    useResponseSnackbar([
        { response, clearAction: () => dispatch(limparEmpresaTabela()) },
        { response: response2, clearAction: () => dispatch(limparEmpresaTabela()) }
    ]);

    useEffect(() => {
        listarEmpresasTabela();
        loadedRef.current.empresasTabela = true;
    }, [response.data, response2.data, dados?.tabelaCobrancaID]);

    useEffect(() => {
        if (!loadedRef.current.empresas) {
            dispatch(listaEmpresasStart({ pagina: 1, paginacao: 10 }));
            loadedRef.current.empresas = true;
        }
    }, []);

    const handleSaveEmpresa = () => {
        // @ts-ignore
        dispatch(adicionarEmpresaTabelaStart({ empresaID: selectedEmpresa.empresaID, tabelaCobrancaID: dados?.tabelaCobrancaID }));
        setShowSelector(false);
    };

    const handleDeletar = (item: any) => {
        setSelectedItem(item);
        setOpenDeletar(true);
    }
    useEffect(() => {
        const valores = hookForm.getValues();
        dispatch(listarEmpresaTabelaCobrancaStart({
            pagina: hookForm.watch("pagina"),
            paginacao: hookForm.watch("paginacao"),
            filtros: valores.filtros,
        }));
    }, [hookForm.watch("pagina"), hookForm.watch("paginacao")]);
    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Button
                variant="iconeComTextoFilled"
                sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                onClick={() => setShowSelector(true)}
                startIcon={<AddCircleOutline />}
            >
                Adicionar nova empresa
            </Button>
            <ModalBase open={showSelector} onClose={() => setShowSelector(false)} headerModal={{ title: 'Adicionar nova empresa' }} size="sm">
                <Box p={2}>
                    <Stack spacing={2}>
                        <Autocomplete
                            options={empresasSelector || []}
                            getOptionLabel={(option: any) => option.nome}
                            value={selectedEmpresa}
                            //@ts-ignore
                            onChange={(event, newValue) => setSelectedEmpresa(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Selecione uma empresa"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                        />

                        <Button
                            variant="containedFilled"
                            sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                            onClick={handleSaveEmpresa}
                        >
                            Salvar
                        </Button>
                    </Stack>
                </Box>
            </ModalBase>
            <Stack sx={{ flex: 1, gap: 2 }}>
                {empresas?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={empresas.indiceInicial}
                        indiceFinal={empresas.indiceFinal}
                        indiceTotal={empresas.total}
                        ultimaPagina={empresas.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Nome</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowLoading />
                        ) :
                            empresasDados?.length > 0 ? (
                                empresasDados.map((item: any) => (
                                    <TableRow key={item.id}>
                                        <TableCell align="left">{item.empresa?.nome}</TableCell>
                                        <TableCell align="right">
                                            <IconButton aria-label="edit" size="small">
                                                <Edit color="warning" />
                                            </IconButton>
                                            <IconButton aria-label="delete" size="small" onClick={() => handleDeletar(item)}>
                                                <Delete color="error" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRowNotFound text="Nenhum resultado encontrado." fezRequisicao={true} />
                            )}
                    </TableBody>
                </Table>
            </Stack>

            {/* Modal de confirmação de exclusão */}
            <ModalOpcoes
                open={openDeletar}
                onClose={() => setOpenDeletar(false)}
                acao="Remover empresa da tabela"
                descricao={(
                    <Typography>
                        Excluir <Typography component="span" fontWeight="bold">{selectedItem?.empresa?.nome}</Typography> da tabela?
                    </Typography>
                )}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => setOpenDeletar(false)
                    },
                    {
                        label: 'Excluir',
                        action: () => {
                            dispatch(deletarEmpresaTabelaStart(selectedItem.tabelaCobrancaEmpresaID));
                            setOpenDeletar(false);
                        }
                    }
                ]}
            />
        </Stack >
    );
};

export default EditarEmpresa;
