import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Autocomplete,
    TextField,
    Stack,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { listarVeiculosStart } from '../../../store/reducers/escalas';
import { useForm, Controller } from 'react-hook-form';
import { salvarUsuarioReq, limparResposta } from '../../../store/reducers/config';
import { ButtonLoading, ModalBase, SelectBanco, useResponsive} from 'src/ds';
import { RootState } from 'src/store/reducers';
import { Entregador } from 'src/dtos/EntregadoresDTO';
import { dynamicMask } from 'src/ds';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface Props {
    entregador?: any | null;
    showEditar: boolean;
    setShowEditar: Dispatch<SetStateAction<boolean>>;
    atualizar: () => void;
    redefinir: boolean;
}

const EditarEntregador = ({ entregador, showEditar, setShowEditar, atualizar, redefinir }: Props) => {
    const dispatch = useDispatch();
    const tiposDeVeiculo = useSelector((state: RootState) => state.escalas?.listarVeiculos?.data?.dados?.lista);
    const tipoDeUsuario = useSelector((state: RootState) => state.config.tipoDeUsuario);
    const respostaEditar = useSelector((state: RootState) => state.config.salvarUsuario.data);
    const loadingEditar = useSelector((state: RootState) => state.config.salvarUsuario.loading);
    const erroEditar = useSelector((state: RootState) => state.config.salvarUsuario.error);
    const { showSuccess, showError } = useSnackbarContext();

    const [editouEntregador, setEditouEntregador] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showSenhas, setShowSenhas] = useState(false);
    const { isMobile } = useResponsive();

    const hookForm = useForm({
        defaultValues: {
            nome: "",
            email: "",
            telefone: "",
            tipoVeiculoID: "",
            senha: "",
            confirmeASenha: "",
            bancoAgencia: "",
            bancoConta: "",
            chavePix: "",
            chavePixTipo: "",
        },
    });

    const { register, handleSubmit, control, formState: { errors }, reset, watch } = hookForm

    useEffect(() => {
        dispatch(listarVeiculosStart());
    }, [dispatch]);

    useEffect(() => {
        if (entregador) {
            reset(entregador);
        }
    }, [entregador, reset]);

    const handleClose = () => {
        setShowSenhas(false);
        setShowEditar(false);
    };

    const toggleShowPassword = () => { setShowPassword(!showPassword) };

    const toggleShowConfirmPassword = () => { setShowConfirmPassword(!showConfirmPassword) };

    const onSubmit = async (data: any) => {
        dispatch(salvarUsuarioReq(data));
        atualizar();
    };

    useEffect(() => {
        if (showEditar) {
            if (respostaEditar) {
                showSuccess({
                    message: respostaEditar.mensagem,
                    duration: 5000
                });
                setEditouEntregador(true);
                setTimeout(() => {
                    setShowEditar(false);
                }, 100);
            } else if (erroEditar) {
                showError({
                    message: erroEditar,
                    duration: 5000
                });
            }
        }
        return () => {
            dispatch(limparResposta());
        }
    }, [respostaEditar, erroEditar, showEditar, dispatch]);

    return (
        <ModalBase
            open={showEditar}
            onClose={handleClose}
            headerModal={{ title: 'Editar entregador' }}
        >
            <Box>
                <Box sx={{ margin: '1rem 1rem 0 1rem', display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Nome do entregador"
                        {...register("nome", { required: "Nome é obrigatório" })}
                        error={!!errors.nome}
                        helperText={errors.nome?.message}
                        size='small'
                    />
                    <TextField
                        label="E-mail"
                        {...register("email", { required: "E-mail é obrigatório", pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
                        error={!!errors.email}
                        helperText={errors.email ? (errors.email.type === 'pattern' ? 'Formato de e-mail inválido' : errors.email.message) : null}
                        size='small'
                    />
                    <Controller
                        name="tipoVeiculoID"
                        control={control}
                        rules={{ required: "Tipo de veículo é obrigatório" }}
                        render={({ field }) => (
                            <Autocomplete
                                options={tiposDeVeiculo || []}
                                getOptionLabel={(option) => option.nome || ""}
                                isOptionEqualToValue={(option: any, value: any) => option.tipoVeiculoID === value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tipo de veículo"
                                        variant="outlined"
                                        error={!!errors.tipoVeiculoID}
                                        helperText={errors.tipoVeiculoID?.message}
                                        size="small"
                                    />
                                )}
                                value={tiposDeVeiculo?.find(v => v.tipoVeiculoID === field.value) || null}
                                onChange={(_, value) => {
                                    field.onChange(value ? value.tipoVeiculoID : "");
                                }}
                            />
                        )}
                    />

                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel>Tipo chave PIX</InputLabel>
                            <Controller
                                name="chavePixTipo"
                                control={control}
                                render={({ field }) => (
                                    <Select {...field} label="Tipo chave PIX" size="small">
                                        <MenuItem value="1">CPF/CNPJ</MenuItem>
                                        <MenuItem value="2">Celular</MenuItem>
                                        <MenuItem value="3">Email</MenuItem>
                                        <MenuItem value="4">Chave aleatória</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Box>

                    {tipoDeUsuario === 'SAAS' &&
                        <TextField
                            label="Chave pix"
                            {...register("chavePix")}
                            error={!!errors.chavePix}
                            helperText={errors.chavePix?.message}
                            size='small'
                        />
                    }

                    {showSenhas && <TextField
                        label="Senha"
                        type={showPassword ? 'text' : 'password'}
                        {...register("senha")}
                        error={!!errors.senha}
                        size='small'
                        helperText={errors.senha?.message}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }
                        }}
                    />}

                    {showSenhas && <TextField
                        label="Confirme a senha"
                        type={showConfirmPassword ? 'text' : 'password'}
                        {...register("confirmeASenha", {
                            validate: (value) => value === watch('senha') || "As senhas não correspondem"
                        })}
                        error={!!errors.confirmeASenha}
                        helperText={errors.confirmeASenha?.message}
                        size='small'
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={toggleShowConfirmPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }
                        }}
                    />}

                </Box>
                <Stack
                    useFlexGap
                    sx={{
                        gap: 2,
                        justifyContent: 'flex-end',
                        p: 2,
                        flexWrap: 'wrap',
                        flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    {(redefinir && !showSenhas) &&
                        <Button
                            sx={{ width: isMobile ? '100%' : 'fit-content' }}
                            variant="containedFilled"
                            onClick={() => setShowSenhas(true)}
                            color='secondary'
                        >
                            Redefinir senha
                        </Button>
                    }

                    <Button
                        variant="containedFilled"
                        onClick={handleClose}
                        color="grey"
                        sx={{ width: isMobile ? '100%' : 'fit-content' }}
                    >
                        Cancelar
                    </Button>

                    <ButtonLoading
                        loading={editouEntregador || loadingEditar}
                        variant="containedFilled"
                        onClick={handleSubmit(onSubmit)}
                        sx={{ width: isMobile ? '100%' : 'fit-content' }}
                    >
                        Salvar
                    </ButtonLoading>
                </Stack>
            </Box>
        </ModalBase>
    );
};

export default EditarEntregador;
