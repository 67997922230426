import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid2, TextField, Box, Autocomplete } from '@mui/material';
import dayjs from 'dayjs';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useDispatch, useSelector } from 'react-redux';
import { adicionarFinanceiroStart, limparAdicionarFinanceiro, listarEmpresasPick } from '../../../store/reducers/config';
import { RootState } from 'src/store/reducers';
import { AutocompleteEmpresas, ButtonLoading, GridPainelLateral, HeaderCard, InputDateTime, TextFieldWithButton } from 'src/ds';
import ModalPesquisarEntregador from 'src/components/common/ModalPesquisarEntregador';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface FinanceiroProps {
    close: () => void
}

interface FormValues {
    codigoExterno: string;
    empresaID: string;
    entregadorUsuarioID: string;
    entregador: {
        nome: string
    };
    dataReferencia: string;
    dataFinalizado: string;
    taxaTotalCobrada: string;
    taxaTotalEntregador: string;
    detalhes: string;
}

export const Financeiro = ({ close }: FinanceiroProps) => {
    const financeiroState = useSelector((state: RootState) => state.config.adicionarFinanceiro);
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext();
    const [criouFinanceiro, setCriouFinanceiro] = useState(false);
    const empresas = useSelector((state: RootState) => state.config.empresasPick?.dados?.lista);

    useEffect(() => {
        dispatch(limparAdicionarFinanceiro())
        return () => { dispatch(limparAdicionarFinanceiro()) }
    }, []);

    useEffect(() => {
        if (!financeiroState.data && !financeiroState.error) return
        if (financeiroState.data?.sucesso) {
            showSuccess({
                message: financeiroState.data.mensagem,
                duration: 5000
            });

            setCriouFinanceiro(true)
            setTimeout(() => {
                close();
            }, 500)
        } else if (financeiroState.error) {
            showError({
                message: financeiroState.error,
                duration: 5000
            });
        }
        dispatch(limparAdicionarFinanceiro())
    }, [financeiroState.data, financeiroState.error]);

    const hookForm = useForm<FormValues>({
        defaultValues: {
            codigoExterno: '',
            empresaID: '',
            entregadorUsuarioID: '',
            entregador: { nome: '' },
            dataReferencia: dayjs().hour(9).minute(0).format('DD/MM/YYYY HH:mm'),
            dataFinalizado: dayjs().hour(9).minute(0).format('DD/MM/YYYY HH:mm'),
            taxaTotalCobrada: '0',
            taxaTotalEntregador: '0',
            detalhes: '',
        }
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors }
    } = hookForm;

    const [modalEntregadoresOpen, setModalEntregadoresOpen] = useState(false);
    const [entregadorSelecionado, setEntregadorSelecionado] = useState(null);

    const taxaTotalCobrada = watch('taxaTotalCobrada');
    const taxaTotalEntregador = watch('taxaTotalEntregador');

    const formatToCurrency = (value: string) => {
        const stringValue = String(value);
        const isNegative = stringValue.startsWith('-');
        const cleanValue = stringValue.replace(/\D/g, '');

        if (!cleanValue) return '';
        const numberValue = (parseInt(cleanValue, 10) / 100).toFixed(2);
        return (isNegative ? '-' : '') + numberValue.replace('.', ',');
    };


    useEffect(() => {
        setValue('taxaTotalCobrada', formatToCurrency(taxaTotalCobrada))
        setValue('taxaTotalEntregador', formatToCurrency(taxaTotalEntregador))
    }, [taxaTotalCobrada, taxaTotalEntregador]);

    const selecionarEntregador = (entregador: any) => {
        setEntregadorSelecionado(entregador);
    };

    useEffect(() => {
        if (empresas.length > 0) return

        dispatch(listarEmpresasPick({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '',
            filtrarPorTags: true
        }))
    }, []);

    useEffect(() => {
        if (entregadorSelecionado) {
            setValue('entregador', entregadorSelecionado);
            //@ts-expect-error
            setValue('entregadorUsuarioID', entregadorSelecionado?.id);
        }
    }, [entregadorSelecionado, setValue]);

    const onSubmit = (data: FormValues) => {
        const normalizedCodigoExterno = data.codigoExterno
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');

        const dataToSubmit = {
            ...data,
            codigoExterno: normalizedCodigoExterno,
        };

        //@ts-expect-error
        delete dataToSubmit.entregador;

        //@ts-expect-error
        dispatch(adicionarFinanceiroStart({ ...dataToSubmit }));
    };

    const codigoExternoOptions = [
        { id: 'diaria', nome: 'Diária' },
        { id: 'vale', nome: 'Vale (ocorrência)' },
        { id: 'bonus', nome: 'Bônus' },
        { id: 'antecipacao', nome: 'Antecipação' },
        { id: 'administrativo', nome: 'Administrativo' }
    ]

    return (
        <>
            <GridPainelLateral sx={{ minHeight: '100%' }}>
                <Box sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column' }} >
                    <HeaderCard title="Financeiro" onClick={close} />
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{
                            flex: '1',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Grid2 container spacing={2}>
                            {/* Tipo Financeiro */}
                            <Grid2 size={{ xs: 12 }}>
                                <Controller
                                    name="codigoExterno"
                                    control={control}
                                    rules={{ required: 'Tipo Financeiro obrigatório' }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            size='small'
                                            options={codigoExternoOptions}
                                            getOptionLabel={(option) => option.nome}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            value={
                                                field.value
                                                    ? { id: field.value, nome: codigoExternoOptions.find(opt => opt.id === field.value)?.nome || '' }
                                                    : null
                                            }
                                            onChange={(_, newValue) => field.onChange(newValue ? newValue.id : '')}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Tipo Financeiro"
                                                    error={!!errors.codigoExterno}
                                                    helperText={errors.codigoExterno ? errors.codigoExterno.message : ''}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid2>

                            {/* Empresa */}
                            <Grid2 size={{ xs: 12 }}>
                                <AutocompleteEmpresas
                                    hookForm={{
                                        methods: hookForm,
                                        name: 'empresaID',
                                        rules: { required: 'Empresa obrigatória' },
                                    }}
                                    sx={{ width: '100%' }}
                                    label="Empresa"
                                    size="small"
                                />
                            </Grid2>

                            {/* Entregador */}
                            <Grid2 size={{ xs: 12 }}>
                                <Controller
                                    name="entregador.nome"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldWithButton
                                            size="small"
                                            label="Entregador"
                                            {...field}
                                            value={field.value || ''}
                                            slotProps={{ htmlInput: { readOnly: true } }}
                                            buttonProps={{
                                                variant: 'containedFilled',
                                                icon: <SearchRoundedIcon />,
                                                onClick: () => setModalEntregadoresOpen(true),
                                            }}
                                        />
                                    )}
                                />
                            </Grid2>

                            {/* Data de Referência */}
                            <Grid2 size={{ xs: 12 }}>
                                <InputDateTime
                                    hookForm={{
                                        name: 'dataReferencia',
                                        methods: hookForm,
                                        rules: { required: 'Data de Referência obrigatório' },
                                    }}
                                    label='Data de Referência'
                                    sx={{ width: '100%' }}
                                />
                            </Grid2>

                            {/* Taxa Total Cobrada */}
                            <Grid2 size={{ xs: 6 }}>
                                <TextField
                                    label="Taxa total cobrada"
                                    fullWidth
                                    value={watch("taxaTotalCobrada")}
                                    {...register("taxaTotalCobrada", {
                                        required: "Campo obrigatório"
                                    })}
                                    size="small"
                                    error={!!errors.taxaTotalCobrada}
                                    helperText={errors.taxaTotalCobrada && errors.taxaTotalCobrada?.message}
                                />
                            </Grid2>

                            {/* Taxa Total a Pagar */}
                            <Grid2 size={{ xs: 6 }}>
                                <TextField
                                    label="Taxa total a pagar"
                                    fullWidth
                                    value={watch("taxaTotalEntregador")}
                                    {...register("taxaTotalEntregador", {
                                        required: "Campo obrigatório"
                                    })}
                                    size="small"
                                    error={!!errors.taxaTotalEntregador}
                                    helperText={errors.taxaTotalEntregador && errors.taxaTotalEntregador?.message}
                                />
                            </Grid2>

                            {/* Descrição */}
                            <Grid2 size={{ xs: 12 }}>
                                <Controller
                                    name="detalhes"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Descrição"
                                            fullWidth
                                            multiline
                                            size="small"
                                            rows={4}
                                            error={!!errors.detalhes}
                                            helperText={errors.detalhes?.message}
                                        />
                                    )}
                                />
                            </Grid2>

                            {/* Botão de Submissão */}
                        </Grid2>
                        <ButtonLoading
                            type="submit"
                            variant="containedFilled"
                            color="primary"
                            loading={financeiroState.loading}
                            disabled={criouFinanceiro}
                        >
                            Registrar financeiro
                        </ButtonLoading>
                    </form>
                </Box>
                <ModalPesquisarEntregador
                    modalProps={{
                        open: modalEntregadoresOpen,
                        onClose: () => setModalEntregadoresOpen(false),
                    }}
                    callback={selecionarEntregador}
                />
            </GridPainelLateral>
        </>
    );
};

export default Financeiro;
