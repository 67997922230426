// TextFieldDistance.tsx
import React, { useEffect, useState, useCallback } from 'react';
import { InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';

/**
 * Formats a numeric distance value according to Brazilian locale standards, with two decimal places.
 * @param {number} value - The distance value to format.
 * @returns {string} - The formatted distance as a string (e.g., "1.234,56").
 */
function formatDistance(value: number): string {
  return `${value.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}

/**
 * Parses a string representing a distance into a number, handling removal of non-numeric characters,
 * converting to a floating-point number with two decimal places.
 * @param {string} value - The raw distance string to parse.
 * @returns {number} - The parsed distance as a float (e.g., "12345" -> 123.45).
 */
function parseToDistance(value: string): number {
  const cleanedValue = Number(value.replace(/[^0-9]/g, ""));
  return parseFloat((cleanedValue / 100).toFixed(2));
}

/**
 * Applies a live masking format to a distance value, optionally formatting based on input state.
 * Converts the input value (string or number) to a locale-formatted string.
 * @param {string | number} value - The value to mask (either as a string or number).
 * @param {boolean} [onInput=true] - Whether the function is being called during user input.
 * @returns {string} - The masked and formatted string representation of the distance.
 */
function liveMaskDistance(value: string | number, onInput: boolean = true): string {
  if (value === null || value === undefined) return "";

  let numericValue: number;
  if (onInput) {
    numericValue = typeof value === "string" ? parseToDistance(value) : value;
  } else {
    numericValue = typeof value === "number" ? value : Number(value.replace(/[^0-9.,]/g, "").replace(".", "").replace(",", "."));
  }

  return formatDistance(numericValue);
}

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name: string;
  defaultValue?: string | number | null;
}

interface TextFieldDistanceProps {
  hookForm: HookForm;
  label?: string;
  size?: 'small' | 'medium';
  sx?: SxProps;
  slotProps?: TextFieldProps['slotProps'];
}

/**
 * Componente `TextFieldDistance`
 * 
 * Um campo de entrada controlado e mascarado para valores de distância (km), integrado ao React Hook Form.
 * Esse componente exibe o valor formatado em tempo real enquanto o usuário digita, seguindo o padrão
 * brasileiro de formatação numérica.
 * 
 * @component
 * 
 * @param {Object} props - Propriedades do componente
 * @param {HookForm} props.hookForm - Objeto contendo os métodos e propriedades do React Hook Form
 * @param {string} [props.label] - Texto exibido como rótulo do campo de entrada
 * @param {'small' | 'medium'} [props.size='small'] - Tamanho do campo de entrada
 * @param {SxProps} [props.sx] - Propriedades de estilo do Material-UI para estilização adicional do campo
 * @param {TextFieldProps['slotProps']} [props.slotProps] - Propriedades extras para os slots do TextField
 * 
 * @example
 * // Exemplo de uso do TextFieldDistance com React Hook Form
 * <TextFieldDistance
 *    hookForm={{ 
 *      methods,
 *      name: 'distance',
 *       defaultValue: '1.234,56',
 *    }}
 *    label="Distância"
 * />
 * 
 * @returns {React.ReactElement} Um campo de entrada controlado com máscara para valores de distância
 */
const TextFieldDistance: React.FC<TextFieldDistanceProps> = ({
  size = 'small',
  sx,
  label,
  hookForm: { methods, rules, name, defaultValue },
  slotProps
}) => {
  const { setValue, formState: { errors }, watch, control } = methods;
  const [displayValue, setDisplayValue] = useState<string>('');

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null) {
      const formattedValue = liveMaskDistance(defaultValue, false);
      setDisplayValue(formattedValue);

      const newValue = parseToDistance(formattedValue)
        .toFixed(2)
        .replace(".", ",");

      setTimeout(() => setValue(name, newValue, { shouldValidate: true }), 0);
    }
  }, [defaultValue, name, setValue]);

  const inputValue = watch(name);
  useEffect(() => {
    if (inputValue) {
      const formattedValue = liveMaskDistance(inputValue);
      setDisplayValue(formattedValue);
    }
  }, [inputValue]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const numericValue = parseToDistance(rawValue);
    const stringValue = numericValue
      .toFixed(2)
      .replace(".", ",");
    setValue(name, stringValue, { shouldValidate: true });
  }, [name, setValue]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          value={displayValue}
          onChange={handleChange}
          error={!!errors[name]}
          helperText={errors[name] ? String(errors[name]?.message ?? '') : ''}
          size={size}
          sx={sx}
          slotProps={slotProps}
        />
      )}
    />
  );
};

export default TextFieldDistance;
