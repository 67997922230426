import { useEffect, useRef } from 'react'
import { CardFormFiltros, CardFormFiltrosRef } from '../../ds/components/CardFormFiltros'
import { Box, Card } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/reducers'
import TabelaTaxaExtra from '../../components/TaxaExtra/TabelaTaxaExtra'
import { BoxTableMinHeight, MenuTabelas, TableNavigation } from 'src/ds'
import { useNavigate } from 'react-router-dom'
import { taxaExtraStart } from 'src/store/reducers/taxaExtra'

const TaxaExtra = () => {
    const taxaExtra = useSelector((state: RootState) => state.taxaExtra.lista)

    const hookForm = useForm({
        defaultValues: {
            paginacao: 20,
            pagina: 1,
        }
    })

    const primeiraRenderizacao = useRef(true);
    const cardRef = useRef<CardFormFiltrosRef>(null)
    const { watch } = hookForm
    const dispatch = useDispatch();

    useEffect(() => {
        if (primeiraRenderizacao.current) {
            primeiraRenderizacao.current = false;
            console.log('primeira renderizacao', primeiraRenderizacao)
            cardRef.current?.onSubmit();
            return;
        }
    }, [watch]);

    useEffect(() => {
        dispatch(taxaExtraStart({
            ...hookForm.getValues(),
        }));
    }, [watch("paginacao"), watch("pagina")]);

    const navigate = useNavigate();
    const optionsMenu = [
        {
            label: 'Inserir',
            action: () => navigate('/TaxaExtra/Index/Cadastro'),
        },
    ];
    return (
        <Box >
            <Box sx={{ m: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    fields={[
                        { name: 'nome', value: 'nome', type: 'input', label: 'Busca Por Nome', isFiltro: true, tipo: 'TEXTO' },
                    ]}

                    formMethods={hookForm}
                    dispatchMethods={{
                        actionName: 'taxaExtraStart',
                    }}
                    loading={taxaExtra.loading}
                />
            </Box>
            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, alignItems: "center" }}>
                    {taxaExtra.data?.dados &&
                        <TableNavigation
                            indiceInicial={taxaExtra.data.dados.indiceInicial}
                            indiceFinal={taxaExtra.data.dados.indiceFinal}
                            indiceTotal={taxaExtra.data.dados.total}
                            ultimaPagina={taxaExtra.data.dados.ultimaPagina}
                            hookForm={hookForm}
                        />
                    }
                    <MenuTabelas options={optionsMenu} />
                </Box>
                <BoxTableMinHeight>
                    <TabelaTaxaExtra data={taxaExtra.data?.dados?.lista} loading={taxaExtra.loading} fezRequisicao={cardRef.current?.fezRequisicao} atualizar={cardRef.current?.onSubmit} />
                </BoxTableMinHeight>
            </Card>
        </Box>
    )
}

export default TaxaExtra