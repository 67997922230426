import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, Button, FormControl, MenuItem, InputLabel, Select, Typography } from '@mui/material';
import TipoDeVeiculo from 'src/ds/components/TipoDeVeiculo';
import { usuarioPaginaStart } from 'src/store/reducers/entregador';
import { AvatarWithModal, InputDateTime, MapWithMarker, SelectBanco, SelectCidade, SelectEstado, ContainerLoading, useResponseSnackbar } from 'src/ds';
import { limparSalvarUsuario, salvarUsuarioReq } from 'src/store/reducers/config';
import { dynamicMask } from 'src/ds';


type FormData = {
    id: string;
    email: string;
    estadoID: number;
    cidadeID: number;
    dataEntregadorSolicitacao: string;
    dataEntregadorResposta: string;
    entregadorResposta: string;
    codigoIndicacao: string;
    pontuacaoTotal: number;
    cnh: string;
    contatoEmergencia: string;
    tipoVeiculoID: string;
    originalTipoVeiculoID: string;
    veiculoAno: number;
    veiculoModelo: string;
    veiculoPlaca: string;
    cpf: string;
    bancoID: string;
    bancoAgencia: string;
    bancoConta: string;
    chavePixTipo: string;
    chavePix: string;
    online: boolean;
    dataOnline: string;
    dataUltimaLocalizacao: string;
    cnhArquivo?: File | null;
    veiculoArquivo?: File;
    veiculoClrvArquivo?: File;
    ultimaLocalizacaoLatitude?: string;
    ultimaLocalizacaoLongitude?: string;
};

const Info = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const entregador = useSelector((state: any) => state.entregadores?.usuarioPagina?.data?.modelo);
    const loading = useSelector((state: any) => state.entregadores?.usuarioPagina?.loading);
    const response = useSelector((state: any) => state.config?.salvarUsuario);

    useResponseSnackbar([{ response, clearAction: () => dispatch(limparSalvarUsuario()) }]);

    useEffect(() => {
        dispatch(usuarioPaginaStart({ id: id }));
    }, []);
    const hookForm = useForm<FormData>({
        defaultValues: {
            id: '',
            email: '',
            estadoID: 7,
            cidadeID: 755,
            dataEntregadorSolicitacao: '2023-11-27T15:00',
            dataEntregadorResposta: '2023-11-27T15:00',
            entregadorResposta: '',
            codigoIndicacao: 'ENTREGAD0401',
            pontuacaoTotal: 0,
            cnh: 'asd',
            contatoEmergencia: '',
            tipoVeiculoID: '3066562b-4635-ee11-ac1d-0e7a8e0c48a3',
            originalTipoVeiculoID: '3066562b-4635-ee11-ac1d-0e7a8e0c48a3',
            veiculoAno: 1997,
            veiculoModelo: 'bizinha',
            veiculoPlaca: '',
            cpf: '02558511114',
            bancoID: 'dd19f862-a3b3-ec11-ab22-021270c809aa',
            bancoAgencia: '3932',
            bancoConta: '025582',
            chavePixTipo: '1',
            chavePix: '02558511114',
            online: true,
            dataOnline: '2025-05-15T14:22:10',
            dataUltimaLocalizacao: '2024-12-19T14:34:04',
            ultimaLocalizacaoLatitude: '',
            ultimaLocalizacaoLongitude: '',
            cnhArquivo: null,
            veiculoArquivo: undefined,
            veiculoClrvArquivo: undefined,
        },
    });

    const { register, control, handleSubmit, setValue, watch } = hookForm;

    useEffect(() => {
        if (entregador) {
            Object.keys(entregador).forEach((key: any) => {
                setValue(key as keyof FormData, entregador[key as keyof typeof entregador]);
            });
        }
    }, [entregador, setValue]);
    const onSubmit = (data: any) => {
        const cleanedData = {
            ...data,
        };

        const formData = new FormData();

        Object.keys(cleanedData).forEach((key) => {
            if (
                key !== 'cnhArquivo' &&
                key !== 'veiculoArquivo' &&
                key !== 'veiculoClrvArquivo' &&
                typeof cleanedData[key] !== 'object'
            ) {
                formData.append(key, cleanedData[key]?.toString() || '');
            }
        });

        if (data.cnhArquivo && data.cnhArquivo[0] instanceof File) {
            formData.append('cnhArquivo', data.cnhArquivo[0]);
        }

        if (data.veiculoArquivo && data.veiculoArquivo[0] instanceof File) {
            formData.append('veiculoArquivo', data.veiculoArquivo[0]);
        }

        if (data.veiculoClrvArquivo && data.veiculoClrvArquivo[0] instanceof File) {
            formData.append('veiculoClrvArquivo', data.veiculoClrvArquivo[0]);
        }

        // @ts-ignore
        dispatch(salvarUsuarioReq(formData));
    };

    if (loading) {
        return <ContainerLoading />;
    }

    const cnhArquivoUrl = entregador?.cnhUrl;
    const veiculoArquivoUrl = entregador?.veiculoArquivoUrl;
    const crlvArquivoUrl = entregador?.veiculoClrvArquivoUrl;

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={(theme) => ({ display: 'flex', gap: 2, flexDirection: 'column', p: 2, backgroundColor: theme.palette.background.paper })}
        >
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="subtitle1">Dados de Entregador</Typography>
                    <SelectEstado hookForm={{ methods: hookForm }} />
                    <SelectCidade hookForm={{ methods: hookForm }} />
                    <InputDateTime
                        hookForm={{
                            name: 'dataEntregadorSolicitacao',
                            methods: hookForm
                        }}
                        label='Data de solicitação'
                    />
                    <InputDateTime
                        hookForm={{
                            name: 'dataEntregadorResposta',
                            methods: hookForm
                        }}
                        label='Data de resposta'
                    />
                    <TextField fullWidth multiline rows={4} label="Resposta" {...register('entregadorResposta')} />
                    <TextField size="small" fullWidth label="Código de indicação" {...register('codigoIndicacao')} />
                    <TextField size="small" fullWidth label="Pontuação total" {...register('pontuacaoTotal')} />
                    <TextField size="small" fullWidth label="CNH" {...register('cnh')} />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {cnhArquivoUrl && (
                            <AvatarWithModal
                                src={cnhArquivoUrl}
                                alt="CNH"
                            />
                        )}
                        <TextField
                            size="small"
                            fullWidth
                            type="file"
                            label="Foto da CNH"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ accept: 'image/*' }}
                            {...register('cnhArquivo')}

                        />
                    </Box>
                    <TextField size="small" fullWidth multiline rows={4} label="Contato de emergência" {...register('contatoEmergencia')} />
                    <TipoDeVeiculo hookForm={hookForm} />
                    <TextField fullWidth size="small" label="Ano do veículo" {...register('veiculoAno')} />
                    <TextField fullWidth size="small" label="Modelo do veículo" {...register('veiculoModelo')} />
                    <TextField fullWidth size="small" label="Placa" {...register('veiculoPlaca')} />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {veiculoArquivoUrl && (
                            <AvatarWithModal
                                src={veiculoArquivoUrl}
                                alt="Foto do veículo"
                            />
                        )}
                        <TextField
                            fullWidth
                            size="small"
                            type="file"
                            label="Foto do veículo"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ accept: 'image/*' }}
                            {...register('veiculoArquivo')}

                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }} >
                        {crlvArquivoUrl && (
                            <AvatarWithModal
                                src={crlvArquivoUrl}
                                alt="CLRV do veículo"
                            />
                        )}
                        <TextField
                            fullWidth
                            size="small"
                            type="file"
                            label="CLRV do veículo"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ accept: 'image/*' }}
                            {...register('veiculoClrvArquivo')}
                        />
                    </Box>
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="subtitle1">Dados Bancários</Typography>

                    <SelectBanco hookForm={hookForm} />

                    <TextField fullWidth size="small" label="Agência" {...register('bancoAgencia')} />
                    <TextField fullWidth size="small" label="Conta" {...register('bancoConta')} />
                    <FormControl fullWidth>
                        <InputLabel>Tipo chave PIX</InputLabel>
                        <Controller
                            name="chavePixTipo"
                            control={control}
                            render={({ field }) => (
                                <Select {...field} label="Tipo chave PIX" size="small">
                                    <MenuItem value="1">CPF/CNPJ</MenuItem>
                                    <MenuItem value="2">Celular</MenuItem>
                                    <MenuItem value="3">Email</MenuItem>
                                    <MenuItem value="4">Chave aleatória</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>

                    <TextField fullWidth size="small" label="Chave PIX" {...register('chavePix')} value={dynamicMask(watch('chavePixTipo'), watch('chavePix'))} />
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="subtitle1">Dados Situacionais</Typography>

                    <FormControl fullWidth>
                        <InputLabel size="small">Situação</InputLabel>
                        <Controller
                            name="online"
                            control={control}
                            render={({ field }) => (
                                <Select {...field} label="Situação" size="small">
                                    <MenuItem value="true">Online</MenuItem>
                                    <MenuItem value="false">Offline</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>


                    <InputDateTime
                        hookForm={{
                            name: 'dataOnline',
                            methods: hookForm
                        }}
                        label='Data online'
                    />
                    <InputDateTime
                        hookForm={{
                            name: 'dataUltimaLocalizacao',
                            methods: hookForm
                        }}
                        label='Data da última localização'
                    />
                    <Box sx={{ width: '100%', border: '1px solid #ccc', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <MapWithMarker
                            position={{
                                latitude: watch("ultimaLocalizacaoLatitude") ? Number(watch("ultimaLocalizacaoLatitude")) : 0,
                                longitude: watch("ultimaLocalizacaoLongitude") ? Number(watch("ultimaLocalizacaoLongitude")) : 0,
                            }}
                            setPosition={({ latitude, longitude }: any) => {
                                setValue('ultimaLocalizacaoLatitude', latitude);
                                setValue('ultimaLocalizacaoLongitude', longitude, { shouldDirty: true });
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button variant="containedFilled" color="primary" type="submit" sx={{ width: '10%' }}>
                    Salvar
                </Button>
            </Box>
        </Box>
    );
};

export default Info;
