import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    resposta: null,
    loading: false,
    error: null,
    modelo: {},
};

export const daRoboSlice = createSlice({
    name: 'daRobo',
    initialState,
    reducers: {
        getInfo: (state, action) => {
            state.loading = true;
        },
        getInfoSuccess: (state, action) => {
            state.loading = false;
            state.modelo = action.payload;
        },
        getInfoFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        listarEmpresasDa: (state, action) => {
            state.loading = true;
        },
        listarEmpresasDaSuccess: (state, action) => {
            state.loading = false;
            state.resposta = action.payload;
        },
        listarEmpresasDaFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setDaRobo: (state, action) => {
            state.loading = true;
        },
        setDaRoboSuccess: (state, action) => {
            state.loading = false;
            state.modelo = action.payload;
        },
        setDaRoboFailure: (state, action) => {
            state.loading = false;
            state.resposta = action.payload.erro;
        },
        limparDaRobo: (state, action) => {
            state.loading = false;
            state.error = null;
        }
    },
});

export const {
    getInfo,
    getInfoSuccess,
    getInfoFailure,
    setDaRobo,
    setDaRoboSuccess,
    setDaRoboFailure,
    listarEmpresasDa,
    listarEmpresasDaFailure,
    listarEmpresasDaSuccess,
    limparDaRobo
} = daRoboSlice.actions;
export default daRoboSlice;
