// SelectTipoPedido.tsx
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SxProps } from '@mui/material';
import React from 'react';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name?: string;
}

interface SelectTipoPedidoProps {
  hookForm: HookForm;
  label?: string;
  size?: 'small' | 'medium';
  sx?: SxProps;
}

/**
 * Componente de seleção de tipos de pedido.
 * 
 * O `SelectTipoPedido` exibe um seletor com tipos de pedido predefinidos, como "Pedido", "Pendente" e outros. 
 * Ele é integrado ao `react-hook-form` para gerenciar o estado do formulário e validar o campo conforme regras opcionais.
 *
 * @component
 * @param {SelectTipoPedidoProps} props - Propriedades do componente.
 * @param {HookForm} props.hookForm - Objeto contendo métodos e configurações do `react-hook-form`.
 * @param {string} [props.label='Tipo de pedido'] - Rótulo exibido no campo de seleção.
 * @param {string} [props.size='small'] - Tamanho do componente, aceita 'small' ou 'medium'.
 * @param {SxProps} [props.sx] - Estilos adicionais para customizar o componente.
 * 
 * @returns {React.ReactElement} Componente de seleção de tipo de pedido.
 * 
 * @example
 * ```jsx
 * const methods = useForm();
 * <SelectTipoPedido
 *    hookForm={{ methods, name: 'tipoPedido' }}
 *    label="Selecione o tipo de pedido"
 * />
 * ```
 */
const SelectTipoPedido: React.FC<SelectTipoPedidoProps> = ({
  size = 'small',
  sx,
  label = 'Tipo de pedido',
  hookForm: { methods, rules, name = 'tipoPedido' },
}) => {
  const { register, formState: { errors }, watch } = methods;
  const inputValue = watch(name)
  return (
    <FormControl
      size={size}
      sx={sx}
      error={!!errors[name]}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        {...register(name, rules)}
        value={inputValue !== undefined && inputValue !== null ? String(inputValue) : ''}
        label={label}
      >
        <MenuItem value="0">Pedido</MenuItem>
        <MenuItem value="1">Pendente</MenuItem>
        <MenuItem value="2">Diária</MenuItem>
        <MenuItem value="3">Vale</MenuItem>
        <MenuItem value="4">Bônus</MenuItem>
        <MenuItem value="5">Antecipação</MenuItem>
        <MenuItem value="6">Administrativo</MenuItem>
      </Select>
      {errors[name] && <FormHelperText>{String(errors[name]?.message ?? '')}</FormHelperText>}
    </FormControl>
  );
};

export default SelectTipoPedido;
