import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import {
    enviarDadosFailure,
    enviarDadosReq,
    enviarDadosSuccess,
    fetchLoginFailure,
    fetchLoginStart,
    fetchLoginSuccess,
    loginFreemium,
    loginFreemiumFailure,
    loginFreemiumSuccess,
    recuperarSenhaStart,
    recuperarSenhaSuccess,
    recuperarSenhaFailure
} from '../reducers/login';

function* fetchLoginSaga(action) {
    try {
        const { email, senha, doisfa } = action.payload;
        const form = new FormData();
        form.append('email', email);
        form.append('senha', senha);
        form.append('codigo2fa', doisfa ? doisfa : '');
        form.append('returnUrl', '/Painel');

        const response = yield call(api.post, '/Home/Login', form);
        if (response.data.sucesso === true) {
            yield put(fetchLoginSuccess(response.data));
            localStorage.setItem('@token', response.data?.token);
            localStorage.setItem('@xpick', response.data.usuario?.pickID || response.data?.usuario?.id);
        }
        else {
            yield put(fetchLoginFailure(response.data.mensagem));
        }
    } catch (error) {
        yield put(fetchLoginFailure(error.toString()));
    }
}

function* loginFreemiumSaga(action) {
    const { email, codigoLogin } = action.payload;

    const form = new FormData();
    form.append('email', email);
    form.append('codigoLogin', codigoLogin);

    const response = yield call(api.post, '/Assinatura/LoginFreemium', form);

    if (response.data.sucesso === true) {
        yield put(loginFreemiumSuccess(response.data));
    }
    else {
        yield put(loginFreemiumFailure(response.data));
    }
}

function* enviarDados(action) {
    try {
        const form = action.payload;
        const response = yield call(api.post, '/Assinatura/AssinarV2', form);
        if (response.data.sucesso === true) {
            yield put(enviarDadosSuccess(response.data));
        }
        else {
            yield put(enviarDadosFailure(response.data));
        }
    } catch (error) {
        console.log(error);
    }
}

function* recuperarSenha(action) {
    try {
        const formData = new FormData();

        Object.keys(action.payload).forEach((key) => {
            formData.append(key, String(action.payload[key]));
        });

        const response = yield call(api.post, '/Usuario/ResetarSenhaLogin', formData);
        if (response.data.sucesso === true) {
            yield put(recuperarSenhaSuccess(response.data));
        } else if (response.data.sucesso === false) {
            yield put(recuperarSenhaFailure(response.data.mensagem));
        }
    } catch (error) {
        yield put(recuperarSenhaFailure(error));
    }
}

function* loginSaga() {
    yield takeLatest(fetchLoginStart.type, fetchLoginSaga);
    yield takeLatest(loginFreemium.type, loginFreemiumSaga);
    yield takeLatest(enviarDadosReq.type, enviarDados);
    yield takeLatest(recuperarSenhaStart.type, recuperarSenha);
}

export default loginSaga;
