import { Box, Card, Typography } from "@mui/material";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/components/CardFormFiltros";
import { useForm } from "react-hook-form";
import TabelaEmpresas from "../../components/Empresas/TabelaEmpresas";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { BoxTableMinHeight, TableNavigation } from "src/ds";
import { listaEmpresasStart } from "src/store/reducers/empresa";

const Empresas = () => {
    const empresasDados = useSelector((state: any) => state.empresas.listaEmpresas?.data);
    const empresas = empresasDados?.lista;
    const loadingEmpresas = useSelector((state: any) => state.empresas.listaEmpresas.loading);

    const hookForm = useForm({
        defaultValues: {
            nomeEmpresa: '',
            codigoIdentificador: '',
            dataCadastroInicial: '',
            dataCadastroFinal: '',
            cnpj: '',
            ativo: true,
            paginacao: 20,
            pagina: 1,
        },
    });
    const dispatch = useDispatch();
    const primeiraRenderizacao = useRef(true);
    const cardRef = useRef<CardFormFiltrosRef>(null)
    const { watch } = hookForm
    useEffect(() => {
        if (primeiraRenderizacao.current === true) {
            cardRef.current?.onSubmit()
            primeiraRenderizacao.current = false
            return
        }
    }, [primeiraRenderizacao]);

    useEffect(() => {
        dispatch(listaEmpresasStart({
            ...hookForm.getValues(),
        }));
    }, [watch("paginacao"), watch("pagina")]);

    return (
        <Box>
            <Box sx={{ p: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    dispatchMethods={{ actionName: 'listaEmpresasStart' }}
                    formMethods={hookForm}
                    fields={[
                        { name: "Buscar", value: "nome", type: "input" },
                        {
                            name: "Tags",
                            value: "tagsFiltro",
                            type: "tags",
                            filtroInline: true,
                        },
                    ]}
                    loading={loadingEmpresas}
                />
            </Box>
            <Card elevation={1} sx={{ m: 2, borderRadius: 2, }}>
                {empresasDados?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={empresasDados.indiceInicial}
                        indiceFinal={empresasDados.indiceFinal}
                        indiceTotal={empresasDados.total}
                        ultimaPagina={empresasDados?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <BoxTableMinHeight>
                    <TabelaEmpresas empresas={empresas} loading={loadingEmpresas} fezRequisicao={cardRef.current?.fezRequisicao} />
                </BoxTableMinHeight>
            </Card>
        </Box>
    );
};

export default Empresas;
