import { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import permissions from 'src/ds/utils/permissions'
import { RootState } from 'src/store/reducers'

const PermissionsContext = createContext(permissions)

export function usePermissions() {
    return useContext(PermissionsContext)
}

export const PermissionsProvider = ({ children }: { children: React.ReactNode }) => {
    const [statePermissions, setStatePermissions] = useState(permissions)
    const permissionsData = useSelector((state: RootState) => state.config?.masterPage?.usuario?.permissoes)
    useEffect(() => {
        if (permissionsData) {
            const updatedPermissions = {
                ...statePermissions,
                usuario: {
                    ...statePermissions.usuario,
                    ...permissionsData
                }
            }
            setStatePermissions(updatedPermissions)
        }
    }, [permissionsData])

    return (
        <PermissionsContext.Provider value={statePermissions}>
            {children}
        </PermissionsContext.Provider>
    )
}
