//@ts-nocheck
//Todo: remover os erros de tipagem do Icon Button
import { CloseOutlined } from "@mui/icons-material";
import { IconButton, IconButtonProps, styled } from "@mui/material";

interface IconButtonFechar extends IconButtonProps {
    variant?: string;
    onClick: () => void
}

export const IconButtonFechar = styled(
    ({ variant = 'contained', ...props }: IconButtonFechar) => (
        <IconButton  {...props} variant={variant}>
            <CloseOutlined />
        </IconButton>
    )
)(({ }) => { })


