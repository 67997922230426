// @ts-nocheck
import { Box, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { formatarCEP, formatCnpj, telefone } from "../../ds/common";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { fetchCnpjStart, limparCnpjAction } from "../../store/reducers/utils";
import { ButtonLoading, MapWithMarker, AutocompleteMap, GridColumn, BoxLine } from "../../ds";
import { useSnackbarContext } from "src/context/SnackbarContext";

const FormFreemium = ({ email, setEmail, updateFormData, isMobile }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const cnpjData = useSelector(state => state.utils.cnpjDados?.empresa);
    const cnpjError = useSelector(state => state.utils.erro);

    const [position, setPosition] = useState({
        latitude: -23.533773,
        longitude: -46.625290
    });

    const cidades = useSelector(state => state.utils.cidades);
    const estados = useSelector(state => state.utils.estados);
    const [filteredCidades, setFilteredCidades] = useState([]);
    const [autocompleteService, setAutocompleteService] = useState(null);
    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const loading = useSelector(state => state.login.loading);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { showWarning } = useSnackbarContext()

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const { register, handleSubmit, control, formState: { errors }, watch, setValue, setError, clearErrors, getValues } = useForm({
        defaultValues: {
            email: email,
            nomeEmpresa: '',
            numeroTelefone: '',
            cnpj: '',
            logradouro: '',
            bairro: '',
            CEP: '',
            complemento: '',
            cidade: '',
            estado: '',
        }
    });

    const onSubmit = (data) => {
        setIsSubmitting(true);
        const { latitude, longitude } = position;
        updateFormData({ ...data, latitude, longitude });

        setTimeout(() => {
            setIsSubmitting(false);
        }, 3000);
    };

    useEffect(() => {
        dispatch(limparCnpjAction());
    }, []);

    useEffect(() => {
        if (watch('estado')) {
            const cidadesFiltradas = cidades.filter(cidade => cidade.estadoID === watch('estado'));
            setFilteredCidades(cidadesFiltradas);
        } else {
            setFilteredCidades([]);
        }
    }, [watch('estado'), cidades]);

    useEffect(() => {
        if (position.latitude && position.longitude) {
            setValue('latitude', position.latitude);
            setValue('longitude', position.longitude);
        }
    }, [position, setValue]);



    const handleCidade = () => {
        if (!watch('estado')) {
            showWarning({
                message: 'Selecione um estado',
                duration: 5000
            });
        }

    };

    const handleCnpj = () => {
        let cnpjValue = watch('cnpj');

        // Remove todos os caracteres que não são números
        cnpjValue = cnpjValue.replace(/\D/g, '');


        if (cnpjValue && cnpjValue.length === 14) { // O CNPJ deve ter 14 dígitos
            clearErrors('cnpj');
            dispatch(fetchCnpjStart(cnpjValue));
        } else {
            setError('cnpj', {
                type: 'manual',
                message: 'CNPJ inválido ou não preenchido'
            });
        }
    };


    useEffect(() => {
        if (autocompleteService) {
            const estadoId = estados.filter((estado) => estado.estadoUF === autocompleteService.estado)[0]?.estadoID || '';
            const cidadeID = cidades.filter((cidade) => (cidade.cidadeNome === autocompleteService.cidade) && (cidade.estadoID === estadoId))[0]?.cidadeID || '';

            setValue('CEP', autocompleteService.cep);
            setValue('complemento', autocompleteService.complemento);
            setValue('logradouro', autocompleteService.logradouro);
            setValue('bairro', autocompleteService.bairro);
            setValue('cidade', cidadeID);
            setValue('estado', estadoId);

            clearErrors(['CEP', 'complemento', 'logradouro', 'bairro', 'cidade', 'estado']);
        }
    }, [autocompleteService, setValue, clearErrors]);

    useEffect(() => {
        if (cnpjData) {
            setValue('nomeEmpresa', cnpjData.nome || cnpjData.razaosocial);
            setValue('logradouro', cnpjData.logradouro);
            setValue('bairro', cnpjData.bairro);
            setValue('CEP', cnpjData.CEP);
            setValue('complemento', cnpjData.complemento);
            setValue('cidade', cnpjData.cidadeID);
            setValue('estado', cnpjData.estadoID);
            setPosition({ latitude: cnpjData.latitude, longitude: cnpjData.longitude });

            clearErrors(['nomeEmpresa', 'logradouro', 'bairro', 'CEP', 'complemento', 'cidade', 'estado']);
        } else if (cnpjError) {
            showWarning({
                message: 'CNPJ inválido',
                duration: 5000
            });
            setError('cnpj', {
                type: 'manual',
                message: 'CNPJ inválido'
            });
        }
    }, [cnpjData, cnpjError, setValue, setError, clearErrors, estados]);


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingX: isMobile ? 0 : 2,
                boxSizing: 'border-box',
                maxHeight: '60vh',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: theme.palette.background.default,
                },
            }}
        >
            <GridColumn
                width={'100%'}
                maxWidth="100%"
                justifyContent={'center'}
                textAlign={'center'}
                sx={{
                    boxSizing: 'border-box',
                    paddingX: 2,
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                    <Box
                        sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}
                    >
                        <TextField
                            size="small"
                            fullWidth
                            label="E-mail"
                            disabled
                            placeholder="Digite seu e-mail"
                            {...register("email", {
                                required: "E-mail é obrigatório",
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Formato de e-mail inválido"
                                }
                            })}
                            error={!!errors.email}
                            helperText={errors.email ? errors.email.message : null}
                            sx={{ marginTop: 1 }}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            label="CNPJ"
                            placeholder="Digite seu CNPJ"
                            {...register("cnpj", { required: "CNPJ é obrigatório" })}
                            value={formatCnpj(watch('cnpj'))}
                            onChange={(e) => setValue('cnpj', e.target.value)}
                            onBlur={handleCnpj}
                            error={!!errors.cnpj}
                            helperText={errors.cnpj ? errors.cnpj.message : null}
                            slotProps={{
                                htmlInput: {
                                    maxLength: 18
                                }
                            }}

                        />
                        <TextField
                            control={control}
                            size="small"
                            fullWidth
                            label="Nome da empresa"
                            placeholder="Digite o nome da empresa"
                            {...register("nomeEmpresa", { required: "Nome da empresa é obrigatório" })}
                            error={!!errors.nomeEmpresa}
                            helperText={errors.nomeEmpresa ? errors.nomeEmpresa.message : null}
                            slotProps={{
                                inputLabel: {
                                    shrink: (watch('nomeEmpresa') || '').trim() !== ''
                                }
                            }}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            label="Nº de celular"
                            placeholder="(61) 99000-0000"
                            {...register("numeroTelefone", {
                                required: "Número de celular é obrigatório",
                                validate: value => {
                                    const formattedValue = telefone(value);
                                    return formattedValue.length >= 15 || "Número de celular inválido";
                                }
                            })}
                            error={!!errors.numeroTelefone}
                            helperText={errors.numeroTelefone ? errors.numeroTelefone.message : null}
                            inputProps={{
                                maxLength: 15
                            }}
                            onChange={(e) => {
                                const formattedValue = telefone(e.target.value);
                                setValue('numeroTelefone', formattedValue); // Atualiza o valor no formulário
                                if (formattedValue.length >= 15) {
                                    clearErrors('numeroTelefone'); // Limpa o erro se o número estiver correto
                                }
                            }}
                        />


                        <Controller
                            control={control}
                            name="logradouro"
                            rules={{ required: 'Logradouro é obrigatório' }}
                            render={({ field: { onChange, value } }) => (
                                <AutocompleteMap
                                    setPosition={setPosition}
                                    setFormState={setAutocompleteService}
                                    isModelLoaded={isModelLoaded}
                                    size="small"
                                    valueController={value}
                                    onValueChangeController={onChange}
                                    fullWidth

                                />
                            )}
                        />
                        <BoxLine sx={{ gap: '1rem' }}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Bairro"
                                placeholder="Digite seu bairro"
                                {...register("bairro", { required: "Bairro é obrigatório" })}
                                error={!!errors.bairro}
                                helperText={errors.bairro?.message}
                                slotProps={{
                                    inputLabel: {
                                        shrink: (watch('bairro') || '').trim() !== ''
                                    }
                                }}
                            />
                            <TextField
                                fullWidth
                                size="small"
                                label="CEP"
                                placeholder="Digite seu CEP"
                                {...register("CEP", { required: "CEP é obrigatório" })}
                                error={!!errors.CEP}
                                helperText={errors.CEP?.message}
                                value={formatarCEP(watch('CEP'))}
                                slotProps={{
                                    htmlInput: {
                                        maxLength: 9
                                    },
                                    inputLabel: {
                                        shrink: (watch('CEP') || '').trim() !== ''
                                    }
                                }}
                            />
                        </BoxLine>
                        <TextField
                            size="small"
                            fullWidth
                            label="Complemento"
                            placeholder="Digite o complemento"
                            {...register("complemento")}
                            error={!!errors.complemento}
                            slotProps={{
                                inputLabel: {
                                    shrink: (watch('complemento') || '').trim() !== ''
                                }
                            }}
                            helperText={errors.complemento?.message}
                        />
                        <FormControl fullWidth error={!!errors.estado}>
                            <InputLabel shrink htmlFor="estado-label">Estado</InputLabel>
                            <Select
                                displayEmpty
                                size="small"
                                id="estado-label"
                                label="Estado"
                                {...register("estado", { required: "Estado é obrigatório" })}
                                value={watch('estado')}
                                sx={{ textAlign: 'left' }}
                                defaultValue=""
                            >
                                <MenuItem disabled value=''>
                                    Selecione um estado
                                </MenuItem>
                                {estados?.map((estado, index) => (
                                    <MenuItem key={index} value={estado.estadoID}>
                                        {estado.estadoNome}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.estado?.message}</FormHelperText>
                        </FormControl>
                        <FormControl shrink fullWidth error={!!errors.cidade}>
                            <InputLabel shrink htmlFor="cidade-label">Cidade</InputLabel>
                            <Select
                                displayEmpty
                                size="small"
                                labelId="cidade-label"
                                {...register('cidade', { required: "Cidade é obrigatório" })}
                                label="Cidade"
                                disabled={!watch('estado')}
                                value={watch('cidade')}
                                onClick={handleCidade}
                                sx={{ textAlign: 'left' }}
                                defaultValue=""
                            >
                                <MenuItem disabled value=''>
                                    Selecione uma cidade
                                </MenuItem>
                                {filteredCidades?.map((cidade, index) => (
                                    <MenuItem key={index} value={cidade.cidadeID}>
                                        {cidade.cidadeNome}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.cidade?.message}</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth variant="outlined">
                            <TextField
                                id="senhaAcesso"
                                type={showPassword ? 'text' : 'password'}
                                {...register("senhaAcesso", { required: "Senha é obrigatória" })}
                                error={!!errors.senhaAcesso}
                                helperText={errors.senhaAcesso ? errors.senhaAcesso.message : null}
                                size="small"
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                }}
                                label="Senha"
                            />
                        </FormControl>

                        <FormControl fullWidth variant="outlined">
                            <TextField
                                id="confirm-password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                {...register("confirmPassword", {
                                    required: "Confirmação de senha é obrigatória",
                                    validate: (value) => value === watch('senhaAcesso') || "As senhas não correspondem"
                                })}
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword ? errors.confirmPassword.message : null}
                                size="small"
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle confirm password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                }}
                                label="Confirmar Senha"
                            />
                        </FormControl>

                    </Box>
                    <Grid container spacing={0} style={{ marginTop: '1rem' }}>
                        <MapWithMarker position={position} setPosition={setPosition} />
                    </Grid>
                    <ButtonLoading
                        loading={loading}
                        type="submit"
                        variant="containedFilled"
                        disabled={isSubmitting || Object.keys(errors).length > 0} // Desabilitar se já está submetendo ou se houver erros
                        sx={{
                            position: isMobile ? 'relative' : 'absolute',
                            bottom: isMobile ? 0 : 6,
                            right: isMobile ? 0 : 6,
                            width: 'fit-content',
                        }}
                    >
                        Continuar
                    </ButtonLoading>
                </form>
            </GridColumn>
        </Box>
    );
}

export default FormFreemium;
