import { Box, Typography, Tabs, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listaEntregadoresStart } from 'src/store/reducers/entregador';
import { ArrowBackIos } from '@mui/icons-material';
import DadosBasicos from './DadosBasicos';
import TiposVeiculo from './TiposVeiculo';
import AgendamentoHorario from './AgendamentoHorario';
import AgendamentoDatas from './AgendamentoDatas';
import { RootState } from 'src/store/reducers';
import { paginaTaxaExtraStart } from 'src/store/reducers/taxaExtra';

interface TabConfig {
    label: string;
    content: React.ReactNode;
}

const TabsTaxaExtra: React.FC = () => {
    const { action, id } = useParams<{ action: string; id?: string }>();
    const [activeTab, setActiveTab] = useState(0);
    const taxaAtual = useSelector((state: RootState) => state.taxaExtra.paginaTaxaExtra.data?.modelo)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(paginaTaxaExtraStart({ id: id }))
    }, [])

    const nomeTaxa = taxaAtual?.nome;

    const isEditMode = action?.toLowerCase() === 'editar' && id;

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const tabsConfig: TabConfig[] = [
        { label: 'Dados básicos', content: <DadosBasicos modelo={isEditMode ? taxaAtual : undefined} /> },
        ...(isEditMode ? [
            { label: 'Tipos de Veículo', content: <TiposVeiculo data={taxaAtual} /> },
            { label: 'Agendamento por Horário', content: <AgendamentoHorario data={taxaAtual} /> },
            { label: 'Agendamento por Datas', content: <AgendamentoDatas data={taxaAtual} /> },
        ] : []),
    ];

    const navigate = useNavigate();

    return (
        <Box p={2} sx={{ backgroundColor: 'background.paper' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <ArrowBackIos
                    sx={{ cursor: 'pointer', mr: 1 }}
                    onClick={() => navigate("/TaxaExtra/Index")}
                />
                <Typography variant="h6">
                    <Typography variant="h6">
                        {isEditMode ? (
                            <>
                                Editar {nomeTaxa} <span style={{ fontSize: '0.6em', color: 'gray' }}>({id})</span>
                            </>
                        ) : (
                            'Cadastrar'
                        )}
                    </Typography>
                </Typography>
            </Box>

            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Tabs de Edição"
                variant="scrollable"
                scrollButtons="auto"
            >
                {tabsConfig.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.label}
                    />
                ))}
            </Tabs>

            <Box sx={{ mt: 2 }}>
                {tabsConfig[activeTab].content}
            </Box>
        </Box>
    );
};

export default TabsTaxaExtra;
