import { CircularProgress, Container, styled } from "@mui/material";

const ContainerLoading = styled(
  (props) => (<Container {...props}><CircularProgress /></Container>)
)(({ }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
  height: '100%',
  width: '100%',
}))

export default ContainerLoading;
