import { Chip, ChipProps, styled } from "@mui/material";

export const ButtonChip = styled((props: ChipProps) => (
    <Chip {...props} />
))(({ theme }) => ({
    cursor: 'pointer',
    transition: '0.3s ease-in-out',
    '&:hover': {
        boxShadow: theme.shadows[3],
        transform: 'scale(1.01)',
    },
    '&:active': {
        boxShadow: theme.shadows[0],
        transform: 'scale(1)',
    },
}));
