import { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSom } from 'src/store/reducers/utils';

interface UseAudio {
    permiteSom: boolean;
    solicitarPermissaoSom: () => void;
    definirFonteAudio: (src: string) => void;
    tocarAudio: () => void;
    audioSrc: string;
    audioRef: React.MutableRefObject<HTMLAudioElement>;
}

const useAudio = (initialSrc: string): UseAudio => {
    const [audioSrc, setAudioSrc] = useState<string>(initialSrc);
    const audioRef = useRef<HTMLAudioElement>(new Audio(initialSrc));
    const permiteSom = useSelector((state: any) => state.utils.permiteSom);
    const dispatch = useDispatch();

    const solicitarPermissaoSom = useCallback(() => {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
            playPromise
                .then(() => {
                    dispatch(toggleSom());
                })
                .catch((err) => {
                    console.error('Erro ao tentar tocar som:', err);
                });
        }
    }, [dispatch]);


    useEffect(() => {
        const audio = audioRef.current;
        const handleError = () => {
            console.error('Erro ao carregar o arquivo de áudio:', audio.src);
        };
        audio.addEventListener('error', handleError);
        return () => {
            audio.removeEventListener('error', handleError);
        };
    }, []);



    const definirFonteAudio = useCallback((src: string): void => {
        audioRef.current.src = src;
        audioRef.current.load();
        setAudioSrc(src);
    }, []);


    const tocarAudio = useCallback((): void => {

        audioRef.current
            .play()
            .catch((err) => {
                console.error('Erro ao reproduzir som:', err);
            });
    }, []
    );


    return { permiteSom, solicitarPermissaoSom, definirFonteAudio, tocarAudio, audioSrc, audioRef };
};

export default useAudio;