import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Empresa {
    empresaID: string;
    nome: string;
}

interface TipoVeiculo {
    nome: string;
}

interface TabelaPagamento {
    tabelaValorID: string;
    pickID: string;
    pick: {
        pickID: string;
        nome: string;
    };
    dataCadastro: string;
    ativo: boolean;
    nome: string;
    valorDiariaPorHora: number;
    valorPontoAdicional: number;
    tipoVeiculoID: string;
    tipoVeiculo: TipoVeiculo | null;
    valores: Array<any>;
    empresas: Array<Empresa>;
}

interface ListaTabelaPagamentoParams {
    tabelaValorID?: string;
    pickID?: string;
    nome?: string;
    dataCadastro?: string;
    ativo?: boolean;
    valorDiariaPorHora?: number;
    valorPontoAdicional?: number;
    tipoVeiculoID?: string;
    paginacao: number;
    pagina: number;
    filtros?: string;
    ordenacaoCampo?: string;
    ordenacaoOrdem?: string;
    titulos?: string;
    variaveis?: string;
    formato?: string;
}

export interface TabelaPagamentoState {
    loading: boolean;
    data: any | null;
    error: string | null;
}

export interface TabelasPagamentoState {
    listaTabelasPagamento: any;
    paginaTabelaPagamento: any;
    empresaTabelaPagamento: any;
    tiposDeVeiculo: any;
    porRaio: any;
    porBairro: any;
    porKmCalculado: any;
    salvarTabela: any;
    adicionarEmpresaTabela: any;
    salvarTipoVeiculo: any;
    salvarTabelaPorBairro: any;
    salvarTabelaPorRaio: any;
    excluirTabelaPorRaio: any;
    excluirTabelaPorBairro: any;
    salvarTabelaPorKm: any;
    excluirTabelaPorKm: any;
    duplicarTabelaPagamento: any;
}

interface RootState {
    listaTabelaPagamento: {
        loading: boolean;
        data: TabelaPagamento[] | null;
        error: string | null;
    };
}
//@ts-ignore
const initialState: TabelasPagamentoState = {
    listaTabelasPagamento: {
        loading: false,
        data: null,
        error: null,
    },
    paginaTabelaPagamento: {
        loading: false,
        data: null,
        permissoes: null,
        error: null,
    },
    empresaTabelaPagamento: {
        loading: false,
        data: null,
        error: null,
    },
    tiposDeVeiculo: {
        loading: false,
        data: null,
        error: null,
    },
    porRaio: {
        loading: false,
        data: null,
        error: null,
    },
    porBairro: {
        loading: false,
        data: null,
        error: null,
    },
    porKmCalculado: {
        loading: false,
        data: null,
        error: null,
    },
    salvarTabela: {
        loading: false,
        data: null,
        error: null,
    },
    adicionarEmpresaTabela: {
        loading: false,
        data: null,
        error: null,
    },

    salvarTipoVeiculo: {
        loading: false,
        data: null,
        error: null,
    },
    salvarTabelaPorBairro: {
        loading: false,
        data: null,
        error: null,
    },
    salvarTabelaPorRaio: {
        loading: false,
        data: null,
        error: null,
    },
    excluirTabelaPorRaio: {
        loading: false,
        data: null,
        error: null,
    },
    excluirTabelaPorBairro: {
        loading: false,
        data: null,
        error: null,
    },
    salvarTabelaPorKm: {
        loading: false,
        data: null,
        error: null,
    },
    excluirTabelaPorKm: {
        loading: false,
        data: null,
        error: null,
    },
    duplicarTabelaPagamento: {
        loading: false,
        data: null,
        error: null,
    }
};

const tabelaPagamentoSlice = createSlice({
    name: 'tabelaPagamento',
    initialState: initialState,
    reducers: {
        listaTabelaPagamentoStart(state, action: PayloadAction<ListaTabelaPagamentoParams>) {
            state.listaTabelasPagamento.loading = true;
            state.listaTabelasPagamento.error = null;
        },
        listaTabelaPagamentoFailure(state, action: PayloadAction<string>) {
            state.listaTabelasPagamento.loading = false;
            state.listaTabelasPagamento.error = action.payload;
        },
        listaTabelaPagamentoSuccess(state, action: PayloadAction<TabelaPagamento[]>) {
            state.listaTabelasPagamento.loading = false;
            state.listaTabelasPagamento.data = action.payload;
        },
        paginaTabelaPagamentoStart(state, action: PayloadAction<any>) {
            state.paginaTabelaPagamento.loading = true;
            state.paginaTabelaPagamento.error = null;
        },
        paginaTabelaPagamentoFailure(state, action: PayloadAction<string>) {
            state.paginaTabelaPagamento.loading = false;
            state.paginaTabelaPagamento.error = action.payload;
        },
        paginaTabelaPagamentoSuccess(state, action: PayloadAction<any>) {
            state.paginaTabelaPagamento.loading = false;
            state.paginaTabelaPagamento.data = action.payload;
        },
        listarEmpresaTabelaPagamentoStart(state, action: PayloadAction<any>) {
            state.empresaTabelaPagamento.loading = true;
            state.empresaTabelaPagamento.error = null;
        },
        listarEmpresaTabelaPagamentoSuccess(state, action: PayloadAction<any>) {
            state.empresaTabelaPagamento.loading = false;
            state.empresaTabelaPagamento.data = action.payload;
        },
        listarEmpresaTabelaPagamentoFailure(state, action: PayloadAction<string>) {
            state.empresaTabelaPagamento.loading = false;
            state.empresaTabelaPagamento.error = action.payload;
        },
        listarTipoVeiculoTabelaPagamentoStart(state, action: PayloadAction<any>) {
            state.tiposDeVeiculo.loading = true;
            state.tiposDeVeiculo.error = null;
        },
        listarTipoVeiculoTabelaPagamentoSuccess(state, action: PayloadAction<any>) {
            state.tiposDeVeiculo.loading = false;
            state.tiposDeVeiculo.data = action.payload;
        },
        listarTipoVeiculoTabelaPagamentoFailure(state, action: PayloadAction<string>) {
            state.tiposDeVeiculo.loading = false;
            state.tiposDeVeiculo.error = action.payload;
        },
        listarPorRaioStart(state, action: PayloadAction<any>) {
            state.porRaio.loading = true;
            state.porRaio.error = null;
        },
        listarPorRaioSuccess(state, action: PayloadAction<any>) {
            state.porRaio.loading = false;
            state.porRaio.data = action.payload;
        },
        listarPorRaioFailure(state, action: PayloadAction<any>) {
            state.porRaio.loading = false;
            state.porRaio.error = action.payload;
        },
        listarPorBairroStart(state, action: PayloadAction<any>) {
            state.porBairro.loading = true;
            state.porBairro.error = null;
        },
        listarPorBairroSuccess(state, action: PayloadAction<any>) {
            state.porBairro.loading = false;
            state.porBairro.data = action.payload;
        },
        listarPorBairroFailure(state, action: PayloadAction<any>) {
            state.porBairro.loading = false;
            state.porBairro.error = action.payload;
        },
        listarPorKmCalculadoStart(state, action: PayloadAction<any>) {
            state.porKmCalculado.loading = true;
            state.porKmCalculado.error = null;
        },
        listarPorKmCalculadoSuccess(state, action: PayloadAction<any>) {
            state.porKmCalculado.loading = false;
            state.porKmCalculado.data = action.payload;
        },
        listarPorKmCalculadoFailure(state, action: PayloadAction<any>) {
            state.porKmCalculado.loading = false;
            state.porKmCalculado.error = action.payload;
        },
        salvarTabelaStart(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = true;
            state.salvarTabela.error = null;
        },
        salvarTabelaSuccess(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = false;
            state.salvarTabela.data = action.payload;
        },
        salvarTabelaFailure(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = false;
            state.salvarTabela.error = action.payload;
        },
        limparSalvarTabela(state) {
            state.salvarTabela.loading = false;
            state.salvarTabela.data = null;
            state.salvarTabela.error = null;
        },
        excluirTabelaStart(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = true;
            state.salvarTabela.error = null;
        },
        excluirTabelaSuccess(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = false;
            state.salvarTabela.data = action.payload;
        },
        excluirTabelaFailure(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = false;
            state.salvarTabela.error = action.payload;
        },
        adicionarEmpresaTabelaStart(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = true;
            state.adicionarEmpresaTabela.error = null;
        },
        adicionarEmpresaTabelaSuccess(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = false;
            state.adicionarEmpresaTabela.data = action.payload;
        },
        adicionarEmpresaTabelaFailure(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = false;
            state.adicionarEmpresaTabela.error = action.payload;
        },
        deletarEmpresaTabelaStart(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = true;
            state.adicionarEmpresaTabela.error = null;
        },
        deletarEmpresaTabelaSuccess(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = false;
            state.adicionarEmpresaTabela.data = action.payload;
        },
        deletarEmpresaTabelaFailure(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = false;
            state.adicionarEmpresaTabela.error = action.payload;
        },
        limparEmpresaTabela(state) {
            state.adicionarEmpresaTabela.loading = false;
            state.adicionarEmpresaTabela.data = null;
            state.adicionarEmpresaTabela.error = null;
        },
        salvarTipoVeiculoTabelaStart(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = true;
            state.salvarTipoVeiculo.error = null;
        },
        salvarTipoVeiculoTabelaSuccess(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.data = action.payload;
        },
        salvarTipoVeiculoTabelaFailure(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.error = action.payload;
        },
        excluirTipoDeVeiculoTabelaStart(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.error = null;
            state.salvarTipoVeiculo.data = action.payload;
        },
        excluirTipoDeVeiculoTabelaFailure(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.error = action.payload;
        },
        excluirTipoDeVeiculoTabelaSuccess(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.data = action.payload;
        },
        limparTipoVeiculo(state) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.data = null;
            state.salvarTipoVeiculo.error = null;
        },
        salvarTabelaPorRaioStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.data = action.payload;
            state.salvarTabelaPorRaio.loading = false;
        },
        salvarTabelaPorRaioSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.data = action.payload;
            state.salvarTabelaPorRaio.loading = false;
        },
        salvarTabelaPorRaioFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.error = action.payload;
            state.salvarTabelaPorRaio.loading = false;

        },
        excluirTabelaPorRaioStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.data = action.payload;
            state.salvarTabelaPorRaio.loading = false;
        },
        excluirTabelaPorRaioSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.data = action.payload;
            state.salvarTabelaPorRaio.loading = false;
        },
        excluirTabelaPorRaioFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.error = action.payload;
            state.salvarTabelaPorRaio.loading = false;
        },
        limparTabelaPorRaio: (state) => {
            state.salvarTabelaPorRaio.data = null;
            state.salvarTabelaPorRaio.error = null;
            state.salvarTabelaPorRaio.loading = false;
        },
        salvarTabelaPorBairroStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.data = action.payload;
            state.salvarTabelaPorBairro.loading = false;
        },
        salvarTabelaPorBairroSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.data = action.payload;
            state.salvarTabelaPorBairro.loading = false;
        },
        salvarTabelaPorBairroFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.error = action.payload;
            state.salvarTabelaPorBairro.loading = false;
        },
        excluirTabelaPorBairroStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.data = action.payload;
            state.salvarTabelaPorBairro.loading = false;
        },
        excluirTabelaPorBairroSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.data = action.payload;
            state.salvarTabelaPorBairro.loading = false;
        },
        excluirTabelaPorBairroFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.error = action.payload;
            state.salvarTabelaPorBairro.loading = false;
        },
        limparTabelaPorBairro: (state) => {
            state.salvarTabelaPorBairro.data = null;
            state.salvarTabelaPorBairro.error = null;
            state.salvarTabelaPorBairro.loading = false;
        },
        salvarTabelaPorKmStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.data = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        salvarTabelaPorKmSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.data = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        salvarTabelaPorKmFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.error = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        excluirTabelaPorKmStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.data = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        excluirTabelaPorKmSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.data = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        excluirTabelaPorKmFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.error = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        limparTabelaPorKm: (state) => {
            state.salvarTabelaPorKm.data = null;
            state.salvarTabelaPorKm.error = null;
            state.salvarTabelaPorKm.loading = false;
        },
        duplicarTabelaPagamentoStart: (state, action: PayloadAction<any>) => {
            state.duplicarTabelaPagamento.data = action.payload;
            state.duplicarTabelaPagamento.loading = false;
        },
        duplicarTabelaPagamentoSuccess: (state, action: PayloadAction<any>) => {
            state.duplicarTabelaPagamento.data = action.payload;
            state.duplicarTabelaPagamento.loading = false;
        },
        duplicarTabelaPagamentoFailure: (state, action: PayloadAction<any>) => {
            state.duplicarTabelaPagamento.error = action.payload;
            state.duplicarTabelaPagamento.loading = false;
        },
    },
});

export const {
    listaTabelaPagamentoStart,
    listaTabelaPagamentoFailure,
    listaTabelaPagamentoSuccess,
    paginaTabelaPagamentoStart,
    paginaTabelaPagamentoFailure,
    paginaTabelaPagamentoSuccess,
    listarEmpresaTabelaPagamentoStart,
    listarEmpresaTabelaPagamentoSuccess,
    listarEmpresaTabelaPagamentoFailure,
    listarTipoVeiculoTabelaPagamentoStart,
    listarTipoVeiculoTabelaPagamentoSuccess,
    listarTipoVeiculoTabelaPagamentoFailure,
    listarPorRaioStart,
    listarPorRaioSuccess,
    listarPorRaioFailure,
    listarPorBairroStart,
    listarPorBairroSuccess,
    listarPorBairroFailure,
    listarPorKmCalculadoStart,
    listarPorKmCalculadoFailure,
    listarPorKmCalculadoSuccess,
    salvarTabelaStart,
    salvarTabelaSuccess,
    salvarTabelaFailure,
    limparSalvarTabela,
    excluirTabelaStart,
    excluirTabelaSuccess,
    excluirTabelaFailure,
    adicionarEmpresaTabelaFailure,
    adicionarEmpresaTabelaStart,
    adicionarEmpresaTabelaSuccess,
    deletarEmpresaTabelaFailure,
    deletarEmpresaTabelaStart,
    deletarEmpresaTabelaSuccess,
    limparEmpresaTabela,
    salvarTipoVeiculoTabelaStart,
    salvarTipoVeiculoTabelaFailure,
    salvarTipoVeiculoTabelaSuccess,
    excluirTipoDeVeiculoTabelaStart,
    excluirTipoDeVeiculoTabelaFailure,
    excluirTipoDeVeiculoTabelaSuccess,
    limparTipoVeiculo,
    salvarTabelaPorRaioStart,
    salvarTabelaPorRaioSuccess,
    salvarTabelaPorRaioFailure,
    excluirTabelaPorRaioStart,
    excluirTabelaPorRaioFailure,
    excluirTabelaPorRaioSuccess,
    limparTabelaPorRaio,
    salvarTabelaPorBairroStart,
    salvarTabelaPorBairroSuccess,
    salvarTabelaPorBairroFailure,
    excluirTabelaPorBairroFailure,
    excluirTabelaPorBairroStart,
    excluirTabelaPorBairroSuccess,
    limparTabelaPorBairro,
    excluirTabelaPorKmFailure,
    excluirTabelaPorKmStart,
    excluirTabelaPorKmSuccess,
    salvarTabelaPorKmFailure,
    salvarTabelaPorKmStart,
    salvarTabelaPorKmSuccess,
    limparTabelaPorKm,
    duplicarTabelaPagamentoFailure,
    duplicarTabelaPagamentoStart,
    duplicarTabelaPagamentoSuccess
} = tabelaPagamentoSlice.actions;

export default tabelaPagamentoSlice;
