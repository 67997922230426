import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalOpcoes } from 'src/ds'
import { Bots } from 'src/dtos/BotsDTO'
import { RootState } from 'src/store/reducers';
import { excluirBotStart } from 'src/store/reducers/bots';

interface ModalRemoveBotProps {
    bot: Bots;
    open: boolean;
    onClose: () => void;
}


const ModalRemoveBot = ({ bot, open, onClose }: ModalRemoveBotProps) => {
    const dispatch = useDispatch()
    const excluirBotState = useSelector((state: RootState) => state.bots.excluirBot);

    const handleClick = () => {
        dispatch(excluirBotStart({ id: bot.pickWppBotID }))
    }

    useEffect(() => {
        if (excluirBotState.data || excluirBotState.error) {
            onClose()
        }
    }, [excluirBotState.data, excluirBotState.error])

    return (
        <ModalOpcoes
            open={open}
            onClose={onClose}
            acao={`Remover Bot '${bot.nomeSessao}'`}
            descricao='Você tem certeza?'
            opcoes={[
                {
                    label: 'Cancelar',
                    action() { onClose() },
                },
                {
                    label: 'Remover',
                    action() { handleClick() },
                    loading: excluirBotState.loading,
                },
            ]}
        />
    )
}

export default ModalRemoveBot