// @ts-nocheck
import { Search, ArrowForwardIos } from "@mui/icons-material";
import { Avatar, Box, Grid2, InputAdornment, TextField, Typography, Badge } from "@mui/material";
import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../services/firebase";

const TabEntregadores = ({
    handleSearchChange,
    searchTerm,
    entregadores,
    setEntregadorSelecionado,
}) => {
    const [mensagensNaoLidas, setMensagensNaoLidas] = useState({});

    const buscarMensagensNaoLidas = async () => {
        const hoje = new Date(new Date().setHours(new Date().getHours() - 24));

        try {
            const q = query(
                collection(db, "chatMensagens"),
                where("dataCadastro", ">", hoje),
                where("lido", "==", false)
            );

            const snapshot = await getDocs(q);

            const resultados = snapshot.docs.reduce((acc, doc) => {
                const data = doc.data();
                const chatIdentificador = data.chatIdentificador;
                const usuarioID = chatIdentificador?.replace("pick_", "");

                if (usuarioID) {
                    acc[usuarioID] = (acc[usuarioID] || 0) + 1;
                }

                return acc;
            }, {});

            setMensagensNaoLidas(resultados);
        } catch (error) {
            console.error("Erro ao buscar mensagens não lidas:", error);
        }
    };

    useEffect(() => {
        if (entregadores.length > 0) {
            buscarMensagensNaoLidas();
        }
    }, [entregadores]);



    const filteredAndSortedEntregadores = searchTerm.length > 0
        ? entregadores.filter(entregador =>
            entregador.nome.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : entregadores;

    const sortedEntregadores = [...filteredAndSortedEntregadores].sort((a, b) => {
        const mensagensA = mensagensNaoLidas[a.usuarioID] || 0;
        const mensagensB = mensagensNaoLidas[b.usuarioID] || 0;

        if (mensagensA !== mensagensB) {
            return mensagensB - mensagensA;
        }

        return a.nome.localeCompare(b.nome);
    });


    return (
        <Box
            sx={{
                height: '100%',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <TextField
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Encontre o entregador pelo nome..."
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{
                    mt: 1,
                    mb: 2,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'orange',
                        },
                        '&:hover fieldset': {
                            borderColor: 'orange',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'orange',
                        },
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
            />
            <Grid2
                container
                spacing={0}
                sx={{ flex: '1', overflowY: 'auto', alignContent: 'flex-start' }}
            >
                {sortedEntregadores.length > 0 ? (
                    sortedEntregadores.map((entregador, index) => (
                        <Grid2
                            size={12}
                            key={index}
                            onClick={() => setEntregadorSelecionado(entregador)}
                            sx={{
                                height: 'fit-content',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    padding: '1rem',
                                    borderRadius: '5px',
                                    borderBottom: '1px solid #e7e7e7',
                                    height: 'fit-content',
                                }}
                            >
                                <Avatar
                                    src={entregador?.perfilUrl}
                                    alt={`Perfil de ${entregador.nome}`}
                                    sx={{ width: 48, height: 48, marginRight: '1rem' }}
                                />
                                <Typography variant="body1" sx={{ fontWeight: '700', flexGrow: 1 }}>
                                    {entregador.nome}
                                </Typography>
                                {mensagensNaoLidas[entregador.usuarioID] > 0 && (
                                    <Badge
                                        badgeContent={mensagensNaoLidas[entregador.usuarioID]}
                                        color="primary"
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                marginLeft: '0.5rem',
                                                width: '1.8rem',
                                                height: '1.8rem',
                                                borderRadius: '50%',
                                                fontSize: '1rem',
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    />
                                )}

                                <ArrowForwardIos />
                            </Box>
                        </Grid2>
                    ))
                ) : (
                    <Grid2 size={12} alignContent={"center"} justifyContent={"center"} sx={{ flex: '1' }}>
                        <Typography variant="notFound" sx={{ textAlign: "center" }} component={'p'}>
                            Nenhum entregador encontrado
                        </Typography>
                    </Grid2>
                )}
            </Grid2>
        </Box>
    );
};

export default TabEntregadores;
