import { Box, Card } from "@mui/material";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/components/CardFormFiltros";
import { useForm } from "react-hook-form";
import TabelaEntregadores from "../../components/Entregadores/Tabela";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { listarTipoDeVeiculoStart } from "src/store/reducers/config";
import { BoxTableMinHeight, MenuTabelas, TableNavigation } from "src/ds";
import { exportarTabelaEntregadores, listaEntregadoresStart } from "src/store/reducers/entregador";

const Entregadores = () => {
    const entregadoresDados = useSelector((state: any) => state.entregadores.listaEntregadores?.data?.dados);
    const entregadores = entregadoresDados?.lista;
    const loadingEntregadores = useSelector((state: any) => state.entregadores.listaEntregadores.loading);
    const tiposDeVeiculo = useSelector((state: any) => state.config?.tiposDeVeiculos?.dados?.lista);
    const dispatch = useDispatch();
    const cardRef = useRef<CardFormFiltrosRef>(null);
    const primeiraRenderizacao = useRef(true);

    const hookForm = useForm({
        defaultValues: {
            nome: '',
            cpf: '',
            dtInicial: '',
            dtFinal: '',
            tagsFiltro: '',
            tipoVeiculoID: '',
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '(entregador || dataEntregadorSolicitacao != null)',
            titulos: '',
            variaveis: '',
            formato: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const { watch, getValues } = hookForm;

    useEffect(() => {
        if (primeiraRenderizacao.current === true) {
            cardRef.current?.onSubmit();
            primeiraRenderizacao.current = false
            return;
        }
    }, [primeiraRenderizacao]);

    useEffect(() => {
        dispatch(listaEntregadoresStart({
            ...hookForm.getValues(),
        }));
    }, [watch("paginacao"), watch("pagina")]);

    useEffect(() => {
        dispatch(listarTipoDeVeiculoStart({
            paginacao: -1,
            pagina: 1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }));
    }, []);

    const menuOptions = [
        {
            label: 'Exportar',
            action: () => {
                dispatch(exportarTabelaEntregadores(getValues()))
            }
        },
    ];

    return (
        <Box>
            <Box sx={{ p: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    formMethods={hookForm}
                    dispatchMethods={{
                        actionName: 'listaEntregadoresStart'
                    }}
                    fields={[
                        { name: "Nome do entregador", value: "nome" },
                        { name: "Data inicial", type: "date", value: "dtInicial", label: "Data inicial" },
                        { name: "Data final", type: "date", value: "dtFinal", label: "Data final" },
                        {
                            name: "Tipo de veículo", value: "tipoVeiculoID",
                            type: "customSelect",
                            options: tiposDeVeiculo,
                            valueKey: 'tipoVeiculoID',
                            labelKey: 'nome',
                        },
                        { name: "CPF", value: "cpf" },
                        {
                            name: "Tags",
                            value: "tagsFiltro",
                            type: "tags",
                            filtroInline: true,
                            fullWidth: true,
                        },
                    ]}
                    loading={loadingEntregadores}
                />
            </Box>
            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, alignItems: "center" }}>
                    {entregadoresDados &&
                        <TableNavigation
                            indiceInicial={entregadoresDados.indiceInicial}
                            indiceFinal={entregadoresDados.indiceFinal}
                            indiceTotal={entregadoresDados.total}
                            ultimaPagina={entregadoresDados?.ultimaPagina}
                            hookForm={hookForm}
                        />
                    }
                    <MenuTabelas options={menuOptions} loading={loadingEntregadores} />
                </Box>
                <BoxTableMinHeight>
                    <TabelaEntregadores entregadores={entregadores} loading={loadingEntregadores} fezRequisicao={cardRef.current?.fezRequisicao} />
                </BoxTableMinHeight>
            </Card>
        </Box>
    );
};

export default Entregadores;
