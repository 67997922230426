// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import { Grid, TextField, Typography, Box, Card, IconButton, InputAdornment, Avatar, useTheme, ToggleButtonGroup, ToggleButton, CircularProgress, Stack, Fade } from '@mui/material';
import { Send } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../../services/firebase';
import { collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, where, doc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import { enviarMensagemRequest } from '../../../store/reducers/chat';
import { listarEntregadoresRequest } from '../../../store/reducers/config';
import TabEntregadores from './TabEntregadores';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import { HeaderCard } from '../../../ds';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const deliveredIcon = (
    <Box sx={{ position: 'relative', width: '12px', height: '9px' }}>
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, left: 0 }}>
            <path d="M10.7402 0.500057L3.58006 8.37898L0.983945 5.92009" stroke="gray" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, left: '4px' }}>
            <path d="M10.7402 0.500057L3.58006 8.37898L0.983945 5.92009" stroke="gray" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </Box>
);

const readIcon = (
    <Box sx={{ position: 'relative', width: '12px', height: '9px' }}>
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, left: 0 }}>
            <path d="M10.7402 0.500057L3.58006 8.37898L0.983945 5.92009" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, left: '4px' }}>
            <path d="M10.7402 0.500057L3.58006 8.37898L0.983945 5.92009" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </Box>
);

const Chat = ({ close }) => {
    const marcarMensagensComoLidas = async (mensagens) => {
        const mensagensNaoLidas = mensagens.filter((msg) => !msg.lido);

        for (const mensagem of mensagensNaoLidas) {
            try {
                const mensagemRef = doc(db, 'chatMensagens', mensagem.id);
                await updateDoc(mensagemRef, {
                    lido: true,
                    dataLido: new Date(),
                });
                console.log(`Mensagem ${mensagem.id} marcada como lida.`);
            } catch (error) {
                console.error(`Erro ao marcar mensagem ${mensagem.id} como lida:`, error);
            }
        }
    };
    const theme = useTheme();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [userType, setUserType] = useState('entregadores');
    const [selectedTab, setSelectedTab] = useState('empresas');
    const [entregadorSelecionado, setEntregadorSelecionado] = useState(null);
    const [showDate, setShowDate] = useState(false);
    const [displayedDate, setDisplayedDate] = useState('');
    const entregadores = useSelector(state => state.config?.entregadores?.lista);
    const chatId = useSelector(state => state.config?.masterPage?.chatIdentificador);
    const destID = useSelector(state => state.config?.masterPage?.usuario?.id);
    const usuario = useSelector(state => state.login?.usuario);
    const destEmpresaID = useSelector(state => state.config?.masterPage?.empresa?.empresaID);
    const mensagemID = 'chatMensagens';
    const masterPage = useSelector(state => state.config?.masterPage);
    const tipoUsuario = useSelector(state => state.config?.tipoDeUsuario);
    const chatLoading = useSelector(state => state.chat.loading);
    const [animateClose, setAnimateClose] = useState(true);
    const messagesEndRef = useRef(null);
    const scrollTimeoutRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const isScrollAtBottom = (element) => {
        return element.scrollHeight - element.scrollTop === element.clientHeight;
    };

    const handleScroll = () => {
        setShowDate(true);
        if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current);
        }
        scrollTimeoutRef.current = setTimeout(() => {
            setShowDate(false);
        }, 2000);

        const scrollContainer = messagesEndRef.current.parentNode;
        /*         if (isScrollAtBottom(scrollContainer)) {
                    const lastMessageDate = new Date(messages[messages.length - 1]?.dataCadastro?.seconds * 1000).toLocaleDateString();
                    if (lastMessageDate === todayDate.toLocaleDateString()) {
                        setDisplayedDate(todayDate.toLocaleDateString());
                        return;
                    }
                } */
        const messageElements = scrollContainer.querySelectorAll('.message');
        for (let i = 0; i < messageElements.length; i++) {
            const rect = messageElements[i].getBoundingClientRect();
            if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                const messageDate = new Date(messages[i]?.dataCadastro?.seconds * 1000).toLocaleDateString();
                if (messageDate !== 'Invalid Date') {
                    setDisplayedDate(messageDate);
                }
                break;
            }
        }
    };

    useEffect(() => {
        dispatch(listarEntregadoresRequest(usuario?.id));
        scrollToBottom();
    }, []);

    const todayDate = new Date(moment().add(-24, 'hour').unix() * 1000);

    useEffect(() => {
        if (!masterPage?.chatIdentificador) return;

        let queryRef = db.collection("chatMensagens")
            .where("dataCadastro", ">", new Date(moment().add(-24, 'hour').unix() * 1000))
            .where("lido", "==", false)
            .orderBy("dataCadastro");

        if (masterPage.chatIdentificador) {
            queryRef = queryRef.where("chatIdentificador", "==", masterPage.chatIdentificador);
        }

        if (masterPage.pickSelecionada?.pickID) {
            queryRef = queryRef.where("pickID", "==", masterPage.pickSelecionada.pickID);
        }

        const unsubscribe = queryRef.onSnapshot((snapshot) => {
            const unreadMessages = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            console.log("Mensagens não lidas:", unreadMessages);
        });

        return () => unsubscribe();
    }, [newMessage]);


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleNewMessageChange = (event) => {
        setNewMessage(event.target.value);
    };


    useEffect(() => {
        const q = query(
            collection(db, mensagemID),
            where('chatIdentificador', '==', `pick_${selectedTab === 'empresas' ? destEmpresaID : entregadorSelecionado?.usuarioID}`),
            where('dataCadastro', '>', todayDate),
            orderBy('dataCadastro')
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const messagesData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(messagesData);
            setTimeout(() => {
                scrollToBottom();
            }, 500)
        });
        return () => unsubscribe();
    }, [destID, selectedTab, entregadorSelecionado]);



    const handleSendMessage = async () => {
        if (newMessage.trim() !== '') {
            setNewMessage('');
            await addDoc(collection(db, mensagemID), {
                text: newMessage,
                user: 'empresa1',
                timestamp: serverTimestamp()
            });
            let destIdSelector = selectedTab === 'empresas' ? destID : entregadorSelecionado?.usuarioID;
            let destEmpresaIDSelector = selectedTab === 'empresas' ? destEmpresaID : entregadorSelecionado?.usuarioID;
            let chatIDSelector = `${selectedTab === 'empresas' ? `pick_${destEmpresaID}` : `pick_${entregadorSelecionado?.usuarioID}`}`;
            if (selectedTab === 'empresas') {
                dispatch(enviarMensagemRequest({ chatIDSelector, newMessage, destEmpresaIDSelector, destIdSelector }));
            } else {
                dispatch(enviarMensagemRequest({ chatIDSelector, newMessage, destIdSelector }));
            }
            setTimeout(() => {
                scrollToBottom();
            }, 500)
        }
    };

    const handleUserTypeChange = (event, newUserType) => {
        if (newUserType !== null) {
            setUserType(newUserType);
            setEntregadorSelecionado(null);
        }
    };

    useEffect(() => {
        const scrollContainer = messagesEndRef.current?.parentNode;
        scrollContainer?.addEventListener('scroll', handleScroll);
        return () => {
            scrollContainer?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (tipoUsuario === 'SAAS') {
            setSelectedTab('entregadores');
        }
    }, [tipoUsuario])

    useEffect(() => {
        if (entregadorSelecionado) {
            setAnimateClose(true)
        }
    }, [entregadorSelecionado])

    const handleCloseEntregador = () => {
        setAnimateClose(false)
        setTimeout(() => {
            setEntregadorSelecionado(null)
            setAnimateClose(true)
        }, 300)
    }

    return (
        <Grid
            container
            direction="column"
            sx={{
                padding: '0.5rem',
                backgroundColor: theme.palette.background.default,
                maxHeight: '100%',
                height: '100%',
                flexWrap: 'nowrap',
                gap: '0.4rem'
            }}
        >
            <HeaderCard title="Chat" onClick={close} />
            <Grid
                item
                sx={{
                    width: '100%',
                    flex: '1 0 0',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                }}
            >
                <Card sx={{
                    backgroundColor: theme.palette.background.paper,
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingY: '0.5rem',
                    boxShadow: 'none',
                }}>
                    {!entregadorSelecionado && selectedTab === 'entregadores' &&
                        <TabEntregadores
                            handleSearchChange={handleSearchChange}
                            searchTerm={searchTerm}
                            entregadores={entregadores}
                            setEntregadorSelecionado={setEntregadorSelecionado}
                            entregadorSelecionado={entregadorSelecionado}
                        />
                    }
                    {((entregadorSelecionado !== null && selectedTab === 'entregadores') || selectedTab === 'empresas') && (
                        <Box
                            sx={{
                                flex: '1 1 0',
                                overflowY: 'auto',
                            }}
                        >
                            <Fade in={animateClose}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <Stack direction='row' sx={{ pl: '.5rem', alignItems: 'center', justifyContent: 'flex-start', flewWrap: 'nowrap' }}>
                                        <IconButton sx={{ flexShrink: '0' }} onClick={handleCloseEntregador}>
                                            <KeyboardArrowLeftRoundedIcon />
                                        </IconButton>
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                fontWeight: '700',
                                                padding: '0.5rem',
                                                wordBreak: 'keep-all',
                                                textWrap: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                flex: '1 1 0',
                                            }}
                                        >
                                            {selectedTab !== 'entregadores' ? masterPage.pickSelecionada?.nome || masterPage.empresa?.pickNome : entregadorSelecionado?.nome}
                                        </Typography>
                                    </Stack>
                                    <Box
                                        sx={{
                                            flex: '1 1 0',
                                            overflowY: 'auto',
                                            padding: '1rem 0.5rem',
                                            borderRadius: '4px',
                                            whiteSpace: 'nowrap',
                                            '&::-webkit-scrollbar': { display: 'none' },
                                            scrollbarWidth: '5px',
                                            msOverflowStyle: 'none',
                                        }}
                                    >
                                        {messages.length === 0 ? (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                <Typography variant="notFound" align="center">
                                                    Nenhuma mensagem encontrada.
                                                </Typography>
                                            </Box>
                                        ) : (
                                            messages.map((msg, index) => {
                                                const isUserMessage = msg.remetenteUsuario?.nome === masterPage.usuario?.nome;
                                                const showAvatar = index === 0 || msg.remetenteUsuario?.nome !== messages[index - 1].remetenteUsuario?.nome;
                                                return (
                                                    <Box
                                                        className="message"
                                                        key={index}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: isUserMessage ? 'row-reverse' : 'row',
                                                            alignItems: 'flex-start',
                                                            mt: showAvatar ? '0.5rem' : '0.2rem',
                                                        }}
                                                    >
                                                        {showAvatar ? (
                                                            <Avatar sx={{ width: 36, height: 36, mt: '0.25rem' }} />
                                                        ) : (
                                                            <Box sx={{ width: 36, height: 36, mt: '0.25rem' }} />
                                                        )}
                                                        <Box
                                                            sx={{
                                                                position: 'relative',
                                                                padding: '0.2rem 1rem',
                                                                backgroundColor: isUserMessage ? theme.palette.primary.main : '#e7e7e7',
                                                                borderRadius: '6px',
                                                                minWidth: '50%',
                                                                maxWidth: '70%',
                                                                color: isUserMessage ? 'white' : 'black',
                                                                wordBreak: 'break-word',
                                                                whiteSpace: 'pre-wrap',
                                                                ml: isUserMessage ? '0' : '1rem',
                                                                mr: isUserMessage ? '1rem' : '0',
                                                            }}
                                                        >
                                                            {!isUserMessage && showAvatar && (
                                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                                    {msg.remetenteUsuario?.nome}
                                                                </Typography>
                                                            )}
                                                            {isUserMessage && (
                                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                                    {msg.remetenteUsuario?.nome}
                                                                </Typography>
                                                            )}
                                                            <Typography variant="body1" marginBottom="-1rem" paddingY={'0.1rem'}>
                                                                {msg.mensagem}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end',
                                                                    alignItems: 'center',
                                                                    mt: '0.05rem',
                                                                    fontSize: '0.75rem',
                                                                    color: 'grey',
                                                                }}
                                                            >
                                                                <Typography variant="caption" sx={{ color: isUserMessage ? 'white' : 'black', mt: '0.55rem' }}>
                                                                    {(() => {
                                                                        return new Intl.DateTimeFormat('pt-BR', {
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                        }).format(new Date(msg?.dataCadastro?.seconds * 1000 + 3 * 3600 * 1000))
                                                                    })()}
                                                                </Typography>


                                                                {isUserMessage && (
                                                                    <Box sx={{ marginLeft: '0.3rem', mt: '0.55rem' }}>
                                                                        {msg.lido ? readIcon : deliveredIcon}
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            {showAvatar && (
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        [isUserMessage ? 'right' : 'left']: '-8px',
                                                                        width: 0,
                                                                        height: 0,
                                                                        border: '8px solid transparent',
                                                                        borderTopColor: isUserMessage ? theme.palette.primary.main : '#e7e7e7',
                                                                        borderBottom: 0,
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                );
                                            })
                                        )}
                                        <div ref={messagesEndRef} />
                                    </Box>
                                </Box>
                            </Fade>
                        </Box>
                    )}
                </Card>
                {((entregadorSelecionado !== null && selectedTab === 'entregadores') || selectedTab === 'empresas') && (
                    <Box>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Digite sua mensagem..."
                            value={newMessage}
                            onChange={handleNewMessageChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'orange',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'orange',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'orange',
                                    },
                                },
                            }}
                            autoComplete='off'
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    handleSendMessage();
                                }
                            }}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={handleSendMessage}>
                                                {chatLoading ? <CircularProgress color='primary' size={24} /> : <Send color='primary' />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                            }}
                        />
                    </Box>)}
                {(tipoUsuario !== 'Representante' && tipoUsuario !== 'SAAS') && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <ToggleButtonGroup
                            value={userType}
                            fullWidth
                            exclusive
                            onChange={handleUserTypeChange}
                        >

                            <ToggleButton
                                onClick={() => setSelectedTab('empresas')}
                                value="Empresa"
                                selected={selectedTab === 'empresas'}
                                sx={{
                                    '&.Mui-selected': {
                                        color: theme.palette.primary.contrastText,
                                        borderColor: theme.palette.grey[500],
                                        backgroundColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-selected:hover': {
                                        backgroundColor: 'rgba(255, 165, 0, 0.2)',
                                    },
                                    textTransform: 'none',
                                }}
                            >
                                Empresa
                            </ToggleButton>
                            <ToggleButton
                                onClick={() => setSelectedTab('entregadores')}
                                value="entregador"
                                sx={{
                                    '&.Mui-selected': {
                                        color: theme.palette.primary.contrastText,
                                        borderColor: theme.palette.grey[500],
                                        backgroundColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-selected:hover': {
                                        backgroundColor: 'rgba(255, 165, 0, 0.2)',
                                    },
                                    textTransform: 'none',
                                }}
                            >
                                Entregador
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default Chat;
