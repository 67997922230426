import { Delete } from '@mui/icons-material';
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FormasDePagamento from 'src/ds/components/FormasDePagamento';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'; 
import { ContainerBodyModal, ModalBase, ModalOpcoes, TableNavigation, TableRowLoading, TableRowNotFound, useResponseSnackbar } from 'src/ds';
import { RootState } from 'src/store/reducers';
import { excluirFormaDePagamentoDAStart, limparTipoDeVeiculoDA, salvarFormaDePagamentoDAStart } from 'src/store/reducers/despachoAutomatico';
import { useState } from 'react';

const ModalFormasDePagamento = ({ open, setOpen, data }: any) => {
    const dispatch = useDispatch();

    const formasPagamento = useSelector((state: RootState) => state.despachoAutomatico.formasDePagamentoDA?.data?.dados);
    const loading = useSelector((state: RootState) => state.despachoAutomatico.formasDePagamentoDA?.loading);
    const response = useSelector((state: RootState) => state.despachoAutomatico.salvarFormaDePagamentoDA);
    useResponseSnackbar([{ response, clearAction: () => dispatch(limparTipoDeVeiculoDA()) }]);
    const hookForm = useForm({
        defaultValues: {
            pagina: 1,
            paginacao: 20,
            formaPagamentoID: ''
        }
    });

    const [showSelector, setShowSelector] = useState(false);
    const [openDeletar, setOpenDeletar] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);


    return (
        <ModalBase open={open} onClose={() => setOpen(false)} headerModal={{ title: 'Formas de pagamento' }} size='md'>
            <ContainerBodyModal>


                <Stack spacing={2} sx={{ flex: 1 }} useFlexGap px={2}>
                    <Button
                        variant="containedFilled"
                        sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                        onClick={() => setShowSelector(true)}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                    >
                        Adicionar forma de pagamento
                    </Button>

                    <ModalBase open={showSelector} onClose={() => setShowSelector(false)} headerModal={{ title: 'Formas de pagamento' }} size='sm'>
                        <Stack spacing={2} p={2} >
                            <FormasDePagamento hookForm={hookForm} />
                            <Stack direction="row" sx={{ justifyContent: 'flex-end', mt: 2, gap: 1 }} useFlexGap>
                                <Button
                                    color='secondary'
                                    variant="containedFilled"
                                    sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                                    onClick={() => setShowSelector(false)}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant="containedFilled"
                                    sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                                    onClick={() => {
                                        dispatch(salvarFormaDePagamentoDAStart({
                                            tabelaDespachoAutomaticoTipoVeiculoID: data?.tabelaDespachoAutomaticoTipoVeiculoID,
                                            formaPagamentoID: hookForm.getValues('formaPagamentoID'),
                                        }));
                                        setShowSelector(false)
                                    }}
                                >
                                    Salvar
                                </Button>
                            </Stack>
                        </Stack>
                    </ModalBase>
                </Stack>

                <Stack sx={{ flex: 1, }}>
                    {formasPagamento?.ultimaPagina ? (
                        <TableNavigation
                            indiceInicial={formasPagamento.indiceInicial}
                            indiceFinal={formasPagamento.indiceFinal}
                            indiceTotal={formasPagamento.total}
                            ultimaPagina={formasPagamento?.ultimaPagina}
                            hookForm={hookForm}
                        />
                    ) : null}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Forma de pagamento</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRowLoading />
                            ) : Array.isArray(formasPagamento?.lista) && formasPagamento?.lista?.length > 0 ? (
                                formasPagamento?.lista.map((item: any) => (
                                    <TableRow key={item.id}>
                                        <TableCell align="left">{item.formaPagamento?.nome}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => {
                                                setSelectedItem(item);
                                                setOpenDeletar(true)
                                            }} color='error'><Delete /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRowNotFound text="Nenhum resultado encontrado." fezRequisicao={true} />
                            )}
                        </TableBody>
                    </Table>
                </Stack>
            </ContainerBodyModal>
            <ModalOpcoes
                descricao={
                    <Typography variant="body1" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        Remover <Typography component="span" fontWeight="bold" sx={{ display: 'inline' }}>{selectedItem?.formaPagamento?.nome}</Typography> da tabela
                    </Typography>}
                acao="Excluir"
                open={openDeletar}
                onClose={() => { setOpenDeletar(false) }}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => setOpenDeletar(false)
                    },
                    {
                        label: 'Excluir',
                        action: () => {
                            dispatch(excluirFormaDePagamentoDAStart({
                                id: selectedItem.tabelaDespachoAutomaticoTipoVeiculoFormaPagamentoID,
                                tabelaDespachoAutomaticoTipoVeiculoID: data?.tabelaDespachoAutomaticoTipoVeiculoID
                            }))
                            setOpenDeletar(false)

                        }
                    }
                ]}
            />
        </ModalBase>
    )
}

export default ModalFormasDePagamento