// ListCardTabelaTotal.tsx
import { Grid2, Stack } from '@mui/material';
import React from 'react';
import CardTabelaTotal, { CardTabelaTotalProps } from './CardTabelaTotal';

export interface ListCardTabelaTotalProps {
  cardData?: CardTabelaTotalProps[]
}

const ListCardTabelaTotal: React.FC<ListCardTabelaTotalProps> = ({
  cardData
}) => {
  if (!cardData) return null;
  return (
    <Grid2
      container
      columns={12}
      spacing={2}
      sx={{
        mt: 2,
        width: '100%'
      }}
    >
      {cardData.map((card, index) => (
        <Grid2 key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <CardTabelaTotal
            srcIcon={card.srcIcon}
            altIcon={card.altIcon}
            bgcolor={card.bgcolor}
            texts={card.texts}
            sx={{
              height: '100%'
            }}
          />
        </Grid2>
      ))}
    </Grid2>
  );
};

export default ListCardTabelaTotal;
