import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

interface TabelaRankingProps {
  data: any;
  title: string;
}

const TabelaRanking = ({ data, title }: TabelaRankingProps) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 500, minWidth: 450, overflow: 'hidden', borderRadius: '6px' }}>
      <Typography variant="h6" sx={{ p: 2, bgcolor: 'background.paperDark', color: 'text.primary', textAlign: 'left' }}>
        {title}
      </Typography>
      <Table size="small" aria-label="a dense table" sx={{ minWidth: 300 }}>
        <TableHead>
          <TableRow sx={{ bgcolor: 'background.paper' }}>
            <TableCell sx={{ fontWeight: 'bold' }}>Ordem</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Nome</TableCell>
            <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Qtd de pedidos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ bgcolor: 'background.paperDark' }}>
          {data?.length > 0 ? (
            data?.map((row: any) => (
              <TableRow
                key={row.empresaID || row.entregadorID || row.bairroID}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.ordem}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.empresa || row.entregador || row.bairro}</TableCell>
                <TableCell>{row.qtdPedidos}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                Nenhum valor encontrado
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TabelaRanking;
