import React, { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle, Box, IconButton } from '@mui/material';
import {
  buscarTemplateRequest,
  emAndamentoStart,
  fetchMasterPageStart,
  listarRequest,
  walletStart,
  definirTipoDeUsuario,
} from '../../store/reducers/config';
import { fecharComunicado, listarComunicadosStart, toggleCriarPedido } from 'src/store/reducers/utils';
import { Close } from '@mui/icons-material';
import Header from 'src/ds/components/Header';
import { setCookies } from 'src/ds';
import { RootState } from 'src/store/reducers';

// Interface para empresa
interface Empresa {
  empresaID: string;
}

// Interface para a MasterPage (corrigida)
interface MasterPage {
  empresas: Empresa[];
  pickSelecionada?: {
    pickID: string;
  };
  usuario?: {
    id: string;
    nome: string;
  };
}

// Estado de login
interface LoginState {
  tokenUsuario: string;
  usuario: {
    id: string;
    nome: string;
  };
}

// Estado de configuração
interface ConfigState {
  masterPage?: MasterPage;
  empresasPick?: any;
}


const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const token = useSelector((state: RootState) => state.login.tokenUsuario);
  const masterPage = useSelector((state: RootState) => state.config?.masterPage);
  const usuario = useSelector((state: any) => state.login.usuario);
  const empresasPick = useSelector((state: RootState) => state.config.empresasPick?.dados?.lista);
  const comunicados = useSelector((state: any) => state.utils?.comunicados?.data?.dados?.lista);
  const dispatch = useDispatch();
  const comunicadosAberto = useSelector((state: any) => state.utils.comunicadosAberto);
  const xtoken = localStorage.getItem('@token');
  const template = useSelector((state: RootState) => state.config?.template?.template);

  useEffect(() => {
    if (!template) return;
    const { faviconUrl, nome } = template
    const favicon = document.getElementById("dynamic-favicon") as HTMLLinkElement;
    const title = document.getElementById("dynamic-title") as HTMLTitleElement;
    if (favicon && faviconUrl && title) {
      favicon.href = faviconUrl;
      title.innerText = `${nome}`;
    }
  }, [template]);


  useEffect(() => {
    if (masterPage?.pickSelecionada?.pickID || masterPage?.usuario?.id) {
      localStorage.setItem(
        '@xpick',
        masterPage?.pickSelecionada?.pickID ??
        masterPage?.usuario?.id ??
        ''
      );
    }

    if (masterPage?.empresas && masterPage.empresas.length > 0) {
      dispatch(definirTipoDeUsuario({ tipo: 'Representante', empresas: masterPage.empresas }));
    } else {
      dispatch(definirTipoDeUsuario({ tipo: 'SAAS', empresas: empresasPick }));
    }
  }, [masterPage]);

  const hasFetchedData = useRef(false);

  const handleClose = (comunicadoID: string) => {
    dispatch(fecharComunicado(comunicadoID));
  };


  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (!hasFetchedData.current) {
          localStorage.setItem('@token', token);
          setCookies('x-token', token);
          dispatch(buscarTemplateRequest());
          dispatch(fetchMasterPageStart());
          dispatch(listarComunicadosStart({}));
          dispatch(emAndamentoStart({ empresaID: usuario?.id }));
          dispatch(walletStart());
          dispatch(listarRequest());
          hasFetchedData.current = true;
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    if (token && !hasFetchedData.current) {
      fetchInitialData();
    }
  }, [usuario]);

  const abrirCriarPedido = () => {
    console.log('abrirCriarPedido');
    dispatch(toggleCriarPedido(true))
    setTimeout(() => {
      dispatch(toggleCriarPedido(false))
    }, 300)
  }

  if (!token && !xtoken) { return <Navigate to="/" /> }

  const stripHtmlTags = (html: string) => html.replace(/<\/?[^>]+(>|$)/g, '');

  return (
    <>
      <Box sx={{ maxWidth: '100vw', minHeight: '100vh' }}>
        <Header abrirCriarPedido={abrirCriarPedido} />
        {comunicados?.map((comunicado: any) => {
          const isOpen = comunicadosAberto[comunicado?.comunicadoID] ?? true;

          if (!isOpen) return null;

          return (
            <Alert
              key={comunicado.comunicadoID}
              title={comunicado.titulo}
              severity={
                comunicado.prioridade === 1
                  ? 'warning'
                  : comunicado.prioridade === 4
                    ? 'error'
                    : 'success'
              }
              sx={{
                alignItems: 'center',
                border: '1px solid black',
                m: 1,
              }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => dispatch(fecharComunicado(comunicado.comunicadoID))}
                >
                  <Close fontSize="small" />
                </IconButton>
              }
            >
              <AlertTitle>{comunicado.titulo}</AlertTitle>
              {stripHtmlTags(comunicado.mensagem)}
            </Alert>
          );
        })}



        <Box sx={{ minHeight: 'calc(100vh - 52px)' }} id="protectedRouteChildren">
          {children}
        </Box>
      </Box>
    </>
  );
};

export default ProtectedRoute;
