function parseToDistance(value: string): number {
    const cleanedValue = Number(value.replace(/[^0-9]/g, ""));
    return parseFloat((cleanedValue / 100).toFixed(2));
}

function formatDistance(value: number): string {
    return `${value.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}


function liveMaskDistance(value?: string | number | null, onInput: boolean = true): string {
    if (value === null || value === undefined) return "";

    let numericValue: number;
    if (onInput) {
        numericValue = typeof value === "string" ? parseToDistance(value) : value;
    } else {
        numericValue = typeof value === "number" ? value : Number(value.replace(/[^0-9.,]/g, "").replace(".", "").replace(",", "."));
    }

    return formatDistance(numericValue);
}

export default liveMaskDistance;