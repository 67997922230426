import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Tv404 from './tv404';
import { RootState } from 'src/store/reducers';

const NotFound = () => {
  const token = useSelector((state: RootState) => state.login.tokenUsuario);
  return (
    <Grid container sx={{
      display: 'flex',
      flexWrap: 'nowrap',
      gap: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    }}>
      <Tv404 />
      <Typography
        variant="h1"
        textAlign={'center'}
        sx={{ marginBottom: 2, fontSize: '2rem' }}
      >
        <Typography variant='span' sx={{ color: '#FF9F0D', fontWeight: 'bold' }}> Error 404: </Typography>
        Página Não Encontrada
      </Typography>
      <Typography
        variant="h1"
        textAlign={'center'}
        sx={{ marginBottom: 2, fontSize: '1.3rem' }}
      >
        A página que você está tentando acessar não existe, por favor, verifque a URL e tente novamente...
      </Typography>
      <Button variant="containedFilled" sx={{ width: 'fit-content' }} component={Link} to={`${token ? '/Painel' : '/'}`} color="primary">
        Voltar para a página inicial
      </Button>
    </Grid>
  );
};

export default NotFound;
