// SelectTipoFatura.tsx
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name?: string;
}


interface SelectTipoFaturaProps {
  hookForm: HookForm;
  label?: string;
  size?: 'small' | 'medium';
  sx?: SxProps;
}

const SelectTipoFatura: React.FC<SelectTipoFaturaProps> = ({
  size = 'small',
  sx,
  label = 'Tipo de fatura',
  hookForm: { methods, rules, name = 'tipoFatura' },
}) => {
  const { register, formState: { errors }, watch } = methods;
  const inputValue = watch(name)
  return (
    <FormControl
      size={size}
      sx={sx}
      error={!!errors[name]}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        {...register(name, rules)}
        value={inputValue !== undefined && inputValue !== null ? String(inputValue) : ''}
        label={label}
      >
        <MenuItem value="0">Pós-pago</MenuItem>
        <MenuItem value="1">Pré-pago</MenuItem>
        <MenuItem value="2">Coleta</MenuItem>
      </Select>
      {errors[name] && <FormHelperText>{String(errors[name]?.message ?? '')}</FormHelperText>}
    </FormControl>
  );
};

export default SelectTipoFatura;
