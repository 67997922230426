import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import ModalBase, { ModalBaseProps } from 'src/ds/components/ModalBase'
import { ButtonLoading, ContainerBodyModal, InputDateTime, TableRowLoading, TableRowNotFound } from 'src/ds'
import { Bots, MensagemBot } from 'src/dtos/BotsDTO';
import { RootState } from 'src/store/reducers';
import { listarMensagensClear, listarMensagensStart } from 'src/store/reducers/bots';

interface ModalMsgsProps {
    modalProps: ModalBaseProps;
    bot: Bots;
}

interface FormData {
    pagina: number;
    paginacao: number;
    dataInicio: string;
    dataFim: string;
}

const ModalMsgs = ({ modalProps, bot }: ModalMsgsProps) => {
    const dispatch = useDispatch()
    const listaMensagensStore = useSelector((state: RootState) => state.bots.listaMensagens)
    const listaMensagensLista = useSelector((state: RootState) => state.bots.listaMensagens.data?.dados)
    const { showError } = useSnackbarContext();

    const hookForm = useForm({
        defaultValues: {
            pagina: 1,
            paginacao: -1,
            dataInicio: dayjs().subtract(2, 'day').format('DD/MM/YYYY HH:mm'),
            dataFim: dayjs().format('DD/MM/YYYY HH:mm'),
        }
    })

    const listarRequisicao = (data?: FormData) => {
        dispatch(listarMensagensStart({
            auxPickWppBotID: bot.pickWppBotID,
            pickWppBotID: bot.pickWppBotID,
            paginacao: -1,
            pagina: 1,
            dataInicio: data?.dataInicio,
            dataFim: data?.dataFim
        }))
    }

    const handleClick = () => {
        listarRequisicao(hookForm.getValues())
    }

    useEffect(() => {
        listarRequisicao()
        return () => {
            dispatch(listarMensagensClear())
        }
    }, [])

    useEffect(() => {
        if (listaMensagensStore.error) {
            showError({
                message: listaMensagensStore.error,
                duration: 5000
            })
        }
    }, [listaMensagensStore.error])

    return (
        <>
            <ModalBase {...modalProps}>
                <ContainerBodyModal>
                    <Box component={'form'}>
                        <Stack
                            useFlexGap
                            sx={{ width: '100%', gap: 2, boxSizing: 'border-box', flexDirection: 'row', flexWrap: 'wrap' }}
                        >
                            <InputDateTime
                                hookForm={{
                                    methods: hookForm,
                                    name: 'dataInicio',
                                }}
                                label='Data Inicial'
                                sx={{ flex: '1 1 250px' }}
                            />
                            <InputDateTime
                                hookForm={{
                                    methods: hookForm,
                                    name: 'dataFim',
                                }}
                                label='Data Final'
                                sx={{ flex: '1 1 250px' }}
                            />
                        </Stack>
                        <Stack sx={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                            <ButtonLoading
                                loading={listaMensagensStore.loading}
                                variant='containedFilled'
                                onClick={handleClick}
                                sx={{ width: 'min-content', mt: 2 }}
                            >
                                Filtrar
                            </ButtonLoading>
                        </Stack>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' width='45%'>
                                        Mensagem
                                    </TableCell>
                                    <TableCell align='center' width='15%'>
                                        Data de envio
                                    </TableCell>
                                    <TableCell align='center' width='15%'>
                                        Data recebido
                                    </TableCell>
                                    <TableCell align='center' width='15%'>
                                        Destinatário
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listaMensagensStore.loading
                                    ? <TableRowLoading />
                                    : listaMensagensLista?.lista && listaMensagensLista?.lista.length > 0
                                        ? listaMensagensLista?.lista.map((mensagem: MensagemBot) => (
                                            <TableRow>
                                                <TableCell align='center' width='60%'>
                                                    {mensagem.mensagemEnviada}
                                                </TableCell>
                                                <TableCell align='center' width='10%'>
                                                    {mensagem.dataEnvio}
                                                </TableCell>
                                                <TableCell align='center' width='10%'>
                                                    {mensagem.dataRecebidoDestinatario}
                                                </TableCell>
                                                <TableCell align='center' width='20%'>
                                                    {mensagem.chatIdTo.replace(/\D/g, '')}
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRowNotFound />
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </ContainerBodyModal>
            </ModalBase>
        </>
    )
}

export default ModalMsgs