// TextFieldTelefone.tsx
import React, { useEffect, useState, useCallback } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';

/**
 * Formata a entrada de um número de telefone de acordo com os padrões brasileiros.
 * @param {string} input - A string bruta que representa o número de telefone.
 * @returns {string} - O número de telefone formatado (ex: "(99) 99999-9999").
 */
const maskTelefone = (input: string): string => {
    if (!input) return '';
    // Remove todos os caracteres não numéricos
    const cleaned = ('' + input).replace(/\D/g, '');

    // Verifica e formata o número com 9 dígitos (ex: (99) 99999-9999)
    const match9 = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    // Verifica e formata o número com 8 dígitos (ex: (99) 9999-9999)
    const match8 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

    if (match9) {
        return `(${match9[1]}) ${match9[2]}-${match9[3]}`;
    } else if (match8) {
        return `(${match8[1]}) ${match8[2]}-${match8[3]}`;
    }

    return cleaned;
};

interface HookForm {
    methods: UseFormReturn<any>;
    rules?: RegisterOptions;
    name: string;
    defaultValue?: string | null;
}

interface TextFieldTelefoneProps {
    hookForm: HookForm;
    label?: string;
    size?: 'small' | 'medium';
    sx?: SxProps;
    slotProps?: TextFieldProps['slotProps'];
}

/**
 * Componente `TextFieldTelefone`
 * 
 * Um campo de entrada controlado e mascarado para números de telefone,
 * integrado ao React Hook Form. Esse componente exibe o número formatado em
 * tempo real enquanto o usuário digita, seguindo o padrão brasileiro de 
 * formatação de telefone.
 * 
 * @component
 * 
 * @param {Object} props - Propriedades do componente
 * @param {HookForm} props.hookForm - Objeto contendo os métodos e propriedades do React Hook Form
 * @param {string} [props.label] - Texto exibido como rótulo do campo de entrada
 * @param {'small' | 'medium'} [props.size='small'] - Tamanho do campo de entrada
 * @param {SxProps} [props.sx] - Propriedades de estilo do Material-UI para estilização adicional do campo
 * @param {TextFieldProps['slotProps']} [props.slotProps] - Propriedades extras para os slots do TextField
 * 
 * @example
 * // Exemplo de uso do TextFieldTelefone com React Hook Form
 * <TextFieldTelefone
 *    hookForm={{ 
 *      methods,
 *      name: 'telefone',
 *      defaultValue: '(99) 99999-9999',
 *    }}
 *    label="Telefone"
 * />
 * 
 * @returns {React.ReactElement} Um campo de entrada controlado com máscara para números de telefone
 */
const TextFieldTelefone: React.FC<TextFieldTelefoneProps> = ({
    size = 'small',
    sx,
    label,
    hookForm: { methods, rules, name, defaultValue },
    slotProps
}) => {
    const { setValue, formState: { errors }, watch, control } = methods;
    const [displayValue, setDisplayValue] = useState<string>('');

    useEffect(() => {
        if (defaultValue !== undefined && defaultValue !== null) {
            const value = defaultValue.replace(/\D/g, '');
            const formattedValue = maskTelefone(value);
            setDisplayValue(formattedValue);
            setValue(name, value, { shouldValidate: true });
        }
    }, [defaultValue, name, setValue]);

    const inputValue = watch(name);
    useEffect(() => {
        if (inputValue) {
            const formattedValue = maskTelefone(inputValue);
            setDisplayValue(formattedValue);
        }
    }, [inputValue]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value.replace(/\D/g, '');
        const maskedValue = maskTelefone(rawValue);
        setValue(name, rawValue, { shouldValidate: true });
        setDisplayValue(maskedValue);
    }, [name, setValue]);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
                <TextField
                    {...field}
                    label={label}
                    value={displayValue}
                    onChange={handleChange}
                    error={!!errors[name]}
                    helperText={errors[name] ? String(errors[name]?.message ?? '') : ''}
                    size={size}
                    sx={sx}
                    slotProps={{
                        htmlInput: {
                            maxLength: 15
                        },
                        ...slotProps
                    }}
                />
            )}
        />
    );
};

export default TextFieldTelefone;
