import React, { useRef, useState } from "react";
import { Box, Theme } from "@mui/material";
import { SnackbarAlertContext } from "src/ds";

interface SnackbarProps {
    id: string;
    onClose: () => void;
    title?: string;
    message?: string;
    duration?: number;
    severity: 'success' | 'error' | 'warning' | 'info';
    position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
    containerRef?: React.RefObject<HTMLDivElement>
}

interface SnackbarContext {
    showSnackbar: (props: Omit<SnackbarProps, 'id' | 'onClose'>) => void;
    showSuccess: (props: Omit<SnackbarProps, 'id' | 'onClose' | 'severity'>) => void;
    showError: (props: Omit<SnackbarProps, 'id' | 'onClose' | 'severity'>) => void;
    showWarning: (props: Omit<SnackbarProps, 'id' | 'onClose' | 'severity'>) => void;
    showInfo: (props: Omit<SnackbarProps, 'id' | 'onClose' | 'severity'>) => void;
}

const SnackbarContext = React.createContext<SnackbarContext | null>(null);

export const useSnackbarContext = () => {
    const context = React.useContext(SnackbarContext);
    if (!context) {
        throw new Error("useSnackbar must be used within a SnackbarProvider");
    }
    return context;
};

const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [snackbarList, setSnackbarList] = useState<SnackbarProps[]>([]);
    // const timersRef = useRef<Map<string, number>>(new Map());

    type Position = "top-left" | "top-right" | "bottom-left" | "bottom-right";

    const containerRefs: Record<Position, React.RefObject<HTMLDivElement>> = {
        "top-left": useRef<HTMLDivElement>(null),
        "top-right": useRef<HTMLDivElement>(null),
        "bottom-left": useRef<HTMLDivElement>(null),
        "bottom-right": useRef<HTMLDivElement>(null),
    };

    const removeSnackbar = React.useMemo(() => (id: string) => {
        setSnackbarList((prev) => prev.filter((snackbar) => snackbar.id !== id));
        // const timer = timersRef.current.get(id);
        // console.log(timer, id)
        // if (timer) {
        //     clearTimeout(timer);
        //     timersRef.current.delete(id);
        // }
    }, [setSnackbarList /*, timersRef */]);

    const showSnackbar = (props: Omit<SnackbarProps, 'id' | 'onClose'>) => {
        const id = crypto.randomUUID();
        const newSnackbar = { ...props, id, position: props.position || 'top-right', onClose: () => removeSnackbar(id) };
        console.log('newSnackbar', newSnackbar)
        setSnackbarList((prev) => [...prev, newSnackbar]);
    };

    const showSuccess = (props: Omit<SnackbarProps, 'id' | 'onClose' | 'severity'>) => {
        showSnackbar({ ...props, severity: 'success' })
    }

    const showError = (props: Omit<SnackbarProps, 'id' | 'onClose' | 'severity'>) => {
        showSnackbar({ ...props, severity: 'error' })
    }

    const showWarning = (props: Omit<SnackbarProps, 'id' | 'onClose' | 'severity'>) => {
        showSnackbar({ ...props, severity: 'warning' })
    }

    const showInfo = (props: Omit<SnackbarProps, 'id' | 'onClose' | 'severity'>) => {
        showSnackbar({ ...props, severity: 'info' })
    }

    // useEffect(() => {
    //     snackbarList.forEach((snackbar) => {
    //         if (snackbar.duration && !timersRef.current.has(snackbar.id)) {console.log('temporizador', snackbar.duration)
    //         }
    //     });
    //     console.log(snackbarList)
    //     return () => {
    //         snackbarList.forEach((snackbar) => {
    //             const timer = timersRef.current.get(snackbar.id);
    //             if (timer) {
    //                 clearTimeout(timer);
    //                 timersRef.current.delete(snackbar.id);
    //             }
    //         });
    //     };
    // }, [snackbarList]);

    return (
        <SnackbarContext.Provider
            value={{
                showSnackbar,
                showSuccess,
                showError,
                showWarning,
                showInfo
            }}
        >
            {children}
            {['top-right', 'top-left', 'bottom-right', 'bottom-left'].map((position) => {
                return (
                    <Box
                        key={position}
                        sx={(theme: Theme) => ({
                            height: 'fit-content',
                            boxSizing: 'border-box',
                            position: 'fixed',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            margin: '20px',
                            width: '100%',
                            maxWidth: '400px',
                            zIndex: theme.zIndex.snackbar,
                            '&.top-left': {
                                top: 0,
                                left: 0,
                            },
                            '&.top-right': {
                                top: 0,
                                right: 0,
                            },
                            '&.bottom-left': {
                                bottom: 0,
                                left: 0,
                            },
                            '&.bottom-right': {
                                bottom: 0,
                                right: 0,
                            },
                        })}
                        className={`${position}`}
                        ref={containerRefs[position as Position]}
                    />
                )
            })}
            {snackbarList.map((snackbar) => (
                <SnackbarAlertContext
                    key={snackbar.id}
                    {...snackbar}
                    open={true}
                    containerRef={containerRefs[snackbar.position!]}
                />
            ))}
        </SnackbarContext.Provider>
    );
}

export default SnackbarProvider;
