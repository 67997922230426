import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import { adicionarEmpresaTabelaFailure, adicionarEmpresaTabelaStart, adicionarEmpresaTabelaSuccess, deletarEmpresaTabelaFailure, deletarEmpresaTabelaStart, deletarEmpresaTabelaSuccess, duplicarTabelaCobrancaFailure, duplicarTabelaCobrancaStart, duplicarTabelaCobrancaSuccess, excluirTabelaFailure, excluirTabelaPorBairroFailure, excluirTabelaPorBairroStart, excluirTabelaPorBairroSuccess, excluirTabelaPorKmFailure, excluirTabelaPorKmStart, excluirTabelaPorKmSuccess, excluirTabelaPorRaioFailure, excluirTabelaPorRaioStart, excluirTabelaPorRaioSuccess, excluirTabelaStart, excluirTabelaSuccess, excluirTipoDeVeiculoTabelaFailure, excluirTipoDeVeiculoTabelaStart, excluirTipoDeVeiculoTabelaSuccess, limparEmpresaTabela, limparSalvarTabela, limparSalvarTipoVeiculo, limparTabelaPorBairro, limparTabelaPorKmCalculado, limparTabelaPorRaio, listarEmpresaTabelaCobranca, listarEmpresaTabelaCobrancaFailure, listarEmpresaTabelaCobrancaStart, listarEmpresaTabelaCobrancaSuccess, listarPorBairroFailure, listarPorBairroStart, listarPorBairroSuccess, listarPorKmCalculadoFailure, listarPorKmCalculadoStart, listarPorKmCalculadoSuccess, listarPorRaioFailure, listarPorRaioStart, listarPorRaioSuccess, listarTipoVeiculoTabelaCobrancaFailure, listarTipoVeiculoTabelaCobrancaStart, listarTipoVeiculoTabelaCobrancaSuccess, listaTabelaCobrancaFailure, listaTabelaCobrancaStart, listaTabelaCobrancaSuccess, paginaTabelaCobrancaFailure, paginaTabelaCobrancaStart, paginaTabelaCobrancaSuccess, salvarTabelaFailure, salvarTabelaPorBairroFailure, salvarTabelaPorBairroStart, salvarTabelaPorBairroSuccess, salvarTabelaPorKmFailure, salvarTabelaPorKmStart, salvarTabelaPorKmSuccess, salvarTabelaPorRaioFailure, salvarTabelaPorRaioStart, salvarTabelaPorRaioSuccess, salvarTabelaStart, salvarTabelaSuccess, salvarTipoVeiculoTabelaFailure, salvarTipoVeiculoTabelaStart, salvarTipoVeiculoTabelaSuccess } from '../reducers/tabelaCobranca';

export function* listaTabelaCobranca(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/TabelaCobranca/Listar', form);

        if (response.data.sucesso !== true) {
            yield put(listaTabelaCobrancaFailure(response.data));
            throw new Error(response.data.erro);
        }

        yield put(listaTabelaCobrancaSuccess(response.data));
    } catch (error) {
        yield put(listaTabelaCobrancaFailure(error.toString()));
    }
}

export function* listaEmpresaTabelaCobranca(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobrancaEmpresa/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(listarEmpresaTabelaCobrancaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(listarEmpresaTabelaCobrancaSuccess(response.data));
    } catch (error) {
        yield put(listarEmpresaTabelaCobrancaFailure(error.toString()));

    }
}

export function* listarTiposDeVeiculoTabelaCobranca(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobrancaTipoVeiculo/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(listarTipoVeiculoTabelaCobrancaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(listarTipoVeiculoTabelaCobrancaSuccess(response.data));
    } catch (error) {
        yield put(listarTipoVeiculoTabelaCobrancaFailure(error.toString()));
    }
}

function* listarPorRaio(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobrancaKm/Listar', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }

        yield put(listarPorRaioSuccess(response.data));
    } catch (error) {
        yield put(listarPorRaioFailure(error.toString()));
    }
}

function* listarPorBairro(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobrancaBairro/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(listarPorBairroFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(listarPorBairroSuccess(response.data));
    } catch (error) {
        yield put(listarPorBairroFailure(error.toString()));
    }
}

function* listarPorKmCalulado(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobrancaKmCalculado/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(listarPorKmCalculadoFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(listarPorKmCalculadoSuccess(response.data));
    } catch (error) {
        yield put(listarPorKmCalculadoFailure(error.toString()));
    }
}

function* salvarTabela(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })

    try {
        const response = yield call(api.post, '/TabelaCobranca/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(salvarTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }

        yield put(salvarTabelaSuccess(response.data));
    } catch (error) {
        yield put(salvarTabelaFailure(error.toString()));
    }
}

function* excluirTabela(action) {
    try {
        const response = yield call(api.post, '/TabelaCobranca/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(excluirTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }

        yield put(excluirTabelaSuccess(response.data));
    } catch (error) {
        yield put(excluirTabelaFailure(error.toString()));
    }
}

function* adicionarEmpresaTabela(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobrancaEmpresa/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(adicionarEmpresaTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(adicionarEmpresaTabelaSuccess(response.data));
    } catch (error) {
        yield put(adicionarEmpresaTabelaFailure(error.toString()));
    }
}

function* deletarEmpresaTabela(action) {
    try {
        const response = yield call(api.post, '/TabelaCobrancaEmpresa/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(adicionarEmpresaTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(deletarEmpresaTabelaSuccess(response.data));
    } catch (error) {
        yield put(deletarEmpresaTabelaFailure(error.toString()));
    }
}

function* salvarTIpoVeiculoTabelaCobranca(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobrancaTipoVeiculo/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(salvarTipoVeiculoTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(salvarTipoVeiculoTabelaSuccess(response.data));
    } catch (error) {
        yield put(salvarTipoVeiculoTabelaFailure(error.toString()));
    }
}

function* excluirTipoDeVeiculoTabela(action) {
    try {
        const response = yield call(api.post, '/TabelaCobrancaTipoVeiculo/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(excluirTipoDeVeiculoTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(excluirTipoDeVeiculoTabelaSuccess(response.data));
    } catch (error) {
        yield put(excluirTipoDeVeiculoTabelaFailure(error.toString()));
    }
}

function* salvarTabelaPorRaio(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobrancaKm/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(salvarTabelaPorRaioFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(salvarTabelaPorRaioSuccess(response.data));
    } catch (error) {
        yield put(salvarTabelaPorRaioFailure(error.toString()));
    }
}

function* excluirTabelaPorRaio(action) {
    try {
        const response = yield call(api.post, '/TabelaCobrancaKm/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(excluirTabelaPorRaioFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(excluirTabelaPorRaioSuccess(response.data));
    } catch (error) {
        yield put(excluirTabelaPorRaioFailure(error.toString()));
    }
}

function* excluirTabelaPorBairro(action) {
    try {
        const response = yield call(api.post, '/TabelaCobrancaBairro/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(excluirTabelaPorBairroFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(excluirTabelaPorBairroSuccess(response.data));
    } catch (error) {
        yield put(excluirTabelaPorBairroFailure(error.toString()));
    }
}

function* salvarTabelaPorKm(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobrancaKmCalculado/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(salvarTabelaPorKmFailure(response.data.mensagem));
            throw new Error(response.data.mensagem);
        }
        yield put(salvarTabelaPorKmSuccess(response.data));
    } catch (error) {
        console.log(error);
    }
}

function* excluirTabelaPorKm(action) {
    try {
        const response = yield call(api.post, '/TabelaCobrancaKmCalculado/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(excluirTabelaPorKmFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(excluirTabelaPorKmSuccess(response.data));
    } catch (error) {
        yield put(excluirTabelaPorKmFailure(error.toString()));
    }
}

function* salvarTabelaPorBairro(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobrancaBairro/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(salvarTabelaPorBairroFailure(response.data));
        }
        yield put(salvarTabelaPorBairroSuccess(response.data));
    } catch (error) {
        yield put(salvarTabelaPorBairroFailure(error.toString()));
    }
}

function* duplicarTabelaCobranca(action) {
    try {
        const response = yield call(api.post, '/TabelaCobranca/Duplicar?tabelaCobrancaID=' + action.payload.tabelaCobrancaID);
        if (response.data.sucesso !== true) {
            yield put(duplicarTabelaCobrancaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(duplicarTabelaCobrancaSuccess(response.data));
    } catch (error) {
        yield put(duplicarTabelaCobrancaFailure(error.toString()));
    }
}

function* paginaTabelaCobranca(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaCobranca/Pagina', form);
        if (response.data.sucesso !== true) {
            yield put(paginaTabelaCobrancaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(paginaTabelaCobrancaSuccess(response.data));
    } catch (error) {
        yield put(paginaTabelaCobrancaFailure(error.toString()));
    }
}

function* listarTabelasCobrancaSaga() {
    yield takeLatest(listaTabelaCobrancaStart.type, listaTabelaCobranca);
    yield takeLatest(listarEmpresaTabelaCobrancaStart.type, listaEmpresaTabelaCobranca);
    yield takeLatest(listarTipoVeiculoTabelaCobrancaStart.type, listarTiposDeVeiculoTabelaCobranca);
    yield takeLatest(listarPorRaioStart.type, listarPorRaio);
    yield takeLatest(listarPorBairroStart.type, listarPorBairro);
    yield takeLatest(listarPorKmCalculadoStart.type, listarPorKmCalulado);
    yield takeLatest(salvarTabelaStart.type, salvarTabela);
    yield takeLatest(excluirTabelaStart.type, excluirTabela);
    yield takeLatest(adicionarEmpresaTabelaStart.type, adicionarEmpresaTabela);
    yield takeLatest(deletarEmpresaTabelaStart.type, deletarEmpresaTabela);
    yield takeLatest(salvarTipoVeiculoTabelaStart.type, salvarTIpoVeiculoTabelaCobranca);
    yield takeLatest(excluirTipoDeVeiculoTabelaStart.type, excluirTipoDeVeiculoTabela);
    yield takeLatest(salvarTabelaPorRaioStart.type, salvarTabelaPorRaio);
    yield takeLatest(excluirTabelaPorRaioStart.type, excluirTabelaPorRaio);
    yield takeLatest(salvarTabelaPorKmStart.type, salvarTabelaPorKm);
    yield takeLatest(excluirTabelaPorKmStart.type, excluirTabelaPorKm);
    yield takeLatest(salvarTabelaPorBairroStart.type, salvarTabelaPorBairro);
    yield takeLatest(excluirTabelaPorBairroStart.type, excluirTabelaPorBairro);
    yield takeLatest(duplicarTabelaCobrancaStart.type, duplicarTabelaCobranca);
    yield takeLatest(paginaTabelaCobrancaStart.type, paginaTabelaCobranca);
}

export default listarTabelasCobrancaSaga;
