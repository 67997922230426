import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper, IconButton, Theme, useTheme, Avatar, Typography, Collapse } from "@mui/material";
import { ArrowDownward, ArrowUpward, Delete, Edit, KeyboardArrowDown, KeyboardArrowUp, Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import editar from "../../assets/icons/editar.png";
import deleteIcon from "../../assets/icons/delete.png";
import { TableRowLoading, TableRowNotFound, ButtonTableOrder, ChipSituacao } from "src/ds"; // Adicione ButtonTableOrder aqui
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
import ModalQrCode from "../common/ModalQrCode";
import { dinheiro, formatCnpj } from "src/ds/common";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "src/context/PermissionsContext";
import { ButtonChip } from "src/ds/components/ButtonChip";
import ModalTransacoes from "./MinhasTransacoes";
import Detalhes from "./Detalhes";
import { RootState } from "src/store/reducers";
import RowFatura from "./RowFatura";

interface Row {
    rowID?: string;
    codigo?: number;
    assinaturaFaturaID: string;
    planoAssinaturaID?: string;
    dataCadastro?: string;
    dataFechamento?: string;
    dataPeriodoInicio?: string;
    dataPeriodoFim?: string;
    descricao?: string;
    tipoFatura?: number;
    faturaAtrasada?: boolean;
    faturaNaoPaga?: boolean;
    telefoneResponsavel?: string;
    emailFinanceiroResponsavel?: string;
    usuarioResponsavel?: string;
    enderecoStr?: string;
    valor?: number;
    formaPagamentoNome?: string;
    formaPagamentoID?: string;
    pickIDs?: string[];
    itens?: {
        assinaturaFaturaItemID?: string;
        descricao?: string;
        dataCadastro?: string;
        valor?: number;
    }[];
    mensagemCliente?: string | null;
    notaFiscal?: {
        notaFiscalID: "a879741b-4ddf-11ef-ba6f-0aa63e100dd7",
        "chave": "aadc2e15-9169-4b63-9607-dc46484f74e6",
        "tipo": "NFSe",
        "dataCadastro": "29/07/2024 16:20:52",
        "dataSituacao": "29/07/2024 16:20:52",
        "log": "{\"metodo\":\"GerarNfse\",\"success\":true,\"status\":\"aprovado\",\"nfse\":[{\"numero\":\"1570\",\"codigo_verificacao\":\"F6A71E01A\",\"data_emissao\":\"2024-07-29T16:20:49\"}]}",
        "motivoCancelamento": null,
        "numeroNfe": 1566,
        "numeroSerie": 3,
        "recibo": "",
        "situacao": "aprovado",
        "urlDanfe": "https://api.webmaniabr.com/nfse/aadc2e15-9169-4b63-9607-dc46484f74e6",
        "urlXml": "https://api.webmaniabr.com/xmlnfse/aadc2e15-9169-4b63-9607-dc46484f74e6",
        "xmlID": "a879741d-4ddf-11ef-ba6f-0aa63e100dd7",
        "xml": {
            "arquivoID": "a879741d-4ddf-11ef-ba6f-0aa63e100dd7",
            "nomeArquivo": "danfe-a879741b-4ddf-11ef-ba6f-0aa63e100dd7.xml",
            "tamanho": 6245,
            "criadorUsuario": null,
            "dataCadastro": "29/07/2024 16:20:52"
        },
        "danfeID": "a879741c-4ddf-11ef-ba6f-0aa63e100dd7",
        "danfe": {
            "arquivoID": "a879741c-4ddf-11ef-ba6f-0aa63e100dd7",
            "nomeArquivo": "danfe-a879741b-4ddf-11ef-ba6f-0aa63e100dd7.pdf",
            "tamanho": 108,
            "criadorUsuarioID": null,
            "dataCadastro": "29/07/2024 16:20:52"
        }
    };
}


interface TabelaFaturasProps {
    data: Row[];
    loading: boolean;
    fezRequisicao?: boolean;
}

const TabelaFaturas = ({ data, loading, fezRequisicao }: TabelaFaturasProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        nome: 'asc',
        codigoIdentificador: 'asc',
        dataCadastro: 'asc',
        cnpj: 'asc',
    });
    const [qrCoderowID, setQrCoderowID] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [assinaturaFaturaID, setAssinaturaFaturaID] = useState<string | undefined>(undefined);

    const podeEditarRow = useSelector((state: any) => state.config?.masterPage?.usuario?.['row.editar']);

    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        setSortDirection((prevState) => ({
            ...prevState,
            [column]: newSortDirection,
        }));

        const payload = {
            paginacao: 20,
            pagina: 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
        };

    };

    const handleQrCodeClick = (rowID: string) => {
        setQrCoderowID(rowID);
        setModalOpen(true);
    }

    const handleQrCodeClose = () => {
        setQrCoderowID(null);
        setModalOpen(false);
    }

    const permissions = usePermissions();

    const [isAllOpen, setIsAllOpen] = useState(false);

    const [openRow, setOpenRow] = useState<{ [key: string]: boolean }>(() => {
        if (!data || data.length === 0) return {};
        return data.reduce((acc, item, index) => ({
            ...acc,
            [item.assinaturaFaturaID]: index === 0,
        }), {});
    });

    useEffect(() => {
        if (!data || data.length === 0) return;

        setOpenRow((prevOpenRow) => {
            const updatedOpenRow = { ...prevOpenRow };
            data.forEach((item, index) => {
                if (index === 0) {
                    updatedOpenRow[item.assinaturaFaturaID] = true;
                }
            });
            return updatedOpenRow;
        });
    }, [data]);


    const handleToggle = useCallback((assinaturaFaturaID: string) => {
        setOpenRow(prev => {
            const newState: Record<string, boolean> = {};
            Object.keys(prev).forEach(key => {
                newState[key] = false;
            });
            newState[assinaturaFaturaID] = !prev[assinaturaFaturaID];
            return newState;
        });
    }, []);
    

    const handleToggleAll = useCallback(() => {
        setOpenRow(prev => {
            const updated = { ...prev };
            data?.forEach(item => {
                updated[item.assinaturaFaturaID] = !isAllOpen;
            });
            return updated;
        });
        setIsAllOpen(prev => !prev);
    }, [data, isAllOpen]);

    const template = useSelector((state: RootState) => state.config?.template?.template);

    return (
        <TableContainer component={Paper} sx={{ width: "100%", overflowY: "auto" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Código</TableCell>
                        <TableCell align="center">Data de vencimento</TableCell>
                        <TableCell align="center">Valor</TableCell>
                        <TableCell align="center">Situação</TableCell>
                        <TableCell align="center">Ações</TableCell>
                        <TableCell align="center">
                            <Stack spacing={1} direction='row' alignItems='center' justifyContent='center'>
                                <Typography>Detalhes</Typography>
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : data?.length > 0 ? (
                        data?.map((row, index) => (
                            <RowFatura
                                index={row.assinaturaFaturaID}
                                row={row}
                                handleToggle={handleToggle}
                                openRow={openRow[row.assinaturaFaturaID]} />

                        ))
                    ) : (
                        <TableRowNotFound text="Nenhuma fatura encontrada." />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TabelaFaturas;
