import { Box, Card, CardContent, Grid2, SxProps, Theme, Typography, useTheme } from '@mui/material'
import React from 'react'
import ImageWithLoading from './ImageWithLoading';

interface Texts {
    title1: string;
    subtitle1?: string | number;
    title2?: string;
    subtitle2?: string;
}

export interface CardTabelaTotalProps {
    srcIcon: string;
    altIcon?: string;
    bgcolor?: string;
    texts: Texts;
    sx?: SxProps
}

const CardTabelaTotal = ({
    srcIcon, altIcon, bgcolor, texts, sx
}: CardTabelaTotalProps) => {
    const theme = useTheme();

    return (
        <Card
            elevation={1}
            sx={{
                borderRadius: '10px',
                flex: '1 1 300px',
                maxHeight: 'fit-content',
                maxWidth: '600px',
                width: '100%',
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 2px 0 rgba(0, 0, 0, 0.16)',
                '& .MuiCardContent-root': {
                    height: '100%',
                    padding: 0,
                    '& .MuiGrid2-root': {
                        height: '100%',
                    }
                },
                '&:hover': {
                    transform: 'scale(1.03)',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                },
                ...sx
            }}
        >
            <CardContent>
                <Grid2 container>
                    <Grid2 size={{ xs: 3, sm: 3, md: 3, lg: 3 }}>
                        <ImageWithLoading
                            alt={altIcon ?? texts.title1}
                            src={srcIcon}
                            loadingColor={theme.palette.getContrastText(bgcolor ?? theme.palette.grey[600])}
                            boxProps={{
                                sx: {
                                    padding: { xs: '0.75rem', sm: '1rem', md: '1.5rem' },
                                    boxSizing: 'border-box',
                                    userSelect: 'none',
                                    bgcolor: bgcolor ?? theme.palette.grey[600],
                                }
                            }}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 9, sm: 9, md: 9, lg: 8 }} sx={{ p: 2 }}>
                        <Typography variant="body2">{texts.title1}</Typography>
                        {texts.subtitle1 && <Typography variant="body1" fontWeight={700}>{texts.subtitle1}</Typography>}
                        {texts.title2 && <Typography variant="body2" sx={{ mt: 1 }}>{texts.title2}</Typography>}
                        {texts.subtitle2 && <Typography variant="body2" fontWeight={700}>{texts.subtitle2}</Typography>}
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

export default CardTabelaTotal
