// SelectBanco.tsx
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listarBancosStart } from 'src/store/reducers/utils';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@mui/material';

interface Props {
  hookForm: UseFormReturn<any>;
}

const SelectBanco = ({ hookForm }: Props) => {
  const dispatch = useDispatch();
  const { control, setValue } = hookForm;

  useEffect(() => {
    dispatch(
      listarBancosStart({
        pagina: 1,
        paginacao: -1,
      })
    );
  }, [dispatch]);

  const bancos = useSelector((state: any) => state.utils?.bancos?.data?.dados?.lista);
  const loading = useSelector((state: any) => state.utils?.bancos?.loading);

  return (
    <FormControl fullWidth>
      <InputLabel size='small' id="select-banco-label">Selecione um Banco</InputLabel>
      <Controller
        name="bancoID"
        control={control}
        render={({ field }) => (
          <Select
            size='small'
            labelId="select-banco-label"
            label="Selecione um Banco"
            {...field}
            onChange={(e) => setValue('bancoID', e.target.value)}
            disabled={loading}
          >
            {loading ? (
              <MenuItem value="">
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              bancos?.map((banco: any) => (
                <MenuItem key={banco.bancoID} value={banco.bancoID}>
                  {banco.nome}
                </MenuItem>
              ))
            )}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default SelectBanco;
