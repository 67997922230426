import { Box, Button, Stack, } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InputDateTime, useResponseSnackbar } from 'src/ds';
import { limparSalvarPorDiaDaSemana, salvarAgendamentoPorDataStart } from 'src/store/reducers/taxaExtra';

interface AgendamentoDataProps {
    data: any;
}

const AgendamentoDatas = ({ data }: AgendamentoDataProps) => {
    const response3 = useSelector((state: any) => state.taxaExtra.salvarAgendamentoPorData);
    const loadingSalvar = response3?.data?.loading;

    useResponseSnackbar([
        { response: response3, clearAction: () => dispatch(limparSalvarPorDiaDaSemana()) }
    ]);

    const hookForm = useForm({
        defaultValues: {
            taxaExtraID: data?.taxaExtraID,
            dataInicio: '',
            dataFim: '',
        }
    });
    const dispatch = useDispatch();
    const handleSave = () => { dispatch(salvarAgendamentoPorDataStart(hookForm.getValues())) }

    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>

            <Stack spacing={2} >
                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} onSubmit={hookForm.handleSubmit(handleSave)}>
                    <InputDateTime
                        hookForm={{
                            name: 'dataInicio',
                            methods: hookForm,
                            rules: { required: 'Data inicial obrigatório' },
                        }}
                        label='Data inicial'
                    />
                    <InputDateTime
                        hookForm={{
                            name: 'dataFim',
                            methods: hookForm,
                            rules: { required: 'Data final obrigatório' },
                        }}
                        label='Data final'
                    />
                    <Button
                        variant="containedFilled"
                        sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                        type='submit'
                        disabled={loadingSalvar}
                    >
                        Salvar
                    </Button>
                </Box>
            </Stack>
        </Stack>
    );
}

export default AgendamentoDatas