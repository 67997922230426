import { Box, BoxProps, CircularProgress, extendTheme, Theme } from '@mui/material'
import React from 'react'

interface BoxLoadingProps extends BoxProps {
    loading?: boolean
}

const BoxLoading = ({ loading, children, ...props }: BoxLoadingProps) => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                ...props.sx,
            }}
            {...props}
        >
            {loading &&
                <Box
                    sx={(theme: Theme) => ({
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.3)',
                        backdropFilter: 'blur(1px)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    })}
                >
                    <CircularProgress />
                </Box>
            }
            {children}
        </Box >
    )
}

export default BoxLoading
