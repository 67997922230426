import { CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select, SxProps } from '@mui/material';
import React, { useEffect } from 'react';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { listarestados } from 'src/store/reducers/utils';

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name?: string;
}

interface SelectEstadoProps {
  hookForm: HookForm;
  label?: string;
  sx?: SxProps;
  size?: 'small' | 'medium';
}

const NoIcon: React.FC = () => <span />;

/**
 * Componente de seleção de estado.
 * 
 * O `SelectEstado` é um componente que exibe uma lista de estados disponíveis para seleção.
 * Ele utiliza o `react-hook-form` para gerenciar o estado do formulário e permite que o
 * usuário selecione um estado a partir de uma lista que é populada a partir de um estado global.
 * Este componente exibe um indicador de carregamento enquanto as opções de pagamento estão sendo buscadas.
 * 
 * @component
 * @param {SelectEstadoProps} props - Propriedades do componente.
 * @param {HookForm} props.hookForm - Objeto que contém métodos e configurações do `react-hook-form`.
 * @param {string} [props.label='Estado'] - Rótulo exibido no campo de seleção de estado.
 * @param {SxProps} [props.sx] - Estilos adicionais para customizar o componente.
 * @param {'small' | 'medium'} [props.size='small'] - Tamanho do componente, pode ser 'small' ou 'medium'.
 * @returns {React.ReactElement} Componente de seleção de estado.
 * 
 * @example
 * ```jsx
 * const methods = useForm();
 * <SelectEstado
 *    hookForm={{ methods: methods, name: 'estadoID' }}
 *    label="Escolha seu estado"
 * />
 * ```
 */
const SelectEstado: React.FC<SelectEstadoProps> = ({
  hookForm: { methods, rules, name = 'estadoID' },
  label = 'Estado',
  sx,
  size = 'small',
}) => {
  const estados = useSelector((state: RootState) => state.utils?.estados);
  const loading = useSelector((state: RootState) => state.utils?.estadosLoading);

  const dispatch = useDispatch();

  const { register, watch, setValue } = methods;

  useEffect(() => {
    if (!estados || estados.length <= 1) {
      const timeoutId = setTimeout(() => {
        dispatch(listarestados());
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, []); 


  return (
    <FormControl size={size} sx={{ width: '100%', ...sx }} >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        {...register(name, rules)}
        value={watch(name) || ''}
        onChange={(event) => setValue(name, event.target.value)}
        fullWidth
        endAdornment={(
          loading ? (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ) : null
        )}
        disabled={loading}
        IconComponent={loading ? NoIcon : undefined}
      >
        <MenuItem value="">
          Selecione...
        </MenuItem>
        {Array.isArray(estados) && estados.map((estado: any) => (
          <MenuItem key={estado.estadoID} value={estado.estadoID}>
            {estado.estadoNome}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectEstado;
