// StarRating.tsx
import { Box, Container } from '@mui/material';
import React, { useState } from 'react';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

interface StarRatingProps {
  estrelas: number;
  setEstrelas: (estrelas: number) => void;
  size?: string;
  cor?: string;
}

/**
 * Componente que exibe 5 estrelas para avaliação.
 *
 * @param {Number} props.estrelas - Estrelas selecionadas
 * @param {Function} props.setEstrelas - Função para definir Estrelas Selecionadas
 * @param {String} props.size - Tamanho das Estrelas
 * @param {String} props.cor - Cor das Estrelas
 */
const StarRating: React.FC<StarRatingProps> = ({ estrelas, setEstrelas, size = '2rem', cor = 'primary.main' }) => {
  const [estrelasTemporaria, setEstrelasTemporaria] = useState(0)
  return (
    <Container
      sx={{ display: 'flex', justifyContent: 'center', width: '100%', fontSize: size, lineHeight: size }}
    >
      {[1, 2, 3, 4, 5].map((x) => (
        <Box
          key={x}
          onClick={() => setEstrelas(x)}
          onMouseEnter={() => {
            setEstrelasTemporaria(x)
          }}
          onMouseLeave={() => {
            setEstrelasTemporaria(estrelas)
          }}
          sx={{
            cursor: 'pointer',
            borderRadius: '50%',
            boxSizing: 'border-box',
            transition: 'all .2s ease-in-out',
            '&:hover': { transform: 'scale(1.2)' }
          }}
        >
          {estrelasTemporaria < x
            ? <StarBorderRoundedIcon sx={{ fontSize: 'inherit' }} />
            : <StarRoundedIcon sx={{ fontSize: 'inherit', color: `${cor}` }} />
          }
        </Box>
      ))}
    </Container>
  );
};

export default StarRating;
