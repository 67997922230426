import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';

const FinalizarRastreio = () => {
    const theme = useTheme()
    const [valor, setValor] = React.useState('')

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0' }}>
                <TaskAltRoundedIcon sx={{ fontSize: { xs: '3rem', md: '6rem' }, color: theme.palette.primary.main }} />
                <Typography
                    sx={{
                        fontSize: { xs: '1rem', md: '2rem' },
                        fontWeight: '500'
                    }}
                >
                    Obrigado por avaliar esta corrida!
                </Typography>
            </Box>
        </Container>
    )
}

export default FinalizarRastreio