const liveMaskPercentage = (value: string | number) => {
    if (!value) return "0,00%"; // Retorna 0,00% se o valor estiver vazio

    // Remove tudo que não for número
    let numericValue = String(value).replace(/\D/g, "");

    // Se houver menos de três dígitos, adiciona zeros à esquerda
    if (numericValue.length <= 2) {
        numericValue = numericValue.padStart(3, "0");
    }

    // Converte para número com duas casas decimais
    const formattedValue = (Number(numericValue) / 100).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    // Retorna o valor formatado com o símbolo de porcentagem
    return `${formattedValue}`;
};

export default liveMaskPercentage;