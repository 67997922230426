//@ts-nocheck
import { Typography, useTheme, Box } from "@mui/material";
import headerImage from '../../assets/images/logo.png'; // Altere para o caminho correto da imagem
import FormFreemium from "./FormFreemium";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enviarDadosReq } from "../../store/reducers/login";
import { listarCidades, listarestados } from "../../store/reducers/utils";
import { ChevronLeft } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useSnackbarContext } from "src/context/SnackbarContext";
import { GridColumn } from "src/ds";

const CadastroFreemium = ({ email, setEmail, verifyEmail, isMobile, voltar }) => {
    const { cupom } = useParams()
    const theme = useTheme();
    const [formData, setFormData] = useState({});
    const [shouldSendForm, setShouldSendForm] = useState(false);
    const resposta = useSelector(state => state.login?.cadastroFreemiumResponse);
    const dispatch = useDispatch();
    const { showSnackbar } = useSnackbarContext()

    useEffect(() => {
        if (resposta) {
            let msg = resposta.mensagem;
            let tipo: 'success' | 'error' = resposta.sucesso ? 'success' : 'error';
            showSnackbar({
                message: msg,
                severity: tipo,
                duration: 5000
            });
        }
    }, [resposta]);

    useEffect(() => {
        dispatch(listarCidades());
        dispatch(listarestados());
    }, []);

    const removeMascaraCnpj = (cnpj) => {
        return cnpj.replace(/\D/g, '');
    };
    const updateFormData = (formData) => {
        const cnpjLimpo = removeMascaraCnpj(formData.cnpj);
        let newData = {
            assinatura: {
                planoAssinaturaOpcaoPagamentoID: '04313262-0855-EF11-ACD3-0E6C0AB65689',
                planoAssinaturaID: "8AD3B371-1A50-EF11-ACD3-0E6C0AB65689",
            },
            senhaAcesso: formData.senhaAcesso,
            emailAcesso: formData.email,
            endereco: {
                logradouro: formData.logradouro,
                CEP: formData.CEP,
                numero: formData.address?.toString(),
                complemento: formData.complemento,
                bairro: formData.bairro,
                cidadeID: formData.cidade,
                estadoID: formData.estado,
                latitude: formData.latitude,
                longitude: formData.longitude,
            },
            pick: {
                telefoneComercial: formData.numeroTelefone,
                nome: formData.nomeEmpresa,
                emailComercial: formData.email,
                cnpj: cnpjLimpo
            },
            emailFinanceiro: formData.email,
            nome: formData.nomeEmpresa,
            telefoneComercial: formData.numeroTelefone,
            usuario: {
                nome: formData.nomeEmpresa
            },
            cupomDesconto: cupom
        };

        setFormData(newData);
        setShouldSendForm(true);
    };

    useEffect(() => {
        if (shouldSendForm) {
            dispatch(enviarDadosReq(formData));
            setShouldSendForm(false);
        }
    }, [formData, shouldSendForm]);


    return (
        <Box
            sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxSizing: 'border-box',
                overflow: 'hidden'
            }}
        >
            {isMobile && <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
                <img src={headerImage} alt="Header" style={{ maxWidth: '60%', height: 'auto' }} />
            </Box>}
            <Box
                sx={{
                    width: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingX: 2,
                    boxSizing: 'border-box',
                    overflowY: isMobile ? 'auto' : 'hidden'
                }}
            >
                <ChevronLeft onClick={voltar} sx={{ cursor: 'pointer', alignSelf: 'flex-start' }} />
                <GridColumn
                    width={isMobile ? '100%' : '70%'}
                    maxWidth="100%"
                    justifyContent={'center'}
                    textAlign={'center'}
                    sx={{
                        boxSizing: 'border-box',
                    }}
                >
                    <Typography variant={isMobile ? 'h5' : 'h1'} fontWeight="bold" alignSelf={'center'}>
                        Vamos cadastrar suas informações básicas
                    </Typography>
                    <Typography variant={isMobile ? 'body1' : 'p'} color={theme.palette.grey[500]} alignSelf={'center'} mb={2}>
                        Preencha todos os dados com cuidado,
                        estas informações serão importantes para o total funcionamento da sua operação.
                    </Typography>
                    <FormFreemium email={email} setEmail={setEmail} updateFormData={updateFormData} />
                </GridColumn>
            </Box>
        </Box>
    );
};

export default CadastroFreemium;
