import {
    escalasStart, escalasSuccess, escalasFailure,
    iniciarJornadaStart, iniciarJornadaSuccess, iniciarJornadaFailure,
    finalizarJornadaStart, finalizarJornadaSuccess, finalizarJornadaFailure,
    excluirJornadaStart, excluirJornadaSuccess, excluirJornadaFailure,
    listarVeiculosStart, listarVeiculosSuccess, listarVeiculosFailure,
    listarEntregadoresStart, listarEntregadoresSuccess, listarEntregadoresFailure,
    criarVagaStart, criarVagaSuccess, criarVagaFailure,
    desassociarEntregadorStart, desassociarEntregadorSuccess, desassociarEntregadorFailure,
    alterarDataStart,alterarDataSuccess,alterarDataFailure,
} from '../reducers/escalas'
import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "../../services/api";

function* getEscalas(action) {
    const data = action.payload.data;
    const empresaID = action.payload.empresaID;
    const form = new FormData();
    form.append('dataAgendamento', data.inicio)
    form.append('dataConcluido', data.fim)
    form.append('empresaID', empresaID)
    form.append('paginacao', -1)
    form.append('pagina', 1)
    try {
        const response = yield call(api.post, '/Pedido/ListarDiarias', form);
        if (response.data.sucesso === true || response.status === 200) {
            yield put(escalasSuccess(response.data));
        } else {
            yield put(escalasFailure(response.data));
        }
    } catch (error) {
        yield put(escalasFailure(error.message));
    }
}

function* finalizarJornada(action) {
    const formData = new FormData();
    formData.append('pedidoID', action.payload);
    try {
        const response = yield call(api.post, '/Pedido/FinalizarJornada', formData);
        yield put(finalizarJornadaSuccess(response.data));
    } catch (error) {
        yield put(finalizarJornadaFailure(error.message));
    }
}

function* iniciarJornada(action) {
    const formData = new FormData();
    formData.append('pedidoID', action.payload);
    try {
        const response = yield call(api.post, '/Pedido/IniciarJornada', formData);
        yield put(iniciarJornadaSuccess(response.data));
    } catch (error) {
        yield put(iniciarJornadaFailure(error.message));
    }
}

function* fecharVaga(action) {
    const formData = new FormData();
    formData.append('pedidoID', action.payload);
    try {
        const response = yield call(api.post, '/Pedido/FecharVaga', formData);
        yield put(excluirJornadaSuccess(response.data));
    } catch (error) {
        console.log(error)
        yield put(excluirJornadaFailure(error.message));
    }
}

function* listarVeiculos() {
    const form = new FormData();
    form.append('paginacao', -1)
    form.append('pagina', 1)
    form.append('ordenacaoCampo', '')
    form.append('ordenacaoOrdem', '')
    form.append('ordenacaoOrdemfiltros', '')

    try {
        const response = yield call(api.post, 'TipoVeiculo/Listar', form);
        if (response.data.sucesso === true && response.status === 200) {
            yield put(listarVeiculosSuccess(response.data));
        } else {
            yield put(listarVeiculosFailure(response.data));
        }
    } catch (error) {
        yield put(escalasFailure(error.message));
    }
}

function* listarEntregadores({ payload }) {
    const form = new FormData();

    Object.keys(payload).forEach(key => {
        form.append(key, payload[key])
    })

    try {
        const response = yield call(api.post, 'Usuario/ListarEntregadoresAtivos', form);
        if (response.data.sucesso === true || response.status === 200) {
            yield put(listarEntregadoresSuccess(response.data));
        } else {
            yield put(listarEntregadoresFailure(response.data));
        }
    } catch (error) {
        yield put(listarEntregadoresFailure(error.message));
    }
}

function* criarVaga({ payload }) {
    try {
        const response = yield call(api.post, 'Pedido/AdicionarFinanceiro', payload);

        if (response.data.sucesso === true && response.status === 200) {
            yield put(criarVagaSuccess(response.data));
        } else {
            yield put(criarVagaFailure(response.data.mensagem));
        }
    } catch (error) {
        yield put(criarVagaFailure(error.message));
    }
}

function* desassociarEntregador({ payload }) {
    try {
        const formData = new FormData();
        formData.append('pedidoID', payload.pedidoID);
        const response = yield call(api.post, 'Pedido/DesassociarEntregador', formData);

        if (response.data.sucesso === true && response.status === 200) {
            yield put(desassociarEntregadorSuccess(response.data));
        } else {
            yield put(desassociarEntregadorFailure(response.data.mensagem));
        }
    } catch (error) {
        yield put(desassociarEntregadorFailure(error.message));
    }
}

function* alterarData({ payload }) {
    try {
        const formData = new FormData();
        Object.keys(payload).forEach(key => {
            formData.append(key, payload[key])
        })

        const response = yield call(api.post, 'Pedido/AlterarData', formData);
        if (response.data.sucesso === true && response.status === 200) {
            yield put(alterarDataSuccess(response.data));
        } else {
            yield put(alterarDataFailure(response.data.mensagem));
        }
    } catch (error) {
        yield put(alterarDataFailure(error.message));
    }
}

function* escalasSaga() {
    yield takeLatest(escalasStart.type, getEscalas);
    yield takeLatest(iniciarJornadaStart.type, iniciarJornada);
    yield takeLatest(finalizarJornadaStart.type, finalizarJornada);
    yield takeLatest(excluirJornadaStart.type, fecharVaga);
    yield takeLatest(listarVeiculosStart.type, listarVeiculos);
    yield takeLatest(listarEntregadoresStart.type, listarEntregadores);
    yield takeLatest(criarVagaStart.type, criarVaga);
    yield takeLatest(desassociarEntregadorStart.type, desassociarEntregador);
    yield takeLatest(alterarDataStart.type, alterarData);
}

export default escalasSaga;
