import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Theme,
    Typography
} from "@mui/material";
import { useDispatch } from "react-redux";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { RegisterOptions, UseFormReturn } from "react-hook-form";
import { actions } from "../../store/Actions";
import { AutocompleteEmpresas, ButtonLoading, InputDate, InputDateTime, SelectMultiple, TextFieldChip } from "..";
import { CamposTypes, FiltrosCampo, getFiltros } from "src/dtos/getFiltros";
import AutocompleteMultiple from "./AutocompleteMultiple";

type FormFieldType =
    'input' |
    'date' |
    'select' |
    'checkbox' |
    'autocompleteEmpresa' |
    'customSelect' |
    'dateTime' |
    'selectMultiple' |
    'autocompleteMultiple' |
    'tags';

export interface Field {
    name: string;
    value: string;
    type?: FormFieldType;
    label?: string;
    rules?: RegisterOptions;
    options?: { /* label: string; value: string; name?: string; */[key: string]: any; }[];
    labelKey?: string;
    valueKey?: string;
    isFiltro?: boolean;
    fullWidth?: boolean;

    filtroInline?: boolean;
    nameFiltro?: string;
    tipo?: CamposTypes;
    filtroCustomizado?: (nome: string, valor: any) => string;
    ou?: boolean;
    maiorQue?: boolean;
    menorQue?: boolean;
    igual?: boolean;
}

export interface DispatchMethods {
    actionName: keyof typeof actions;
    payload?: any;
}

export interface CardFormFiltrosProps {
    fields: Field[];
    ehFiltro?: boolean;
    formMethods: UseFormReturn<any>;
    dispatchMethods?: DispatchMethods;
    considerados?: any;
    consideradosFiltro?: FiltrosCampo;
    solicitadoParceiro?: any;
    // solicitadoParceiroFiltro?: FiltrosCampo;
    alocados?: any;
    // alocadosFiltro?: FiltrosCampo;
    reentrega?: any;
    reentregaFiltro?: FiltrosCampo;
    valueKey?: string;
    labelKey?: string;
    loading?: boolean;
    containerProps?: {
        elevation?: number;
        showHeader?: boolean;
        containerHasPadding?: boolean;
    }
}

export interface CardFormFiltrosRef {
    onSubmit: () => void;
    atualizarDispatch: boolean;
    fezRequisicao: boolean;
}

/**
 * Componente `CardFormFiltros` que renderiza um formulário dinâmico para filtros com diferentes tipos de campos.
 *
 * @component
 *
 * @param {Object} props - As propriedades do componente.
 * @param {Array<Object>} props.fields - Lista de objetos que representam os campos do formulário. Cada objeto define o tipo de campo, o nome, as opções (se aplicável), e outros atributos.
 * @param {Object} props.formMethods - Objeto contendo os métodos do formulário do React Hook Form, como `register`, `control`, `getValues`, `setValue`, `handleSubmit`, entre outros.
 * @param {Object} [props.dispatchMethods] - Objeto opcional contendo os métodos para disparar ações via Redux, incluindo o nome da ação a ser despachada.
 * @param {boolean} [props.considerados] - Se `true`, renderiza uma caixa de seleção adicional "Somente Considerados na Fatura".
 * @param {boolean} [props.solicitadoParceiro] - Se `true`, renderiza uma caixa de seleção adicional "Solicitado entregador parceiro".
 * @param {boolean} [props.alocados] - Se `true`, renderiza uma caixa de seleção adicional "Somente alocados".
 * @param {boolean} [props.loading] - Booleano utilizado para definir estado de loading do componente.
 * 
 * @returns {JSX.Element} O componente de formulário de filtros renderizado.
 *
 * @example
 * // Exemplo de uso do componente CardFormFiltros
 * const fields = [
 *   { name: 'nome', type: 'input', label: 'Nome' },
 *   { name: 'data', type: 'date', label: 'Data' },
 *   { name: 'status', type: 'select', label: 'Status', options: [{ label: 'Ativo', value: 'ativo' }, { label: 'Inativo', value: 'inativo' }] },
 * ];
 * 
 * const formMethods = useForm();
 * const dispatchMethods = { actionName: 'filtrarDados' };
 * 
 * <CardFormFiltros 
 *   fields={fields} 
 *   formMethods={formMethods} 
 *   dispatchMethods={dispatchMethods} 
 *   considerados={true} 
 *   solicitadoParceiro={false} 
 *   alocados={true} 
 * />
 */
export const CardFormFiltros = forwardRef<CardFormFiltrosRef, CardFormFiltrosProps>(({
    fields,
    formMethods,
    dispatchMethods,
    loading,
    considerados,
    consideradosFiltro,
    solicitadoParceiro,
    // solicitadoParceiroFiltro,
    alocados,
    // alocadosFiltro,
    reentrega,
    reentregaFiltro,
    containerProps,
}, ref) => {
    const dispatch = useDispatch();
    const [atualizarDispatch, setAtualizarDispatch] = useState(false);
    const [fezRequisicao, setFezRequisicao] = useState(false);

    const {
        register,
        getValues,
        watch,
        setValue,
        handleSubmit,
    } = formMethods;

    const onSubmit = (data: any) => {
        setAtualizarDispatch(true)
        const filteredData: any = {};

        const fieldNames = fields.map((field) => field.value);

        const processField = (parentKey: string, value: any) => {
            if (typeof value === "object" && value !== null && !Array.isArray(value)) {
                Object.keys(value).forEach((key) => {
                    const fullKey = `${parentKey}.${key}`;
                    processField(fullKey, value[key]);
                });
            } else {
                if (fieldNames.indexOf(parentKey) === -1 && value) {
                    filteredData[parentKey] = value;
                }
            }
        };

        Object.keys(data).forEach((key) => {
            const value = getValues(key);
            processField(key, value);
        });


        if (data.pagina < 1) filteredData.pagina = 1;

        const filtrosData: FiltrosCampo[] = []
        fields.forEach((field) => {
            const fieldValue = getValues(field.value);
            if (fieldValue && typeof fieldValue === 'object') {
                if (Array.isArray(fieldValue)) {
                    if (field?.isFiltro) {
                        const campo = {
                            nome: field.value,
                            valor: fieldValue,
                            tipo: field.tipo,
                            filtroCustomizado: field.filtroCustomizado,
                            ou: field.ou,
                            maiorQue: field.maiorQue,
                            menorQue: field.menorQue,
                            igual: field.igual,
                        };
                        filtrosData.push(campo);
                    } else if (field?.filtroInline) {
                        filteredData[field.value] = fieldValue?.map((item: any) => item?.toString())?.join('|');
                    } else {
                        const valueKey = field.valueKey || 'value';
                        // Todo: talvez tenha que tratar este else de uma forma diferente, por enquanto ele manda para o filteredData: filteredData[field.value] = fieldValue
                        // Caso seja o valor do campo seja um obj, tenta acessar o valor com o valueKey, caso n exista, tenta pegar pelo padrão, que é 'value'. 
                        fieldValue.forEach((item: string | object, index) => {
                            if (typeof item === 'object') {
                                filteredData[`${field.value}[${index}]`] = item?.[valueKey as keyof typeof item]
                            } else {
                                filteredData[`${field.value}[${index}]`] = item
                            }
                        });
                    }
                } else {
                    Object.keys(fieldValue)?.forEach((key) => {
                        const nomeCompleto = `${field.value}.${key}`;
                        const valorKey = fieldValue[key];
                        console.log('valorKey', nomeCompleto, valorKey);
                        if (field.isFiltro) {
                            const campo = {
                                nome: nomeCompleto,
                                valor: valorKey,
                                tipo: field.tipo,
                                filtroCustomizado: field.filtroCustomizado,
                                ou: field.ou,
                                maiorQue: field.maiorQue,
                                menorQue: field.menorQue,
                                igual: field.igual,
                            };
                            filtrosData.push(campo);
                        } else {
                            filteredData[nomeCompleto] = valorKey;
                        }
                    });
                }

            } else {
                if (field?.isFiltro) {
                    const campo = {
                        nome: field.nameFiltro ? field.nameFiltro : field.value,
                        valor: getValues(field?.value),
                        tipo: field.tipo,
                        filtroCustomizado: field.filtroCustomizado,
                        ou: field.ou,
                        maiorQue: field.maiorQue,
                        menorQue: field.menorQue,
                        igual: field.igual,
                    };
                    filtrosData.push(campo);
                } else {
                    const fieldValue = getValues(field.value);

                    if (typeof fieldValue === 'object' && fieldValue !== null) { // Verificação adicional
                        Object.keys(fieldValue).forEach((key) => {
                        });
                    }
                    filteredData[field.value] = getValues(field.value);
                }

            }

        });

        let filtrosString = getFiltros(filtrosData);

        if (considerados && getValues('considerados')) {
            if (consideradosFiltro) {
                filtrosString = getFiltros([consideradosFiltro], filtrosString)
            } else {
                filteredData.entraFatura = 'entraFatura';
            }
        }

        if (solicitadoParceiro && getValues('solicitadoParceiro')) {
            filteredData.solicitadoEntregadorParceiro = 'solicitado';
        }

        if (alocados && getValues('alocados')) {
            filteredData.somenteAlocados = 'alocados';
        }

        if (reentrega && getValues('reentrega')) {
            if (reentregaFiltro) {
                filtrosString = getFiltros([reentregaFiltro], filtrosString)
            } else {
                filteredData.mostrarReentregas = 'mostrarReentregas';
            }
        }

        const finalData = {
            ...filteredData,
            filtros: filtrosString || undefined,
        };

        Object.keys(finalData).forEach((key) => {
            const value = finalData[key];
            if (typeof value === 'boolean' || value === '' || value === null || value === undefined) {
                delete finalData[key];
            }
        });

        if (dispatchMethods?.actionName) {
            const actionCreator = actions[dispatchMethods.actionName];
            if (actionCreator) {
                dispatch(actionCreator(finalData as any));
                if (!fezRequisicao) setFezRequisicao(true)
            }
        }
    };

    useImperativeHandle(ref, () => ({
        onSubmit: handleSubmit(onSubmit),
        atualizarDispatch: atualizarDispatch,
        fezRequisicao: fezRequisicao,
    }));

    const renderField = (field: Field, formMethods: UseFormReturn<any>) => {
        const { name, type = 'input', label, options, value, rules, valueKey, labelKey, fullWidth } = field;
        const styles = fullWidth ? { flex: '1 0 100%' } : { flex: '2 0 250px' };

        switch (type as FormFieldType) {
            case 'input':
                return (
                    <TextField
                        {...register(value, rules)}
                        label={label || name.charAt(0).toUpperCase() + name.slice(1)}
                        sx={styles}
                        size="small"
                    />
                );
            case 'date':
                return <InputDate sx={styles} hookForm={{ name: value, methods: formMethods, rules }} label={name} />;
            case 'dateTime':
                return <InputDateTime sx={styles} hookForm={{ name: value, methods: formMethods, rules }} label={name} />;
            case 'select':
                return (
                    <FormControl size="small" sx={styles}>
                        <InputLabel id={`${value}-label`}>{label || name.charAt(0).toUpperCase() + name.slice(1)}</InputLabel>
                        <Select
                            label={label || name.charAt(0).toUpperCase() + name.slice(1)}
                            labelId={`${name}-label`}
                            id={name}
                            {...register(value, rules)}
                            value={watch(value)}
                            onChange={(event) => setValue(value, event.target.value)}
                            sx={{ flex: '2 0 0px' }}
                            defaultValue={''}
                        >
                            <MenuItem value=''>
                                Selecione...
                            </MenuItem>
                            {Array.isArray(options) && options.map((option) => (
                                <MenuItem key={option.value} value={option.value?.toString()}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'selectMultiple':
                if (!valueKey || !labelKey) {
                    throw new Error('valueKey and labelKey are required for selectMultiple fields');
                }
                return (
                    <SelectMultiple
                        formControl={{
                            sx: styles,
                        }}
                        size="small"
                        label={label || name.charAt(0).toUpperCase() + name.slice(1)}
                        hookForm={{
                            methods: formMethods,
                            name: value,
                        }}
                        valueKey={valueKey}
                        labelKey={labelKey}
                        options={options}
                    />
                )
            case 'autocompleteMultiple':
                return (
                    <AutocompleteMultiple
                        sx={styles}
                        size="small"
                        label={label || name.charAt(0).toUpperCase() + name.slice(1)}
                        hookForm={{
                            methods: formMethods,
                            name: value,
                        }}
                        valueKey={valueKey}
                        labelKey={labelKey}
                        options={options || []}
                    />
                )
            case 'customSelect':
                const resolvedValueKey = field.valueKey || 'value';
                const resolvedLabelKey = field.labelKey || 'label';
                return (
                    <FormControl size="small" sx={styles}>
                        <InputLabel id={`${value}-label`}>{label || name.charAt(0).toUpperCase() + name.slice(1)}</InputLabel>
                        <Select
                            label={label || name.charAt(0).toUpperCase() + name.slice(1)}
                            labelId={`${name}-label`}
                            id={name}
                            {...register(value, rules)}
                            value={watch(value) || ''}
                            onChange={(event) => setValue(value, event.target.value)}
                            sx={{ gap: 2 }}
                            defaultValue={''}
                        >
                            <MenuItem value=''>
                                Selecione...
                            </MenuItem>
                            {Array.isArray(options) && options.map((option) => {
                                return (
                                    <MenuItem key={option[resolvedValueKey]} value={option[resolvedValueKey]}>
                                        {option[resolvedLabelKey]}
                                    </MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>
                );
            case 'checkbox':
                return (
                    <FormControlLabel
                        control={<Checkbox {...register(value, rules)} />}
                        label={label || name.charAt(0).toUpperCase() + name.slice(1)}
                        sx={styles}
                    />
                );
            case 'autocompleteEmpresa':
                return (
                    <AutocompleteEmpresas
                        label={label || name.charAt(0).toUpperCase() + name.slice(1)}
                        hookForm={{
                            methods: formMethods,
                            name: value,
                            rules,
                        }}
                        sx={styles}
                    />
                );
            case 'tags':
                return (
                    <TextFieldChip
                        sx={styles}
                        label={label || name.charAt(0).toUpperCase() + name.slice(1)}
                        hookForm={{
                            methods: formMethods,
                            name: value,
                            rules,
                        }}
                    />
                )
            default:
                return null;
        }
    };

    useEffect(() => {
        if (atualizarDispatch) {
            setAtualizarDispatch(false)
        }
    }, [atualizarDispatch])

    return (
        <Card
            elevation={containerProps?.elevation ?? 1}
            sx={{ borderRadius: '10px', overflow: 'visible' }}
        >
            {(containerProps?.showHeader ?? true) && (
                <CardContent>
                    <Button
                        disableRipple
                        sx={(theme: Theme) => ({
                            cursor: 'default',
                            alignItems: 'flex-start',
                            color: theme.palette.grey[600],
                            backgroundColor: theme.palette.grey[100],
                            '&:hover': { backgroundColor: theme.palette.grey[300] },
                            ...theme.applyStyles('dark', {
                                color: theme.palette.grey[100],
                                backgroundColor: theme.palette.grey[900],
                                '&:hover': { backgroundColor: theme.palette.grey[800] },
                            })
                        })}
                    >
                        <Typography variant="h6">Filtros</Typography>
                    </Button>
                </CardContent>
            )}

            <CardContent
                sx={!(containerProps?.containerHasPadding ?? true) ? {
                    p: 0,
                    '&:last-child': { pb: 0 }
                } : {}}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        gap={2}
                    >
                        {fields?.map((field) => (
                            <React.Fragment key={field.value}>
                                {renderField(field, formMethods)}
                            </React.Fragment>
                        ))}
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="left"
                        flexWrap={'wrap'}
                        sx={{ paddingTop: 2, gap: 2 }}
                    >
                        {considerados && (
                            <Box
                                sx={(theme: Theme) => ({
                                    borderRadius: theme.sizes.borderRadius.xs,
                                    pr: theme.sizes.padding.sm,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                })}
                            >
                                <FormControlLabel
                                    control={<Checkbox {...register('considerados')} />}
                                    label={
                                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                            Somente Considerados na Fatura
                                        </Typography>
                                    }
                                />
                            </Box>
                        )}
                        {solicitadoParceiro && (
                            <Box
                                sx={(theme: Theme) => ({
                                    borderRadius: theme.sizes.borderRadius.xs,
                                    pr: theme.sizes.padding.sm,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                })}
                            >
                                <FormControlLabel
                                    control={<Checkbox {...register('solicitadoParceiro')} />}
                                    label={
                                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                            Solicitado entregador parceiro
                                        </Typography>
                                    }
                                />
                            </Box>
                        )}
                        {alocados && (
                            <Box
                                sx={(theme: Theme) => ({
                                    borderRadius: theme.sizes.borderRadius.xs,
                                    pr: theme.sizes.padding.sm,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                })}
                            >
                                <FormControlLabel
                                    control={<Checkbox {...register('alocados')} />}
                                    label={
                                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                            Somente alocados
                                        </Typography>
                                    }
                                />
                            </Box>
                        )}
                        {reentrega && (
                            <Box
                                sx={(theme: Theme) => ({
                                    borderRadius: theme.sizes.borderRadius.xs,
                                    pr: theme.sizes.padding.sm,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                })}
                            >
                                <FormControlLabel
                                    control={<Checkbox {...register('reentrega')} />}
                                    label={
                                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                            Pedidos reentrega
                                        </Typography>
                                    }
                                />
                            </Box>
                        )}
                    </Box>
                    <Box display="flex" justifyContent="flex-end" sx={{ width: '100%', mt: 2 }}>
                        <ButtonLoading
                            type="submit"
                            variant="containedFilled"
                            loading={loading}
                            sx={{
                                width: 'min-content',
                            }}
                        >
                            Filtrar
                        </ButtonLoading>
                    </Box>
                </form>
            </CardContent>
        </Card >
    );
});
