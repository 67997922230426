import { Stack, TextField } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ModalOpcoes, SelectBotFuncao } from 'src/ds'
import { Bots } from 'src/dtos/BotsDTO'
import { RootState } from 'src/store/reducers'
import { criarBotStart } from 'src/store/reducers/bots'

interface ModalEditarProps {
    open: boolean;
    onClose: () => void;
    pickID: string;
    bot?: Bots | null
}

interface FormData {
    funcao: string,
    nomeSessao: string,
    ativo: boolean,
}

export default ({ open, onClose, pickID, bot }: ModalEditarProps) => {
    const dispatch = useDispatch()
    const criarBotStore = useSelector((state: RootState) => state.bots.criarBot);

    const initialValues: FormData = {
        funcao: String(bot?.funcao || ''),
        nomeSessao: bot?.nomeSessao || '',
        ativo: bot?.ativo || false,
    }

    const hookForm = useForm<FormData>({
        defaultValues: { ...initialValues },
        mode: 'onChange'
    })
    const { register, formState: { errors }, handleSubmit } = hookForm

    useEffect(() => {
        if (criarBotStore.data || criarBotStore.error) {
            onClose();
        }
    }, [criarBotStore.data, criarBotStore.error])

    const onSubmit = (data: FormData) => {
        dispatch(criarBotStart({
            pickID: pickID,
            funcao: data.funcao,
            nomeSessao: data.nomeSessao,
            ativo: data.ativo,
            ...bot?.pickWppBotID && { pickWppBotID: bot.pickWppBotID }
        }))
    }

    return (
        <>
            <ModalOpcoes
                open={open}
                onClose={onClose}
                acao={bot?.pickWppBotID ? 'Editar Bot' : 'Criar Bot'}
                descricao={(
                    <Stack spacing={2} mt={2}>
                        <TextField
                            {...register('nomeSessao', { required: 'Campo obrigatório', maxLength: { value: 10, message: 'Tamanho máximo de 10 caracteres' } })}
                            label="Nome da Sessão"
                            size="small"
                            error={!!errors.nomeSessao}
                            helperText={errors.nomeSessao ? errors.nomeSessao.message : ''}
                        // slotProps={{ htmlInput: { maxLength: 10 } }}
                        />
{/*                         <SelectToggleAtivo
                            hookForm={{
                                methods: hookForm,
                                name: "ativo",
                            }}
                            label="Situação"
                        /> */}
                        <SelectBotFuncao
                            hookForm={{
                                methods: hookForm,
                                name: "funcao",
                                rules: { required: 'Campo obrigatório' }
                            }}
                            label="Função"
                        />
                    </Stack>
                )}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action() { onClose() },
                    },
                    {
                        label: bot?.pickWppBotID ? 'Editar' : 'Criar',
                        action: handleSubmit(onSubmit),
                        disabled: Object.keys(errors).length > 0,
                        loading: criarBotStore.loading,
                    },
                ]}
            />
        </>
    )
}
