import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBaseProps } from 'src/ds/components/ModalBase'
import { ButtonLoading, ModalBase, TextFieldMoney, useResponseSnackbar } from 'src/ds'
import { limparTabelaPorBairro as limparTabelaPorBairroCobranca, salvarTabelaPorBairroStart as salvarTabelaPorBairroStartCobranca } from 'src/store/reducers/tabelaCobranca';
import { salvarTabelaPorBairroStart as salvarTabelaPagamentoStart, limparTabelaPorBairro as limparTabelaPorBairroPagamento } from 'src/store/reducers/tabelaPagamento';

interface ErroDespachar {
  bairro: string;
  mensagem: string;
  sucesso: boolean;
  tabelaCobrancaID: string | null;
  tabelaValorID: string | null;
}

interface ModalConfigCobrancaProps {
  modalProps: ModalBaseProps;
  erroDespachar: ErroDespachar | null
  callback: () => void;
}

const ModalConfigCobranca: React.FC<ModalConfigCobrancaProps> = ({
  modalProps,
  erroDespachar,
  callback,
}) => {
  const dispatch = useDispatch();
  const hookForm = useForm({})
  const { register, getValues, setValue, watch } = hookForm
  const salvarTabelaPagamento = useSelector((state: any) => state.tabelaPagamento.salvarTabelaPorBairro)
  const salvarTabelaCobranca = useSelector((state: any) => state.tabelaCobranca.salvarTabelaPorBairro)
  const [cobrancaDisabled, setCobrancaDisabled] = useState(false);
  const [pagamentoDisabled, setPagamentoDisabled] = useState(false);

  useResponseSnackbar([
    { response: salvarTabelaPagamento, clearAction: () => { dispatch(limparTabelaPorBairroCobranca()) } },
    { response: salvarTabelaCobranca, clearAction: () => { dispatch(limparTabelaPorBairroPagamento()) } }
  ])

  useEffect(() => {
    if (salvarTabelaPagamento.data?.sucesso === true) {
      callback()
    }
  }, [salvarTabelaPagamento.data])

  useEffect(() => {
    if (salvarTabelaCobranca.data?.sucesso === true) {
      callback()
    }
  }, [salvarTabelaCobranca.data])

  useEffect(() => {
    if (erroDespachar) {
      console.log(erroDespachar)
      setValue('bairro', erroDespachar.bairro)
      if (erroDespachar.tabelaCobrancaID === null) {
        console.log('Taxa de Cobrança para ' + erroDespachar.bairro + ' não cadastrada')
        setCobrancaDisabled(true);
        setPagamentoDisabled(false)
      } else if (erroDespachar.tabelaValorID === null) {
        console.log('Bairro ' + erroDespachar.bairro + ' não cadastrado')
        setPagamentoDisabled(true);
        setCobrancaDisabled(false)
      }
    }
  }, [erroDespachar])

  const handleTaxaCobranca = () => {
    if (!erroDespachar) return
    dispatch(salvarTabelaPorBairroStartCobranca({
      tabelaCobrancaID: erroDespachar.tabelaCobrancaID,
      tabelaValorID: '',
      bairro: erroDespachar.bairro,
      valor: getValues('valorCobranca'),
      taxaRetorno: getValues('taxaRetornoCobranca')
    }));
  }

  const handleTaxaPagamento = () => {
    if (!erroDespachar) return
    dispatch(salvarTabelaPagamentoStart({
      tabelaValorID: erroDespachar.tabelaValorID,
      tabelaCobrancaID: '',
      bairro: erroDespachar.bairro,
      valor: getValues('valor'),
      taxaRetorno: getValues('taxaRetorno')
    }));

  }


  return (
    <>
      <ModalBase
        headerModal={{ title: `Configurar ${erroDespachar?.bairro}` }}
        {...modalProps}
      >
        <Box p={2} sx={{ width: '100%', boxSizing: 'border-box' }}>
          <Stack direction={'column'} spacing={2} sx={{ width: '100%' }}>
            <Typography>Tabela de pagamento (Entregador)</Typography>
            <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
              <TextField
                value={watch('bairro')}
                size='small'
                label="Bairro"
                disabled
                sx={{ flex: '1' }}
              />
              <TextFieldMoney
                hookForm={{
                  methods: hookForm,
                  name: 'valor',
                }}
                label="Valor"
                disabled={pagamentoDisabled}
                sx={{ flex: '1' }}
              />
              <TextFieldMoney
                hookForm={{
                  methods: hookForm,
                  name: 'taxaRetorno',
                }}
                label="Taxa de Retorno"
                disabled={pagamentoDisabled}
                sx={{ flex: '1' }}
              />
              <ButtonLoading
                loading={salvarTabelaPagamento.loading}
                disabled={pagamentoDisabled}
                onClick={handleTaxaPagamento}
                sx={{ width: 'min-content' }}
                variant='containedFilled'
              >
                Salvar
              </ButtonLoading>
            </Stack>
            <Typography>Tabela de cobranca (Cliente)</Typography>
            <Stack direction='row' spacing={1}>
              <TextField
                {...register('bairro')}
                size='small'
                label="Bairro"
                disabled
                sx={{ flex: '1' }}
              />
              <TextFieldMoney
                hookForm={{
                  methods: hookForm,
                  name: 'valorCobranca',
                }}
                label="Valor"
                disabled={cobrancaDisabled}
                sx={{ flex: '1' }}
              />
              <TextFieldMoney
                hookForm={{
                  methods: hookForm,
                  name: 'taxaRetornoCobranca',
                }}
                label="Taxa de Retorno"
                disabled={cobrancaDisabled}
                sx={{ flex: '1' }}
              />
              <ButtonLoading
                loading={salvarTabelaCobranca.loading}
                disabled={cobrancaDisabled}
                onClick={handleTaxaCobranca}
                sx={{ width: 'min-content' }}
                variant='containedFilled'
              >
                Salvar
              </ButtonLoading>
            </Stack>
          </Stack>
        </Box>
      </ModalBase>
    </>
  )
}

export default ModalConfigCobranca