import { Box, Typography, Container, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AutocompleteEmpresas } from 'src/ds'

interface SelecionarEmpresaProps {
    setEmpresaID: React.Dispatch<React.SetStateAction<string | null>>
    storeEmpresaID?: string | null
}

const SelecionarEmpresa = ({ setEmpresaID, storeEmpresaID }: SelecionarEmpresaProps) => {
    const hookForm = useForm()
    const { control, setValue, watch } = hookForm

    useEffect(() => {
        if (!storeEmpresaID) return
        setValue('empresa', storeEmpresaID)
    }, [storeEmpresaID])

    useEffect(() => {
        setEmpresaID(watch('empresa'))
    }, [watch('empresa')])

    return (
        <Box sx={{ display: 'flex', mb: 2 }}>
            <Stack direction='row' useFlexGap sx={{ gap: 2, width: '100%' }}>
                <AutocompleteEmpresas
                    hookForm={{
                        methods: hookForm,
                        name: 'empresa'
                    }}
                    label='Empresa'
                    sx={{ flex: '3 1 100%' }}
                />
            </Stack>
        </Box>
    )
}

export default SelecionarEmpresa