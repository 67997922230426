// @ts-nocheck
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useSelector } from "react-redux";
import { formatarMoeda } from "../../../ds/common";
import { Controller } from "react-hook-form";
import { useEffect } from "react";
import { CardPedidoContent } from "src/ds";

const DetalhesDoPedido = ({
    register,
    errors,
    watch,
    setValue,
    control
}) => {
    const formasDePagamento = useSelector(state => state.config.formaDePagamento);

    useEffect(() => {
        const formaExistente = formasDePagamento.find(f => f.formaPagamentoID === watch('formaPagamentoID'));
        if (!formaExistente) { setValue('formaPagamentoID', '') }
    }, [watch('formaPagamentoID')])

    return (
        <CardPedidoContent>
            <div style={{ marginTop: '1rem' }}>
                <TextField
                    label="Valor do pedido"
                    variant="outlined"
                    size='small'
                    {...register('valor', { required: 'Valor obrigatório' })}
                    error={!!errors.valor}
                    helperText={errors?.valor?.message}
                    value={formatarMoeda(watch('valor'))}
                    fullWidth
                />
                <div style={{ display: 'flex', alignItems: 'stretch', marginTop: '1rem' }}>
                    <FormControl
                        variant="outlined"
                        sx={{ width: '100%' }}
                        error={!!errors.formaPagamentoID}
                    >
                        <InputLabel shrink htmlFor="formaPagamento">Forma de Pagamento</InputLabel>
                        <Controller
                            name="formaPagamentoID"
                            control={control}
                            rules={{ required: 'Forma de Pagamento obrigatório' }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Select
                                    size="small"
                                    defaultValue={''}
                                    displayEmpty
                                    fullWidth
                                    label="Forma de Pagamento"
                                    id='formaPagamento'
                                    inputProps={{ 'aria-label': 'formaPagamento' }}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                >
                                    <MenuItem value="" disabled>Selecione uma forma</MenuItem>
                                    {formasDePagamento?.map((pagamento) => (
                                        <MenuItem key={pagamento.formaPagamentoID} value={pagamento.formaPagamentoID}>
                                            {pagamento.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        <FormHelperText>{errors.formaPagamentoID?.message}</FormHelperText>
                    </FormControl>
                </div>
            </div>
            <div style={{ marginTop: '1rem' }}>
                <TextField
                    label="Descrição"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    {...register('detalhes')}
                    InputLabelProps={{ shrink: true }}
                    placeholder="Digite aqui detalhes do pedido...."
                />
            </div>
        </CardPedidoContent>
    )
}

export default DetalhesDoPedido