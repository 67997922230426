import { AddCircleOutline, Delete } from "@mui/icons-material";
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Box, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { excluirTipoDeVeiculoTabelaStart, listarTipoVeiculoTabelaCobrancaStart, salvarTipoVeiculoTabelaStart } from "src/store/reducers/tabelaCobranca";
import { ModalBase, ModalOpcoes, TableNavigation, TableRowLoading, TableRowNotFound } from "src/ds";
import TipoDeVeiculo from "src/ds/components/TipoDeVeiculo";

const EditarTiposDeVeiculo = ({ dados }: any) => {
    const dispatch = useDispatch();
    const [showSelector, setShowSelector] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [openDeletar, setOpenDeletar] = useState(false);

    const hookForm = useForm({
        defaultValues: {
            paginacao: 20,
            pagina: 1,
            tipoVeiculoID: "",
            tabelaCobrancaID: dados?.tabelaCobrancaID,
        },
    });

    const { watch } = hookForm;

    const itens = useSelector((state: any) => state.tabelaCobranca.tiposDeVeiculo.data?.dados);
    const loading = useSelector((state: any) => state.tabelaCobranca.tiposDeVeiculo.loading);
    const itensDados = itens?.lista;

    const loadedRef = useRef({ tiposVeiculoTabela: false, tiposVeiculo: false });

    const handleSaveTipoVeiculo = () => {
        // @ts-ignore
        dispatch(salvarTipoVeiculoTabelaStart({ tipoVeiculoID: watch("tipoVeiculoID"), tabelaCobrancaID: dados?.tabelaCobrancaID }));
        setShowSelector(false);
        setTimeout(() => {
            dispatch(listarTipoVeiculoTabelaCobrancaStart({ pagina: hookForm.getValues().pagina || 1, paginacao: hookForm.getValues().paginacao || 5, filtros: `tabelaCobrancaID.toString() = "${dados?.tabelaCobrancaID}"` }));
        }, 1000)

    };

    const handleDeletar = (item: any) => {
        setSelectedItem(item);
        setOpenDeletar(true);

    };

    useEffect(() => {
        if (!loadedRef.current.tiposVeiculoTabela) {
            // @ts-ignore
            dispatch(listarTipoVeiculoTabelaCobrancaStart({ pagina: hookForm.getValues().pagina || 1, paginacao: hookForm.getValues().paginacao || 5, filtros: `tabelaCobrancaID.toString() = "${dados?.tabelaCobrancaID}"` }));
            loadedRef.current.tiposVeiculoTabela = true;
        }
    }, []);

    useEffect(() => {
        dispatch(listarTipoVeiculoTabelaCobrancaStart({ pagina: hookForm.getValues().pagina || 1, paginacao: hookForm.getValues().paginacao || 5, filtros: `tabelaCobrancaID.toString() = "${dados?.tabelaCobrancaID}"` }));
    }, [hookForm.watch("pagina"), hookForm.watch("paginacao")]);

    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Button
                variant="containedFilled"
                sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                onClick={() => setShowSelector(true)}
                startIcon={<AddCircleOutline />}
            >
                Adicionar novo tipo de veículo
            </Button>


            <ModalBase open={showSelector} onClose={() => setShowSelector(false)} headerModal={{ title: 'Adicionar novo tipo de veículo' }} size="sm">
                <Box p={2}>
                    <Stack spacing={2}>
                        <TipoDeVeiculo hookForm={hookForm} />
                        <Button
                            variant="containedFilled"
                            sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                            onClick={handleSaveTipoVeiculo}
                        >
                            Salvar
                        </Button>
                    </Stack>
                </Box>
            </ModalBase>

            <Stack sx={{ flex: 1, gap: 2 }}>
                {itens?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={itens.indiceInicial}
                        indiceFinal={itens.indiceFinal}
                        indiceTotal={itens.total}
                        ultimaPagina={itens?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Nome</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ? (
                                <TableRowLoading />
                            ) : itensDados?.length > 0 ? (
                                itensDados.map((item: any) => (
                                    <TableRow key={item.id}>
                                        <TableCell align="left">{item.tipoVeiculo.nome}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                onClick={() => handleDeletar(item)}
                                            >
                                                <Delete color="error" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRowNotFound text="Nenhum resultado encontrado." fezRequisicao={true} />
                            )}

                    </TableBody>
                </Table>
            </Stack>
            <ModalOpcoes
                open={openDeletar}
                onClose={() => setOpenDeletar(false)}
                acao="Remover tipo de veículo da tabela"
                descricao={(
                    <Typography>
                        Tem certeza que deseja remover <span style={{ fontWeight: 'bold' }}>{selectedItem?.tipoVeiculo.nome}</span> da tabela?
                    </Typography>
                )}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => setOpenDeletar(false)
                    },
                    {
                        label: 'Excluir',
                        action: () => {
                            dispatch(excluirTipoDeVeiculoTabelaStart(selectedItem?.tabelaCobrancaTipoVeiculoID));
                            setOpenDeletar(false);
                            setTimeout(() => {
                                dispatch(listarTipoVeiculoTabelaCobrancaStart({ pagina: 1, paginacao: 10, filtros: `tabelaCobrancaID.toString() = "${dados?.tabelaCobrancaID}"` }));
                            }, 1000)
                        }
                    }
                ]}
            />
        </Stack>
    );
};

export default EditarTiposDeVeiculo;
