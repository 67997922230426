import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultDTO, DefaultPayload } from "src/dtos/DefaultDTO";
import { ListaTabelaCobrancaParams, PaginaCobranca, PaginaCobrancaDTOS, TabelaCobrancaDTOS, TabelaCobrancaEmpresa, TabelasCobrancaState } from "src/dtos/TabelaCobrancaDTO";


const initialState: TabelasCobrancaState = {
    listaTabelasCobranca: {
        loading: false,
        data: null,
        error: null,
    },
    paginaTabelaCobranca: {
        loading: false,
        data: null,
        permissoes: null,
        error: null,
    },
    empresaTabelaCobranca: {
        loading: false,
        data: null,
        error: null,
    },
    tiposDeVeiculo: {
        loading: false,
        data: null,
        error: null,
    },
    porRaio: {
        loading: false,
        data: null,
        error: null,
    },
    porBairro: {
        loading: false,
        data: null,
        error: null,
    },
    porKmCalculado: {
        loading: false,
        data: null,
        error: null,
    },
    salvarTabela: {
        loading: false,
        data: null,
        error: null,
    },
    adicionarEmpresaTabela: {
        loading: false,
        data: null,
        error: null,
    },
    deletarEmpresaTabela: {
        loading: false,
        data: null,
        error: null,
    },
    salvarTipoVeiculo: {
        loading: false,
        data: null,
        error: null,
    },
    excluirTipoVeiculo: {
        loading: false,
        data: null,
        error: null,
    },
    salvarTabelaPorBairro: {
        loading: false,
        data: null,
        error: null,
    },
    salvarTabelaPorRaio: {
        loading: false,
        data: null,
        error: null,
    },
    excluirTabelaPorRaio: {
        loading: false,
        data: null,
        error: null,
    },
    excluirTabelaPorBairro: {
        loading: false,
        data: null,
        error: null,
    },
    salvarTabelaPorKm: {
        loading: false,
        data: null,
        error: null,
    },
    excluirTabelaPorKm: {
        loading: false,
        data: null,
        error: null,
    },
    duplicarTabelaCobranca: {
        loading: false,
        data: null,
        error: null,
    },
    pagina: {
        loading: false,
        data: null,
        error: null,
    }
};


const tabelaCobrancaSlice = createSlice({
    name: 'TabelaCobranca',
    initialState: initialState,
    reducers: {
        listaTabelaCobrancaStart(state, action: PayloadAction<ListaTabelaCobrancaParams>) {
            state.listaTabelasCobranca.loading = true;
            state.listaTabelasCobranca.error = null;
        },
        listaTabelaCobrancaFailure(state, action: PayloadAction<string>) {
            state.listaTabelasCobranca.loading = false;
            state.listaTabelasCobranca.error = action.payload;
        },
        listaTabelaCobrancaSuccess(state, action: PayloadAction<TabelaCobrancaDTOS>) {
            state.listaTabelasCobranca.loading = false;
            state.listaTabelasCobranca.data = action.payload;
        },
        paginaTabelaCobrancaStart(state, action: PayloadAction<PaginaCobranca>) {
            state.paginaTabelaCobranca.loading = true;
            state.paginaTabelaCobranca.error = null;
        },
        paginaTabelaCobrancaFailure(state, action: PayloadAction<string>) {
            state.paginaTabelaCobranca.loading = false;
            state.paginaTabelaCobranca.error = action.payload;
        },
        paginaTabelaCobrancaSuccess(state, action: PayloadAction<PaginaCobrancaDTOS>) {
            state.paginaTabelaCobranca.loading = false;
            state.paginaTabelaCobranca.data = action.payload;
        },
        listarEmpresaTabelaCobrancaStart(state, action: PayloadAction<DefaultPayload>) {
            state.empresaTabelaCobranca.loading = true;
            state.empresaTabelaCobranca.error = null;
        },
        listarEmpresaTabelaCobrancaSuccess(state, action: PayloadAction<TabelaCobrancaEmpresa>) {
            state.empresaTabelaCobranca.loading = false;
            state.empresaTabelaCobranca.data = action.payload;
        },
        listarEmpresaTabelaCobrancaFailure(state, action: PayloadAction<string>) {
            state.empresaTabelaCobranca.loading = false;
            state.empresaTabelaCobranca.error = action.payload;
        },
        listarTipoVeiculoTabelaCobrancaStart(state, action: PayloadAction<any>) {
            state.tiposDeVeiculo.loading = true;
            state.tiposDeVeiculo.error = null;
        },
        listarTipoVeiculoTabelaCobrancaSuccess(state, action: PayloadAction<any>) {
            state.tiposDeVeiculo.loading = false;
            state.tiposDeVeiculo.data = action.payload;
        },
        listarTipoVeiculoTabelaCobrancaFailure(state, action: PayloadAction<string>) {
            state.tiposDeVeiculo.loading = false;
            state.tiposDeVeiculo.error = action.payload;
        },
        listarPorRaioStart(state, action: PayloadAction<any>) {
            state.porRaio.loading = true;
            state.porRaio.error = null;
        },
        listarPorRaioSuccess(state, action: PayloadAction<any>) {
            state.porRaio.loading = false;
            state.porRaio.data = action.payload;
        },
        listarPorRaioFailure(state, action: PayloadAction<any>) {
            state.porRaio.loading = false;
            state.porRaio.error = action.payload;
        },
        listarPorBairroStart(state, action: PayloadAction<any>) {
            state.porBairro.loading = true;
            state.porBairro.error = null;
        },
        listarPorBairroSuccess(state, action: PayloadAction<any>) {
            state.porBairro.loading = false;
            state.porBairro.data = action.payload;
        },
        listarPorBairroFailure(state, action: PayloadAction<any>) {
            state.porBairro.loading = false;
            state.porBairro.error = action.payload;
        },
        listarPorKmCalculadoStart(state, action: PayloadAction<any>) {
            state.porKmCalculado.loading = true;
            state.porKmCalculado.error = null;
        },
        listarPorKmCalculadoSuccess(state, action: PayloadAction<any>) {
            state.porKmCalculado.loading = false;
            state.porKmCalculado.data = action.payload;
        },
        listarPorKmCalculadoFailure(state, action: PayloadAction<any>) {
            state.porKmCalculado.loading = false;
            state.porKmCalculado.error = action.payload;
        },
        salvarTabelaStart(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = true;
            state.salvarTabela.error = null;
        },
        salvarTabelaSuccess(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = false;
            state.salvarTabela.data = action.payload;
        },
        salvarTabelaFailure(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = false;
            state.salvarTabela.error = action.payload;
        },
        excluirTabelaStart(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = true;
            state.salvarTabela.error = null;
        },
        excluirTabelaSuccess(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = false;
            state.salvarTabela.data = action.payload;
        },
        excluirTabelaFailure(state, action: PayloadAction<any>) {
            state.salvarTabela.loading = false;
            state.salvarTabela.error = action.payload;
        },
        limparSalvarTabela(state) {
            state.salvarTabela.loading = false;
            state.salvarTabela.data = null;
            state.salvarTabela.error = null;
        },
        adicionarEmpresaTabelaStart(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = true;
            state.adicionarEmpresaTabela.error = null;
        },
        adicionarEmpresaTabelaSuccess(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = false;
            state.adicionarEmpresaTabela.data = action.payload;
        },
        adicionarEmpresaTabelaFailure(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = false;
            state.adicionarEmpresaTabela.error = action.payload;
        },
        limparEmpresaTabela(state) {
            state.adicionarEmpresaTabela.loading = false;
            state.adicionarEmpresaTabela.data = null;
            state.adicionarEmpresaTabela.error = null;
        },
        deletarEmpresaTabelaStart(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = true;
            state.adicionarEmpresaTabela.error = null;
        },
        deletarEmpresaTabelaSuccess(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = false;
            state.adicionarEmpresaTabela.data = action.payload;
        },
        deletarEmpresaTabelaFailure(state, action: PayloadAction<any>) {
            state.adicionarEmpresaTabela.loading = false;
            state.adicionarEmpresaTabela.error = action.payload;
        },
        salvarTipoVeiculoTabelaStart(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = true;
            state.salvarTipoVeiculo.error = null;
        },
        salvarTipoVeiculoTabelaSuccess(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.data = action.payload;
        },
        salvarTipoVeiculoTabelaFailure(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.error = action.payload;
        },
        limparSalvarTipoVeiculo(state) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.data = null;
            state.salvarTipoVeiculo.error = null;
        },
        excluirTipoDeVeiculoTabelaStart(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.error = null;
            state.salvarTipoVeiculo.data = action.payload;
        },
        excluirTipoDeVeiculoTabelaFailure(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.error = action.payload;
        },
        excluirTipoDeVeiculoTabelaSuccess(state, action: PayloadAction<any>) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.data = action.payload;
        },
        limparExcluirTipoVeiculo(state) {
            state.salvarTipoVeiculo.loading = false;
            state.salvarTipoVeiculo.data = null;
            state.salvarTipoVeiculo.error = null;
        },
        salvarTabelaPorRaioStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.data = action.payload;
            state.salvarTabelaPorRaio.loading = false;
        },
        salvarTabelaPorRaioSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.data = action.payload;
            state.salvarTabelaPorRaio.loading = false;
        },
        salvarTabelaPorRaioFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.error = action.payload;
            state.salvarTabelaPorRaio.loading = false;

        },
        excluirTabelaPorRaioStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.data = action.payload;
            state.salvarTabelaPorRaio.loading = false;
        },
        excluirTabelaPorRaioSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.data = action.payload;
            state.salvarTabelaPorRaio.loading = false;
        },
        excluirTabelaPorRaioFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorRaio.error = action.payload;
            state.salvarTabelaPorRaio.loading = false;
        },
        limparTabelaPorRaio(state) {
            state.salvarTabelaPorRaio.data = null;
            state.salvarTabelaPorRaio.error = null;
            state.salvarTabelaPorRaio.loading = false;
        },
        salvarTabelaPorBairroStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.data = null;
            state.salvarTabelaPorBairro.loading = false;
        },
        salvarTabelaPorBairroSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.data = action.payload;
            state.salvarTabelaPorBairro.loading = false;
        },
        salvarTabelaPorBairroFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.error = action.payload;
            state.salvarTabelaPorBairro.loading = false;
        },
        excluirTabelaPorBairroStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.data = action.payload;
            state.salvarTabelaPorBairro.loading = false;
        },
        excluirTabelaPorBairroSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.data = null;
            state.salvarTabelaPorBairro.loading = false;
        },
        excluirTabelaPorBairroFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorBairro.error = action.payload;
            state.salvarTabelaPorBairro.loading = false;
        },
        limparTabelaPorBairro: (state) => {
            state.salvarTabelaPorBairro.data = null;
            state.salvarTabelaPorBairro.error = null;
            state.salvarTabelaPorBairro.loading = false;
        },
        salvarTabelaPorKmStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.loading = false;
        },
        salvarTabelaPorKmSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.data = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        salvarTabelaPorKmFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.error = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        excluirTabelaPorKmStart: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.data = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        excluirTabelaPorKmSuccess: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.data = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        excluirTabelaPorKmFailure: (state, action: PayloadAction<any>) => {
            state.salvarTabelaPorKm.error = action.payload;
            state.salvarTabelaPorKm.loading = false;
        },
        limparTabelaPorKmCalculado: (state) => {
            state.salvarTabelaPorKm.data = null;
            state.salvarTabelaPorKm.error = null;
            state.salvarTabelaPorKm.loading = false;
        },
        duplicarTabelaCobrancaStart: (state, action: PayloadAction<any>) => {
            state.duplicarTabelaCobranca.data = action.payload;
            state.duplicarTabelaCobranca.loading = false;
        },
        duplicarTabelaCobrancaSuccess: (state, action: PayloadAction<any>) => {
            state.duplicarTabelaCobranca.data = action.payload;
            state.duplicarTabelaCobranca.loading = false;
        },
        duplicarTabelaCobrancaFailure: (state, action: PayloadAction<any>) => {
            state.duplicarTabelaCobranca.error = action.payload;
            state.duplicarTabelaCobranca.loading = false;
        },
    },
});

export const {
    listaTabelaCobrancaStart,
    listaTabelaCobrancaFailure,
    listaTabelaCobrancaSuccess,
    paginaTabelaCobrancaStart,
    paginaTabelaCobrancaFailure,
    paginaTabelaCobrancaSuccess,
    listarEmpresaTabelaCobrancaStart,
    listarEmpresaTabelaCobrancaSuccess,
    listarEmpresaTabelaCobrancaFailure,
    listarTipoVeiculoTabelaCobrancaStart,
    listarTipoVeiculoTabelaCobrancaSuccess,
    listarTipoVeiculoTabelaCobrancaFailure,
    listarPorRaioStart,
    listarPorRaioSuccess,
    listarPorRaioFailure,
    listarPorBairroStart,
    listarPorBairroSuccess,
    listarPorBairroFailure,
    listarPorKmCalculadoStart,
    listarPorKmCalculadoFailure,
    listarPorKmCalculadoSuccess,
    salvarTabelaStart,
    salvarTabelaSuccess,
    salvarTabelaFailure,
    excluirTabelaStart,
    excluirTabelaSuccess,
    excluirTabelaFailure,
    limparSalvarTabela,
    adicionarEmpresaTabelaFailure,
    adicionarEmpresaTabelaStart,
    adicionarEmpresaTabelaSuccess,
    limparEmpresaTabela,
    deletarEmpresaTabelaFailure,
    deletarEmpresaTabelaStart,
    deletarEmpresaTabelaSuccess,
    salvarTipoVeiculoTabelaStart,
    salvarTipoVeiculoTabelaFailure,
    salvarTipoVeiculoTabelaSuccess,
    limparSalvarTipoVeiculo,
    excluirTipoDeVeiculoTabelaStart,
    excluirTipoDeVeiculoTabelaFailure,
    excluirTipoDeVeiculoTabelaSuccess,
    limparExcluirTipoVeiculo,
    salvarTabelaPorRaioStart,
    salvarTabelaPorRaioSuccess,
    salvarTabelaPorRaioFailure,
    excluirTabelaPorRaioStart,
    excluirTabelaPorRaioFailure,
    excluirTabelaPorRaioSuccess,
    limparTabelaPorRaio,
    salvarTabelaPorBairroStart,
    salvarTabelaPorBairroSuccess,
    salvarTabelaPorBairroFailure,
    excluirTabelaPorBairroFailure,
    excluirTabelaPorBairroStart,
    excluirTabelaPorBairroSuccess,
    limparTabelaPorBairro,
    excluirTabelaPorKmFailure,
    excluirTabelaPorKmStart,
    excluirTabelaPorKmSuccess,
    salvarTabelaPorKmFailure,
    salvarTabelaPorKmStart,
    salvarTabelaPorKmSuccess,
    limparTabelaPorKmCalculado,
    duplicarTabelaCobrancaFailure,
    duplicarTabelaCobrancaStart,
    duplicarTabelaCobrancaSuccess

} = tabelaCobrancaSlice.actions;

export default tabelaCobrancaSlice;
