import { Box, Button, ButtonProps, CircularProgress, SxProps } from "@mui/material";
import { ReactNode } from "react";

interface ButtonLoadingProps extends ButtonProps {
    loading?: boolean;
    children?: ReactNode;
    size?: string;
    sx?: SxProps;
    [key: string]: any;
}

/**
 * `ButtonLoading` é um componente de botão customizado que pode exibir um indicador de carregamento.
 * Este componente é baseado no botão do Material-UI e permite que os desenvolvedores desabilitem o botão
 * e mostrem uma animação de carregamento enquanto uma operação está em andamento.
 * 
 * @component
 * @example
 * ```tsx
 * <ButtonLoading loading={true} color="primary" size="2rem">
 *   Salvar
 * </ButtonLoading>
 * ```
 * 
 * @param {ButtonLoadingProps} props As propriedades do componente.
 * @param {boolean} [props.loading=false] Define se o indicador de carregamento deve ser exibido. Se `true`, o botão é desabilitado e o spinner de carregamento é exibido.
 * @param {ReactNode} [props.children] Conteúdo filho a ser exibido dentro do botão. Normalmente, isso é o texto ou ícone do botão.
 * @param {string} [props.variant='outlined'] Variante do botão, com o valor padrão sendo `outlined`. Pode ser sobrescrito.
 * @param {string} [props.size='1rem'] Define o tamanho do spinner de carregamento. Também pode ser usado para ajustar o tamanho do botão.
 * @param {string} [props.color] Cor do spinner de carregamento. Herda a cor do botão se não for especificada.
 * @param {SxProps} [props.sx] Propriedades de estilo (sx) adicionais para customizar o estilo do botão.
 * @param {ButtonProps} [props.props] Todas as outras propriedades de botão padrão do Material-UI que podem ser aplicadas.
 * 
 * @returns {JSX.Element} Um botão com indicador de carregamento.
 */
const ButtonLoading = ({
    loading = false,
    children,
    variant = 'outlined',
    size = '1rem',
    color,
    sx,
    ...props
}: ButtonLoadingProps) => {
    return (
        <Button
            disabled={loading}
            variant={variant}
            sx={{
                position: 'relative',
                ...sx,
            }}
            color={color || 'primary'}
            {...props}
        >
            <span style={{
                visibility: (loading ?? false) ? 'hidden' : 'visible',
                display: 'inherit',
                gap: 'inherit',
                alignItems: 'inherit',
                justifyContent: 'inherit',
            }}>
                {children}
            </span>
            {loading && <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress
                    size={size}
                    sx={{ lineHeight: size }}
                    color={color || 'primary'}
                />
            </Box>}
        </Button>
    )
}

export default ButtonLoading
