import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardTabelaTotal, ContainerBodyModal, ModalBase, TableNavigation, TableRowLoading } from 'src/ds'
import { RootState } from 'src/store/reducers'
import circleDollar from "../../assets/icons/circle-dollar.svg";
import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import { dinheiro } from 'src/ds/common';
import dayjs from 'dayjs';
import QrCode from './QrCode';
import { gerarQrCodeStart } from 'src/store/reducers/utils';
import { useForm } from 'react-hook-form';
import { listarMinhasTransacoesStart } from 'src/store/reducers/faturas';

const ModalCobrancas = ({ open, onClose, row }: { open: boolean, onClose: () => void, row: any }) => {
    const [openQr, setOpenQr] = useState(false);
    const qrCode = useSelector((state: RootState) => state.utils.gerarQrCode?.data?.qrcode);
    const [copiaECola, setCopiaECola] = useState('');

    const data = useSelector((state: RootState) => state.faturas?.listarMinhasTransacoes?.data?.dados);
    const loading = useSelector((state: RootState) => state.faturas?.listarMinhasTransacoes?.loading);
    const theme = useTheme();
    const dispatch = useDispatch();

    const hookForm = useForm({
        defaultValues: { pagina: 1, paginacao: 20 }
    })

    useEffect(() => {
        console.log(hookForm.getValues("paginacao"), hookForm.getValues("pagina"));
        dispatch(listarMinhasTransacoesStart({
            empresaID: row?.empresaID,
            paginacao: hookForm.getValues("paginacao"),
            pagina: hookForm.getValues("pagina"),
            filtros: `faturaID.toString().Equals("${row?.faturaID}")`
        }));
    }, [hookForm.watch("paginacao"), hookForm.watch("pagina")]);

    return (
        <ModalBase headerModal={{ title: 'Cobranças' }} open={open} onClose={onClose}>
            <ContainerBodyModal >
                <Stack
                    useFlexGap
                    direction="row"
                    spacing={2}
                    sx={{ p: 2, flexWrap: 'wrap' }}
                >
                    <CardTabelaTotal
                        srcIcon={circleDollar}
                        altIcon="Ícone valor"
                        bgcolor={theme.palette.green[600]}
                        texts={{
                            title1: 'Total a pagar',
                            subtitle1: data?.totalFaturado ? dinheiro(data?.totalFaturado) : 'R$0,00',
                        }}
                        sx={{ flex: '1 1 200px' }}
                    />
                    <CardTabelaTotal
                        srcIcon={circleDollar}
                        altIcon="Ícone valor"
                        bgcolor={theme.palette.blue[600]}
                        texts={{
                            title1: 'Total pago',
                            subtitle1: data?.totalPago ? dinheiro(data?.totalPago) : 'R$0,00',
                        }}
                        sx={{ flex: '1 1 200px' }}
                    />
                </Stack>
                {(data?.ultimaPagina) ? (
                    <TableNavigation
                        indiceInicial={data?.indiceInicial}
                        indiceFinal={data?.indiceFinal}
                        indiceTotal={data?.indiceFinal}
                        ultimaPagina={data?.ultimaPagina}
                        hookForm={hookForm}
                    />

                ) : null}
                <TableContainer component={Paper} sx={{ maxHeight: 500, minWidth: 450, overflow: 'hidden', borderRadius: '6px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }} align='center'>Data de vencimento</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align='center'>Valor</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align='center'>Situação</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRowLoading />
                            ) : (

                                data?.lista?.map((row: any) => (
                                    <TableRow
                                        key={row.faturaTransacaoID}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" align='center'>
                                            {dayjs(row.dataVencimento, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')}
                                        </TableCell>
                                        <TableCell align='center'>{dinheiro(row.valor)}</TableCell>
                                        <TableCell align='center'>{row.situacaoNome}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Button
                                                    variant="containedFilled"
                                                    color="primary"
                                                    sx={{ width: "min-content" }}
                                                    onClick={() => {
                                                        if (!row.boletoUrl) {
                                                            setCopiaECola(row.pixQrcode);
                                                            dispatch(gerarQrCodeStart(row.pixQrcode));
                                                            setOpenQr(true);
                                                        } else {
                                                            window.open(row.boletoUrl, '_blank');
                                                        }
                                                    }}>
                                                    {row.boletoUrl ? 'Ver boleto' : 'Pagar'}
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )))}

                        </TableBody>
                    </Table>
                </TableContainer>
                <QrCode modalProps={{ open: openQr, onClose: () => setOpenQr(false) }} qrCode={qrCode} loading={false} atualizar={() => { }} copiaECola={copiaECola} />

            </ContainerBodyModal>
        </ModalBase>
    )
}


export default ModalCobrancas