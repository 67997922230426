// TimeCounter.tsx
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface TimeCounterProps {
  initialTimeString?: string;
  parenteses?: boolean;
  [key: string]: any;
}

const TimeCounter: React.FC<TimeCounterProps> = ({
  initialTimeString,
  parenteses = false,
  ...props
}) => {
  const formatTime = (seconds: number | string) => {
    const hours = Math.floor(Number(seconds) / 3600);
    const minutes = Math.floor((Number(seconds) % 3600) / 60);
    const secs = Number(seconds) % 60;

    return hours > 0
      ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
      : `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const convertToSeconds = (timeString?: string) => {
    const timeParts = timeString?.split(' ');
    let totalSeconds = 0;
    timeParts?.forEach(part => {
      if (part.endsWith('h')) {
        totalSeconds += parseInt(part) * 3600;
      } else if (part.endsWith('m')) {
        totalSeconds += parseInt(part) * 60;
      } else if (part.endsWith('s')) {
        totalSeconds += parseInt(part);
      }
    });
    return totalSeconds;
  };

  const initialTimeInSeconds = convertToSeconds(initialTimeString);
  const [elapsedTime, setElapsedTime] = useState(initialTimeInSeconds);

  useEffect(() => {
    const startTime = Date.now() - initialTimeInSeconds;
    const intervalId = setInterval(() => {
      const secondsElapsed = (Date.now() - startTime) / 1000;
      setElapsedTime(initialTimeInSeconds + secondsElapsed);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [initialTimeInSeconds]);

  return (
    <Typography
      variant="monospaced"
      fontSize={'0.7rem'}
      fontWeight={'700'}
      whiteSpace={'nowrap'}
      width={'35%'}
      {...props}
    >
      {parenteses ? `(${formatTime(elapsedTime.toFixed(0))})` : formatTime(elapsedTime.toFixed(0))}
    </Typography>
  );
};

export default TimeCounter;
