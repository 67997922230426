import { styled, Switch } from "@mui/material";

export const SwitchOnOff = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: theme.palette.green[500],
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.green[400],
            },
        },
        '&:not(.Mui-checked)': {
            color: theme.palette.red[500],
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.red[400],
            },
        },
    },
}));
