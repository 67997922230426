import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { MeusPedidos, Pedido } from '../../dtos/PedidosDTO';
import { ButtonTableOrder, TableRowLoading, TableRowNotFound } from 'src/ds';
import { useCallback, useEffect, useState } from 'react';
import PedidoRow from './RowPedido';
import TableCellOrdenado from 'src/ds/components/TableCellOrdenado';
import { actions } from 'src/store/Actions';
import { UseFormReturn } from 'react-hook-form';

export interface DispatchMethods {
  actionName: 'listarMeusPedidosStart' | 'listarPedidosGeral' | keyof typeof actions;
  payload?: any;
}

interface TabelaPedidosRepresentanteProps {
  pedidos: MeusPedidos[] | undefined;
  loading: boolean;
  fezRequisicao?: boolean;
  tipoUsuario: 'Representante';
  hookForm: UseFormReturn<any>;
  onSubmit?: () => void;
  isAllOpen: boolean;
}

interface TabelaPedidosGenericoProps {
  pedidos: Pedido[] | MeusPedidos[] | undefined;
  loading: boolean;
  fezRequisicao?: boolean;
  tipoUsuario: string;
  hookForm: UseFormReturn<any>;
  onSubmit?: () => void;
  isAllOpen: boolean;
}

type TabelaPedidosProps = TabelaPedidosRepresentanteProps | TabelaPedidosGenericoProps;

const TabelaPedidos = ({
  pedidos,
  loading,
  fezRequisicao,
  tipoUsuario,
  hookForm,
  onSubmit,
  isAllOpen,
}: TabelaPedidosProps) => {
  const ehRepresentante = tipoUsuario === 'Representante';
  const [stepperVisible, setStepperVisible] = useState<{ [key: string]: boolean }>(
    () => pedidos?.reduce((acc, pedido) => ({ ...acc, [pedido.pedidoID]: false }), {}) || {}
  );

  const toggleStepperVisibility = useCallback((pedidoID: string) => {
    setStepperVisible(prev => ({
      ...prev,
      [pedidoID]: !prev[pedidoID],
    }));
  }, []);

  useEffect(() => {
    setStepperVisible(prev => {
      const updated = { ...prev };
      pedidos?.forEach(pedido => {
        updated[pedido.pedidoID] = isAllOpen;
      });
      return updated;
    });
  }, [isAllOpen]);

  const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>(
    {
      origem: 'asc',
      codigo: 'asc',
      situacao: 'asc',
      entregador: 'asc',
      taxaCobrada: 'asc',
      avaliacao: 'asc',
      empresa: 'asc',
      cliente: 'asc',
      formaPagamento: 'asc',
      valorPedido: 'asc',
      distanciaRaio: 'asc',
      distanciaRota: 'asc',
      taxaEntregador: 'asc',
    }
  );


  const toggleSortDirection = (column: string) => {
    const newSortDirection: 'asc' | 'desc' = sortDirection[column] === 'asc' ? 'desc' : 'asc';

    const updatedSortDirection: { [key: string]: 'asc' | 'desc' } = {
      ...sortDirection,
      [column]: newSortDirection,
    };

    setSortDirection(updatedSortDirection);

    hookForm.setValue('ordenacaoOrdem', newSortDirection);
    hookForm.setValue('ordenacaoCampo', column);

    onSubmit && onSubmit();
  };

  return (
    <>
      <TableContainer
        sx={{
          width: '100%',
          overflowX: 'auto',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCellOrdenado sx={{ maxWidth: '50px' }} label="Origem" column="aplicacaoOrigemNome" sortDirection={sortDirection} onSortChange={toggleSortDirection} />
              <TableCellOrdenado sx={{ textAlign: 'center' }} label="Código" column="codigoExterno" sortDirection={sortDirection} onSortChange={toggleSortDirection} />
              <TableCellOrdenado sx={{ maxWidth: '55px' }} label="Situação" column="situacao" sortDirection={sortDirection} onSortChange={toggleSortDirection} />
              {ehRepresentante && <TableCellOrdenado label="Avaliação" column="avaliacaoNotaCliente" sortDirection={sortDirection} onSortChange={toggleSortDirection} />}
              {!ehRepresentante && <TableCellOrdenado sx={{ maxWidth: '60px' }} label="Empresa" column="empresa.nome" sortDirection={sortDirection} onSortChange={toggleSortDirection} />}
              <TableCellOrdenado
                label={ehRepresentante ? "Endereço" : "Endereços"}
                column="entregaEndereco.enderecoStr"
                sortDirection={sortDirection}
                onSortChange={toggleSortDirection}
                sx={{ minWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              />
              <TableCellOrdenado label="Entregador" column="entregadorUsuario.nome" sortDirection={sortDirection} onSortChange={toggleSortDirection} />
              {!ehRepresentante && <TableCellOrdenado label="Cliente" column="clienteNome" sortDirection={sortDirection} onSortChange={toggleSortDirection} />}
              {!ehRepresentante && <TableCellOrdenado label="Forma de pagamento" column="formaPagamento" sortDirection={sortDirection} onSortChange={toggleSortDirection} />}
              {!ehRepresentante && <TableCellOrdenado label="Valor" column="valor" sortDirection={sortDirection} onSortChange={toggleSortDirection} />}
              {ehRepresentante && <TableCellOrdenado label="Km por raio" column="distanciaCalculadaKm" sortDirection={sortDirection} onSortChange={toggleSortDirection} />}
              {ehRepresentante && <TableCellOrdenado label="Km por rota" column="distanciaCalculadaKmPorRota" sortDirection={sortDirection} onSortChange={toggleSortDirection} />}
              <TableCellOrdenado label="Taxa cobrada" column="taxaTotalCobrada" sortDirection={sortDirection} onSortChange={toggleSortDirection} />
              {!ehRepresentante && <TableCellOrdenado label="Taxa entregador" column="taxaTotalEntregador" sortDirection={sortDirection} onSortChange={toggleSortDirection} />}
              <TableCell align="center">
                <ButtonTableOrder>Ações</ButtonTableOrder>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? <TableRowLoading />
              : (!pedidos || pedidos?.length === 0)
                ? <TableRowNotFound text="Nenhum resultado encontrado" fezRequisicao={fezRequisicao} />
                : pedidos.map((pedido) => (
                  <PedidoRow
                    //@ts-expect-error
                    tipoUsuario={tipoUsuario}
                    key={pedido.pedidoID}
                    pedido={pedido}
                    onToggleStepper={toggleStepperVisibility}
                    stepperVisible={stepperVisible[pedido.pedidoID] || false}
                  />
                ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TabelaPedidos;
