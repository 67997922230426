import { TableCell, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { ButtonTableOrder } from 'src/ds';

interface TableCellOrdenadoProps {
    label: ReactNode;
    column: string;
    sortDirection: { [key: string]: 'asc' | 'desc' };
    onSortChange: (column: string) => void;
    sx?: SxProps<Theme>;
}

const TableCellOrdenado = ({ label, column, sortDirection, onSortChange, sx }: TableCellOrdenadoProps) => {
    return (
        <TableCell align="center" sx={sx}>
            <ButtonTableOrder
                onClick={() => onSortChange(column)}
                sortDirection={sortDirection}
                nome={column}
            >
                {label}
            </ButtonTableOrder>
        </TableCell>
    );
};

export default TableCellOrdenado;
