import React from 'react';
import { ContainerBodyModal, InputDateTime, ModalBase } from 'src/ds';
import { Stack, SxProps, Button } from '@mui/material';
import { UseFormReturn, useForm } from 'react-hook-form';

interface ModalAndamentoPedidoProps {
    open: boolean;
    onClose: () => void;
    hookForm: UseFormReturn<any>;
}

const ModalAndamentoPedido: React.FC<ModalAndamentoPedidoProps> = ({
    open,
    onClose,
    hookForm
}) => {
    const styles: SxProps = {
        flex: '250px',
    };

    // Hook form para gerenciamento local dos dados no modal
    const localForm = useForm({
        defaultValues: {
            dataAgendamento: hookForm.getValues('dataAgendamento'),
            dataCadastro: hookForm.getValues('dataCadastro'),
            dataPronto: hookForm.getValues('dataPronto'),
            dataDespachado: hookForm.getValues('dataDespachado'),
            dataAceito: hookForm.getValues('dataAceito'),
            dataChegouEstabelecimento: hookForm.getValues('dataChegouEstabelecimento'),
            dataEmRota: hookForm.getValues('dataEmRota'),
            dataRetorno: hookForm.getValues('dataRetorno'),
            dataFinalizado: hookForm.getValues('dataFinalizado'),
            dataCancelamento: hookForm.getValues('dataCancelamento'),
        },
    });

    const handleSave = () => {
        const localValues = localForm.getValues();
        console.log('localValues', localValues)
        // Atualiza os valores no hook form recebido por props
        hookForm.setValue('dataAgendamento', localValues.dataAgendamento || '');
        // hookForm.setValue('dataCadastro', localValues.dataCadastro); -- Nao pode ser alterado
        hookForm.setValue('dataPronto', localValues.dataPronto || '');
        hookForm.setValue('dataDespachado', localValues.dataDespachado || '');
        hookForm.setValue('dataAceito', localValues.dataAceito || '');
        hookForm.setValue('dataChegouEstabelecimento', localValues.dataChegouEstabelecimento || '');
        hookForm.setValue('dataEmRota', localValues.dataEmRota || '');
        hookForm.setValue('dataRetorno', localValues.dataRetorno || '');
        hookForm.setValue('dataFinalizado', localValues.dataFinalizado || '');
        hookForm.setValue('dataCancelamento', localValues.dataCancelamento || '');

        // Fecha o modal
        onClose();
    };

    return (
        <ModalBase
            open={open}
            onClose={onClose}
            size='sm'
            headerModal={{ title: 'Andamento do Pedido' }}
        >
            <ContainerBodyModal sx={{ maxHeight: '75vh', height: 'fit-content' }}>
                <Stack direction={'column'} sx={{ height: '100%', justifyContent: 'space-between' }}>
                    <Stack spacing={2} useFlexGap direction="row" flexWrap="wrap">
                        <InputDateTime
                            sx={styles}
                            hookForm={{
                                methods: localForm,
                                name: 'dataAgendamento',
                            }}
                            label="Data agendamento"
                        />
                        <InputDateTime
                            sx={styles}
                            hookForm={{
                                methods: localForm,
                                name: 'dataCadastro',
                            }}
                            disabled
                            label="Data recebido"
                        />
                        <InputDateTime
                            sx={styles}
                            hookForm={{
                                methods: localForm,
                                name: 'dataPronto',
                            }}
                            label="Data pronto"
                        />
                        <InputDateTime
                            sx={styles}
                            hookForm={{
                                methods: localForm,
                                name: 'dataDespachado',
                            }}
                            label="Data despacho"
                        />
                        <InputDateTime
                            sx={styles}
                            hookForm={{
                                methods: localForm,
                                name: 'dataAceito',
                            }}
                            label="Data aceito"
                        />
                        <InputDateTime
                            sx={styles}
                            hookForm={{
                                methods: localForm,
                                name: 'dataChegouEstabelecimento',
                            }}
                            label="Data no local"
                        />
                        <InputDateTime
                            sx={styles}
                            hookForm={{
                                methods: localForm,
                                name: 'dataEmRota',
                            }}
                            label="Data em rota"
                        />
                        <InputDateTime
                            sx={styles}
                            hookForm={{
                                methods: localForm,
                                name: 'dataRetorno',
                            }}
                            label="Data retorno"
                        />
                        <InputDateTime
                            sx={styles}
                            hookForm={{
                                methods: localForm,
                                name: 'dataFinalizado',
                            }}
                            label="Data finalizado"
                        />
                        <InputDateTime
                            sx={styles}
                            hookForm={{
                                methods: localForm,
                                name: 'dataCancelamento',
                            }}
                            label="Data cancelado"
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ marginTop: 2 }}>
                        <Button
                            sx={{ width: 'fit-content' }}
                            variant="containedFilled"
                            color='secondary'
                            onClick={onClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            sx={{ width: 'fit-content' }}
                            variant="containedFilled"
                            onClick={handleSave}
                        >
                            Salvar
                        </Button>
                    </Stack>
                </Stack>
            </ContainerBodyModal>
        </ModalBase>
    );
};

export default ModalAndamentoPedido;
