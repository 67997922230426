import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Collapse, Container, Grid2, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { fetchLoginStart, limparError, limparLoginFreemium } from '../../store/reducers/login';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { onlyNumbers } from '../../ds/common';
import RestaurarSenha from './RestaurarSenha';
import { ButtonLoading, ImageWithLoading, SnackbarAlert, useResponsive } from 'src/ds';
import { RootState } from 'src/store/reducers';
import image from 'src/assets/robo2.png';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { buscarTemplateLoginStart } from 'src/store/reducers/utils';

// Todo: Usuário não possui pick com assinatura

const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { showSuccess, showError } = useSnackbarContext();
  const { isMobile } = useResponsive();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    defaultValues: {
      email: '',
      senha: '',
      doisfa: '',
    },
    mode: 'onBlur',
  });
  const [show2fa, setShow2fa] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recuperarSenha, setRecuperarSenha] = useState(false);
  const token = useSelector((state: RootState) => state.login?.tokenUsuario);
  const navigationCallback = useSelector((state: RootState) => state.login?.navigation);
  const error = useSelector((state: RootState) => state?.login?.error);
  const devMode = useSelector((state: RootState) => state?.utils?.devMode);
  const [firstLoading, setFirstLoading] = useState(true);
  const template = useSelector((state: RootState) => state.config?.template?.template);


  useEffect(() => {
    if (!template) return;
    const { faviconUrl, nome } = template
    const favicon = document.getElementById("dynamic-favicon") as HTMLLinkElement;
    const title = document.getElementById("dynamic-title") as HTMLTitleElement;
    if (favicon && faviconUrl && title) {
      favicon.href = faviconUrl;
      title.innerText = `${nome}`;
    }
  }, [template]);

  useEffect(() => {
    dispatch(buscarTemplateLoginStart({}));
  }, [])

  useEffect(() => {
    if (token && navigationCallback) {
      navigation(navigationCallback);
    } else {
      setFirstLoading(false)
    }
    localStorage.clear();
  }, [token, navigation, navigationCallback]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleLogin = () => {
    setLoading(true);
    dispatch(fetchLoginStart({
      email: watch('email'),
      senha: watch('senha'),
      doisfa: watch('doisfa'),
    }));
  };

  useEffect(() => {
    if (error) {
      if (error === "É necessário fazer login utilizando o segundo fator de autenticação") {
        showError({
          message: error,
          duration: 5000
        });
        setShow2fa(true);
        setLoading(false);
        return;
      }
      dispatch(limparLoginFreemium());
      showError({
        // @ts-expect-error
        message: error?.mensagem ?? error ?? 'Ocorreu um erro ao fazer login',
        duration: 5000
      });
      if (error === "Sistema desativado. Por favor, contate o administrador do sistema. (3)") {
        dispatch(limparLoginFreemium());
        navigation('/PrimeiroAcesso');
      }
      setLoading(false);
    }
    return () => {
      dispatch(limparError());
    };
  }, [error, dispatch, navigation]);

  if (firstLoading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress
        sx={{
          color: theme.palette.grey[600],
          ...theme.applyStyles('dark', { color: theme.palette.grey[200] })
        }}
      />
    </Box>
  )

  return (
    <Grid2 container spacing={0} sx={{ width: '100%', height: '100vh' }} justifyContent='center'>
      <Grid2
        size={{ xs: 12, md: 7 }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '1rem',
        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            border: '0px solid #ccc',
            borderRadius: '6px',
            paddingTop: '3rem',
            paddingBottom: '1rem',
            boxShadow: theme.palette.mode === 'light'
              ? '2px 2px 12px 12px rgba(0, 0, 0, 0.07)'
              : '2px 2px 12px 12px rgba(250, 250, 250, 0.1)',
            '@media (min-width: 1280px)': { maxWidth: '500px' },
            maxWidth: '500px',
            minHeight: '450px',
            margin: '0 auto'
          }}
        >
          <ImageWithLoading
            src={template?.whitelabelUrl !== "acesso.pickngo.online" ? template?.logoUrl : 'https://pickngo.com.br/wp-content/uploads/2022/06/Logo-Pick-4-1024x264.png'}
            alt='Logo'
            boxProps={{ sx: { width: '80%', paddingBottom: '1.5rem', mx: 'auto' } }}
          />
          {!recuperarSenha
            ? (
              <form onSubmit={handleSubmit(handleLogin)} style={{ width: '100%', height: '100%' }}>
                <TextField
                  fullWidth
                  placeholder='exemplo@exemplo.com'
                  label="Email"
                  {...register('email', { required: 'Email obrigatório', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Email inválido' } })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  sx={{ marginBottom: '1rem' }}
                />
                <TextField
                  fullWidth
                  label="Senha"
                  {...register('senha', { required: 'Senha obrigatória', minLength: { value: 6, message: 'Senha deve ter pelo menos 6 caracteres' } })}
                  error={!!errors.senha}
                  helperText={errors.senha?.message}
                  type={showPassword ? 'text' : 'password'}
                  sx={{ marginBottom: '1rem' }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  }}
                />
                <Collapse in={show2fa}>
                  <TextField
                    fullWidth
                    label="2FA"
                    variant="outlined"
                    sx={{ marginBottom: '1rem' }}
                    {...register('doisfa', (show2fa ? { required: '2FA obrigatória', minLength: { value: 6, message: '2FA deve ter pelo menos 6 caracteres' } } : {}))}
                    error={!!errors.doisfa}
                    helperText={errors.doisfa?.message}
                    value={onlyNumbers(watch('doisfa'))}
                  />
                </Collapse>
                <Typography variant="body1">
                  Ao clicar em Acessar, você concorda com os <a href="https://pickngo.com.br/termos-de-uso/">Termos de Serviço</a> da Pickngo e reconhece nossa <a href="https://pickngo.com.br/politica-de-privacidade/">Política de Privacidade</a>.
                </Typography>
                <ButtonLoading loading={loading} type='submit' variant='containedFilled' sx={{ marginTop: '1rem' }}>
                  Acessar
                </ButtonLoading>
              </form>
            )
            : (
              <RestaurarSenha />
            )
          }
        </Container>
        <Button
          sx={{ mx: 'auto', maxWidth: '500px', bgcolor: 'transparent', mt: 2 }}
          onClick={() => setRecuperarSenha(!recuperarSenha)}
        >
          {recuperarSenha ? 'Voltar para o Login' : 'Esqueceu sua senha?'}
        </Button>
      </Grid2>
      {!isMobile && (
        <Grid2 size={{ xs: 12, md: 5 }}>
          <Box
            sx={{
              background: `url(${devMode ? 'https://steamuserimages-a.akamaihd.net/ugc/934931664793124225/D9861E4A35F464C8EE9E5B3B58BA2E02B352B743/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false' : image}) no-repeat center center`,
              backgroundSize: 'cover',
              height: '100%',
              width: '100%',
            }}
          />
        </Grid2>
      )}
    </Grid2>
  );
}

export default Login;
