import { Container, styled } from "@mui/material";

export const ContainerBodyModal = styled(Container)(({ theme }) => ({
    height: '75vh',
    maxWidth: '100%',
    padding: '1rem',
    width: '100%',
    '@media (min-width: 1280px)': {
        maxWidth: '100%',
    },
    '@media (min-width: 600px)': {
        padding: '1rem',
    },
    overflow: 'auto',
}))
