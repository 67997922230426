import { all } from 'redux-saga/effects';
import loginSaga from './login';
import configSaga from './config';
import criarPedidoSaga from './criarPedido';
import rastreioSaga from './rastreio';
import utilsSaga from './utils';
import chatSaga from './chat';
import escalasSaga from './escalas';
import daRoboSaga from './darobo';
import ListarPedidos from './pedidos';
import ListarEntregadores from './entregador';
import ListarClientes from './cliente';
import ListarEmpresas from './empresa';
import listarTabelasCobrancaSaga from './tabelaCobranca';
import ListarTabelaPagamento from './tabelaPagamento';
import TaxaExtraSaga from './taxaExtra';
import botsSaga from './bots';
import despachoAutomaticoSaga from './despachoAutomatico';
import walletSaga from './wallet';
import faturasSaga from './faturas';

export default function* rootSaga() {
    yield all([
        loginSaga(),
        configSaga(),
        criarPedidoSaga(),
        rastreioSaga(),
        utilsSaga(),
        chatSaga(),
        escalasSaga(),
        daRoboSaga(),
        ListarPedidos(),
        TaxaExtraSaga(),
        ListarEntregadores(),
        ListarClientes(),
        ListarEmpresas(),
        listarTabelasCobrancaSaga(),
        ListarTabelaPagamento(),
        botsSaga(),
        despachoAutomaticoSaga(),
        walletSaga(),
        faturasSaga(),
    ]);
}
