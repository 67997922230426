import { Autocomplete, IconButton, InputAdornment, SxProps, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Control, Controller, RegisterOptions, UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface HookForm {
  methods: UseFormReturn<any>;
  name: string;
  rules?: RegisterOptions;
}

interface AutocompleteEmpresaProps {
  hookForm: HookForm;
  label?: string;
  sx?: SxProps;
  size?: 'small' | 'medium';
}

interface Empresa {
  empresaRepresentanteID: string;
  empresaID: string;
  nome: string;
  ehBase: boolean;
}

/**
* Componente `AutocompleteEmpresas`
*
* Este componente exibe um campo de autocompletar para seleção de empresas, utilizando o `Autocomplete` do `@mui/material`.
* Permite que o usuário pesquise e selecione uma empresa da lista gerenciada pelo estado global do Redux.
* Quando apenas uma empresa está disponível, o componente exibe um campo de texto desabilitado com o nome da empresa e um ícone informativo.
*
* O `AutocompleteEmpresas` está integrado ao `react-hook-form` para controle do estado do formulário, recebendo os métodos `control` e `setValue` do formulário
* via `hookForm.methods`.
*
* @component
* @param {Object} hookForm - Objeto que contém os métodos do formulário fornecidos pelo `react-hook-form`.
* @param {UseFormReturn<any>} hookForm.methods - Objeto de métodos do `react-hook-form`, incluindo `control` para gerenciamento do estado e `setValue` para definir valores de campo.
* @param {string} hookForm.name - Nome do campo para identificação no formulário.
* @param {RegisterOptions} [hookForm.rules] - Regras de validação do `react-hook-form` para o campo (opcional).
* @param {string} label - Rótulo exibido no campo de autocompletar.
* @param {SxProps} [sx] - Estilos customizados aplicados ao componente, no formato do Material-UI (opcional).
* @param {'small' | 'medium'} [size='small'] - Tamanho do campo de autocompletar, podendo ser 'small' ou 'medium' (opcional).
*
* @returns {JSX.Element | null} Retorna o campo de autocompletar se houver várias empresas disponíveis; caso contrário, retorna um `TextField` preenchido com a única empresa disponível ou `null`.
*
* @example
* ```tsx
* <AutocompleteEmpresas
*   hookForm={{
*     methods: methods,
*     name: "empresa",
*     rules: { required: 'Seleção de empresa é obrigatória' }
*   }}
*   label="Empresa"
*   sx={{ width: 300 }}
* />
* ```
*
* @business_rules
* - Exibe apenas as empresas com `ehBase` igual a `false`.
* - Quando houver apenas uma empresa, o campo é substituído por um `TextField` desabilitado preenchido com o nome da empresa.
* - Inclui um ícone informativo quando há apenas uma empresa disponível.
* - Gerencia o estado do campo de entrada via `inputValue` para atualização dinâmica do valor digitado.
*/
const AutocompleteEmpresas = ({
  size = 'small',
  hookForm,
  label,
  sx,
}: AutocompleteEmpresaProps) => {
  const [inputValue, setInputValue] = useState('');
  const { control, setValue, getValues } = hookForm.methods; 


  const empresas = useSelector((state: RootState) => state.config.empresasPick?.dados?.lista);

  const filteredBase = empresas?.filter((empresa: Empresa) => empresa.ehBase === false);
  const [options, setOptions] = useState<{ label: string; value: string | number }[]>([]);

  useEffect(() => {
    if (Array.isArray(filteredBase)) {
      const mappedOptions = filteredBase.map((empresa: Empresa) => ({
        label: empresa.nome,
        value: empresa.empresaID,
      }));
      setOptions(mappedOptions);
    } else {
      setOptions([]);
    }
  }, [filteredBase]);

  useEffect(() => {
    if (options.length === 1) {
      const currentValue = getValues(hookForm.name); 
      if (currentValue !== options[0].value) {
        setValue(hookForm.name, options[0].value); 
      }
    }
  }, [options, hookForm.name]);



  if (options.length <= 1) {
    return (
      <TextField
        label={label}
        size={size}
        sx={sx}
        value={options.length > 0 ? options[0].label : ''}
        disabled
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end" sx={{ width: 18 }}>
                <Tooltip title="Apenas uma empresa disponível" arrow placement="top">
                  <InfoOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }
        }}
      />
    );
  }



  return (
    <Controller
      name={hookForm.name}
      control={control}
      rules={hookForm.rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          // disablePortal
          options={options}
          renderInput={(params) => (
            <TextField
              error={!!error}
              helperText={error?.message || ''}
              {...params}
              label={label}
              size={size}
            />)}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          value={options?.find(option => option.value === value) || null}
          onChange={(_event, newValue) => {
            onChange(newValue ? newValue.value : '');
          }}
          sx={sx}
        />
      )}
    />
  )
}

export default AutocompleteEmpresas;
