import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import {
    alterarSituacaoFailure, alterarSituacaoRequest, alterarSituacaoSuccess,
    listarAvaliacao, avaliacaoSuccess, avaliacaoFailure,
    avaliarEntregadorReq, avaliarEntregadorSuccess, avaliarEntregadorFailure,
    buscarTemplateFailure, buscarTemplateRequest, buscarTemplateSuccess,
    emAndamentoFailure, emAndamentoStart, emAndamentoSuccess,
    listarEntregaParceira, entregaParceiraFailure, entregaParceiraSuccess,
    fetchMasterPageFailure, fetchMasterPageStart, fetchMasterPageSuccess,
    listarEntregadoresFailure, listarEntregadoresRequest, listarEntregadoresSuccess, listarFailure, listarOcorrenciaFailure, listarOcorrenciaRequest, listarOcorrenciaSuccess, listarRequest, listarSuccess, ocorrenciaFailure, ocorrenciaSuccess, salvarEntregador, salvarUsuarioFailure, salvarUsuarioReq, salvarUsuarioSuccess,
    tempoMedioRequest, tempoMedioFailure, tempoMedioSuccess,
    empresasPickSuccess,
    empresasPickFailure,
    listarEmpresasPick,
    adicionarFinanceiroSuccess, adicionarFinanceiroFailure, adicionarFinanceiroStart,
    walletStart, walletSuccess, walletFailure,
    excluirEntregadorFailure,
    excluirEntregadorSuccess,
    excluirEntregador,
    listarTipoDeVeiculoStart,
    tipoDeVeiculoSuccess,
    tipoDeVeiculoFailure,
    definirTipoDeCobrancaStart,
    definirTipoDeCobrancaFailure,
    definirTipoDeCobrancaSuccess,
    definirTipoDePagamentoFailure,
    definirTipoDePagamentoSuccess,
    definirTipoDePagamentoStart,
    limparTipoDePagamento,
    solicitarEntregaParceiraStart,
    solicitarEntregaParceiraSuccess,
    solicitarEntregaParceiraFailure,
    getHubspotTokenSuccess,
    getHubspotTokenFailure,
    getHubspotTokenStart,
    getIfoodMerchantIdsStart,
    getIfoodMerchantIdsFailure,
    getIfoodMerchantIdsSuccess,
    listarFormasPagamentoStart,
    listarFormasPagamentoSuccess,
    listarFormasPagamentoFailure,
} from '../reducers/config';
import moment from 'moment';
import { usuarioPaginaStart } from '../reducers/entregador';

function* fetchMasterPage() {
    try {
        const response = yield call(api.post, '/Home/MasterPage', {}, { headers: { 'x-token': localStorage.getItem('@token'), 'x-pick': localStorage.getItem('@xpick'), 'x-wl': window.location.origin } }, { timeout: 60000 });

        if (response.data.sucesso === true) {
            yield put(fetchMasterPageSuccess(response.data));
            const empresasIds = response?.data?.empresas?.map((emp) => emp.empresaID) || [];
            yield put(listarEntregadoresRequest(empresasIds))
            yield put(listarEmpresasPick({
                empresaIDs: empresasIds,
                filtrarPorTags: false,
                pagina: 1,
                paginacao: -1,
            }))

        } else {
            yield put(fetchMasterPageFailure(response.data.mensagem));
        }
    } catch (error) {
        if (error.code === 'ECONNABORTED') {
            console.error("A requisição demorou mais de 10 segundos.");
            window.location.href = '/timeout'; // Redireciona para a página de erro
        } else {
            console.log(error);
            yield put(fetchMasterPageFailure(error.toString()));
        }
    }
}

function* listarEmAndamento(action) {
    const empresaID = action.payload?.empresaID;
    const form = new FormData();
    form.append('pagina', '1')
    form.append('paginacao', '-1')
    form.append('empresaID', empresaID)
    try {
        const response = yield call(api.post, '/Pedido/ListarEmAndamento', form);
        yield put(emAndamentoSuccess(response.data));
    } catch (error) {
        yield put(emAndamentoFailure(error.toString()));
    }
}

function* listarEntregadores(action) {
    const form = new FormData();
    form.append('empresasIDs', [action.payload])
    form.append('pagina', '1')
    form.append('paginacao', '-1')
    try {
        const response = yield call(api.post, '/Usuario/ListarEntregadoresOnline', form);
        yield put(listarEntregadoresSuccess(response.data));
    } catch (error) {
        yield put(listarEntregadoresFailure(error.toString()));
    }
}


function* buscarTemplate() {
    try {
        const response = yield call(api.post, '/Pick/Template', {}, { headers: { 'x-wl': window.location.hostname } });
        yield put(buscarTemplateSuccess(response.data));
    } catch (error) {
        yield put(buscarTemplateFailure(error.toString()));
    }
}

function* listar() {
    const form = new FormData();
    const formPagamento = new FormData();
    form.append('pagina', 1)
    form.append('paginacao', -1)
    formPagamento.append('pagina', 1)
    formPagamento.append('paginacao', -1)
    formPagamento.append('filtros', 'visivel')


    try {
        const prioridades = yield call(api.post, '/Prioridade/Listar', form);
        const formasDePagamento = yield call(api.post, '/FormaPagamento/Listar', formPagamento);

        yield put(listarSuccess([prioridades.data.dados, formasDePagamento.data.dados]));
    } catch (error) {
        yield put(listarFailure(error.toString()));
    }
}

function* alterarSituacao({ payload }) {
    const pedidoID = payload.pedidoID;
    const situacao = payload.situacao;
    const form = new FormData();
    form.append('pedidoID', pedidoID)
    form.append('situacao', situacao)
    try {
        const response = yield call(api.post, '/Pedido/AlterarSituacao', form);
        yield put(alterarSituacaoSuccess(response.data));
    } catch (error) {
        yield put(alterarSituacaoFailure(error.toString()));
    }
}

function* listarOcorrencia(action) {

    const form = new FormData();
    form.append('paginacao', '-1')
    form.append('pagina', '-1')
    form.append('ordenacaoCampo', '')
    form.append('ordenacaoOrdem', '')
    form.append('filtros', 'visivelParaEmpresa == true')

    try {
        const response = yield call(api.post, '/TipoOcorrencia/Listar', form);
        yield put(listarOcorrenciaSuccess(response.data.dados.lista));
    } catch (error) {
        yield put(listarOcorrenciaFailure(error.toString()));
    }
}

function* ocorrencia(action) {

    const form = new FormData();
    form.append('usuarioID', action.payload)
    form.append('tipoOcorrenciaID', action.payload)
    form.append('observacoes', action.payload)
    form.append('pedidoID', action.payload)
    form.append('empresaID', action.payload)
    try {
        const response = yield call(api.post, '/Ocorrencia/Registrar', form);
        yield put(ocorrenciaSuccess(response.data));
    } catch (error) {
        yield put(ocorrenciaFailure(error.toString()));
    }
}

function* entregaParceira(action) {
    const form = new FormData();
    form.append('pedidoID', action.payload)
    try {
        const response = yield call(api.post, '/Pedido/VerificarDisponibilidadeEntregaParceiro', form)
        yield put(entregaParceiraSuccess(response.data))
    } catch (error) {
        yield put(entregaParceiraFailure(error.toString()))
    }
}

function* solicitarEntregaParceira(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/Pedido/SolicitarEntregaParceiro', form)
        yield put(solicitarEntregaParceiraSuccess(response.data))
    } catch (error) {
        yield put(solicitarEntregaParceiraFailure(error.toString()))
    }
}

function* listarParaAvaliacao() {
    const dataHoje = moment().format('DD/MM/YYYY%20HH:mm');
    const ontem = moment().subtract(1, 'days').format('DD/MM/YYYY%20HH:mm');

    const form = new FormData();
    form.append('pagina', '1')
    form.append('paginacao', '-1')

    try {
        const response = yield call(api.post, `/Pedido/ListarParaAvaliacao?dataInicio=${ontem}&dataFim=${dataHoje}`, form);
        yield put(avaliacaoSuccess(response.data.dados.lista));
    } catch (error) {
        yield put(avaliacaoFailure(error.toString()));
    }
}

function* avaliarEntregador(action) {
    const form = new FormData();
    form.append('nota', action.payload.nota)
    form.append('pedidoID', action.payload.pedidoID)
    try {
        const response = yield call(api.post, '/Pedido/AvaliarEntregador', form);
        if (response.data.sucesso === true) {
            yield put(avaliarEntregadorSuccess(response.data));
        } else {
            yield put(avaliarEntregadorFailure(response.data));
        }
    } catch (error) {
        yield put(avaliarEntregadorFailure(error.toString()));
    }
}

function* buscarTempoMedio() {
    const dataHoje = moment().format('DD/MM/YYYY%20HH:mm');
    const ontem = moment().subtract(1, 'hour').format('DD/MM/YYYY%20HH:mm');

    const form = new FormData();
    form.append('pagina', '1')
    form.append('paginacao', '-1')
    try {
        const response = yield call(api.post, `/Pedido/ListarTempoMedioPorKilometragemSql?dataInicio=${ontem}&dataFim=${dataHoje}`, form);
        yield put(tempoMedioSuccess(response.data.dados.lista));
    } catch (error) {
        yield put(tempoMedioFailure(error.toString()));
    }
}


function* salvarUsuario(action) {

    try {
        const response = yield call(api.post, '/Usuario/Salvar', action.payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        if (response.data.sucesso !== true) {
            yield put(salvarUsuarioFailure(response.data));
            throw new Error(response.data.mensagem);
        }

        yield put(salvarUsuarioSuccess(response.data));
        yield put(usuarioPaginaStart({ id: action.payload.id }))
    } catch (error) {
        yield put(salvarUsuarioFailure(error.toString()));
    }
}


function* listarEmpresasPickRequest(action) {
    const form = new FormData();
    const filtrarPorTags = action.payload.filtrarPorTags
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/Empresa/Listar?filtrarPorTags=' + filtrarPorTags, form);
        if (response.data.sucesso !== true) {
            yield put(empresasPickFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(empresasPickSuccess(response.data));
    } catch (error) {
        yield put(empresasPickFailure(error.toString()));
    }
}

function* adicionarFinanceiro(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/Pedido/AdicionarFinanceiro', form);
        if (response.data.sucesso === true) {
            yield put(adicionarFinanceiroSuccess(response.data));
        } else if (response.data.sucesso === false) {
            yield put(adicionarFinanceiroFailure(response.data.mensagem));
        }
    } catch (error) {
        yield put(adicionarFinanceiroFailure(error.toString()));
    }
}

function* fetchWallet() {
    try {
        const response = yield call(api.post, '/Empresa/GetSaldo');
        if (response.data.sucesso !== true) {
            yield put(walletFailure(response.data.erro));
        } else {
            yield put(walletSuccess(response.data.dados));
        }
    } catch (error) {
        yield put(walletFailure(error.toString()));
    }
}


function* excluirEntregadorReq(action) {
    console.log(action.payload, 'aaaaaaaa')
    const form = new FormData();
    const id = action.payload;
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/Usuario/Excluir?id=' + id);
        if (response.data.sucesso !== true) {
            yield put(excluirEntregadorFailure(response.data));
            throw new Error(response.data.mensagem);
        }
        yield put(excluirEntregadorSuccess(response.data));
    } catch (error) {
        yield put(excluirEntregadorFailure(error.toString()));
    }
}

function* listarTiposDeVeiculo(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/TipoVeiculo/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(tipoDeVeiculoFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(tipoDeVeiculoSuccess(response.data));
    } catch (error) {
        yield put(tipoDeVeiculoFailure(error.toString()));
    }
}

function* definirTipoCobranca(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/Pick/DefinirPadraoCobranca', form);
        if (response.data.sucesso !== true) {
            yield put(definirTipoDeCobrancaFailure(response.data.erro));
            throw new Error(response.data.mensagem);
        }
        yield put(definirTipoDeCobrancaSuccess(response.data));
        yield put(fetchMasterPageStart())
    } catch (error) {
        yield put(definirTipoDeCobrancaFailure(error.toString()));
    }
}
function* definirTipoPagamento(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/Pick/DefinirPadraoPagamento', form);
        if (response.data.sucesso !== true) {
            yield put(definirTipoDePagamentoFailure(response.data));
            throw new Error(response.data.mensagem);
        }
        yield put(definirTipoDePagamentoSuccess(response.data));
        yield put(fetchMasterPageStart())

    } catch (error) {
        yield put(definirTipoDePagamentoFailure(error.toString()));
    }
}

function* getHubspotToken(action) {
    try {
        const response = yield call(api.post, '/Home/TokenSuporte');
        if (response.data.sucesso !== true) {
            yield put(getHubspotTokenFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(getHubspotTokenSuccess(response.data));
    } catch (error) {
        yield put(getHubspotTokenFailure(error.toString()));
    }
}

function* getIfoodMerchantIds() {
    try {
        const response = yield call(api.post, '/Pick/GetIfoodMerchantIds');
        if (response.data.sucesso !== true) {
            yield put(getIfoodMerchantIdsFailure(response.data.erro));
        }
        yield put(getIfoodMerchantIdsSuccess(response.data));
    } catch (error) {
        console.error(error);
    }
}

function* listarFormasPagamento({ payload }) {
    try {
        const formData = new FormData();
        Object.keys(payload).forEach(key => {
            formData.append(key, payload[key]);
        })
        const response = yield call(api.post, '/FormaPagamento/Listar', formData);
        if (response.data.sucesso !== true) {
            yield put(listarFormasPagamentoFailure(response.data.mensagem));
        } else {
            yield put(listarFormasPagamentoSuccess(response.data));
        }
    } catch (error) {
        yield put(listarFormasPagamentoFailure(error));
    }
}

function* configSaga() {
    yield takeLatest(fetchMasterPageStart.type, fetchMasterPage);
    yield takeLatest(emAndamentoStart.type, listarEmAndamento);
    yield takeLatest(buscarTemplateRequest.type, buscarTemplate);
    yield takeLatest(listarRequest.type, listar);
    yield takeLatest(listarEntregadoresRequest.type, listarEntregadores);
    yield takeLatest(alterarSituacaoRequest, alterarSituacao);
    yield takeLatest(listarOcorrenciaRequest.type, listarOcorrencia);
    yield takeLatest(listarEntregaParceira.type, entregaParceira);
    yield takeLatest(solicitarEntregaParceiraStart.type, solicitarEntregaParceira);
    yield takeLatest(listarAvaliacao.type, listarParaAvaliacao);
    yield takeLatest(avaliarEntregadorReq.type, avaliarEntregador);
    yield takeLatest(tempoMedioRequest.type, buscarTempoMedio);
    yield takeLatest(salvarUsuarioReq.type, salvarUsuario);
    yield takeLatest(listarEmpresasPick.type, listarEmpresasPickRequest);
    yield takeLatest(adicionarFinanceiroStart.type, adicionarFinanceiro);
    yield takeLatest(walletStart.type, fetchWallet);
    yield takeLatest(excluirEntregador.type, excluirEntregadorReq);
    yield takeLatest(listarTipoDeVeiculoStart.type, listarTiposDeVeiculo);
    yield takeLatest(definirTipoDeCobrancaStart.type, definirTipoCobranca);
    yield takeLatest(definirTipoDePagamentoStart.type, definirTipoPagamento);
    yield takeLatest(getHubspotTokenStart.type, getHubspotToken);
    yield takeLatest(getIfoodMerchantIdsStart.type, getIfoodMerchantIds);
    yield takeLatest(listarFormasPagamentoStart.type, listarFormasPagamento);

}

export default configSaga;
