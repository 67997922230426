import React from 'react';
import { Grid, Paper, Box, Collapse, Typography, IconButton, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CardPedido from '../CardPedido';

interface ItemListaPedidosProps {
    groupedPedidos: Record<string, any[]>;
    empresas: string[];
    expandedGroups: Record<string, boolean>;
    toggleGroup: (groupName: string) => void;
    abrir: (action: string, item?: any) => void;
    hasMultipleEmpresas: boolean;

}

const ItemListaPedidos: React.FC<ItemListaPedidosProps> = ({ groupedPedidos, empresas, expandedGroups, toggleGroup, abrir, hasMultipleEmpresas }) => {
    const theme = useTheme();
    return (
        <Grid container height={'100%'} width={'100%'} padding={'0 0.5rem'} sx={{ alignContent: 'flex-start' }}>
            {hasMultipleEmpresas ? (
                empresas?.map((groupName, index) => (
                    <Grid item xs={12} key={index}>
                        <Paper
                            elevation={0}
                            sx={{
                                borderRadius: '4px',
                                minWidth: '100%',
                            }}
                        >
                            <Box
                                onClick={() => toggleGroup(groupName)}
                                sx={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0.5rem',
                                    borderRadius: `${theme.sizes.borderRadius.xs} ${theme.sizes.borderRadius.xs} 0 0`,
                                    backgroundColor: expandedGroups[groupName] ? theme.palette.primary.light : 'transparent',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    component="div"
                                    color={
                                        expandedGroups[groupName] ? theme.palette.text.secondary : theme.palette.text.primary
                                    }
                                >
                                    {groupName}
                                </Typography>
                                <IconButton>
                                    {expandedGroups[groupName] ? (
                                        <ExpandLess sx={{ color: theme.palette.text.secondary }} />
                                    ) : (
                                        <ExpandMore sx={{ color: theme.palette.text.primary }} />
                                    )}
                                </IconButton>
                            </Box>
                            <Collapse in={expandedGroups[groupName]} timeout="auto" unmountOnExit mountOnEnter>
                                <Box
                                    sx={{
                                        padding: '0.3rem',
                                        borderTop: '0px solid #ccc',
                                        borderRadius: `0 0 ${theme.sizes.borderRadius.xs} ${theme.sizes.borderRadius.xs}`,
                                    }}
                                >
                                    {groupedPedidos[groupName]?.map((item) => (
                                        <CardPedido
                                            key={item?.pedidoID}
                                            pedido={item}
                                            abrirCriarOcorrencia={() => abrir('abrirOcorrencia', item)}
                                            abrirEditarPedido={() => abrir('editarPedido', item)}
                                            abrirEntregaParceira={() => abrir('abrirParceira', item)}
                                            abrirAtribuirPedido={() => abrir('atribuirPedido', item)}
                                            id={item.pedidoID}
                                        />
                                    ))}
                                </Box>
                            </Collapse>
                        </Paper>
                    </Grid>
                ))
            ) : (
                groupedPedidos[empresas[0]]?.map((item) => (
                    <Grid item xs={12} key={item?.pedidoID}>
                        <CardPedido
                            pedido={item}
                            abrirCriarOcorrencia={() => abrir('abrirOcorrencia', item)}
                            abrirEditarPedido={() => abrir('editarPedido', item)}
                            abrirEntregaParceira={() => abrir('abrirParceira', item)}
                            abrirAtribuirPedido={() => abrir('atribuirPedido', item)}
                            id={item?.pedidoID}

                        />
                    </Grid>
                ))
            )}
        </Grid>
    )
};

export default ItemListaPedidos;
