import { Theme } from "@mui/material";

const getSituacaoStyles = (theme: Theme, situacao?: string | null) => {
  if (!situacao) return {};
  const newSituacao = situacao.toUpperCase();
  const situacaoColors = {
    CRIADO: theme.palette.status["Criado"],
    PRONTO: theme.palette.status["Pronto"],
    DESPACHADO: theme.palette.status["Despachado"],
    RECEBIDO: theme.palette.status["Recebido"],
    "EM ROTA": theme.palette.status["Em rota"],
    "EM_Tempo total ROTA": theme.palette.status["Em rota"],
    ACEITO: theme.palette.status["Aceito"],
    "NO LOCAL": theme.palette.status["No local"],
    RETORNANDO: theme.palette.status["Retornando"],
    CANCELADO: theme.palette.status["Recebido"],
    FINALIZADO: theme.palette.status["Retornando"],
    ATIVA: theme.palette.green[500],
    INATIVA: theme.palette.red[500],
  };
  const backgroundColor =
    situacaoColors[newSituacao as keyof typeof situacaoColors] || "#9e9e9e";
  const color = theme.palette.getContrastText(backgroundColor);

  return { backgroundColor, color };
};

export default getSituacaoStyles;
