import React from 'react';
import {
    Box,
    Table,
    TableCell,
    TableRow,
    Typography,
    TableHead,
    TableBody,
    TableContainer,
    Paper,
    Button,
    Card,
    Stack,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Pedido } from 'src/dtos/PedidosDTO';
import { RootState } from 'src/store/reducers';
import { CustomTableHead, CustomTableRow } from './components/TablePerformance';

const PainelPerformance = () => {
    const emAndamento = useSelector((state: RootState) => state.config?.emAndamento?.dados?.lista);

    const pedidosAgrupados = emAndamento?.reduce((acc: Record<string, Pedido[]>, pedido: Pedido) => {
        const situacao = pedido.situacao || 'Outros';
        if (!acc[situacao]) {
            acc[situacao] = [];
        }
        acc[situacao].push(pedido);
        return acc;
    }, {});

    const headers = [
        'Origem',
        'Código',
        'Empresa',
        'Cliente',
        'Destino',
        'Entregador',
        'Pagamento',
        'Situação',
        'Tempo',
        'Ações',
    ];

    return (
        <Box>
            {pedidosAgrupados &&
                Object.keys(pedidosAgrupados).map((situacao) => (
                    <Card
                        key={situacao}
                        sx={{ my: 4, backgroundColor: 'white', padding: '16px', borderRadius: '8px' }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '16px',
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                {situacao.toUpperCase()}
                            </Typography>
                            
                        </Box>

                        {/* Tabela com espaçamento */}
                        <TableContainer
                            component={Paper}
                            sx={{
                                borderRadius: '8px',
                                overflow: 'hidden',
                                backgroundColor: 'white',
                            }}
                        >
                            <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
                                <CustomTableHead headers={headers} />
                                <TableBody>
                                    {pedidosAgrupados[situacao].map((pedido: Pedido, index: number) => (
                                        <CustomTableRow
                                            key={index}
                                            pedido={pedido}
                                            isLast={index === pedidosAgrupados[situacao].length - 1}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                ))}
        </Box>
    );
};

export default PainelPerformance;
