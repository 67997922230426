import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import { getInfo, getInfoFailure, getInfoSuccess, listarEmpresasDa, listarEmpresasDaFailure, listarEmpresasDaSuccess, setDaRobo, setDaRoboFailure, setDaRoboSuccess } from '../reducers/darobo';

function* listarEmpresasDaReq(action) {
    const form = new FormData();
    const pickID = action.payload.pickID;
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/TabelaDespachoAutomatico/ListarEmpresasDAs?pickID' + pickID, form);
        if (response.data.sucesso === true) {
            yield put(listarEmpresasDaSuccess(response.data));
        }
    } catch (error) {
        yield put(listarEmpresasDaFailure(error));
    }
}

function* getInfoDaRobo(action) {
    const form = new FormData();
    const pickID = action.payload.pickID;
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/TabelaDespachoAutomatico/GetInfoDARoboSAAS?pickID=' + pickID);
        if (response.data.sucesso === true) {
            yield put(getInfoSuccess(response.data.dados.resposta));
        } else {
            yield put(getInfoFailure(response.data.mensagem ?? 'Ocorreu um erro inesperado.'));
        }
    } catch (error) {
        yield put(getInfoFailure(error));
    }
}

function* setDaRoboReq(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaDespachoAutomatico/SetDARoboSaas', form);
        if (response.data.sucesso === true) {
            yield put(setDaRoboSuccess(response.data.dados.resposta));
        } else {
            yield put(setDaRoboFailure(response.data.erro));

        }
    } catch (error) {
        yield put(setDaRoboFailure(error));
    }
}

export function* daRoboSaga() {
    yield takeLatest(listarEmpresasDa.type, listarEmpresasDaReq);
    yield takeLatest(getInfo.type, getInfoDaRobo);
    yield takeLatest(setDaRobo.type, setDaRoboReq);
}

export default daRoboSaga;
