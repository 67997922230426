import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ExcluirDespachoDTO,
  ListarMeusPedidosDTO,
  LogDTO,
  PayloadExcluirDespacho,
  PayloadListarMeusPedidos,
  PayloadLog,
  PayloadPedidoDespachos,
  PayloadPedidoMapaRastreio,
  PayloadPedidoModelo,
  PayloadPedidoTaxaExtra,
  PayloadRecalcularQuilometragem,
  PayloadRecalcularTabelas,
  PayloadSalvarPedido,
  PedidoDespachosDTO,
  PedidoMapaRastreioDTO,
  PedidoModeloDTO,
  PedidosDTO,
  PedidosTipoDiaDTO,
  PedidoTaxaExtraDTO,
  RecalcularQuilometragemDTO,
  RecalcularTabelasDTO,
  SalvarPedidoDTO,
} from "../../dtos/PedidosDTO";
import { DefaultAsyncState } from ".";

interface ListaPedidosParams {
  empresaID: string;
  paginacao: number;
  pagina: number;
}

interface RelatorioState {
  loading: boolean;
  data: PedidosTipoDiaDTO | null;
  error: string | null;
}

interface PedidoPerformance {
  filtroCodigo: string | null;
  filtroNome: string | null;
  distancia: number;
  dtInicial: string | null;
  dtFinal: string | null;
  tempoProducao: number;
  tempoAceiteMotoboy: number;
  tempoChegadaMotoboy: number;
  tempoRotaEntregador: number;
  tempoTotalPedido: number;
  tempoETA: number;
  dtPronto: string;
  dtCadastro: string;
  nomeEmpresa: string;
  empresaID: string | null;
  pickID: string;
  pedidoID: string;
  codigoExterno: string;
  codigoPedido: number;
  tempoDespacho: number;
  tipoCobranca: number;
  distanciaFiltro: number;
  nomeUsuario: string;
  usuarioId: string;
  distanciaInicial: number;
  distanciaFinal: number;
}

interface DadosPerformance {
  dados: {
    lista: PedidoPerformance[];
    pagina: number;
    indiceInicial: number;
    indiceFinal: number;
    ultimaPagina: number;
    total: number;
    mediaTempoPedidoPronto: number;
    mediaTempoRotaEntregador: number;
    porcentagemNegativos: number;
    tempoMedioDespacho: number;
    registrosTempoZero: number;
    porcentagemPositivos: number;
    media_0_a_5: number;
    media_5_a_10: number;
    media_10_a_15: number;
    media_15_a_20: number;
    media_20_a_30: number;
    media_30_a_40: number;
    media_40_a_50: number;
    media_50_a_60: number;
    media_0_a_5_despacho: number;
    media_5_a_10_despacho: number;
    media_10_a_15_despacho: number;
    media_15_a_20_despacho: number;
    media_20_a_30_despacho: number;
    media_30_a_40_despacho: number;
    media_40_a_50_despacho: number;
    media_50_a_60_despacho: number;
    media_0_a_5_producao: number;
    media_5_a_10_producao: number;
    media_10_a_15_producao: number;
    media_15_a_20_producao: number;
    media_20_a_30_producao: number;
    media_30_a_40_producao: number;
    media_40_a_50_producao: number;
    media_50_a_60_producao: number;
    faixa_0_a_5: number;
    faixa_5_a_10: number;
    faixa_10_a_15: number;
    faixa_15_a_20: number;
    faixa_20_a_25: number;
    faixa_30_a_35: number;
  };
}

interface PerformancePedidosState {
  loading: boolean;
  data: DadosPerformance | null;
  error: string | null;
}

interface PedidosState {
  listaPedidos: DefaultAsyncState<PedidosDTO>;
  pedidosPorTipoPorDia: RelatorioState;
  pedidosPorTipoEmpresa: RelatorioState;
  pedidosPorTipo: RelatorioState;
  pedidosPorFaixaKm: RelatorioState;
  pedidosPorFaixaKmPorDia: RelatorioState;
  rankingEmpresas: RelatorioState;
  rankingEntregadores: RelatorioState;
  rankingBairros: RelatorioState;
  exportarRelatorioGeral: {
    loading: boolean;
    error: string | null;
  };
  performancePedidos: PerformancePedidosState;
  exportarPerformancePedidos: any;
  pedidoModelo: DefaultAsyncState<PedidoModeloDTO>;
  salvarPedido: DefaultAsyncState<SalvarPedidoDTO>;
  recalcularTabelasPedido: DefaultAsyncState<RecalcularTabelasDTO>;
  recalcularQuilometragemPedido: DefaultAsyncState<RecalcularQuilometragemDTO>;
  pedidoTaxaExtra: DefaultAsyncState<PedidoTaxaExtraDTO>;
  log: DefaultAsyncState<LogDTO>;
  pedidoDespachos: DefaultAsyncState<PedidoDespachosDTO>;
  pedidoMapaRastreio: DefaultAsyncState<PedidoMapaRastreioDTO>;
  listarLocalizacoes: DefaultAsyncState<any>;
  meusPedidos: DefaultAsyncState<ListarMeusPedidosDTO>;
  exportarMeusPedidos: {
    loading: boolean;
    error: string | null;
  };
  excluirDespacho: DefaultAsyncState<ExcluirDespachoDTO>;
  finalizarVarios: DefaultAsyncState<any>;
}

const initialValues = {
  loading: false,
  data: null,
  error: null,
};

const initialState: PedidosState = {
  listaPedidos: { ...initialValues },
  pedidosPorTipoPorDia: { ...initialValues },
  pedidosPorTipoEmpresa: { ...initialValues },
  pedidosPorTipo: { ...initialValues },
  pedidosPorFaixaKm: { ...initialValues },
  pedidosPorFaixaKmPorDia: { ...initialValues },
  rankingEmpresas: { ...initialValues },
  rankingEntregadores: { ...initialValues },
  rankingBairros: { ...initialValues },
  exportarRelatorioGeral: {
    loading: false,
    error: null,
  },
  performancePedidos: { ...initialValues },
  exportarPerformancePedidos: { ...initialValues },
  pedidoModelo: { ...initialValues },
  salvarPedido: { ...initialValues },
  recalcularTabelasPedido: { ...initialValues },
  recalcularQuilometragemPedido: { ...initialValues },
  pedidoTaxaExtra: { ...initialValues },
  log: { ...initialValues },
  pedidoDespachos: { ...initialValues },
  pedidoMapaRastreio: { ...initialValues },
  listarLocalizacoes: { ...initialValues },
  meusPedidos: { ...initialValues },
  exportarMeusPedidos: { loading: false, error: null },
  excluirDespacho: { ...initialValues },
  finalizarVarios: { ...initialValues },
};

const pedidosSlice = createSlice({
  name: "pedidos",
  initialState: initialState,
  reducers: {
    listaPedidosStart(state, action: PayloadAction<ListaPedidosParams>) {
      state.listaPedidos.loading = true;
      state.listaPedidos.error = null;
    },
    listaPedidosFailure(state, action: PayloadAction<string>) {
      // Tipando o payload como string
      state.listaPedidos.loading = false;
      state.listaPedidos.error = action.payload;
    },
    listaPedidosSuccess(state, action: PayloadAction<any>) {
      // Tipando o payload como o tipo correto dos dados
      state.listaPedidos.loading = false;
      state.listaPedidos.data = action.payload;
    },
    listarPedidosGeral(state, action: PayloadAction<any>) {
      state.listaPedidos.loading = true;
      state.listaPedidos.error = null;
    },
    listarPedidosGeralSuccess(state, action: PayloadAction<any>) {
      state.listaPedidos.loading = false;
      state.listaPedidos.data = action.payload;
    },
    listarPedidosGeralFailure(state, action: PayloadAction<any>) {
      state.listaPedidos.loading = false;
      state.listaPedidos.error = action.payload;
    },
    listarPedidosPorTipoPorDia(state, action: PayloadAction<any>) {
      state.pedidosPorTipoPorDia.loading = true;
      state.pedidosPorTipoPorDia.error = null;
    },
    listarPedidosPorTipoPorDiaSuccess(state, action: PayloadAction<any>) {
      state.pedidosPorTipoPorDia.loading = false;
      state.pedidosPorTipoPorDia.data = action.payload;
    },
    listarPedidosPorTipoPorDiaFailure(state, action: PayloadAction<any>) {
      state.pedidosPorTipoPorDia.loading = false;
      state.pedidosPorTipoPorDia.error = action.payload;
    },
    listarPedidosPorTipoEmpresa(state, action: PayloadAction<any>) {
      state.pedidosPorTipoEmpresa.loading = true;
      state.pedidosPorTipoEmpresa.error = null;
    },
    listarPedidosPorTipoEmpresaSuccess(state, action: PayloadAction<any>) {
      state.pedidosPorTipoEmpresa.loading = false;
      state.pedidosPorTipoEmpresa.data = action.payload;
    },
    listarPedidosPorTipoEmpresaFailure(state, action: PayloadAction<any>) {
      state.pedidosPorTipoEmpresa.loading = false;
      state.pedidosPorTipoEmpresa.error = action.payload;
    },
    listarPedidosPorTipo: (state, action: PayloadAction<any>) => {
      state.pedidosPorTipo.loading = true;
      state.pedidosPorTipo.error = null;
    },
    listarPedidosPorTipoSuccess: (state, action: PayloadAction<any>) => {
      state.pedidosPorTipo.loading = false;
      state.pedidosPorTipo.data = action.payload;
    },
    listarPedidosPorTipoFailure: (state, action: PayloadAction<any>) => {
      state.pedidosPorTipo.loading = false;
      state.pedidosPorTipo.error = action.payload;
    },
    listarPedidosPorFaixaKm: (state, action: PayloadAction<any>) => {
      state.pedidosPorFaixaKm.loading = true;
      state.pedidosPorFaixaKm.error = null;
    },
    listarPedidosPorFaixaKmSuccess: (state, action: PayloadAction<any>) => {
      state.pedidosPorFaixaKm.loading = false;
      state.pedidosPorFaixaKm.data = action.payload;
    },
    listarPedidosPorFaixaKmFailure: (state, action: PayloadAction<any>) => {
      state.pedidosPorFaixaKm.loading = false;
      state.pedidosPorFaixaKm.error = action.payload;
    },
    listarPedidosPorFaixaKmPorDia: (state, action: PayloadAction<any>) => {
      state.pedidosPorFaixaKmPorDia.loading = true;
      state.pedidosPorFaixaKmPorDia.error = null;
    },
    listarPedidosPorFaixaKmPorDiaSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.pedidosPorFaixaKmPorDia.loading = false;
      state.pedidosPorFaixaKmPorDia.data = action.payload;
    },
    listarPedidosPorFaixaKmPorDiaFailure: (
      state,
      action: PayloadAction<any>
    ) => {
      state.pedidosPorFaixaKmPorDia.loading = false;
      state.pedidosPorFaixaKmPorDia.error = action.payload;
    },
    listarRankingEmpresas: (state, action: PayloadAction<any>) => {
      state.rankingEmpresas.loading = true;
      state.rankingEmpresas.error = null;
    },
    listarRankingEmpresasSuccess: (state, action: PayloadAction<any>) => {
      state.rankingEmpresas.loading = false;
      state.rankingEmpresas.data = action.payload;
    },
    listarRankingEmpresasFailure: (state, action: PayloadAction<any>) => {
      state.rankingEmpresas.loading = false;
      state.rankingEmpresas.error = action.payload;
    },
    listarRankingEntregadores: (state, action: PayloadAction<any>) => {
      state.rankingEntregadores.loading = true;
      state.rankingEntregadores.error = null;
    },
    listarRankingEntregadoresSuccess: (state, action: PayloadAction<any>) => {
      state.rankingEntregadores.loading = false;
      state.rankingEntregadores.data = action.payload;
    },
    listarRankingEntregadoresFailure: (state, action: PayloadAction<any>) => {
      state.rankingEntregadores.loading = false;
      state.rankingEntregadores.error = action.payload;
    },
    listarRankingBairros: (state, action: PayloadAction<any>) => {
      state.rankingBairros.loading = true;
      state.rankingBairros.error = null;
    },
    listarRankingBairrosSuccess: (state, action: PayloadAction<any>) => {
      state.rankingBairros.loading = false;
      state.rankingBairros.data = action.payload;
    },
    listarRankingBairrosFailure: (state, action: PayloadAction<any>) => {
      state.rankingBairros.loading = false;
      state.rankingBairros.error = action.payload;
    },
    exportarRelatorioGeralStart: (state, action: PayloadAction<any>) => {
      state.exportarRelatorioGeral.loading = true;
      state.exportarRelatorioGeral.error = null;
    },
    exportarRelatorioGeralSuccess: (state, action: PayloadAction<any>) => {
      state.exportarRelatorioGeral.loading = false;
      state.exportarRelatorioGeral.error = null;
    },
    exportarRelatorioGeralFailure: (state, action: PayloadAction<any>) => {
      state.exportarRelatorioGeral.loading = false;
      state.exportarRelatorioGeral.error = action.payload;
    },
    listarPerformancePedidosStart: (state, action: PayloadAction<any>) => {
      state.performancePedidos.loading = true;
      state.performancePedidos.error = null;
    },
    listarPerformancePedidosSuccess: (state, action: PayloadAction<any>) => {
      state.performancePedidos.loading = false;
      state.performancePedidos.data = action.payload;
    },
    listarPerformancePedidosFailure: (state, action: PayloadAction<any>) => {
      state.performancePedidos.loading = false;
      state.performancePedidos.error = action.payload;
    },
    exportarPerformancePedidosStart: (state, action: PayloadAction<any>) => {
      state.exportarPerformancePedidos.loading = true;
      state.exportarPerformancePedidos.error = null;
    },
    exportarPerformancePedidosSuccess: (state, action: PayloadAction<any>) => {
      state.exportarPerformancePedidos.loading = false;
      state.exportarPerformancePedidos.error = null;
    },
    exportarPerformancePedidosFailure: (state, action: PayloadAction<any>) => {
      state.exportarPerformancePedidos.loading = false;
      state.exportarPerformancePedidos.error = action.payload;
    },
    pedidoModeloStart: (state, action: PayloadAction<PayloadPedidoModelo>) => {
      state.pedidoModelo.loading = true;
      state.pedidoModelo.data = null;
      state.pedidoModelo.error = null;
    },
    pedidoModeloSuccess: (state, action) => {
      state.pedidoModelo.loading = false;
      state.pedidoModelo.data = action.payload;
      state.pedidoModelo.error = null;
    },
    pedidoModeloFailure: (state, action) => {
      state.pedidoModelo.loading = false;
      state.pedidoModelo.data = null;
      state.pedidoModelo.error = action.payload;
      console.log(action.payload);
    },
    pedidoModeloClear: (state) => {
      state.pedidoModelo = { ...initialState.pedidoModelo };
    },
    salvarPedidoStart: (state, action: PayloadAction<PayloadSalvarPedido>) => {
      state.salvarPedido.loading = true;
      state.salvarPedido.data = null;
      state.salvarPedido.error = null;
    },
    salvarPedidoSuccess: (state, action) => {
      state.salvarPedido.loading = false;
      state.salvarPedido.data = action.payload;
      state.salvarPedido.error = null;
    },
    salvarPedidoFailure: (state, action) => {
      state.salvarPedido.loading = false;
      state.salvarPedido.data = null;
      state.salvarPedido.error = action.payload;
    },
    salvarPedidoClear: (state) => {
      state.salvarPedido = { ...initialState.salvarPedido };
    },
    recalcularTabelasPedidoStart: (
      state,
      action: PayloadAction<PayloadRecalcularTabelas>
    ) => {
      state.recalcularTabelasPedido.loading = true;
      state.recalcularTabelasPedido.data = null;
      state.recalcularTabelasPedido.error = null;
    },
    recalcularTabelasPedidoSuccess: (state, action) => {
      state.recalcularTabelasPedido.loading = false;
      state.recalcularTabelasPedido.data = action.payload;
      state.recalcularTabelasPedido.error = null;
    },
    recalcularTabelasPedidoFailure: (state, action) => {
      state.recalcularTabelasPedido.loading = false;
      state.recalcularTabelasPedido.data = null;
      state.recalcularTabelasPedido.error = action.payload;
    },
    recalcularTabelasPedidoClear: (state) => {
      state.recalcularTabelasPedido = {
        ...initialState.recalcularTabelasPedido,
      };
    },
    recalcularQuilometragemPedidoStart: (
      state,
      action: PayloadAction<PayloadRecalcularQuilometragem>
    ) => {
      state.recalcularQuilometragemPedido.loading = true;
      state.recalcularQuilometragemPedido.data = null;
      state.recalcularQuilometragemPedido.error = null;
    },
    recalcularQuilometragemPedidoSuccess: (state, action) => {
      state.recalcularQuilometragemPedido.loading = false;
      state.recalcularQuilometragemPedido.data = action.payload;
      state.recalcularQuilometragemPedido.error = null;
    },
    recalcularQuilometragemPedidoFailure: (state, action) => {
      state.recalcularQuilometragemPedido.loading = false;
      state.recalcularQuilometragemPedido.data = null;
      state.recalcularQuilometragemPedido.error = action.payload;
    },
    recalcularQuilometragemPedidoClear: (state) => {
      state.recalcularQuilometragemPedido = {
        ...initialState.recalcularQuilometragemPedido,
      };
    },
    pedidoTaxaExtraStart: (
      state,
      action: PayloadAction<PayloadPedidoTaxaExtra>
    ) => {
      state.pedidoTaxaExtra.loading = true;
      state.pedidoTaxaExtra.data = null;
      state.pedidoTaxaExtra.error = null;
    },
    pedidoTaxaExtraSuccess: (state, action) => {
      state.pedidoTaxaExtra.loading = false;
      state.pedidoTaxaExtra.data = action.payload;
      state.pedidoTaxaExtra.error = null;
    },
    pedidoTaxaExtraFailure: (state, action) => {
      state.pedidoTaxaExtra.loading = false;
      state.pedidoTaxaExtra.data = null;
      state.pedidoTaxaExtra.error = action.payload;
    },
    pedidoTaxaExtraClear: (state) => {
      state.pedidoTaxaExtra = {
        ...initialState.pedidoTaxaExtra,
      };
    },
    listarLogStart: (state, action: PayloadAction<PayloadLog>) => {
      state.log.loading = true;
      state.log.data = null;
      state.log.error = null;
    },
    listarLogSuccess: (state, action) => {
      state.log.loading = false;
      state.log.data = action.payload;
      state.log.error = null;
    },
    listarLogFailure: (state, action) => {
      state.log.loading = false;
      state.log.data = null;
      state.log.error = action.payload;
    },
    listarLogClear: (state) => {
      state.log = {
        ...initialState.log,
      };
    },
    pedidoDespachosStart: (
      state,
      action: PayloadAction<PayloadPedidoDespachos>
    ) => {
      state.pedidoDespachos.loading = true;
      state.pedidoDespachos.data = null;
      state.pedidoDespachos.error = null;
    },
    pedidoDespachosSuccess: (state, action) => {
      state.pedidoDespachos.loading = false;
      state.pedidoDespachos.data = action.payload;
      state.pedidoDespachos.error = null;
    },
    pedidoDespachosFailure: (state, action) => {
      state.pedidoDespachos.loading = false;
      state.pedidoDespachos.data = null;
      state.pedidoDespachos.error = action.payload;
    },
    pedidoDespachosClear: (state) => {
      state.pedidoDespachos = { ...initialState.pedidoDespachos };
    },
    pedidoMapaRastreioStart: (
      state,
      action: PayloadAction<PayloadPedidoMapaRastreio>
    ) => {
      state.pedidoMapaRastreio.loading = true;
      state.pedidoMapaRastreio.data = null;
      state.pedidoMapaRastreio.error = null;
    },
    pedidoMapaRastreioSuccess: (state, action) => {
      state.pedidoMapaRastreio.loading = false;
      state.pedidoMapaRastreio.data = action.payload;
      state.pedidoMapaRastreio.error = null;
    },
    pedidoMapaRastreioFailure: (state, action) => {
      state.pedidoMapaRastreio.loading = false;
      state.pedidoMapaRastreio.data = null;
      state.pedidoMapaRastreio.error = action.payload;
    },
    pedidoMapaRastreioClear: (state) => {
      state.pedidoMapaRastreio = { ...initialState.pedidoMapaRastreio };
    },
    listarLocalizacoesStart: (state, action: PayloadAction<any>) => {
      state.listarLocalizacoes.loading = true;
      state.listarLocalizacoes.data = null;
      state.listarLocalizacoes.error = null;
    },
    listarLocalizacoesSuccess: (state, action) => {
      state.listarLocalizacoes.loading = false;
      state.listarLocalizacoes.data = action.payload;
      state.listarLocalizacoes.error = null;
    },
    listarLocalizacoesFailure: (state, action) => {
      state.listarLocalizacoes.loading = false;
      state.listarLocalizacoes.data = null;
      state.listarLocalizacoes.error = action.payload;
    },
    listarMeusPedidosStart: (
      state,
      action: PayloadAction<PayloadListarMeusPedidos>
    ) => {
      state.meusPedidos.loading = true;
      state.meusPedidos.data = null;
      state.meusPedidos.error = null;
    },
    listarMeusPedidosSuccess: (state, action) => {
      state.meusPedidos.loading = false;
      state.meusPedidos.data = action.payload;
      state.meusPedidos.error = null;
    },
    listarMeusPedidosFailure: (state, action) => {
      state.meusPedidos.loading = false;
      state.meusPedidos.data = null;
      state.meusPedidos.error = action.payload;
    },
    exportarMeusPedidosStart: (state, action: PayloadAction<any>) => {
      state.exportarMeusPedidos.loading = true;
      state.exportarMeusPedidos.error = null;
    },
    exportarMeusPedidosSuccess: (state, action) => {
      state.exportarMeusPedidos.loading = false;
      state.exportarMeusPedidos.error = null;
    },
    exportarMeusPedidosFailure: (state, action) => {
      state.exportarMeusPedidos.loading = false;
      state.exportarMeusPedidos.error = action.payload;
    },
    excluirDespachoStart: (
      state,
      action: PayloadAction<PayloadExcluirDespacho>
    ) => {
      state.excluirDespacho.loading = true;
      state.excluirDespacho.data = null;
      state.excluirDespacho.error = null;
    },
    excluirDespachoSuccess: (state, action) => {
      state.excluirDespacho.loading = false;
      state.excluirDespacho.data = action.payload;
      state.excluirDespacho.error = null;
    },
    excluirDespachoFailure: (state, action) => {
      state.excluirDespacho.loading = false;
      state.excluirDespacho.data = null;
      state.excluirDespacho.error = action.payload;
    },
    excluirDespachoClear: (state) => {
      state.excluirDespacho = { ...initialState.excluirDespacho };
    },
    finalizarVariosStart: (state, action) => {
      state.finalizarVarios.loading = true;
      state.finalizarVarios.data = null;
      state.finalizarVarios.error = null;
    },
    finalizarVariosSuccess: (state, action) => {
      state.finalizarVarios.loading = false;
      state.finalizarVarios.data = action.payload;
      state.finalizarVarios.error = null;
    },
    finalizarVariosError: (state, action) => {
      state.finalizarVarios.loading = false;
      state.finalizarVarios.data = null;
      state.finalizarVarios.error = action.payload;
    },
    limparFinalizarVarios: (state) => {
      state.finalizarVarios = { ...initialState.finalizarVarios };
    }
  },
});

export const {
  listaPedidosStart,
  listaPedidosFailure,
  listaPedidosSuccess,
  listarPedidosGeral,
  listarPedidosGeralFailure,
  listarPedidosGeralSuccess,
  listarPedidosPorTipoPorDia,
  listarPedidosPorTipoPorDiaSuccess,
  listarPedidosPorTipoPorDiaFailure,
  listarPedidosPorTipoEmpresa,
  listarPedidosPorTipoEmpresaSuccess,
  listarPedidosPorTipoEmpresaFailure,
  listarPedidosPorTipo,
  listarPedidosPorTipoFailure,
  listarPedidosPorTipoSuccess,
  listarPedidosPorFaixaKm,
  listarPedidosPorFaixaKmSuccess,
  listarPedidosPorFaixaKmFailure,
  listarPedidosPorFaixaKmPorDia,
  listarPedidosPorFaixaKmPorDiaSuccess,
  listarPedidosPorFaixaKmPorDiaFailure,
  listarRankingEmpresas,
  listarRankingEmpresasSuccess,
  listarRankingEmpresasFailure,
  listarRankingEntregadores,
  listarRankingEntregadoresSuccess,
  listarRankingEntregadoresFailure,
  listarRankingBairros,
  listarRankingBairrosSuccess,
  listarRankingBairrosFailure,
  exportarRelatorioGeralStart,
  exportarRelatorioGeralSuccess,
  exportarRelatorioGeralFailure,
  listarPerformancePedidosFailure,
  listarPerformancePedidosStart,
  listarPerformancePedidosSuccess,
  exportarPerformancePedidosFailure,
  exportarPerformancePedidosStart,
  exportarPerformancePedidosSuccess,
  pedidoModeloStart,
  pedidoModeloSuccess,
  pedidoModeloFailure,
  pedidoModeloClear,
  salvarPedidoStart,
  salvarPedidoSuccess,
  salvarPedidoFailure,
  salvarPedidoClear,
  recalcularTabelasPedidoStart,
  recalcularTabelasPedidoSuccess,
  recalcularTabelasPedidoFailure,
  recalcularTabelasPedidoClear,
  recalcularQuilometragemPedidoStart,
  recalcularQuilometragemPedidoSuccess,
  recalcularQuilometragemPedidoFailure,
  recalcularQuilometragemPedidoClear,
  pedidoTaxaExtraStart,
  pedidoTaxaExtraSuccess,
  pedidoTaxaExtraFailure,
  pedidoTaxaExtraClear,
  listarLogStart,
  listarLogSuccess,
  listarLogFailure,
  listarLogClear,
  pedidoDespachosStart,
  pedidoDespachosSuccess,
  pedidoDespachosFailure,
  pedidoDespachosClear,
  pedidoMapaRastreioStart,
  pedidoMapaRastreioSuccess,
  pedidoMapaRastreioFailure,
  pedidoMapaRastreioClear,
  listarLocalizacoesFailure,
  listarLocalizacoesStart,
  listarLocalizacoesSuccess,
  listarMeusPedidosStart,
  listarMeusPedidosSuccess,
  listarMeusPedidosFailure,
  exportarMeusPedidosStart,
  exportarMeusPedidosSuccess,
  exportarMeusPedidosFailure,
  excluirDespachoStart,
  excluirDespachoSuccess,
  excluirDespachoFailure,
  excluirDespachoClear,
  finalizarVariosStart,
  finalizarVariosSuccess,
  finalizarVariosError,
  limparFinalizarVarios
} = pedidosSlice.actions;
export default pedidosSlice;
