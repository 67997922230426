import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "../../services/api";
import { ApiResponse, DefaultDTO } from "../../dtos/DefaultDTO";
import {
  botsFailure,
  botsStart,
  botsSuccess,
  listarMensagensStart,
  listarMensagensSuccess,
  listarMensagensFailure,
  listarFuncoesStart,
  listarFuncoesSuccess,
  listarFuncoesFailure,
  botQrCodeStart,
  botQrCodeSuccess,
  botQrCodeFailure,
  excluirBotStart,
  excluirBotSuccess,
  excluirBotFailure,
  criarBotStart,
  criarBotSuccess,
  criarBotFailure,
  listarEmpresasVinculadasStart,
  listarEmpresasVinculadasSuccess,
  listarEmpresasVinculadasFailure,
  excluirEmpresaVinculadaStart,
  excluirEmpresaVinculadaSuccess,
  excluirEmpresaVinculadaFailure,
  salvarEmpresaVinculadaStart,
  salvarEmpresaVinculadaSuccess,
  salvarEmpresaVinculadaFailure,
  listarMensagensPadroesStart,
  listarMensagensPadroesSuccess,
  listarMensagensPadroesFailure,
  enviarMensagemTesteStart,
  enviarMensagemTesteSuccess,
  enviarMensagemTesteFailure,
  listarFuncoesMensagemStart,
  listarFuncoesMensagemSuccess,
  listarFuncoesMensagemFailure,
  listarPalavrasChaveStart,
  listarPalavrasChaveSuccess,
  listarPalavrasChaveFailure,
  salvarMensagemPadraoStart,
  salvarMensagemPadraoSuccess,
  salvarMensagemPadraoFailure,
  excluirMensagemPadraoStart,
  excluirMensagemPadraoSuccess,
  excluirMensagemPadraoFailure,
} from "../reducers/bots";
import {
  BotQrCodeDTO,
  BotsDTO,
  CriarBotDTO,
  EmpresasVinculadasDTO,
  ExcluirBotDTO,
  ExcluirEmpresaVinculadaDTO,
  ExcluirMensagemPadraoDTO,
  FuncoesMensagemDTO,
  MensagemTesteDTO,
  MensagensPadroesDTO,
  PalavrasChaveDTO,
  PayloadCriarBot,
  PayloadEnviarMensagemTeste,
  PayloadExcluirEmpresaVinculada,
  PayloadExcluirMensagemPadrao,
  PayloadListarBots,
  PayloadListarEmpresasVinculadas,
  PayloadListarFuncoesMensagem,
  PayloadListarMensagensBot,
  PayloadListarMensagensPadroes,
  PayloadListarPalavrasChave,
  PayloadSalvarEmpresaVinculada,
  PayloadSalvarMensagemPadrao,
  PayloadTiposFuncoes,
  SalvarEmpresaVinculadaDTO,
  SalvarMensagemPadraoDTO,
  TiposFuncaoDTO,
} from "src/dtos/BotsDTO";

function* listarBots({ payload }: { payload: PayloadListarBots }) {
  const form = new FormData();
  const typedPayload: Record<string, any> = payload;
  Object.keys(typedPayload).forEach((key) => {
    form.append(key, String(typedPayload[key]));
  });
  try {
    const response: ApiResponse<BotsDTO> = yield call(
      api.post,
      "/PickWppBot/Listar",
      form
    );
    if (response.data.sucesso === true) {
      yield put(botsSuccess(response.data));
    } else {
      yield put(botsFailure(response.data.mensagem));
    }
  } catch (error) {
    yield put(botsFailure(error));
  }
}

function* listarMensagensBot({
  payload,
}: {
  payload: PayloadListarMensagensBot;
}) {
  const form = new FormData();
  const typedPayload: Record<string, any> = payload;
  Object.keys(typedPayload).forEach((key) => {
    if (key === "auxPickWppBotID") {
      return;
    }
    form.append(key, String(typedPayload[key]));
  });
  try {
    const response: ApiResponse<BotsDTO> = yield call(
      api.post,
      "/PickWppBot/listarMensagensEnviadasDoBot?auxPickWppBotID=" +
        payload.auxPickWppBotID,
      form
    );
    if (response.data.sucesso === true) {
      yield put(listarMensagensSuccess(response.data));
    } else {
      yield put(listarMensagensFailure(response.data.mensagem));
    }
  } catch (error) {
    yield put(listarMensagensFailure(error));
  }
}

function* listarFuncoes({ payload }: { payload: PayloadTiposFuncoes }) {
  const form = new FormData();
  const typedPayload: Record<string, any> = payload;

  Object.keys(typedPayload).forEach((key) => {
    form.append(key, String(typedPayload[key]));
  });

  try {
    const response: ApiResponse<TiposFuncaoDTO> = yield call(
      api.post,
      "PickWppBot/getTiposFuncaoBot?id=" + payload.id,
      form
    );
    if (response.data.sucesso === true) {
      yield put(listarFuncoesSuccess(response.data));
    } else {
      yield put(listarFuncoesFailure(response.data.sucesso));
    }
  } catch (error) {
    yield put(listarFuncoesFailure(error));
  }
}

function* botsQrCode({ payload }: { payload: { id: string } }) {
  const { id } = payload;

  try {
    const response: ApiResponse<BotQrCodeDTO> = yield call(
      api.post,
      "PickWppBot/getQrCodePickWppBot?id=" + id
    );
    if (response.data.sucesso === true) {
      yield put(botQrCodeSuccess(response.data));
    } else {
      yield put(botQrCodeFailure(response.data.sucesso));
    }
  } catch (error) {
    yield put(botQrCodeFailure(error));
  }
}

function* excluirBot({ payload }: { payload: { id: string } }) {
  const { id } = payload;

  try {
    const response: ApiResponse<ExcluirBotDTO> = yield call(
      api.post,
      "PickWppBot/Excluir?id=" + id
    );
    if (response.data.sucesso === true) {
      yield put(excluirBotSuccess(response.data));
    } else {
      yield put(excluirBotFailure(response.data.mensagem));
    }
  } catch (error) {
    yield put(excluirBotFailure(error));
  }
}

function* criarBot({ payload }: { payload: PayloadCriarBot }) {
  try {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      const typedKey = key as keyof PayloadCriarBot;
      formData.append(typedKey, String(payload[typedKey]));
    });

    const response: ApiResponse<CriarBotDTO> = yield call(
      api.post,
      "PickWppBot/Salvar",
      formData
    );
    if (response.data.sucesso === true) {
      yield put(criarBotSuccess(response.data));
    } else {
      yield put(criarBotFailure(response.data.mensagem));
    }
  } catch (error) {
    yield put(criarBotFailure(error));
  }
}

function* listarEmpresasVinculadas({
  payload,
}: {
  payload: PayloadListarEmpresasVinculadas;
}) {
  try {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      const typedKey = key as keyof PayloadListarEmpresasVinculadas;
      if (typedKey === "pickWppBotID") {
        return;
      }
      formData.append(typedKey, String(payload[typedKey]));
    });

    const response: ApiResponse<EmpresasVinculadasDTO> = yield call(
      api.post,
      "PickWppBot/ListarEmpresasVinculadas?pickWppBotID=" +
        payload.pickWppBotID,
      formData
    );
    if (response.data.sucesso === true) {
      yield put(listarEmpresasVinculadasSuccess(response.data));
    } else {
      yield put(listarEmpresasVinculadasFailure(response.data.mensagem));
    }
  } catch (error) {
    yield put(listarEmpresasVinculadasFailure(error));
  }
}

function* excluirEmpresaVinculada({
  payload,
}: {
  payload: PayloadExcluirEmpresaVinculada;
}) {
  try {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      const typedKey = key as keyof PayloadExcluirEmpresaVinculada;
      formData.append(typedKey, String(payload[typedKey]));
    });

    const response: ApiResponse<ExcluirEmpresaVinculadaDTO> = yield call(
      api.post,
      "PickWppBot/ExcluirEmpresaVinculada?PickWppBotEmpresasID=" +
        payload.PickWppBotEmpresasID,
      formData
    );
    if (response.data.sucesso === true) {
      yield put(excluirEmpresaVinculadaSuccess(response.data));
    } else {
      yield put(excluirEmpresaVinculadaFailure(response.data.mensagem));
    }
  } catch (error) {
    yield put(excluirEmpresaVinculadaFailure(error));
  }
}

function* salvarEmpresaVinculada({
  payload,
}: {
  payload: PayloadSalvarEmpresaVinculada;
}) {
  try {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      const typedKey = key as keyof PayloadSalvarEmpresaVinculada;
      formData.append(typedKey, String(payload[typedKey]));
    });

    const response: ApiResponse<SalvarEmpresaVinculadaDTO> = yield call(
      api.post,
      "PickWppBot/SalvarEmpresaVinculada",
      formData
    );
    if (response.data.sucesso === true) {
      yield put(salvarEmpresaVinculadaSuccess(response.data));
    } else {
      yield put(salvarEmpresaVinculadaFailure(response.data.mensagem));
    }
  } catch (error) {
    yield put(salvarEmpresaVinculadaFailure(error));
  }
}

function* listarMensagensPadroes({
  payload,
}: {
  payload: PayloadListarMensagensPadroes;
}) {
  try {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      const typedKey = key as keyof PayloadListarMensagensPadroes;
      if (typedKey === "pickID") {
        return;
      }
      formData.append(typedKey, String(payload[typedKey]));
    });

    const response: ApiResponse<MensagensPadroesDTO> = yield call(
      api.post,
      "PickWppBot/listarMensagensPadrao?pickID=" + payload.pickID,
      formData
    );
    if (response.data.sucesso === true) {
      yield put(listarMensagensPadroesSuccess(response.data));
    } else {
      // @ts-expect-error
      yield put(listarMensagensPadroesFailure(response.data?.mensagem));
    }
  } catch (error) {
    yield put(listarMensagensPadroesFailure(error));
  }
}

function* enviarMensagemTeste({
  payload,
}: {
  payload: PayloadEnviarMensagemTeste;
}) {
  try {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      const typedKey = key as keyof PayloadEnviarMensagemTeste;
      formData.append(typedKey, String(payload[typedKey]));
    });

    const response: ApiResponse<MensagemTesteDTO> = yield call(
      api.post,
      "PickWppBot/enviarTeste",
      formData
    );
    if (response.data.sucesso === true) {
      yield put(enviarMensagemTesteSuccess(response.data));
    } else {
      yield put(enviarMensagemTesteFailure(response.data?.mensagem));
    }
  } catch (error) {
    yield put(enviarMensagemTesteFailure(error));
  }
}

function* listarFuncoesMensagem({
  payload,
}: {
  payload: PayloadListarFuncoesMensagem;
}) {
  try {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      if (key === "funcaoBot" || key === "all") return;
      const typedKey = key as keyof PayloadListarFuncoesMensagem;
      formData.append(typedKey, String(payload[typedKey]));
    });

    const response: ApiResponse<FuncoesMensagemDTO> = yield call(
      api.post,
      `PickWppBot/getTiposFuncaoMsg?funcaoBot=${payload.funcaoBot}&all=${payload.all}`,
      formData
    );
    if (response.data.sucesso === true) {
      yield put(listarFuncoesMensagemSuccess(response.data));
    } else {
      // @ts-expect-error
      yield put(listarFuncoesMensagemFailure(response.data?.mensagem));
    }
  } catch (error) {
    yield put(listarFuncoesMensagemFailure(error));
  }
}

function* listarPalavrasChave({
  payload,
}: {
  payload: PayloadListarPalavrasChave;
}) {
  try {
    const response: ApiResponse<PalavrasChaveDTO> = yield call(
      api.post,
      `PickWppBot/getPalavrasChaves?funcaoBot=${payload.funcaoBot}`
    );
    if (response.data.sucesso === true) {
      yield put(listarPalavrasChaveSuccess(response.data));
    } else {
      // @ts-expect-error
      yield put(listarPalavrasChaveFailure(response.data?.mensagem));
    }
  } catch (error) {
    yield put(listarPalavrasChaveFailure(error));
  }
}

function* salvarMensagemPadrao({
  payload,
}: {
  payload: PayloadSalvarMensagemPadrao;
}) {
  try {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      const typedKey = key as keyof PayloadSalvarMensagemPadrao;
      formData.append(typedKey, String(payload[typedKey]));
    });

    const response: ApiResponse<SalvarMensagemPadraoDTO> = yield call(
      api.post,
      'PickWppBot/salvarMensagemPadrao',
      formData
    );
    if (response.data.sucesso === true) {
      yield put(salvarMensagemPadraoSuccess(response.data));
    } else {
      yield put(salvarMensagemPadraoFailure(response.data?.mensagem));
    }
  } catch (error) {
    yield put(salvarMensagemPadraoFailure(error));
  }
}

function* excluirMensagemPadrao({
  payload,
}: {
  payload: PayloadExcluirMensagemPadrao;
}) {
  try {
    const response: ApiResponse<ExcluirMensagemPadraoDTO> = yield call(
      api.post,
      'PickWppBot/excluirMensagemPadrao?pickWppBotMensagemPadraoID=' + payload.pickWppBotMensagemPadraoID
    );
    if (response.data.sucesso === true) {
      yield put(excluirMensagemPadraoSuccess(response.data));
    } else {
      yield put(excluirMensagemPadraoFailure(response.data?.mensagem));
    }
  } catch (error) {
    yield put(excluirMensagemPadraoFailure(error));
  }
}

function* botsSaga() {
  yield takeLatest(botsStart, listarBots);
  yield takeLatest(listarMensagensStart, listarMensagensBot);
  yield takeLatest(listarFuncoesStart, listarFuncoes);
  yield takeLatest(botQrCodeStart, botsQrCode);
  yield takeLatest(excluirBotStart, excluirBot);
  yield takeLatest(criarBotStart, criarBot);
  yield takeLatest(listarEmpresasVinculadasStart, listarEmpresasVinculadas);
  yield takeLatest(excluirEmpresaVinculadaStart, excluirEmpresaVinculada);
  yield takeLatest(salvarEmpresaVinculadaStart, salvarEmpresaVinculada);
  yield takeLatest(listarMensagensPadroesStart, listarMensagensPadroes);
  yield takeLatest(enviarMensagemTesteStart, enviarMensagemTeste);
  yield takeLatest(listarFuncoesMensagemStart, listarFuncoesMensagem);
  yield takeLatest(listarPalavrasChaveStart, listarPalavrasChave);
  yield takeLatest(salvarMensagemPadraoStart, salvarMensagemPadrao);
  yield takeLatest(excluirMensagemPadraoStart, excluirMensagemPadrao);
}

export default botsSaga;
