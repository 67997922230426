import { Box, Button, SxProps, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { Today } from '@mui/icons-material';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import useResponsive from "../hooks/UseResponsive";
import { useState } from 'react';

dayjs.locale('pt-br');

interface HookForms {
  name: string;
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
}

interface InputDateTimeProps {
  hookForm: HookForms;
  label?: string;
  sx?: SxProps;
  disabled?: boolean;
}

const CustomToolbar = (props: any) => {
  const handleTodayClick = () => {
    console.log(props);
    const today = dayjs().startOf('day');
    props.onChange(today);
    props?.ownerState?.onClose();
  };

  const handleNowClick = () => {
    const now = dayjs();
    props.onChange(now);
    props?.ownerState?.onClose();

  };

  const { isMobile } = useResponsive();

  return (
    <Box
      display="flex"
      sx={{
        position: 'absolute',
        left: isMobile ? `calc(50% - 120px)` : `calc(50% - 160px)`,
        zIndex: 1,
        bottom: 55,
        width: isMobile ? '75%' : '55%',
        justifyContent: 'space-between',
      }}
    >
      <Button
        onClick={handleTodayClick}
        color="primary"
        sx={{ maxHeight: '40px', width: 'min-content' }}
        variant="contained"
        startIcon={<Today />}
      >
        <Typography variant="monospaced">Hoje</Typography>
      </Button>

      <Button
        onClick={handleNowClick}
        color="primary"
        sx={{ maxHeight: '40px', width: 'min-content' }}
        variant="contained"
        startIcon={<AccessTimeRoundedIcon />}
      >
        <Typography variant="monospaced">Agora</Typography>
      </Button>
    </Box>
  );
};

const InputDateTime = ({
  hookForm,
  sx,
  label,
  disabled,
}: InputDateTimeProps) => {
  const { name, methods, rules } = hookForm;
  const {
    control,
    formState: { errors },
  } = methods;

  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <Controller
        control={control}
        name={name}
        rules={rules}
        disabled={disabled}
        render={({ field: { onChange, value: watchValue, ...field } }) => (
          <DateTimePicker
            {...field}
            label={label || name}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            slots={{
              toolbar: (props) => <CustomToolbar {...props} />,
            }}
            slotProps={{
              field: { clearable: true },
              textField: {
                size: 'small',
                label: label || name,
                error: !!errors?.[name],
                helperText: errors?.[name]?.message
                  ? String(errors?.[name]?.message)
                  : '',
                onClick: () => {
                  if (!watchValue) {
                    const today = dayjs().startOf('day').format('DD/MM/YYYY HH:mm');
                    onChange(today);
                  }
                  setTimeout(() => setOpen(true), 0);
                },
              },
            }}
            sx={sx}
            ampm={false}
            value={watchValue ? dayjs(watchValue, 'DD/MM/YYYY HH:mm') : null}
            onChange={(newValue) => {
              onChange(
                newValue ? dayjs(newValue).format('DD/MM/YYYY HH:mm') : null
              );
            }}
            format="DD/MM/YYYY HH:mm"
            showDaysOutsideCurrentMonth
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default InputDateTime;
