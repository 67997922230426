// SelectMultiple.tsx
import { Box, Chip, FormControl, FormControlProps, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';

interface HookForm {
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  name: string;
}

type SelectMultipleProps<T> = SelectProps & {
  hookForm: HookForm;
  options?: T[];
  valueKey: keyof T;
  labelKey: keyof T;
  formControl?: FormControlProps;
};

/**
 * Componente `SelectMultiple` é um componente personalizado de select (caixa de seleção) que permite selecionar múltiplos itens ao integrar-se com o `react-hook-form`. 
 * Este componente é baseado no `Select` do Material UI e pode ser configurado para trabalhar com diferentes tipos de objetos, 
 * onde as chaves `value` e `label` são configuráveis.
 *
 * @template T - O tipo genérico para as opções do select.
 *
 * @param {SelectMultipleProps<T>} props - As propriedades do componente.
 * @param {HookForm} props.hookForm - Objeto que contém os métodos do `react-hook-form`, como controle e validação.
 * @param {T[]} [props.options=[]] - Array de objetos que contém as opções que serão exibidas no select.
 * @param {keyof T} props.valueKey - A chave do objeto `T` que representa o valor (`value`) para cada opção.
 * @param {keyof T} props.labelKey - A chave do objeto `T` que representa o rótulo (`label`) para cada opção.
 * @param {string} [props.size='small'] - O tamanho do campo de seleção (padrão é `small`).
 * @param {boolean} [props.error] - Indica se o campo tem erros de validação.
 * @param {string} [props.label] - O rótulo exibido acima do campo select.
 * @param {FormControlProps} [props.formControl] - As propriedades do `FormControl` do Material UI.
 * @param {...SelectProps} props - Todas as demais propriedades aceitas pelo componente `Select` do Material UI.
 *
 * @returns {JSX.Element} - Retorna o componente JSX do campo de seleção múltipla.
 *
 * @example
 * ```tsx
 * import React from 'react';
 * import { useForm, Controller } from 'react-hook-form';
 * import SelectMultiple from './SelectMultiple';
 *
 * interface Entregador {
 *   entregadorID: number;
 *   entregadorNome: string;
 * }
 *
 * const entregadores: Entregador[] = [
 *   { entregadorID: 1, entregadorNome: 'João' },
 *   { entregadorID: 2, entregadorNome: 'Maria' },
 *   { entregadorID: 3, entregadorNome: 'Carlos' },
 * ];
 *
 * const MyForm = () => {
 *   const methods = useForm();
 *
 *   return (
 *     <form>
 *       <SelectMultiple
 *         hookForm={{ methods, name: 'entregadores', rules: { required: 'Selecione pelo menos um entregador' } }}
 *         options={entregadores}
 *         valueKey="entregadorID"
 *         labelKey="entregadorNome"
 *         label="Entregadores"
 *       />
 *       <button type="submit">Enviar</button>
 *     </form>
 *   );
 * };
 * ```
 */
const SelectMultiple = <T,>({ hookForm, options = [], valueKey, labelKey, formControl, ...props }: SelectMultipleProps<T>) => {
  const { control, watch, setValue, formState: { errors } } = hookForm.methods;

  const selectedValues = watch(hookForm.name) || [];

  const handleChange = useCallback((event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[];
    setValue(hookForm.name, value);
  }, [setValue, hookForm.name]);

  const getLabelForValue = (value: string) => {
    const option = options?.find((option) => String(option[valueKey]) === value);
    return option ? String(option[labelKey]) : value;
  }

  const chipsSelect = useCallback(({ selected }: { selected: string[] }) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        transition: 'all 0.3s ease',
        gap: '.5rem',
      }}
    >
      {selected.map((value) => (
        <Chip
          key={value}
          sx={{ height: 22 }}
          size="small"
          label={getLabelForValue(value)}
        />
      ))}
    </Box>
  ), [options, valueKey, labelKey]);

  return (
    <FormControl size={props?.size ?? 'small'} error={!!errors[hookForm.name]} sx={formControl?.sx} {...formControl}>
      <InputLabel>{props?.label}</InputLabel>
      <Controller
        name={hookForm.name}
        control={control}
        rules={hookForm?.rules}
        render={({ field }) => (
          <Select
            multiple
            {...field}
            sx={props?.sx}
            label={props?.label}
            value={selectedValues}
            onChange={handleChange}
            renderValue={(selected) => {
              if (Array.isArray(selected)) {
                return chipsSelect({ selected })
              } else {
                return (
                  <Typography>{`${selected}`}</Typography>
                )
              }
            }}
            {...props}
          >
            {options?.map((option) => {
              const value = String(option[valueKey]);
              const label = String(option[labelKey]);
              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              )
            })}
          </Select>
        )}
      />
      <FormHelperText>{`${errors[hookForm.name]?.message || ''}`}</FormHelperText>
    </FormControl>
  );
};

export default SelectMultiple;
