import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl'; // Certifique-se de instalar mapbox-gl
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_API_KEY, TableRowLoading, TableRowNotFound } from 'src/ds';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

mapboxgl.accessToken = MAPBOX_API_KEY;

const containerStyle = {
    width: '100%',
    height: '500px',
};

const MapaDeCalor = ({ data }: { data: { latitude: number; longitude: number }[] }) => {
    const mapContainerRef = useRef<HTMLDivElement | null>(null);
    const mapRef = useRef<mapboxgl.Map | null>(null);
    const [loadError, setLoadError] = useState<boolean>(false);
    const colorMode = useSelector((state: RootState) => state.utils.colorMode);
    const template = useSelector((state: RootState) => state.config?.template?.template);
    const theme = useTheme();

    useEffect(() => {
        if (!mapContainerRef.current) return;

        try {
            const map = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/pickngo-demo/clabx63al002z14qpcdwwsq40',
                center: data?.length > 0
                    ? [data[0].longitude, data[0].latitude]
                    : [-47.929022, -15.800710],
                zoom: 10,
            });

            mapRef.current = map;

            map.on('load', () => {
                if (data?.length > 0) {
                    map.addSource('heatmap-source', {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: data.map((location) => ({
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [location.longitude, location.latitude],
                                },
                                properties: {},
                            })),
                        },
                    });


                    map.addLayer({
                        id: 'heatmap-layer',
                        type: 'heatmap',
                        source: 'heatmap-source',
                        maxzoom: 20,
                        paint: {
                            'heatmap-weight': 1,
                            'heatmap-intensity': 1,
                            'heatmap-color': [
                                'interpolate',
                                ['linear'],
                                ['heatmap-density'],
                                0,
                                'rgba(33,102,172,0)',
                                0.2,
                                'rgba(0, 191, 255, 0.6)',
                                0.4,
                                'rgba(0, 255, 127, 0.7)',
                                0.6,
                                'rgba(255, 255, 0, 0.7)',
                                0.8,
                                'rgba(255, 165, 0, 0.9)',
                                1,
                                'rgba(255, 69, 0, 1)',
                            ],
                            'heatmap-radius': 40,
                            'heatmap-opacity': 0.8,
                        },
                    });

                }
            });

            return () => {
                map.remove();
            };
        } catch (error) {
            console.error('Erro ao carregar o Mapbox:', error);
            setLoadError(true);
        }
    }, [data]);

    /*     useEffect(() => {
            if (colorMode === 'light') {
                mapRef.current?.setStyle(template?.mapa || 'mapbox://styles/pickngo-demo/clabx63al002z14qpcdwwsq40');
            } else if (colorMode === 'dark') {
                mapRef.current?.setStyle('mapbox://styles/pickngo-demo/clkbehgyk03kl01ns9k0t2o39');
            }
        }, [colorMode]) */

    if (loadError) {
        return <TableRowNotFound fezRequisicao={true} text="Erro ao carregar o mapa" />;
    }

    return <div ref={mapContainerRef} style={containerStyle} />;
};

export default MapaDeCalor;
