// @ts-nocheck
import React, { useState } from 'react';
import { Box, Button, Grid, Grid2, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material';
import pcFoto from '../../../assets/macbooknopainel.png';
import integracoesFoto from '../../../assets/integracoes.png';
import sublinhado from '../../../assets/sublinhado.png';
import sublinhado2 from '../../../assets/sublinhado2.png';
import robotexto from '../../../assets/robotexto.png';
import prancheta from '../../../assets/prancheta.png';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { lightMode } from '../../../store/reducers/utils';
import { ContainerBodyModal, GridColumn, ImageWithLoading, ModalBase, useResponsive} from 'src/ds';
import { IconButtonFechar } from 'src/ds/components/styledIconButtons';

const ModalPickngo = ({ open, onClose }) => {
    const devMode = useSelector(state => state.utils?.devMode);
    const { isMobile } = useResponsive();
    const theme = useTheme();
    const [isContratarModalOpen, setContratarModalOpen] = useState(false);
    const [isIframeModalOpen, setIframeModalOpen] = useState(false);
    const [corridasPorMes, setCorridasPorMes] = useState('');
    const usuarioID = useSelector(state => state.config?.masterPage?.usuario?.id);

    const dispatch = useDispatch();

    const handleOpenContratarModal = () => {
        setContratarModalOpen(true);
    };

    const handleCloseContratarModal = () => {
        setContratarModalOpen(false);
    };

    const handleCorridasChange = (event) => {
        setCorridasPorMes(event.target.value);
    };

    const planoID = useSelector(state => state.config?.masterPage?.pickSelecionada?.assinaturaID);
    const xtoken = localStorage.getItem('@token');

    const handleConfirmContratar = () => {
        dispatch(lightMode())
        setContratarModalOpen(false);
        setIframeModalOpen(true);
    };

    const handleCloseIframeModal = () => {
        setIframeModalOpen(false);
    };
    return (
        <>
            <ModalBase
                open={open}
                onClose={onClose}
                size="lg"
                headerModal={{
                    title: "Pickngo"
                }}
            >
                <ContainerBodyModal>
                    <Grid2 container direction="column" sx={{ width: '100%' }}>
                        <Grid2 size={{ xs: 12 }} sx={{ p: isMobile ? 1 : 12, pb: 1, width: '100%' }}>
                            <Stack
                                direction="row"
                                sx={{ flexWrap: 'wrap', textAlign: isMobile && 'center' }}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography variant="h1">
                                    Gerencie suas entregas e entregadores sem estresse!
                                    <Typography variant="body1">
                                        Conheça nossos benefícios para otimizar seu delivery.
                                    </Typography>
                                </Typography>
                                <ImageWithLoading src={pcFoto} alt="pc" boxProps={{ sx: { maxWidth: '500px', width: '100%', marginTop: '1rem', aspectRatio: '1/1' } }} />
                            </Stack>
                            <Typography variant="h1" textAlign="center" paddingY={6}>
                                Conheça nossas{' '}
                                <Typography variant="span" color={'primary'}>
                                    integrações
                                </Typography>
                            </Typography>
                            <Stack
                                direction="row"
                                sx={{ flexWrap: 'wrap' }}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <ImageWithLoading src={integracoesFoto} alt="integracoes" boxProps={{ sx: { width: '100%', maxWidth: '500px', aspectRatio: '1/1' } }} />
                                <Stack>
                                    <Typography variant="h1" textAlign={'right'}>
                                        Seu delivery conectado! A Pick integra com as melhores do mercado.
                                    </Typography>
                                    <Typography variant="body2" color={theme.palette.grey[600]} textAlign={'right'}>
                                        Facilite a vida dos seus atendentes. Com os sistemas integrados, seus pedidos chegam
                                        automaticamente.
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Typography variant="h1" textAlign="center" paddingY={6} paddingX={isMobile ? 2 : 30}>
                                Roteirize{' '}
                                <Typography
                                    variant="span"
                                    sx={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        '&::after, &::before': {
                                            content: '""',
                                            position: 'absolute',
                                            width: '50%',
                                            height: '20px',
                                            bottom: '-10px',
                                            backgroundSize: 'contain',
                                            // marginRight: '5.25%',
                                        },
                                        '&::after': {
                                            background: `url(${sublinhado}) no-repeat bottom left`,
                                            left: 0,
                                        },
                                        '&::before': {
                                            background: `url(${sublinhado2}) no-repeat bottom right`,
                                            right: '2px',
                                            transform: 'scaleX(-1)',
                                        },
                                    }}
                                >
                                    automaticamente
                                </Typography>{' '}
                                suas rotas.
                            </Typography>
                            <Stack direction={isMobile ? 'column' : "row"}>
                                <GridColumn>
                                    <Typography variant="h1" textAlign={'left'}>
                                        Sofrendo com atraso nos pedidos? Temos a solução.
                                    </Typography>
                                    <Typography variant="span" fontSize={'0.8rem'} color={'text.gray'} textAlign={'left'}>
                                        Um robô criado para programar com mais facilidade o despacho automático dos seus pedidos,
                                        aliado a uma tecnologia que roteiriza o melhor caminho para seu entregador.
                                    </Typography>
                                </GridColumn>
                                <ImageWithLoading src={robotexto} alt="Robô" boxProps={{ sx: { width: '100%', maxWidth: '500px', aspectRatio: '1/1' } }} />
                            </Stack>
                            <Typography variant="h1" textAlign="center" paddingY={6} paddingX={isMobile ? 2 : 30}>
                                Tenha controle da sua operação <br />
                                <span style={{ display: 'block' }}>com relatórios personalizados.</span>
                            </Typography>
                            <Stack direction={isMobile ? 'column' : "row"}>
                                <ImageWithLoading src={prancheta} alt="relatorios" boxProps={{ sx: { width: isMobile ? '80%' : '100%', maxWidth: '500px', marginInline: isMobile && 'auto', aspectRatio: '1/1' } }} />
                                <GridColumn>
                                    <Typography variant="h1" textAlign={'right'}>
                                        Com nossos relatórios você fica por dentro de tudo!
                                    </Typography>
                                    <Typography variant="body2" color={theme.palette.grey[600]} textAlign={'right'}>
                                        Filtre resultados de entregadores, origens, datas, status e outros diversos relatórios
                                        para otimizar sua operação.
                                    </Typography>
                                </GridColumn>
                            </Stack>
                            <Typography variant="h1" textAlign="center" paddingY={6} paddingX={isMobile ? 2 : 40}>
                                Quer saber mais?
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '2rem', width: '100%' }}>
                                <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', backgroundColor: 'orange' }}>
                                    <iframe
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        src={devMode ? "https://www.youtube.com/embed/dQw4w9WgXcQ" : "https://www.youtube.com/embed/RvYMCj92QX4"}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem', position: 'sticky', bottom: '0' }}>
                                <Button
                                    variant="containedFilled"
                                    sx={{
                                        width: '20%',
                                        mb: '1.5rem',
                                        zIndex: 1500
                                    }}
                                    onClick={handleOpenContratarModal}
                                >
                                    Contratar
                                </Button>
                            </div>
                        </Grid2>
                    </Grid2>
                </ContainerBodyModal>
            </ModalBase>

            {/* Modal para contratação */}
            <ModalBase
                open={isContratarModalOpen}
                onClose={handleCloseContratarModal}
                size="sm"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                headerModal={{
                    title: 'Contratar',
                }}
            >
                <Grid container direction="column" alignItems="center" sx={{ p: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                        Quantas corridas você faz por mês?
                    </Typography>
                    <TextField
                        select
                        size="small"
                        label="Corridas por mês"
                        value={corridasPorMes}
                        onChange={handleCorridasChange}
                        sx={{ width: '100%', mb: 2 }}
                    >
                        <MenuItem value="">Selecione...</MenuItem>
                        <MenuItem value={500}>Até 500 corridas</MenuItem>
                        <MenuItem value={1000}>Até 1000 corridas</MenuItem>
                        <MenuItem value={1500}>Até 1500 corridas</MenuItem>
                        <MenuItem value={2000}>Até 2000 corridas</MenuItem>
                        <MenuItem value={3000}>Até 3000 corridas</MenuItem>
                        <MenuItem value={4000}>Até 4000 corridas</MenuItem>
                        <MenuItem value={5000}>Até 5000 corridas</MenuItem>
                        <MenuItem value={8000}>Até 8000 corridas</MenuItem>
                        <MenuItem value={10000}>Até 10000 corridas</MenuItem>
                    </TextField>
                    <Button
                        variant="containedFilled"
                        sx={{ width: 'fit-content' }}
                        disabled={!corridasPorMes}
                        onClick={handleConfirmContratar}
                    >
                        Confirmar
                    </Button>
                </Grid>
            </ModalBase>

            {/* Modal para abrir o iframe */}
            <ModalBase
                open={isIframeModalOpen}
                onClose={handleCloseIframeModal}
                size="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <IconButtonFechar onClick={handleCloseIframeModal} sx={{ position: 'absolute', top: 12, left: 12 }} />
                <ContainerBodyModal sx={{ height: '90vh' }}>
                    <iframe
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                        }}
                        src={`https://assinar.pickngo.com.br/trocarPlano/${corridasPorMes}/${planoID}/${usuarioID}/${xtoken}`}
                        title="PicknGo"
                    />
                </ContainerBodyModal>
            </ModalBase>
        </>
    );
};

export default ModalPickngo;
