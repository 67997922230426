import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AlterarDataDTO,
  CriarVagaDTO,
  DesassociarEntregadorDTO,
  EscalaDTO,
  ExcluirVagaDTO,
} from "../../dtos/EscalasDTO";
import { DefaultAsyncState } from ".";
import { TipoVeiculoDTO } from "src/dtos/TipoVeiculosDTO";

interface EscalasState {
  listaEscalas: DefaultAsyncState<EscalaDTO | null>;
  iniciarJornada: DefaultAsyncState<any | null>;
  finalizarJornada: DefaultAsyncState<any | null>;
  excluirJornada: DefaultAsyncState<ExcluirVagaDTO | null>;
  listarVeiculos: DefaultAsyncState<TipoVeiculoDTO | null>;
  listarEntregadores: DefaultAsyncState<any | null>;
  criarVaga: DefaultAsyncState<CriarVagaDTO | null>;
  desassociarEntregador: DefaultAsyncState<DesassociarEntregadorDTO | null>;
  alterarData: DefaultAsyncState<AlterarDataDTO | null>;
}

const initialState: EscalasState = {
  listaEscalas: {
    loading: false,
    data: null,
    error: null,
  },
  iniciarJornada: {
    loading: false,
    data: null,
    error: null,
  },
  finalizarJornada: {
    loading: false,
    data: null,
    error: null,
  },
  excluirJornada: {
    loading: false,
    data: null,
    error: null,
  },
  listarVeiculos: {
    loading: false,
    data: null,
    error: null,
  },
  listarEntregadores: {
    loading: false,
    data: null,
    error: null,
  },
  criarVaga: {
    loading: false,
    data: null,
    error: null,
  },
  desassociarEntregador: {
    loading: false,
    data: null,
    error: null,
  },
  alterarData: {
    data: null,
    error: null,
    loading: false,
  },
};

export const escalasSlice = createSlice({
  name: "escalas",
  initialState: initialState,
  reducers: {
    escalasStart: (state, action) => {
      state.listaEscalas.loading = true;
      state.listaEscalas.data = null;
      state.listaEscalas.error = null;
    },
    escalasSuccess: (state, action) => {
      state.listaEscalas.loading = false;
      state.listaEscalas.data = action.payload;
      state.listaEscalas.error = null;
    },
    escalasFailure: (state, action) => {
      state.listaEscalas.loading = false;
      state.listaEscalas.data = null;
      state.listaEscalas.error = action.payload;
    },
    iniciarJornadaStart: (state, action) => {
      state.iniciarJornada.loading = true;
      state.iniciarJornada.data = null;
      state.iniciarJornada.error = null;
    },
    iniciarJornadaSuccess: (state, action) => {
      state.iniciarJornada.loading = false;
      state.iniciarJornada.data = action.payload;
      state.iniciarJornada.error = null;
    },
    iniciarJornadaFailure: (state, action) => {
      state.iniciarJornada.loading = false;
      state.iniciarJornada.data = null;
      state.iniciarJornada.error = action.payload;
    },
    finalizarJornadaStart: (state, action) => {
      state.finalizarJornada.loading = true;
      state.finalizarJornada.data = null;
      state.finalizarJornada.error = null;
    },
    finalizarJornadaSuccess: (state, action) => {
      state.finalizarJornada.loading = false;
      state.finalizarJornada.data = action.payload;
      state.finalizarJornada.error = null;
    },
    finalizarJornadaFailure: (state, action) => {
      state.finalizarJornada.loading = false;
      state.finalizarJornada.data = null;
      state.finalizarJornada.error = action.payload;
    },
    excluirJornadaStart: (state, action) => {
      state.excluirJornada.loading = true;
      state.excluirJornada.data = null;
      state.excluirJornada.error = null;
    },
    excluirJornadaSuccess: (state, action) => {
      state.excluirJornada.loading = false;
      state.excluirJornada.data = action.payload;
      state.excluirJornada.error = null;
    },
    excluirJornadaFailure: (state, action) => {
      state.excluirJornada.loading = false;
      state.excluirJornada.data = null;
      state.excluirJornada.error = action.payload;
    },
    excluirJornadaClear: (state) => {
      state.excluirJornada = { ...initialState.excluirJornada };
    },
    limparEscalas: (state) => {
      state.listaEscalas.data = null;
      state.iniciarJornada.data = null;
      state.finalizarJornada.data = null;
      state.excluirJornada.data = null;
    },
    listarVeiculosStart: (state) => {
      state.listarVeiculos.loading = true;
      state.listarVeiculos.data = null;
      state.listarVeiculos.error = null;
    },
    listarVeiculosSuccess: (state, action) => {
      state.listarVeiculos.loading = false;
      state.listarVeiculos.data = action.payload;
      state.listarVeiculos.error = null;
    },
    listarVeiculosFailure: (state, action) => {
      state.listarVeiculos.loading = false;
      state.listarVeiculos.data = null;
      state.listarVeiculos.error = action.payload;
    },
    listarEntregadoresStart: (state, action) => {
      state.listarEntregadores.loading = true;
      state.listarEntregadores.data = null;
      state.listarEntregadores.error = null;
    },
    listarEntregadoresSuccess: (state, action) => {
      state.listarEntregadores.loading = false;
      state.listarEntregadores.data = action.payload;
      state.listarEntregadores.error = null;
    },
    listarEntregadoresFailure: (state, action) => {
      state.listarEntregadores.loading = false;
      state.listarEntregadores.data = null;
      state.listarEntregadores.error = action.payload;
    },
    criarVagaStart: (state, action) => {
      state.criarVaga.loading = true;
      state.criarVaga.data = null;
      state.criarVaga.error = null;
    },
    criarVagaSuccess: (state, action) => {
      state.criarVaga.loading = false;
      state.criarVaga.data = action.payload;
      state.criarVaga.error = null;
    },
    criarVagaFailure: (state, action) => {
      state.criarVaga.loading = false;
      state.criarVaga.data = null;
      state.criarVaga.error = action.payload;
    },
    limparCriarVaga: (state) => {
      state.criarVaga = { ...initialState.criarVaga };
    },
    desassociarEntregadorStart: (
      state,
      action: PayloadAction<{ pedidoID: string }>
    ) => {
      state.desassociarEntregador.loading = true;
      state.desassociarEntregador.data = null;
      state.desassociarEntregador.error = null;
    },
    desassociarEntregadorFailure: (state, action) => {
      state.desassociarEntregador.loading = false;
      state.desassociarEntregador.data = null;
      state.desassociarEntregador.error = action.payload;
    },
    desassociarEntregadorSuccess: (state, action) => {
      state.desassociarEntregador.loading = false;
      state.desassociarEntregador.data = action.payload;
      state.desassociarEntregador.error = null;
    },
    desassociarEntregadorClear: (state) => {
      state.desassociarEntregador = { ...initialState.desassociarEntregador };
    },
    alterarDataStart: (
      state,
      action: PayloadAction<{
        pedidoID: string;
        dataAgendamento: string;
        dataConcluido: string;
      }>
    ) => {
      state.alterarData.loading = true;
      state.alterarData.data = null;
      state.alterarData.error = null;
    },
    alterarDataSuccess: (state, action) => {
      state.alterarData.loading = false;
      state.alterarData.data = action.payload;
      state.alterarData.error = null;
    },
    alterarDataFailure: (state, action) => {
      state.alterarData.loading = false;
      state.alterarData.data = null;
      state.alterarData.error = action.payload;
    },
    alterarDataClear: (state) => {
      state.alterarData = { ...initialState.alterarData };
    },
  },
});

export const {
  escalasStart,
  escalasSuccess,
  escalasFailure,
  iniciarJornadaStart,
  iniciarJornadaSuccess,
  iniciarJornadaFailure,
  finalizarJornadaStart,
  finalizarJornadaSuccess,
  finalizarJornadaFailure,
  excluirJornadaStart,
  excluirJornadaSuccess,
  excluirJornadaFailure,
  excluirJornadaClear,
  limparEscalas,
  listarVeiculosStart,
  listarVeiculosSuccess,
  listarVeiculosFailure,
  listarEntregadoresStart,
  listarEntregadoresSuccess,
  listarEntregadoresFailure,
  criarVagaStart,
  criarVagaSuccess,
  criarVagaFailure,
  limparCriarVaga,
  desassociarEntregadorStart,
  desassociarEntregadorSuccess,
  desassociarEntregadorFailure,
  desassociarEntregadorClear,
  alterarDataStart,
  alterarDataSuccess,
  alterarDataFailure,
  alterarDataClear,
} = escalasSlice.actions;
export default escalasSlice;
