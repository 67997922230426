import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { listarTipoDeVeiculoStart } from 'src/store/reducers/config';

const TipoDeVeiculo = ({hookForm}: any) => {
    const tiposVeiculo = useSelector((state: RootState) => state.config?.tiposDeVeiculos?.dados?.lista);
    const dispatch = useDispatch();
    useEffect(() => {
        if(!tiposVeiculo) {
            dispatch(listarTipoDeVeiculoStart({pagina: 1, paginacao: -1, filtros: ''}));
        }
    })
    const { register, handleSubmit, watch, setValue, formState: { errors }, rules } = hookForm;

    return (
        <FormControl size="small" fullWidth>
            <InputLabel id="tipoVeiculoID-label">
                {'Tipo de veículo'.charAt(0).toUpperCase() + 'Tipo de veículo'.slice(1)}
            </InputLabel>
            <Select
                label={'Tipo de veículo'.charAt(0).toUpperCase() + 'Tipo de veículo'.slice(1)}
                labelId="tipoVeiculoID-label"
                id="tipoVeiculoID"
                {...register('tipoVeiculoID', rules)}
                value={watch('tipoVeiculoID') || ''}
                onChange={(event) => setValue('tipoVeiculoID', event.target.value)}
                defaultValue={''}
                fullWidth
            >
                <MenuItem value="">
                    Selecione...
                </MenuItem>
                {Array.isArray(tiposVeiculo) && tiposVeiculo.map((option: any) => {
                    return (
                        <MenuItem key={option.tipoVeiculoID} value={option.tipoVeiculoID}>
                            {option.nome}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default TipoDeVeiculo;
