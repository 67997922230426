import { AddCircleOutline, Delete, Edit } from "@mui/icons-material";
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { listarPorRaioStart, excluirTabelaPorRaioStart, limparTabelaPorRaio } from "src/store/reducers/tabelaPagamento";
import ModalEditarValoresPorRaio from "./ModalEditarValoresPorRaio";
import { dinheiro } from "src/ds/common";
import { ModalOpcoes, TableNavigation, TableRowLoading, TableRowNotFound, useResponseSnackbar } from "src/ds";

const EditarValoresPorRaio = ({ dados }: any) => {
    const dispatch = useDispatch();
    const hookForm = useForm({
        defaultValues: {
            paginacao: 20,
            pagina: 1,
            filtros: "tabelaValorID.toString() =" + `"` + dados?.tabelaValorID + `"`,
        },
    });

    const itens = useSelector((state: any) => state.tabelaPagamento.porRaio.data?.dados);
    const loading = useSelector((state: any) => state.tabelaPagamento.porRaio.loading);
    const itensDados = itens?.lista;
    const [openEditar, setOpenEditar] = useState(false);
    const [openDeletar, setOpenDeletar] = useState(false);
    const [item, setItem] = useState<any>();

    const listarPorRaio = () => {
        const valores = hookForm.getValues();
        dispatch(listarPorRaioStart({
            pagina: valores.pagina,
            paginacao: valores.paginacao,
            filtros: valores.filtros,
        }));
    }

    const response = useSelector((state: any) => state.tabelaPagamento.salvarTabelaPorRaio);

    useEffect(() => {
        listarPorRaio();
    }, [response?.data, dados?.tabelaValorID]);

    useResponseSnackbar([{ response, clearAction: () => dispatch(limparTabelaPorRaio()) }]);

    const handleEditar = (item: any) => {
        setOpenEditar(true);
        setItem(item);
    };

    const handleDeletar = (item: any) => {
        setItem(item);
        setOpenDeletar(true);
    };

    const confirmarDeletar = () => {
        dispatch(excluirTabelaPorRaioStart(item?.tabelaValorKmID));
        setOpenDeletar(false);
    };
    useEffect(() => {
        const valores = hookForm.getValues();
        dispatch(listarPorRaioStart({
            pagina: valores.pagina,
            paginacao: valores.paginacao,
            filtros: valores.filtros,
        }));
    }, [hookForm.watch('pagina'), hookForm.watch('paginacao')]);
    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Button
                variant="containedFilled"
                sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                onClick={() => {
                    setOpenEditar(true);
                    setItem(dados);
                }}
                startIcon={<AddCircleOutline />}
            >
                Adicionar novo valor
            </Button>

            <Stack sx={{ flex: 1, gap: 2 }}>
                {itens?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={itens.indiceInicial}
                        indiceFinal={itens.indiceFinal}
                        indiceTotal={itens.total}
                        ultimaPagina={itens?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Km Inicial</TableCell>
                            <TableCell align="left">Km Final</TableCell>
                            <TableCell align="left">Valor</TableCell>
                            <TableCell align="left">Taxa de Retorno</TableCell>
                            <TableCell align="right">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowLoading />
                        ) : itensDados?.length > 0 ? (
                            itensDados.map((item: any) => (
                                <TableRow key={item.id}>
                                    <TableCell align="left">{item.kmInicial}</TableCell>
                                    <TableCell align="left">{item.kmFinal}</TableCell>
                                    <TableCell align="left">{dinheiro(item.valor)}</TableCell>
                                    <TableCell align="left">{dinheiro(item.taxaRetorno)}</TableCell>
                                    <TableCell align="right">
                                        <IconButton aria-label="edit" size="small" onClick={() => handleEditar(item)}>
                                            <Edit color="warning" />
                                        </IconButton>
                                        <IconButton aria-label="delete" size="small" onClick={() => handleDeletar(item)}>
                                            <Delete color="error" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRowNotFound text="Nenhum resultado encontrado." fezRequisicao={true} />
                        )}
                    </TableBody>
                </Table>
            </Stack>

            <ModalEditarValoresPorRaio open={openEditar} handleClose={() => setOpenEditar(false)} dados={item} />

            <ModalOpcoes
                descricao={(
                    <Typography>
                        Deseja excluir o valor de <strong>{item?.kmInicial}</strong>km a <strong>{item?.kmFinal}</strong>km?
                    </Typography>
                )}
                acao="Excluir"
                open={openDeletar}
                onClose={() => setOpenDeletar(false)}
                opcoes={[
                    { label: 'Cancelar', action: () => setOpenDeletar(false) },
                    { label: 'Excluir', action: confirmarDeletar },
                ]}
            />
        </Stack>
    );
};

export default EditarValoresPorRaio;
