import { TableCell, TableRow, Typography } from '@mui/material'

const TableRowNotFound = ({ text = 'Nenhum resultado encontrado.', fezRequisicao, colSpan }: { text?: string, fezRequisicao?: boolean, colSpan?: number }) => {
    return (
        <TableRow>
            <TableCell colSpan={colSpan ?? 12}>
                <Typography variant="body2" align="center">
                    <Typography variant="notFound">
                        {(fezRequisicao ?? true) ? text : 'Filtre para buscar resultados'}
                    </Typography>
                </Typography>
            </TableCell>
        </TableRow>
    )
}

export default TableRowNotFound