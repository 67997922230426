import { Box, Typography, Tabs, Tab, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listaEntregadoresStart, usuarioPaginaStart } from 'src/store/reducers/entregador';
import { ArrowBackIos } from '@mui/icons-material';
import { RootState } from 'src/store/reducers';
import DadosBasicos from './DadosBasicos';
import Historico from './Historico';
import { paginaClientesStart } from 'src/store/reducers/cliente';


interface TabConfig {
    label: string;
    content: React.ReactNode;
}

const TabsClientes: React.FC = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(paginaClientesStart({ id: id }))
    }, []);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const tabsConfig: TabConfig[] = [
        { label: 'Dados básicos', content: <DadosBasicos id={id} /> },
        { label: 'Histórico de pedidos', content: <Historico /> },
    ];

    const navigate = useNavigate();


    const atual = useSelector((state: RootState) => state.clientes?.pagina?.data?.modelo)
    const theme = useTheme();

    return (
        <Box p={2} sx={{ backgroundColor: 'background.paper' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <ArrowBackIos
                    sx={{ cursor: 'pointer', mr: 1 }}
                    onClick={() => navigate("/EmpresaCliente/Index")}
                />
                <Typography variant="h6">
                    <>
                        Editar {atual?.nome} <span style={{ fontSize: '0.6em', color: theme.palette.grey[400] }}>({id})</span>
                    </>
                </Typography>
            </Box>

            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Tabs de Edição"
                variant="scrollable"
                scrollButtons="auto"
            >
                {tabsConfig.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.label}
                    />
                ))}
            </Tabs>

            <Box sx={{ mt: 3 }}>
                {tabsConfig[activeTab].content}
            </Box>
        </Box>
    );
};

export default TabsClientes;
